import { Car, KingTable, Motorbike, ShoppingBag } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { PickUpType, ServiceCode } from 'types/api.enum'

import { StyledContainer } from './style'

interface PickUpTypeStampProps {
    pickUpType: PickUpType
    color?: string
    isFilter?: boolean
}

export const PickUpTypeStamp = ({ pickUpType, color = colors.blackLight, isFilter = false }: PickUpTypeStampProps) => {
    const { t } = useTranslation()
    let isCC = false

    let Icon
    switch (pickUpType) {
        case PickUpType.PARKING:
        case PickUpType.DRIVE:
            Icon = Car
            break
        case PickUpType.DELIVERY_IN_APP:
            Icon = Motorbike
            break
        case PickUpType.TABLE_ORDER:
            Icon = KingTable
            break
        default:
            isCC = true
            Icon = ShoppingBag
    }

    return (
        <StyledContainer>
            <Icon size={18} color={color} style={{ marginRight: 10 }} />
            <Text color={color}>
                {isCC && isFilter
                    ? t(`services.servicesCode.${ServiceCode.CLICK_AND_COLLECT}`)
                    : t(`orders.pickUpType.${pickUpType}`)}
            </Text>
        </StyledContainer>
    )
}
