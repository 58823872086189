import { services } from 'httpServices/services'
import { BirthdayBookingListItemDTO } from 'types/api'
import { AppDispatch } from 'types/redux'
import { sortByDate } from 'utils/util'

import { setBirthdays } from './BirthdaySlice'

const reservationDateTime = ({ reservationDate, reservationHour }: BirthdayBookingListItemDTO) =>
    `${reservationDate} ${reservationHour}`

export const fetchBirthdays = (frNumber: string) => async (dispatch: AppDispatch) => {
    const {
        data: { content, totalPages = 0 },
    } = await services.birthdays.fetchList(frNumber)
    const birthdays = content && [...content]
    for (let page = 1; page < totalPages; page++) {
        const {
            data: { content },
        } = await services.birthdays.fetchList(frNumber, page)

        if (content) {
            birthdays?.push(...content)
        }
    }
    birthdays?.sort((a, b) => sortByDate(reservationDateTime(a), reservationDateTime(b)))
    dispatch(setBirthdays(birthdays ?? []))
    return birthdays
}

export const fetchBirthdayById = (frNumber: string, id: string) => async () => {
    const response = await services.birthdays.fetch(frNumber, id)
    const birthday = response?.data
    return birthday
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateBirthdayById = (frNumber: string, id: string, data: any) => async () => {
    const response = await services.birthdays.updateBirthday(frNumber, id, data)
    return response.data
}
