import styled, { css } from 'styled-components'

import { colors } from './colors'
import { fonts } from './fonts'

interface TextProps {
    font?: string
    color?: string
    upperCase?: boolean
    bold?: boolean
    size?: string
}

const base = css<TextProps>`
    font-family: ${(props) => (props.font ? props.font : fonts.flameSans)};
    color: ${(props) => (props.color ? props.color : colors.defaultGrey)};
    ${(props) => props.upperCase && 'text-transform: uppercase'};
    ${(props) => props.bold && `font-family: ${fonts.flame}`};
`

const titleBase = css`
    ${base};
    font-family: ${(props) => (props.font ? props.font : fonts.flame)};
    font-weight: normal;
    margin: 0;
`

export const BigText = styled.span`
    ${base};
    font-size: 20px;
    line-height: 28px;
`

export const Text = styled.span`
    ${base};
    font-size: ${(props) => props.size || '14px'};
    line-height: 22px;
`

export const SmallText = styled.span`
    ${base};
    font-size: 12px;
    line-height: 20px;
`

export const TinyText = styled.span`
    ${base};
    font-size: 10px;
    line-height: 12px;
`

export const BigTitle = styled.h1`
    ${titleBase};
    font-size: 64px;
    line-height: 64px;
`

export const H1 = styled.h1`
    ${titleBase};
    font-size: 32px;
    line-height: 32px;
`

export const H2 = styled.h2`
    ${titleBase};
    font-size: 24px;
    line-height: 31px;
`

export const H3 = styled.h3`
    ${titleBase};
    font-size: 20px;
    line-height: 22px;
`

export const H4 = styled.h4`
    ${titleBase};
    font-size: 16px;
    line-height: 24px;
`
