import { services } from 'httpServices/services'
import {
    PickupInfoForm,
    PreparationTimeForm,
    RestaurantPermissionDTO,
    RestaurantServiceForm,
    ServiceDayParameterForm,
    ServiceOpeningsForm,
    SwapParkingTimeForm,
    SwapParkingToDriveForm,
} from 'types/api'
import { ServiceCode } from 'types/api.enum'
import { AppDispatch } from 'types/redux'
import { formatTimeFromString } from 'utils/util'

import { setBirthdaysSchedule, setStorePreparationTime } from './restaurantSlice'

export const fetchRestaurant = (frNumber: string) => async () => {
    const response = await services.restaurant.fetch(frNumber)
    return response.data.restaurant
}

export const setPreparationTime = (form: PreparationTimeForm) => async (dispatch: AppDispatch) => {
    const response = await services.restaurant.setPreparationTime(form)
    if (response?.status === 200) {
        dispatch(setStorePreparationTime(form))
    }
    return response.data
}

export const changePickupInfo = (form: PickupInfoForm) => async () => {
    const response = await services.restaurant.changePickupInfo(form)
    return response.data.restaurant
}

export const toggleSwap = (form: SwapParkingToDriveForm) => async () => {
    const response = await services.restaurant.toggleSwap(form)
    return response.data.restaurant
}

export const swapDriveParkingTime = (form: SwapParkingTimeForm) => async () => {
    const response = await services.restaurant.swapDriveParkingTime(form)
    return response.data.restaurant
}

export const toggleService = (form: RestaurantServiceForm) => async () => {
    const response = await services.restaurant.toggleService(form)
    return response.data
}

export const changeRestaurantRight = (form: RestaurantPermissionDTO) => async () => {
    const response = await services.parameters.changeRestaurantRight(form)
    return response.data
}

export const fetchIntervalParkingTime = (frNumber: string) => async () => {
    const response = await services.restaurant.fetch(frNumber)
    return {
        startTime: response.data.startIntervalParkingTime,
        endTime: response.data.endIntervalParkingTime,
    }
}

export const getServiceSchedule =
    (frNumber: string, serviceCode: ServiceCode, enabled?: boolean) => async (dispatch: AppDispatch) => {
        const response = await services.restaurant.getServiceSchedule({
            frNumber,
            serviceCode,
            enabled: enabled ?? false,
        })
        const tmpDays = response.data.days.map((day: ServiceDayParameterForm) => {
            return {
                ...day,
                start: (day.start && formatTimeFromString(day.start)) || null,
                end: (day.end && formatTimeFromString(day.end)) || null,
            }
        })

        if (response) {
            dispatch(setBirthdaysSchedule({ ...response.data, days: tmpDays } as ServiceOpeningsForm))
        }

        return { ...response.data, days: tmpDays } as ServiceOpeningsForm
    }

export const postServiceSchedule = (schedule: ServiceOpeningsForm) => async (dispatch: AppDispatch) => {
    const tmpDays = schedule.days.map((day: ServiceDayParameterForm) => {
        return { ...day, start: `${day.start}:00`, end: `${day.end}:00` }
    })
    const response = await services.restaurant.postServiceSchedule({
        ...schedule,
        days: tmpDays,
    })
    if (response?.status === 200) {
        dispatch(setBirthdaysSchedule(schedule))
    }
    return response.data
}
