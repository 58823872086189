import React from 'react'
import styled from 'styled-components'

import { colors } from '../../styles/colors'
import { Text } from '../../styles/typography'

export type GridColumnTextProps = {
    value: string
    color: string
}

export const GridColumnText = ({ value, color }: GridColumnTextProps) => (
    <StyledColumn>
        <Text color={color}>{value}</Text>
    </StyledColumn>
)

export const StyledColumn = styled.td`
    padding: 10px;
    border: 1px solid ${colors.greyLight};
    border-left: none;
    border-right: none;
`
