import 'react-datepicker/dist/react-datepicker.css'

import { FormControl, TextField } from '@mui/material'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H2, H4 } from 'styles/typography'
import { BirthdayStatus } from 'types/api.enum'

import { SPACING } from '../../styles/tokens'

interface StatusContainerProps {
    status: BirthdayStatus
}

type ValidationButtonProps = {
    disabled?: boolean
}

export const StyledStatusContainer = styled.div<StatusContainerProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ status }) => {
        switch (status) {
            case BirthdayStatus.REFUSED:
                return colors.redLight
            case BirthdayStatus.CONFIRMED:
                return colors.greenLight
            case BirthdayStatus.STANDBY:
                return colors.orangeLight
            default:
                return colors.greyLight
        }
    }};
    padding: 8px 20px;
    gap: 12px;
    border-radius: 5px;
    &:hover {
        cursor: pointer;
    }
`

export const StyledModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 62px;
    gap: 20px;
    border-radius: 20px;
    background-color: ${colors.white};
`

export const StyledCommentModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 62px;
    gap: 20px;
    border-radius: 20px;
    background-color: ${colors.white};
`

export const StyledDropdownChoiceContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
    &:hover {
        cursor: pointer;
    }
`

export const StyledPhoneNumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid ${colors.greyLight};
    padding: 5px;
    border-radius: 5px;
    gap: 5px;
`

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
`

export const StyledCommentButtonsContainer = styled(ButtonsContainer)`
    align-self: center;
`

const Button = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    gap: 10px;
    border-radius: 250px;
    min-width: 70%;
    &:hover {
        cursor: pointer;
    }
`

export const StyledIconsContainer = styled.div`
    display: flex;
    position: relative;
    height: 200px;
`

export const StyledExclamationContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(65%, -25%);
`

export const StyledPhoneContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-100%, 0%);
`

export const StyledValidationButton = styled(Button)<ValidationButtonProps>`
    background-color: ${(props) => (props.disabled ? colors.redLight : colors.red)};
`

export const StyledCancellationButton = styled(Button)`
    border: 2px solid ${colors.brown};
`

export const StyledH2 = styled(H2)`
    color: ${colors.brown};
`

export const StyledH4 = styled(H4)`
    color: ${colors.brown};
`

export const StyledWhiteH4 = styled(H4)`
    color: ${colors.white};
`

export const StyledDot = styled.span`
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background: ${(props) => props.color};
`

export const StyledTextField = styled(TextField)`
    width: 100%;
    & .MuiInputBase-input {
        font-family: ${fonts.flameSans};
    }
`

export const StyledDatePicker = styled(DatePicker)`
    font-size: 30px;
    background-color: #f0f0f0;
`

export const StyledForm = styled(FormControl)`
    width: 30%;
`

export const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 100%;
    justify-content: center;
    gap: 5px;
`

export const StyledFilterButton = styled.div<{ selected: boolean }>`
    background: ${(props) => (props.selected ? colors.brown : colors.beige)};
    border-radius: 8px;
    margin-left: 8px;
    max-width: 100px;
    padding: 6px 10px;
    &:first-child {
        margin-left: 0;
    }
    &:hover {
        cursor: pointer;
    }
`

export const StyledFiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXXS};
    padding: 0 0 ${SPACING.S};
`
