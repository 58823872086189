import { Crown2, Phone } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { H4, Text } from 'styles/typography'

type DisplayPhoneNumberProps = {
    clientVip?: boolean
    clientPhone: string | null
    className?: string
}

export const DisplayPhoneNumber = ({ clientVip, clientPhone, className }: DisplayPhoneNumberProps) => {
    const { t } = useTranslation()

    return (
        <>
            {(clientVip || clientPhone) && (
                <StyledContainer className={className}>
                    {clientVip && (
                        <StyledVipMessageContainer>
                            <Crown2 width={24} />
                            <StyledH4 color={colors.brown} font={fonts.flameSans}>
                                {t('complaints.beginProcessModal.vip')}
                            </StyledH4>
                        </StyledVipMessageContainer>
                    )}
                    {clientPhone && (
                        <StyledTextContainer>
                            <Text color={colors.brown}>{t('complaints.displayPhoneNumber')}</Text>
                            <StyledPhoneContainer>
                                <Phone size={16} color={colors.brown} />
                                <Text color={colors.brown}>{clientPhone}</Text>
                            </StyledPhoneContainer>
                        </StyledTextContainer>
                    )}
                </StyledContainer>
            )}
        </>
    )
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${SPACING.S} ${SPACING.L};
    border-left: ${BORDER.WIDTH.M} solid ${colors.orange};
    background-color: ${colors.orangeLight};
    border-top-right-radius: ${SPACING.XS};
    border-bottom-right-radius: ${SPACING.XS};
    gap: ${SPACING.XXS};
`

const StyledTextContainer = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    gap: ${SPACING.XXS};
`

const StyledPhoneContainer = styled.div`
    padding: ${SPACING.XXXS} ${SPACING.XXS};
    border: ${BORDER.WIDTH.XS} solid ${colors.brown};
    border-radius: ${BORDER.RADIUS.S};
    display: flex;
    gap: ${SPACING.XXS};
    align-items: center;
`

const StyledVipMessageContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${SPACING.XXS};
    background: ${colors.orangeLight};
`

const StyledH4 = styled(H4)`
    font-size: 15px;
`
