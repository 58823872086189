import { Phone, Restaurant } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { H2, Text } from 'styles/typography'
import { RestaurantDTO } from 'types/api'
import { ServiceCode } from 'types/api.enum'

import { StyledHeaderCard, StyledInfo, StyledStamp, StyledTitle } from './style'

interface ServiceRestaurantProps {
    restaurant: RestaurantDTO
}

export const ServiceRestaurant = ({ restaurant }: ServiceRestaurantProps) => {
    const { t } = useTranslation()
    return (
        <>
            <StyledHeaderCard>
                <StyledTitle>
                    <H2 color={colors.brown}>{t(`services.servicesCode.${ServiceCode.RESTAURANT}`)}</H2>
                    <Text>{t('services.subtitleId', { id: restaurant.frNumber })}</Text>
                </StyledTitle>
                <StyledInfo>
                    <StyledStamp>
                        <Text>{restaurant.name}</Text>
                        <Restaurant color={colors.brown} size={20} />
                    </StyledStamp>
                    <StyledStamp>
                        <Text>{restaurant.phoneNumber}</Text>
                        <Phone color={colors.brown} size={20} />
                    </StyledStamp>
                </StyledInfo>
            </StyledHeaderCard>
        </>
    )
}
