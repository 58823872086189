import { createSelector } from '@reduxjs/toolkit'
import { ComplaintLightDTO } from 'types/api'
import { ComplaintStatus, ComplaintType } from 'types/api.enum'
import { RootState } from 'types/redux'
import { StatusType } from 'utils/enums'
import { getComplaintStatusType } from 'utils/util'

export const selectComplaintsByStatus = createSelector(
    [(state: RootState) => state.complaint.complaints, (state: RootState, statusType: string) => statusType],
    (complaints: ComplaintLightDTO[] = [], statusType: string) => {
        const complaintsData = [...complaints].filter((complaint) => statusType === getComplaintStatusType(complaint))
        return complaintsData
    },
)

export const selectComplaintsWithMultipleFilters = createSelector(
    [
        (state: RootState) => state,
        (
            state: RootState,
            filter: {
                status?: StatusType
                stateStatus: ComplaintStatus[]
                complaintType?: ComplaintType
            },
        ) => filter,
    ],
    (state: RootState, filter) => {
        const { status, stateStatus, complaintType } = filter
        let filteredComplaints =
            status === StatusType.FINISHED
                ? selectComplaintsByStatus(state, StatusType.FINISHED)
                : selectComplaintsByStatus(state, StatusType.IN_PROGRESS)

        if (stateStatus) {
            filteredComplaints = getComplaintByStatusState(filteredComplaints, stateStatus)
        }

        if (complaintType) {
            filteredComplaints = getComplaintByComplaintType(filteredComplaints, complaintType)
        }

        return filteredComplaints
    },
)

export const selectNumberOfComplaintsbyComplaintStatus = createSelector(
    [(state: RootState) => state.complaint.complaints, (state: RootState, statusType: string) => statusType],
    (complaints: ComplaintLightDTO[] = [], statusType: string) => {
        const complaintsData = [...complaints].filter((complaint) => statusType === complaint.status)
        return complaintsData.length
    },
)

export const selectNumberOfComplaintsbyMultipleComplaintStatus = createSelector(
    [(state: RootState) => state, (state: RootState, statusType: string[]) => statusType],
    (state: RootState = [], statusType: string[]) => {
        let totalLenght = 0
        statusType.map((status) => {
            const complaintLenght = selectNumberOfComplaintsbyComplaintStatus(state, status)
            totalLenght = totalLenght + complaintLenght
            return totalLenght
        })
        return totalLenght
    },
)

const getComplaintByStatusState = (complaints: ComplaintLightDTO[], status: ComplaintStatus[]) => {
    if (status.includes(ComplaintStatus.ALL)) {
        return complaints
    }
    return complaints.filter((complaint) => complaint.status && status.includes(complaint.status))
}

const getComplaintByComplaintType = (complaints: ComplaintLightDTO[], complaintType: ComplaintType) => {
    return complaints.filter((complaint) => complaint.complaintType === complaintType)
}
