import { DrawerButton } from 'components/drawer-button/DrawerButton'
import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { DrawerItem } from 'types/drawer-item'

import { StyledDrawer, StyledDrawerButtonContainer, StyledDrawerContainer } from './style'

interface DrawerProps {
    children: ReactNode
    buttons: Array<DrawerItem>
    drawerItemSelectedIndex: number
    onDrawerItemClick: (value: DrawerItem, index: number) => void
    [key: string]: unknown
}

export const Drawer = ({ children, buttons, drawerItemSelectedIndex, onDrawerItemClick }: DrawerProps) => {
    const { t } = useTranslation()

    const getIndex = (index: number, type: string) => {
        const size = type === 'bottom' ? buttons.filter((button) => !button.bottom).length : 0
        return index + size
    }

    const renderButtons = (type: string) => {
        const filteredButtons = buttons.filter((button) => (type === 'bottom' ? button.bottom : !button.bottom))
        return filteredButtons.map((button, index) => {
            return (
                <ShouldDisplay permission={button.permission} key={index}>
                    <StyledDrawerButtonContainer place={type === 'bottom'}>
                        <DrawerButton
                            icon={button.icon}
                            text={t(button.titleKey)}
                            selected={getIndex(index, type) === drawerItemSelectedIndex}
                            onClick={() => onDrawerItemClick(button, getIndex(index, type))}
                        />
                    </StyledDrawerButtonContainer>
                </ShouldDisplay>
            )
        })
    }

    return (
        <StyledDrawerContainer>
            <StyledDrawer>
                {renderButtons('top')}
                {renderButtons('bottom')}
            </StyledDrawer>
            {children}
        </StyledDrawerContainer>
    )
}
