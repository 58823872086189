import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'

export const StyledContainer = styled.div`
    background-color: ${colors.white};
    border-radius: ${BORDER.RADIUS.XS};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
`

export const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${SPACING.L};
    margin-bottom: ${SPACING.L};
`

export const StyledSectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${SPACING.L};
`

export const StyledButtonsContainer = styled.div`
    display: flex;
    gap: ${SPACING.XS};
    max-width: 584px;
    width: 100%;
`

export const StyledInputsContainer = styled.div`
    display: flex;
    width: 100%;
    padding: ${SPACING.L};
    gap: ${SPACING.L};
    box-sizing: border-box;
`

export const StyledButtonContainer = styled.div`
    --botmind-button-padding: 65px;
    display: flex;
    justify-content: flex-end;
    padding: ${SPACING.L} var(--botmind-button-padding) ${SPACING.L} ${SPACING.L};
    box-sizing: border-box;
`
