import { TextField } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'

import { SendIcon } from '../../../assets/icons'
import { Notif } from '../../../components/notif/Notif'
import { ShouldDisplay } from '../../../components/should-display/ShouldDisplay'
import { useAppDispatch } from '../../../hooks/useRedux'
import { updateComplaint } from '../../../redux/complaint/complaintSlice'
import { answerComplaint } from '../../../redux/complaint/thunks'
import { colors } from '../../../styles/colors'
import { fonts } from '../../../styles/fonts'
import { BORDER } from '../../../styles/tokens'
import { ComplaintDTO } from '../../../types/api'
import { ComplaintStatus, Permissions } from '../../../types/api.enum'
import { NotyfType, SenderType } from '../../../utils/enums'
import { StyledButton, StyledInput, StyledInputContainer } from '../style'

type ExchangeDiscussionComplaintProps = {
    complaint: ComplaintDTO
}

const waitingStatus = [
    ComplaintStatus.WAITING_RESTAURANT,
    ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER,
    ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER,
    ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER,
    ComplaintStatus.WAITING_CUSTOMER,
]

const closedComplaintStatus: string[] = [
    ComplaintStatus.REFUNDED,
    ComplaintStatus.CLOSED,
    ComplaintStatus.TO_REFUND,
    ComplaintStatus.REFUND_VALIDATED,
]

export const ExchangeDiscussionComplaint = ({ complaint }: ExchangeDiscussionComplaintProps) => {
    const { t } = useTranslation()
    const [inputHasFocus, setInputHasFocus] = useState(false)
    const [restaurantMessage, setRestaurantMessage] = useState<string | undefined>()
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()

    const { id = '', status, createdDate, messages, refundType, complaintNumber, price } = complaint

    const isRefunded = !!(status && closedComplaintStatus.includes(status))
    const isClosed = isRefunded && !!refundType

    const isWaitingUpdateStatus = !!(status && waitingStatus.includes(status))

    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (restaurantMessage) {
                await dispatch(answerComplaint(id, restaurantMessage))
                Notif({
                    type: NotyfType.SUCCESS,
                    text: t('complaints.successNotifMessage'),
                })
                const updatedMessages = [
                    ...(messages || []),
                    {
                        message: restaurantMessage,
                        sender: SenderType.RESTAURANT,
                        date: new Date().toISOString(),
                    },
                ]
                const updatedComplaint = {
                    ...complaint,
                    complaintNumber: complaintNumber || 0,
                    createdDate: createdDate || '',
                    price: price || 0,
                    messages: updatedMessages,
                    status: ComplaintStatus.WAITING_CUSTOMER,
                }
                dispatch(updateComplaint(updatedComplaint))
            }
        } catch (_error) {
            Notif({ type: NotyfType.ERROR, text: t('error.sendMessage') })
        } finally {
            setRestaurantMessage('')
            setLoading(false)
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value
        setRestaurantMessage(value)
    }

    return (
        <ShouldDisplay permission={Permissions.MANAGE_COMPLAINT} condition={!isClosed && isWaitingUpdateStatus}>
            <StyledInputContainer>
                <StyledInput hasFocus={inputHasFocus}>
                    <TextField
                        value={restaurantMessage}
                        placeholder={t(`complaints.inputPlaceholder`)}
                        multiline
                        fullWidth
                        maxRows={5}
                        onChange={(e) => handleChange(e)}
                        onFocus={() => setInputHasFocus(true)}
                        onBlur={() => setInputHasFocus(false)}
                        InputProps={{
                            style: {
                                padding: 0,
                                borderWidth: BORDER.WIDTH.S,
                                borderRadius: BORDER.RADIUS.S,
                                fontFamily: fonts.flameSans,
                                color: colors.brown,
                                opacity: 1,
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: 16,
                                fontFamily: fonts.flameSans,
                            },
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                    />
                    <div>
                        {loading ? (
                            <StyledButton
                                icon={<ClipLoader size={16} color={colors.brown} loading={true} />}
                                color={colors.white}
                            />
                        ) : (
                            <StyledButton icon={<SendIcon size={25} />} onClick={handleSubmit} color={colors.white} />
                        )}
                    </div>
                </StyledInput>
            </StyledInputContainer>
        </ShouldDisplay>
    )
}
