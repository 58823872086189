import { services } from 'httpServices/services'
import { QueryOptions, useQuery } from 'react-query'
import { FEATURE_TOGGLE_CODE } from 'utils/enums'

export const FEATURE_TOGGLE_KEYS = {
    FETCH_ALL: 'featureToggle',
} as const

export const useFeatureToggleData = (options?: QueryOptions<FEATURE_TOGGLE_CODE[]>) => {
    return useQuery({
        queryKey: [FEATURE_TOGGLE_KEYS.FETCH_ALL],
        queryFn: () => services.featureToggle.getFeatureToggle(),
        ...options,
    })
}
