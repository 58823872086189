import { client } from './client'
import { endpoints } from './endpoints'

export const services = {
    restaurant: {
        fetchList: async () => {
            const response = await client.get(endpoints.restaurant.list)
            return response.data
        },
    },
}
