import { Pencil } from 'assets/icons'
import { Button } from 'components/button/Button'
import { Dropdown } from 'components/dropdown/Dropdown'
import { Modal } from 'components/modal/Modal'
import { Notif } from 'components/notif/Notif'
import { useAppDispatch } from 'hooks/useRedux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { patchComplaint } from 'redux/complaint/thunks'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { H2 } from 'styles/typography'
import { DropDownItem } from 'types/dropdown-item'
import { NotyfType } from 'utils/enums'

import { StyledInfoLineText } from '../style'
import { InfoText } from '.'
import { StyledH4Info } from './style'

type IssueMap = {
    [k: string]: string
}

type SubIssueMap<IM extends IssueMap> = {
    [key in keyof IM]: {
        [k: string]: string
    }
}

const translateIssueMap = <IM extends IssueMap>(issueMap: IM, t: (k: string) => string) =>
    Object.fromEntries(Object.entries(issueMap).map(([k, v]) => [k, t(v)])) as IM

const translateSubIssueMap = <IM extends IssueMap, SIM extends SubIssueMap<IM>>(
    subIssueMap: SIM,
    t: (k: string) => string,
) =>
    Object.fromEntries(
        Object.entries(subIssueMap).map(([k1, v1]) => [
            k1,
            Object.fromEntries(Object.entries(v1).map(([k, v]) => [k, t(v)])),
        ]),
    ) as SIM

type IssueAndSubIssueEditorProps<IM extends IssueMap, SIM extends SubIssueMap<IM>> = {
    complaintId: string
    issueMap: IM
    subIssueMap: SIM
    issue: string
    subIssue: string
    editable: boolean
    updateRequest: () => void
}

export const IssueAndSubIssueEditor = <IM extends IssueMap, SIM extends SubIssueMap<IM>>(
    props: IssueAndSubIssueEditorProps<IM, SIM>,
) => {
    const { issue, subIssue, editable, issueMap, subIssueMap } = props
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)

    const issues = translateIssueMap(issueMap, t)
    const subIssues = translateSubIssueMap(subIssueMap, t)

    const issueKey = Object.keys(issues).find((key) => issues[key as keyof typeof issues] === issue) as
        | undefined
        | keyof typeof subIssues

    const isValid =
        Object.values(issues).find((el) => el === issue) &&
        !!issueKey &&
        Object.values(subIssues[issueKey]).find((el) => el === subIssue)

    const toggleModal = () => {
        setIsOpen((x) => !x)
    }

    return (
        <>
            {isOpen && <EditorModal handleClose={toggleModal} {...props} />}
            <StyledPencilContainer>
                <InfoText title={t('complaints.issue')} text={issue} first />
                {!!isValid && editable && <StyledPencil onClick={toggleModal} color={colors.brown} />}
            </StyledPencilContainer>
            <StyledPencilContainer>
                <InfoText title={t('complaints.subIssue')} text={subIssue} />
                {!!isValid && editable && <StyledPencil onClick={toggleModal} color={colors.brown} />}
            </StyledPencilContainer>
        </>
    )
}

type EditorModalProps<IM extends IssueMap, SIM extends SubIssueMap<IM>> = {
    handleClose: () => void
} & IssueAndSubIssueEditorProps<IM, SIM>

const EditorModal = <IM extends IssueMap, SIM extends SubIssueMap<IM>>({
    handleClose,
    updateRequest,
    complaintId,
    issueMap,
    subIssueMap,
    issue,
    subIssue,
}: EditorModalProps<IM, SIM>) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const issues = translateIssueMap(issueMap, t)
    const subIssues = translateSubIssueMap(subIssueMap, t)
    const [selectedIssue, setSelectedIssue] = useState(issue)
    const [selectedSubIssue, setSelectedSubIssue] = useState(subIssue)
    const issueDropdownData = Object.values(issues).map((value) => ({ label: value, value }))
    const issueKey = Object.keys(issues).find((key) => issues[key as keyof typeof issues] === selectedIssue) as
        | undefined
        | keyof typeof issues
    const subIssueDropdownData = issueKey
        ? Object.values(subIssues[issueKey]).map((value) => ({ label: value, value }))
        : []
    const isValid =
        issueDropdownData.find(({ value }) => value === selectedIssue) &&
        subIssueDropdownData.find(({ value }) => value === selectedSubIssue)

    const issueChangeHandler = (item: DropDownItem) => {
        setSelectedIssue(item.value)
    }

    const subIssueChangeHandler = (item: DropDownItem) => {
        setSelectedSubIssue(item.value)
    }

    const confirmClickHandler = async () => {
        handleClose()
        try {
            await dispatch(
                patchComplaint(complaintId, {
                    issue: selectedIssue,
                    subIssue: selectedSubIssue,
                }),
            )
        } catch (_error) {
            Notif({ type: NotyfType.ERROR, text: t('error.patchComplaint') })
        } finally {
            updateRequest()
        }
    }

    return (
        <Modal handleClose={handleClose}>
            <StyledModalContent>
                <StyledModalTitle color={colors.brown}>{t('complaints.editIssue')}</StyledModalTitle>
                <StyledInfoLineText first>
                    <StyledH4Info color={colors.brown}>{t('complaints.issue')}</StyledH4Info>
                    <StyledDropdownContainer>
                        <Dropdown
                            list={issueDropdownData}
                            initIndex={issueDropdownData?.findIndex((item) => item.value === selectedIssue)}
                            onChange={issueChangeHandler}
                            dialogVariant
                        />
                    </StyledDropdownContainer>
                </StyledInfoLineText>
                <StyledInfoLineText>
                    <StyledH4Info color={colors.brown}>{t('complaints.subIssue')}</StyledH4Info>
                    <StyledDropdownContainer>
                        <Dropdown
                            disabled={!subIssueDropdownData.length}
                            key={
                                // force remount on issueKey change
                                typeof issueKey === 'string' ? issueKey : 'UNKNOWN'
                            }
                            list={subIssueDropdownData}
                            initIndex={subIssueDropdownData?.findIndex((item) => item.value === selectedSubIssue)}
                            onChange={subIssueChangeHandler}
                            dialogVariant
                        />
                    </StyledDropdownContainer>
                </StyledInfoLineText>
                <StyledButtonsContainer>
                    <StyledButtonContainer>
                        <Button
                            text={t('component.button.cancel')}
                            color={colors.black}
                            outlined
                            onClick={handleClose}
                        />
                    </StyledButtonContainer>
                    <StyledButtonContainer>
                        <Button
                            text={t('component.button.confirm')}
                            color={colors.red}
                            onClick={confirmClickHandler}
                            disabled={!isValid}
                        />
                    </StyledButtonContainer>
                </StyledButtonsContainer>
            </StyledModalContent>
        </Modal>
    )
}

const StyledDropdownContainer = styled.div`
    margin-left: 10px;
`

const StyledPencilContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledPencil = styled(Pencil)`
    cursor: pointer;
`

const StyledModalContent = styled.div`
    width: 35vw;
    padding: 32px;
    background-color: ${colors.white};
    border-radius: 8px;
`

const StyledModalTitle = styled(H2)`
    margin-bottom: 24px;
`

const StyledButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: fit-content;
`

const StyledButtonContainer = styled.div`
    margin: 30px 5px 10px;
`
