import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const CommentBlock = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M16.45 12.209a.85.85 0 00-1.037.612 5.95 5.95 0 01-5.764 4.488H4.897l.544-.535a.85.85 0 000-1.199 5.95 5.95 0 012.72-9.98.852.852 0 00-.425-1.65A7.65 7.65 0 003.7 16.137l-1.454 1.42a.85.85 0 00-.179.926.85.85 0 00.783.527h6.8a7.65 7.65 0 007.413-5.738.85.85 0 00-.612-1.062zm1.309-8.96a4.25 4.25 0 10-6.019 6.003A4.25 4.25 0 0017.76 3.25zm-5.56 3.009a2.55 2.55 0 012.55-2.55c.38.002.755.09 1.097.255l-3.4 3.4a2.55 2.55 0 01-.247-1.105zm4.353 1.802a2.618 2.618 0 01-2.89.485l3.4-3.4c.162.348.243.729.238 1.113a2.55 2.55 0 01-.748 1.802z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
