import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledContainer = styled.div`
    width: 35vw;
    padding: 32px;
    background-color: ${colors.white};
    border-radius: 8px;
`

export const StyledTimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    width: 50%;
    padding: 40px 0 10px;
`

export const StyledButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: fit-content;
`

export const StyledButtonContainer = styled.div`
    margin: 30px 5px 10px;
`
