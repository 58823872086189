import { InputAdornment, OutlinedTextFieldProps, TextField } from '@mui/material'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'

export enum TextFieldVariant {
    OUTLINED = 'outlined',
    STANDARD = 'standard',
    FILLED = 'filled',
}

interface Props extends OutlinedTextFieldProps {
    label: string
    value: string | number | undefined
    width: string
    multiline?: boolean
    icon?: JSX.Element | null
    disabled?: boolean
    type?: string
    min?: number
    max?: number
    variant: 'outlined'
    hiddenDisable?: boolean
}

export const OutlinedTextField = (props: Props) => {
    const {
        label,
        value,
        width,
        multiline = false,
        icon = null,
        disabled = false,
        type = 'text',
        min,
        max,
        variant = 'outlined',
        hiddenDisable = false,
        ...rest
    } = props

    return (
        <TextField
            {...rest}
            variant={variant}
            disabled={disabled}
            label={label}
            value={value}
            multiline={multiline}
            rows={multiline ? 4 : 1}
            type={type}
            InputProps={{
                style: {
                    borderWidth: '2px',
                    borderRadius: '8px',
                    fontFamily: fonts.flameSans,
                },
                startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : undefined,
                inputProps: {
                    max: max,
                    min: min,
                },
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontFamily: fonts.flameSans,
                },
            }}
            sx={{
                width: width,
                ...(hiddenDisable && {
                    '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: colors.brown,
                    },
                }),
            }}
        />
    )
}
