import { CombinedTextField } from 'screens/complaint-detail/components/complaint-answer-process/CombinedTextField'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'

export const StyledModalContainer = styled.div`
    width: 45vw;
    padding: 2rem;
    background-color: ${colors.white};
    border-radius: 0.5rem;
`

export const StyledModalHeader = styled.div`
    margin-bottom: 1rem;
`

export const StyledModalBody = styled.div`
    margin-bottom: 1rem;
`

export const StyledModalFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`

export const StyledCombinedTextField = styled(CombinedTextField)`
    margin-top: ${SPACING.S};
`
