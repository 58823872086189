import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux'
import { fetchFinishedOrderList, fetchPendingOrderList } from '../../../../redux/order/thunks'
import { selectPermissionsByFrNumber } from '../../../../redux/user/selectors'
import { SPACING } from '../../../../styles/tokens'
import { Permissions } from '../../../../types/api.enum'
import { hasPermission } from '../../../../utils/util'
import { ComplaintsSection } from './complaints-section/ComplaintsSection'
import { OrdersSection } from './orders-section/OrdersSection'
import { ServiceSection } from './services-section/ServiceSection'
import { UsefulLinksSection } from './useful-links-section/UsefulLinksSection'

export const Dashboard = () => {
    const dispatch = useAppDispatch()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true)
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))

    const dispatchOrder = useCallback(() => {
        if (hasPermission(userPermissions, Permissions.SHOW_ORDER)) {
            dispatch(fetchPendingOrderList({ frNumber: restaurantFrNumber }))
                .catch((error: unknown) => setError(error))
                .finally(() => setIsLoading(false))
            dispatch(fetchFinishedOrderList({ frNumber: restaurantFrNumber }))
                .catch((error: unknown) => setError(error))
                .finally(() => setIsLoading(false))
        }
    }, [dispatch, restaurantFrNumber, userPermissions])

    useEffect(() => {
        if (restaurantFrNumber) {
            dispatchOrder()
        }
    }, [restaurantFrNumber, dispatchOrder])

    return (
        <StyledMainContainer>
            <OrdersSection isOrderDataLoading={isLoading} isOrderDataError={!!error} handleRefresh={dispatchOrder} />
            <ServiceSection isOrderDataLoading={isLoading} isOrderDataError={!!error} handleRefresh={dispatchOrder} />
            <ComplaintsSection />
            <UsefulLinksSection />
        </StyledMainContainer>
    )
}

const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.M};
`
