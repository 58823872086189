import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Chevron = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M19.467 5.524a1.802 1.802 0 00-2.541 0l-6.471 6.38-6.38-6.38a1.802 1.802 0 10-2.542 2.56l7.642 7.642a1.802 1.802 0 002.56 0l7.732-7.642a1.802 1.802 0 000-2.56z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
