import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CheckBoldIcon } from '../../../../../../assets/icons'
import { Cross2 } from '../../../../../../assets/icons/Cross2'
import { CircledIcon } from '../../../../../../components/circled-icon/CircledIcon'
import { colors } from '../../../../../../styles/colors'
import { BORDER, SPACING } from '../../../../../../styles/tokens'
import { H4, Text } from '../../../../../../styles/typography'
import { ServiceCardProps } from '../../../../../../types/card'

type CardProps = {
    card: ServiceCardProps
}

export const ServiceCard = ({ card }: CardProps) => {
    const { t } = useTranslation()

    return (
        <StyledServiceCardContainer>
            <CircledIcon icon={card.icon} size={SPACING.S} color={card.isActive ? colors.green : colors.red} />
            <H4 color={colors.brown}>{card.label}</H4>
            {card.isActive ? (
                <StyledServiceCardElement>
                    <CheckBoldIcon color={colors.green} size={13} />
                    <Text color={colors.brown}>{t('page.home.dashboard.services.active')}</Text>
                </StyledServiceCardElement>
            ) : (
                <StyledServiceCardElement>
                    <Cross2 color={colors.red} size={8} />
                    <Text color={colors.brown}>{t('page.home.dashboard.services.inactive')}</Text>
                </StyledServiceCardElement>
            )}
            <StyledHorizontalLine />
            {card.schedule ? (
                <Text color={colors.brown}>
                    {t('page.home.dashboard.services.schedule', {
                        opening: card.schedule.opening,
                        closing: card.schedule.closing,
                    })}
                </Text>
            ) : (
                <Text color={colors.brown}>{t('page.home.dashboard.services.noSchedule')}</Text>
            )}
        </StyledServiceCardContainer>
    )
}

const StyledServiceCardElement = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXS};
    align-items: center;
`

const StyledServiceCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.XXS};
    padding: ${SPACING.XS};
    align-items: center;
`

const StyledHorizontalLine = styled.div`
    background: linear-gradient(to right, ${colors.grey}, ${colors.greyExtraLight}, ${colors.grey});
    height: ${BORDER.WIDTH.XS};
    width: 110px;
`
