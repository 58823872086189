import { Button } from 'components/button/Button'
import { Dropdown } from 'components/dropdown/Dropdown'
import { Modal } from 'components/modal/Modal'
import { Switch } from 'components/switch/Switch'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H2, H4, Text } from 'styles/typography'
import { ServiceDayParameterForm, ServiceOpeningsForm } from 'types/api'
import { DropDownItem } from 'types/dropdown-item'
import { getDopdownTimeListFromTimeLimit } from 'utils/util'

import {
    StyledButtonContainer,
    StyledButtonsContainer,
    StyledContainer,
    StyledDayContainer,
    StyledErrorText,
    StyledLineContainer,
    StyledScheduleContainer,
    StyledTimeContainer,
} from './style'

interface TimeDaysModalProps {
    handleClose: () => void
    title: string
    subTitle: string
    schedule: ServiceOpeningsForm
    onValidate: (value: ServiceOpeningsForm) => void
}

export const TimeDaysModal = ({ handleClose, title, subTitle, onValidate, schedule }: TimeDaysModalProps) => {
    const { t } = useTranslation()
    const [tmpSchedule, setTmpSchedule] = useState<ServiceOpeningsForm>(schedule)
    const [error, setError] = useState<boolean>(false)
    const listHours = getDopdownTimeListFromTimeLimit({ startTime: '00:00', endTime: '23:55' }, 15)

    const onClick = useCallback(() => {
        const isNotCorrect = tmpSchedule.days.some((day) => day.active && (!day.start || !day.end))
        if (isNotCorrect) {
            setError(true)
        } else {
            onValidate(tmpSchedule)
            handleClose()
        }
    }, [handleClose, onValidate, tmpSchedule])

    const onChangeActive = useCallback(
        (selectedDay: ServiceDayParameterForm) => {
            const tmp = tmpSchedule.days.map((day) =>
                day.day === selectedDay.day ? { ...day, active: !day.active } : day,
            )
            setTmpSchedule({ ...tmpSchedule, days: tmp })
        },
        [tmpSchedule],
    )

    const onChangeSchedule = useCallback(
        (selectedDay: ServiceDayParameterForm, item: DropDownItem, type: string) => {
            const newValue = item.value
            const tmp = tmpSchedule.days.map((day) =>
                day.day === selectedDay.day ? { ...day, [type]: newValue } : day,
            )
            setTmpSchedule({ ...tmpSchedule, days: tmp })
        },
        [tmpSchedule],
    )

    return (
        <Modal handleClose={handleClose}>
            <StyledContainer>
                <H2 color={colors.brown}>{title}</H2>
                <H4 font={fonts.flameSans}>{subTitle}</H4>
                <StyledScheduleContainer>
                    {tmpSchedule.days.map((day) => (
                        <StyledLineContainer key={day.day}>
                            <StyledDayContainer>
                                <Switch onChange={() => onChangeActive(day)} checked={!!day.active} />
                                <Text>{t(`days.${day.day}`)}</Text>
                            </StyledDayContainer>
                            <StyledTimeContainer>
                                <Dropdown
                                    list={listHours}
                                    initIndex={listHours.findIndex((hour) => hour.value === day.start)}
                                    onChange={(start) => onChangeSchedule(day, start, 'start')}
                                    isInsideModal
                                    disabled={!day.active}
                                />
                                -
                                <Dropdown
                                    list={listHours}
                                    initIndex={listHours.findIndex((hour) => hour.value === day.end)}
                                    onChange={(end) => onChangeSchedule(day, end, 'end')}
                                    isInsideModal
                                    disabled={!day.active}
                                />
                            </StyledTimeContainer>
                        </StyledLineContainer>
                    ))}
                </StyledScheduleContainer>
                <StyledErrorText visible={error}>{t('error.birthdayActivationDay')}</StyledErrorText>
                <StyledButtonsContainer>
                    <StyledButtonContainer>
                        <Button
                            text={t('component.button.cancel')}
                            color={colors.black}
                            outlined
                            onClick={handleClose}
                        />
                    </StyledButtonContainer>
                    <StyledButtonContainer>
                        <Button text={t('component.button.validate')} color={colors.red} onClick={onClick} />
                    </StyledButtonContainer>
                </StyledButtonsContainer>
            </StyledContainer>
        </Modal>
    )
}
