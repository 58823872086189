import React from 'react'
import styled from 'styled-components'

import { ShouldDisplay } from '../../../../../components/should-display/ShouldDisplay'
import { useAppSelector } from '../../../../../hooks/useRedux'
import { selectPermissionsByFrNumber } from '../../../../../redux/user/selectors'
import { SPACING } from '../../../../../styles/tokens'
import { Permissions } from '../../../../../types/api.enum'
import { hasPermission } from '../../../../../utils/util'
import { ServicesCardContainer } from './components/ServicesCardContainer'
import { SmallCardsContainer } from './components/SmallCardsContainer'

type SericeSectionProps = {
    isOrderDataLoading: boolean
    isOrderDataError: boolean
    handleRefresh: () => void
}

export const ServiceSection = ({ isOrderDataLoading, isOrderDataError, handleRefresh }: SericeSectionProps) => {
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    const ordersPermissions = hasPermission(userPermissions, Permissions.SHOW_ORDER)
    const servicePermissions = hasPermission(userPermissions, Permissions.SHOW_SERVICE)

    return (
        <StyledMainServiceContainer>
            <ShouldDisplay condition={servicePermissions}>
                <ServicesCardContainer />
            </ShouldDisplay>
            <ShouldDisplay condition={ordersPermissions}>
                <SmallCardsContainer
                    isOrderDataLoading={isOrderDataLoading}
                    isOrderDataError={isOrderDataError}
                    handleRefresh={handleRefresh}
                />
            </ShouldDisplay>
        </StyledMainServiceContainer>
    )
}

const StyledMainServiceContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.M};
    padding: 0 ${SPACING.XXS};
    box-sizing: border-box;
`
