import mustacheGuy from 'assets/images/mustache-guy.webp'
import { Button } from 'components/button/Button'
import { useAppDispatch } from 'hooks/useRedux'
import { useTranslation } from 'react-i18next'
import { getPermissions } from 'redux/user/thunks'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { H2, Text } from 'styles/typography'

import { BORDER, SPACING } from '../../styles/tokens'

interface ErrorBlockProps {
    titleKey?: string
    descriptionKey?: string
    errorCode?: number
    image?: string
    disableImage?: boolean
    handleClick?: () => void
    labelButton?: string
}

export const ErrorBlock = ({
    errorCode,
    titleKey = 'error.unexpected',
    descriptionKey = 'page.home.error',
    image = mustacheGuy,
    disableImage = false,
    handleClick,
    labelButton,
}: ErrorBlockProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const refreshPage = async () => {
        // @ts-ignore
        await dispatch(getPermissions())
        window.location.reload()
    }

    return (
        <StyledContainer>
            <StyledBlockContainer>
                {!disableImage && <StyledImage alt="error" src={image} />}
                <StyledTextContainer>
                    <StyledTitle color={colors.brownDark}>{t(titleKey)}</StyledTitle>
                    <Text>{t(descriptionKey)}</Text>
                    {!!errorCode && (
                        <StyledErrorCode>
                            <Text color={colors.brownDark}>{t('error.code', { value: errorCode })}</Text>
                        </StyledErrorCode>
                    )}
                </StyledTextContainer>
                <Button
                    text={labelButton ? labelButton : t('component.button.refresh')}
                    color={colors.red}
                    onClick={handleClick ? handleClick : refreshPage}
                />
            </StyledBlockContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const StyledBlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    background-color: ${colors.white};
    border-radius: ${BORDER.RADIUS.L};
    padding: ${SPACING.XXL};
`

const StyledImage = styled.img`
    width: 200px;
    max-width: 50%;
`

const StyledTitle = styled(H2)`
    margin: ${SPACING.L} 0 ${SPACING.XXS};
`

const StyledTextContainer = styled.div`
    max-width: 500px;
    margin-bottom: ${SPACING.L};
    text-align: center;
`

const StyledErrorCode = styled.div`
    margin-top: ${SPACING.S};
`
