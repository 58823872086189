import { createSelector } from '@reduxjs/toolkit'
import { RestaurantPermissionDTO } from 'types/api'
import { RootState } from 'types/redux'

export const selectPermissionsByFrNumber = createSelector(
    [(state: RootState) => state.user.permissions, (state: RootState) => state.restaurant.selectedFrNumber],
    (permissions: RestaurantPermissionDTO[], restaurantFrNumber: string) => {
        if (!permissions) return []
        const permission = permissions.find((permission) => restaurantFrNumber === permission.frNumber)
        if (permission?.rights) {
            const finalPermissionsArray = permission.rights.filter((right) => right.active)
            return finalPermissionsArray.map((right) => right.code)
        }
        return []
    },
)
