import { CommentInfo, CrossCircle } from 'assets/icons'
import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { H1, Text } from 'styles/typography'

import { StyledContainer, StyledMessageContainer } from './style'

interface OrderModalErrorProps {
    handleClose: () => void
    order: { orderNumber: string; id: string }
    onValidate: (value: string) => void
}

export const OrderModalError = ({ handleClose, onValidate, order }: OrderModalErrorProps) => {
    const { t } = useTranslation()

    return (
        <Modal handleClose={handleClose} closeIconColor={colors.white}>
            <StyledContainer>
                <CrossCircle size={160} />
                <H1 color={colors.white}>{t('error.newOrderErrorNumber', { orderNumber: order.orderNumber })}</H1>
                <StyledMessageContainer>
                    <CommentInfo color={colors.white} size={30} />
                    <Text color={colors.white}>{t('error.newOrderError')}</Text>
                </StyledMessageContainer>
                <Button
                    text={t('component.button.seeError')}
                    color={colors.white}
                    textColor={colors.brown}
                    onClick={onValidate}
                />
            </StyledContainer>
        </Modal>
    )
}
