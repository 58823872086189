import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const DriveIcon = (props: IconProps) => {
    const { color = colors.brown, size = 21, ...otherProps } = props

    return (
        <svg width={size} height={size} viewBox="0 0 41 40" {...otherProps}>
            <g clipPath="url(#clip0_8377_69386)">
                <path
                    d="M12.3736 30.2152C12.1732 30.0882 11.9587 29.9866 11.733 29.9132C10.1186 29.3798 7.97371 30.1729 7.1242 31.7533C6.23237 33.4128 6.51459 34.7337 6.90689 35.5493C7.55601 36.8955 8.97561 37.8015 10.3585 37.7478C11.1996 37.7168 12.3793 37.3273 13.2457 35.6481C13.3699 35.4082 13.4715 35.1457 13.5477 34.872C13.9936 33.2717 13.8186 31.1324 12.368 30.2096L12.3736 30.2152Z"
                    fill={color}
                />
                <path
                    d="M33.7692 30.2038C33.5689 30.074 33.3515 29.9724 33.1258 29.899C31.503 29.36 29.3439 30.1502 28.4859 31.7363C27.5405 33.4833 27.8227 34.8578 28.2235 35.7016C28.8698 37.0507 30.2329 37.9256 31.6215 37.8776C32.4879 37.8494 33.7156 37.443 34.6328 35.6762C34.7599 35.4335 34.8615 35.171 34.9405 34.9001C35.3949 33.2914 35.2227 31.138 33.7692 30.2067V30.2038Z"
                    fill={color}
                />
                <path
                    d="M39.332 26.422C38.1861 24.1134 36.2868 23.9018 34.7091 23.0269C32.8944 22.0221 31.503 21.215 28.7541 18.7116C26.8971 17.0211 23.547 14.989 20.4905 14.6278C14.4255 13.9109 9.2212 16.18 4.79024 20.5799C2.00749 23.3429 0.136324 27.2038 0.125034 31.2058C0.122212 32.1512 0.291548 33.2237 1.04227 33.7515C1.46843 34.0478 2.00184 34.1127 2.51267 34.1522C3.87583 34.2567 3.52587 34.2538 4.55035 34.1438C4.50802 33.0403 4.77613 31.9057 5.36316 30.8078C6.34813 28.979 8.47895 27.7485 10.6634 27.7485C11.2532 27.7485 11.8233 27.8388 12.3596 28.0138C12.7434 28.138 13.1103 28.313 13.449 28.5275C15.3173 29.7156 15.9072 31.9255 15.7237 33.9773C18.3146 33.8898 23.2338 33.8023 25.8585 33.7148C25.9121 32.7383 26.2 31.7448 26.7249 30.7796C27.7155 28.9536 29.8492 27.7259 32.0364 27.7259C32.6347 27.7259 33.2133 27.8162 33.7552 27.9969C34.1334 28.121 34.5031 28.296 34.8446 28.5133C36.4928 29.5717 37.1419 31.4175 37.1504 33.2463C38.2793 33.4128 40.1561 33.2943 40.1222 32.5605C40.1137 32.3996 40.2577 28.2847 39.332 26.4192V26.422ZM14.575 22.1774C14.4904 23.4107 10.5844 22.9648 9.08573 22.9027C7.58711 22.8406 7.31335 21.8754 8.6511 20.2018C9.98886 18.5282 12.7349 17.3851 14.0755 17.4444C14.9927 17.4867 14.7613 19.4793 14.575 22.1774ZM26.0278 22.6571C15.9608 23.0099 16.8752 23.0889 16.8413 20.7578C16.8046 18.2459 16.5309 16.8433 18.0239 16.753C20.2055 16.6203 23.4229 17.007 25.596 18.9035C27.772 20.8029 28.6582 22.5668 26.0278 22.66V22.6571Z"
                    fill={color}
                />
                <path
                    d="M2.87954 7.14881C3.44117 7.21655 4.0536 7.36895 4.65757 7.55522C5.77519 7.90236 6.88434 8.27207 7.99913 8.62204C9.09417 8.96353 9.48929 8.72082 9.6925 7.58344C9.92957 6.24569 10.1356 4.90229 10.4009 3.56735C10.5081 3.03112 10.4799 2.2804 11.1206 2.13646C11.6624 2.01511 12.0406 2.59649 12.3962 2.98597C13.2316 3.89756 13.909 4.93051 14.6089 5.94653C14.8601 6.3106 15.1084 6.67749 15.385 7.02181C15.8761 7.63424 15.989 7.65964 16.6325 7.21655C17.7529 6.44324 18.7238 5.48367 19.7652 4.61159C19.9599 4.45072 20.1405 4.26728 20.3522 4.13181C20.6344 3.95401 20.959 3.9032 21.244 4.11205C21.5206 4.31808 21.5996 4.62853 21.5178 4.96438C20.9675 7.24195 20.8094 9.31914 20.2986 11.6024C20.1123 12.4321 19.9514 12.7256 19.116 12.703C14.9673 12.5901 12.7659 13.1602 9.13369 15.0991C8.54665 15.4124 7.94269 15.7539 7.38106 16.1039C6.72629 16.5103 6.46382 16.5837 6.14208 15.8809C5.016 13.434 3.62744 11.1282 2.43644 8.71799C2.31226 8.46399 2.21066 8.19023 2.15704 7.91365C2.05544 7.37459 2.26146 7.14881 2.87954 7.14599V7.14881Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_8377_69386">
                    <rect width={size} height={size} fill="white" transform="translate(0.125)" />
                </clipPath>
            </defs>
        </svg>
    )
}
