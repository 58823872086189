import { DateRangePicker } from 'components/date-range-picker/DateRangePicker'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { ComplaintStatus } from 'types/api.enum'
import { DateRange } from 'types/date-range'
import { FilterType } from 'utils/enums'
import { formatDate, getDateRangeFromString, stringToDateDDMMYYYY } from 'utils/util'

import { FilterButton } from './FilterButton'
import { StyledFilterButtons, StyledFilterContainer, StyledFilterDate } from './style'

interface Props {
    buttons: Array<ComplaintStatus[]>
    onChangeFilter: (label: string, value?: string) => void
    isFinished: boolean
}

export const ComplaintFilters = ({ buttons, onChangeFilter, isFinished }: Props) => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)

    const onClickFilter = useCallback(
        (values: ComplaintStatus[]) => {
            const formatType = values.join('-')
            onChangeFilter(isFinished ? FilterType.STATE_FINISHED : FilterType.STATE_IN_PROGRESS, formatType)
        },
        [isFinished, onChangeFilter],
    )

    const onClickDateRange = useCallback(
        (value: (Date | null)[]) => {
            setStartDate(value[0])
            setEndDate(value[1])
            if (value[0] && value[1]) {
                const formatType = formatDate(value[0]) + '_' + formatDate(value[1])
                setIsCalendarOpen(!isCalendarOpen)
                onChangeFilter(FilterType.DATE_RANGE, formatType.replaceAll('/', '-'))
            } else if (!value[0] && !value[1]) {
                onChangeFilter(FilterType.DATE_RANGE)
            }
        },
        [isCalendarOpen, onChangeFilter],
    )

    useEffect(() => {
        const getStatusFilterFromUrl = () => {
            const filter = searchParams.get(isFinished ? FilterType.STATE_FINISHED : FilterType.STATE_IN_PROGRESS)
            if (filter) {
                return buttons.findIndex((button) => filter === button.join('-'))
            }
            return 0
        }

        const getDateRangeFilterFromUrl = (): DateRange => {
            const filter = searchParams.get(FilterType.DATE_RANGE)
            if (filter) {
                const dates = getDateRangeFromString(filter)
                return {
                    startDate: stringToDateDDMMYYYY(dates[0]),
                    endDate: stringToDateDDMMYYYY(dates[1]),
                }
            }
            return { startDate: null, endDate: null }
        }

        setSelectedIndex(getStatusFilterFromUrl())
        if (!isCalendarOpen) {
            setStartDate(getDateRangeFilterFromUrl().startDate)
            setEndDate(getDateRangeFilterFromUrl().endDate)
        }
    }, [searchParams, buttons, isCalendarOpen, isFinished])

    return (
        <StyledFilterContainer>
            <StyledFilterButtons>
                {buttons.map((button, index) => (
                    <FilterButton
                        key={button.toString()}
                        values={button}
                        selected={index === selectedIndex}
                        onClick={onClickFilter}
                    />
                ))}
            </StyledFilterButtons>
            {isFinished && (
                <StyledFilterDate>
                    <Text color={colors.brown}>{t('filters.date')}</Text>
                    <DateRangePicker
                        dates={{ startDate, endDate }}
                        isCalendarOpen={isCalendarOpen}
                        setIsOpen={setIsCalendarOpen}
                        onChange={onClickDateRange}
                    />
                </StyledFilterDate>
            )}
        </StyledFilterContainer>
    )
}
