import { CheckIcon, QrCodeIcon, TableIcon, TerraceIcon } from 'assets/icons'
import { Button } from 'components/button/Button'
import { TextList } from 'components/list-text/TextList'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'
import { H2, Text } from 'styles/typography'

interface ConfirmationModalContentProps {
    onConfirm: () => void
    onCancel: () => void
    hasTerrace: boolean
    loading: boolean
    lowerBoundRoomTableNumber: number
    upperBoundRoomTableNumber: number
    lowerBoundTerraceTableNumber: number
    upperBoundTerraceTableNumber: number
}

export const ConfirmationModalContent = ({
    onConfirm,
    onCancel,
    hasTerrace,
    loading,
    lowerBoundRoomTableNumber,
    lowerBoundTerraceTableNumber,
    upperBoundRoomTableNumber,
    upperBoundTerraceTableNumber,
}: ConfirmationModalContentProps) => {
    const { t } = useTranslation()

    const gapRoomNumber = upperBoundRoomTableNumber - lowerBoundRoomTableNumber + 1

    const gapTerraceNumber = hasTerrace ? upperBoundTerraceTableNumber - lowerBoundTerraceTableNumber + 1 : 0
    const qrCodeNumber = (gapRoomNumber + gapTerraceNumber) * 2

    return (
        <StyledContainer>
            <H2 color={colors.brown}>{t('services.kingTable.management.confirmationModal.title')}</H2>

            <TextList
                textList={[
                    t('services.kingTable.management.confirmationModal.adviceOne'),
                    t('services.kingTable.management.confirmationModal.adviceTwo'),
                ]}
            />

            <StyledInformationsContainer>
                <StyledCard>
                    <StyledIconContainer>
                        <TableIcon color={colors.brown} />
                        <StyledText color={colors.brown}>
                            {t('services.kingTable.management.confirmationModal.roomTable')}
                        </StyledText>
                    </StyledIconContainer>
                    <Text>
                        {t('services.kingTable.management.confirmationModal.roomTableNumber', {
                            count: gapRoomNumber,
                            min: lowerBoundRoomTableNumber,
                            max: upperBoundRoomTableNumber,
                        })}
                    </Text>
                </StyledCard>

                {gapTerraceNumber >= 1 && (
                    <StyledCard>
                        <StyledIconContainer>
                            <TerraceIcon color={colors.brown} />
                            <StyledText color={colors.brown}>
                                {t('services.kingTable.management.confirmationModal.terraceTable')}
                            </StyledText>
                        </StyledIconContainer>
                        <Text>
                            {t('services.kingTable.management.confirmationModal.terraceTableNumber', {
                                count: gapTerraceNumber,
                                min: lowerBoundTerraceTableNumber,
                                max: upperBoundTerraceTableNumber,
                            })}
                        </Text>
                    </StyledCard>
                )}

                <StyledCard>
                    <StyledIconContainer>
                        <QrCodeIcon color={colors.brown} />
                        <StyledText color={colors.brown}>
                            {t('services.kingTable.management.confirmationModal.qrCode')}
                        </StyledText>
                    </StyledIconContainer>
                    <Text>
                        {t('services.kingTable.management.confirmationModal.descriptionQrCode', {
                            count: qrCodeNumber,
                        })}
                    </Text>
                </StyledCard>
            </StyledInformationsContainer>

            <StyledButtonsContainer>
                <Button
                    text={t('services.kingTable.management.confirmationModal.buttons.cancel')}
                    color={colors.brown}
                    outlined
                    onClick={onCancel}
                />

                <Button
                    text={t('services.kingTable.management.confirmationModal.buttons.confirm')}
                    color={colors.red}
                    icon={<CheckIcon color={colors.white} size={18} />}
                    onClick={onConfirm}
                    loading={loading}
                />
            </StyledButtonsContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    background-color: ${colors.white};
    border-radius: ${BORDER.RADIUS.XXXXL};
    display: flex;
    flex-direction: column;
    padding: ${SPACING.XXL};
    box-sizing: border-box;
`

const StyledInformationsContainer = styled.div`
    background-color: ${colors.grey};
    padding: ${SPACING.S};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${SPACING.XS};

    border-radius: ${BORDER.RADIUS.XS};
`

const StyledText = styled(Text)`
    margin-left: ${SPACING.XXS};
`

const StyledCard = styled.div`
    padding: ${SPACING.S};
    width: 100%;
    background-color: ${colors.white};
    box-sizing: border-box;
    border-radius: ${BORDER.RADIUS.M};
`

const StyledButtonsContainer = styled.div`
    display: flex;
    gap: ${SPACING.XS};
    align-items: center;
    margin: ${SPACING.XXXL} auto 0px auto;
`

const StyledIconContainer = styled.div`
    display: flex;
    align-items: center;
`
