import { services } from 'httpServices/services'
import { setRestaurants, setSelectedRestaurant } from 'redux/restaurant/restaurantSlice'
import { LoginTabForm, ParameterPermissionForm, TabUserResetPasswordForm } from 'types/api'
import { AppDispatch } from 'types/redux'

import { login, setPermissions, setUserInfo } from './userSlice'

export const getUserToken = (form: LoginTabForm) => async (dispatch: AppDispatch) => {
    const response = await services.user.login(form)
    if (response?.headers?.authorization) {
        dispatch(login(response.headers.authorization))
    }
    return response?.headers?.authorization
}

export const appInitialization = () => async (dispatch: AppDispatch) => {
    const response = await services.app.init()
    if (response && response.data) {
        const { restaurantLights, ...userInfo } = response.data
        dispatch(setUserInfo(userInfo))
        dispatch(setRestaurants(restaurantLights ?? []))
        dispatch(setSelectedRestaurant(restaurantLights?.[0]?.frNumber ?? ''))
    }
    return response.data
}

export const setUserPassword = (form: TabUserResetPasswordForm, token: string) => async () => {
    const response = await services.user.setPassword(form, token)
    return response.data
}

export const getPermissions = () => async (dispatch: AppDispatch) => {
    const response = await services.user.getPermissions()
    const data = response?.data?.restaurants
    if (data) {
        dispatch(setPermissions(data))
    }
    return data
}

export const forgottenPassword = (form: { email: string }) => async () => {
    const response = await services.user.forgottenPassword(form)
    return response.data
}

export const changePermission = (form: ParameterPermissionForm) => async () => {
    const response = await services.parameters.setPermission(form)
    return response.data
}

export const initServiceParams = (frNumber: string) => async () => {
    const response = await services.parameters.getParameters(frNumber)
    const data = response?.data?.parameterProfileInfos
    return data
}
