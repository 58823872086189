import { ArrowLeft, Crown2 } from 'assets/icons'
import { CouponIcon } from 'assets/icons/CouponIcon'
import { RestaurantManIcon } from 'assets/icons/RestaurantManIcon'
import { Button } from 'components/button/Button'
import { useCurrentComplaint } from 'hooks/useCurrentComplaint'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'
import { H2 } from 'styles/typography'
import { RefundType } from 'types/api.enum'

import { ComplaintCard } from './ComplaintCard'

type RefundChoiceModalContentProps = {
    onBackClick: () => void
    onConfirm: (refundType: RefundType) => void
}

export const RefundChoiceModalContent = ({ onBackClick, onConfirm }: RefundChoiceModalContentProps) => {
    const { t } = useTranslation()
    const complaint = useCurrentComplaint()
    const { clientKingdom } = complaint

    return (
        <StyledContent>
            <StyledFirstRow>
                <StyledButton
                    onClick={onBackClick}
                    icon={<ArrowLeft color={colors.brown} />}
                    color={colors.transparent}
                    borderColor={colors.transparent}
                />

                <StyledH2 color={colors.brown}>{t('complaints.refundChoiceModal.title')}</StyledH2>
            </StyledFirstRow>

            <ComplaintCard
                icon={<CouponIcon color={colors.yellowMelty} size={32} />}
                title={t('complaints.refundChoiceModal.coupon')}
                text={t('complaints.refundChoiceModal.couponDescription')}
                onClick={() => onConfirm(RefundType.COUPON)}
            />

            {clientKingdom && (
                <ComplaintCard
                    icon={<Crown2 color={colors.yellowMelty} size={32} />}
                    title={t('complaints.refundChoiceModal.crown')}
                    text={t('complaints.refundChoiceModal.crownDescription')}
                    onClick={() => onConfirm(RefundType.CROWN)}
                />
            )}

            <ComplaintCard
                icon={<RestaurantManIcon color={colors.brown} size={32} />}
                title={t('complaints.refundChoiceModal.restaurant')}
                text={t('complaints.refundChoiceModal.restaurantDescription')}
                onClick={() => onConfirm(RefundType.RESTAURANT_MEETING)}
            />
        </StyledContent>
    )
}

const StyledContent = styled.div`
    box-sizing: border-box;
    padding: ${SPACING.XXL};
    background-color: ${colors.white};
    border-radius: 20px;
    width: 45vw;
`

const StyledH2 = styled(H2)`
    width: 100%;
    text-align: center;
`

const StyledFirstRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXXS};
    align-items: center;
    margin-bottom: ${SPACING.XXL};
`

const StyledButton = styled(Button)`
    border: none;
    padding: 0;
`
