import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H4 } from 'styles/typography'
import { formatPrice } from 'utils/util'

type PriceLineProps = {
    text?: string
    price: number
    bold?: boolean
}

export const PriceLine = ({ text, price, bold }: PriceLineProps) => {
    return (
        <StyledTotal>
            <StyledPriceText color={colors.brown} margin bold={bold}>
                {text}
            </StyledPriceText>
            <StyledPriceText color={colors.brown} bold={bold}>
                {formatPrice(price)}
            </StyledPriceText>
        </StyledTotal>
    )
}

const StyledTotal = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 2px 0 2px auto;
    padding: 2px;
`

const StyledPriceText = styled(H4)<{ bold?: boolean; margin?: boolean }>`
    font-family: ${(props) => (props.bold ? fonts.flame : fonts.flameSans)};
    ${(props) => props.margin && 'margin-right: 35px'};
`
