import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BigTitle, H2 } from 'styles/typography'

export const StyledNumber = styled(BigTitle)`
    margin: 0;
    color: ${colors.brown};
`

export const StyledTextBelowNumber = styled(H2)`
    margin-top: 0;
    color: ${colors.brown};
`

export const StyledNumberSelector = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    width: 100%;
    max-width: 200px;
`

export const StyledNumberContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledIconContainer = styled.div<{ visible: boolean }>`
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    margin-top: 10px;
    cursor: pointer;
`
