import { StyledFamilyInfoContainer, StyledFamilyInfoText } from './style'

interface FamilyInfoContainerProps {
    text: string
    icon?: JSX.Element
}

export const FamilyInfoContainer = ({ text, icon }: FamilyInfoContainerProps) => {
    return (
        <StyledFamilyInfoContainer>
            {icon || null}
            <StyledFamilyInfoText>{text}</StyledFamilyInfoText>
        </StyledFamilyInfoContainer>
    )
}
