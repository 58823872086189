import { BackIcon } from 'assets/icons'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'

interface BackButtonProps {
    onClick: () => void
}

export const BackButton = ({ onClick }: BackButtonProps) => {
    return (
        <StyledBackButton onClick={onClick}>
            <BackIcon color={colors.orange} />
        </StyledBackButton>
    )
}

const StyledBackButton = styled.div`
    display: flex;
    border: 2px solid ${colors.greyLight};
    width: ${SPACING.L};
    max-height: ${SPACING.L};
    padding: ${SPACING.S};
    border-radius: ${BORDER.RADIUS.M};
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
    }
    margin-right: ${SPACING.L};
`
