import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING, TEXT } from 'styles/tokens'

interface RadioButtonProps {
    label: string
    name: string
    checked: boolean
    icon?: JSX.Element
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onPress: (x: any) => void
}

export const RadioButton = ({ icon, label, checked, name, onPress }: RadioButtonProps) => {
    return (
        <StyledButtonContainer checked={checked} onClick={onPress}>
            {icon}
            <StyledInputContainer>
                <label htmlFor={name}>{label}</label>
                <input type="radio" id={name} name={name} checked={checked} readOnly />
            </StyledInputContainer>
        </StyledButtonContainer>
    )
}

const StyledInputContainer = styled.div`
    padding-left: ${SPACING.XXS};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: ${fonts.flameSans};
    font-size: ${TEXT.FONT_SIZE.S};
    color: ${colors.brown};
    accent-color: ${colors.brown};
`

const StyledButtonContainer = styled.div<{ checked: boolean }>`
    border: ${BORDER.WIDTH.XS} solid ${colors.greyLight};
    padding: ${SPACING.XS} ${SPACING.S};
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 286px;
    width: 100%;
    border-radius: ${BORDER.RADIUS.M};
    box-sizing: border-box;
    border: ${BORDER.WIDTH.XS} solid ${(props) => (props.checked ? colors.brown : colors.greyLight)};
`
