import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { brown } = colors

export const PointsIcon = (props: IconProps) => {
    const { color = brown, size = 27, ...otherProps } = props
    return (
        <svg width={size} height={size / 24 + 7} viewBox="0 0 24 7" fill="none" {...otherProps}>
            <g id="Calque_3">
                <path
                    id="Vector"
                    d="M6.62447 3.55052C6.62447 5.24652 5.24847 6.62252 3.55247 6.62252C1.85647 6.62252 0.480469 5.24652 0.480469 3.55052C0.480469 1.85452 1.85647 0.478516 3.55247 0.478516C5.24847 0.478516 6.62447 1.85452 6.62447 3.55052Z"
                    fill={color}
                />
                <path
                    id="Vector_2"
                    d="M15.2573 3.55052C15.2573 5.24652 13.8813 6.62252 12.1853 6.62252C10.4893 6.62252 9.11328 5.24652 9.11328 3.55052C9.11328 1.85452 10.4893 0.478516 12.1853 0.478516C13.8813 0.478516 15.2573 1.85452 15.2573 3.55052Z"
                    fill={color}
                />
                <path
                    id="Vector_3"
                    d="M23.521 3.55052C23.521 5.24652 22.145 6.62252 20.449 6.62252C18.753 6.62252 17.377 5.24652 17.377 3.55052C17.377 1.85452 18.753 0.478516 20.449 0.478516C22.145 0.478516 23.521 1.85452 23.521 3.55052Z"
                    fill={color}
                />
            </g>
        </svg>
    )
}
