import styled from 'styled-components'
import { colors } from 'styles/colors'
import { TinyText } from 'styles/typography'

export const StyledText = styled(TinyText)`
    text-align: center;
    color: ${colors.white};
`

export const StyledTextContainer = styled.div`
    margin-top: -3px;
`

const BasicButton = styled.button`
    border-radius: 5px;
    border-style: none;
    width: 100%;
    min-width: 79px;
    height: 80px;
    padding: 10px;
    &:hover {
        cursor: pointer;
    }
    path {
        fill: ${colors.white};
    }
`

export const StyledDrawerButton = styled(BasicButton)<{ selected: boolean }>`
    ${(props) =>
        props.selected
            ? `background: ${colors.orange};
        box-shadow: 0px 4px 16px ${colors.orangeShadow}, 0px 4px 12px ${colors.blackShadow},
            inset 0px -4px 0px ${colors.shadowDark}`
            : `background: ${colors.transparent};
        &:hover {
            background: ${colors.white};
            path {
                fill: ${colors.orange};
            }
            span {
                color: ${colors.orange};
            }
        }`}
`

export const StyledIconContainer = styled.div`
    margin-top: 5px;
`
