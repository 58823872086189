import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const CrownVIP = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 15.32V5.51178C0 5.219 0.320231 5.04333 0.553126 5.219L5.32748 9.05444C5.47304 9.17155 5.70594 9.14228 5.82238 8.96661L10.0436 2.32046C10.1892 2.11551 10.4803 2.11551 10.6258 2.32046L14.6433 8.84949C14.7597 9.02516 14.9926 9.08372 15.1673 8.93733L19.5341 5.24828C19.7379 5.04333 20.0872 5.219 20.0872 5.51178V15.32C18.3114 13.9146 14.2648 13.2119 10.1018 13.2119C6.08439 13.2119 1.86316 14.0025 0 15.32ZM10.131 14.3832C15.5458 14.3832 19.2139 15.5836 19.8544 16.5497C18.8355 17.8673 14.876 18.8334 10.131 18.8334C5.38577 18.8334 1.39744 17.8673 0.407631 16.5497C1.04809 15.5836 4.68708 14.3832 10.131 14.3832Z"
                fill={color}
            />
        </svg>
    )
}
