import { AuthRequired } from 'components/auth-required/AuthRequired'
import { ComplaintReminder } from 'components/complaint-reminder/ComplaintReminder'
import { ReactNode } from 'react'

import { Layout } from './Layout'

type PrivateLayoutProps = {
    children?: ReactNode
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
    return (
        <AuthRequired>
            <ComplaintReminder>
                <Layout>{children}</Layout>
            </ComplaintReminder>
        </AuthRequired>
    )
}

export default PrivateLayout
