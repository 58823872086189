import { Button } from 'components/button/Button'
import styled from 'styled-components/macro'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { Text } from 'styles/typography'

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.S};
`

export const StyledCardContainer = styled.div<{ hasBanner: boolean }>`
    background-color: ${colors.white};
    border-radius: ${BORDER.RADIUS.XS};
    padding: ${SPACING.L};
    display: flex;
    flex-direction: column;
    flex: 1;
    ${(props) => props.hasBanner && `padding-bottom: 90px;`}
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    gap: ${SPACING.XXS};
`

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    & > h2 {
        margin-left: ${SPACING.L};
    }
`

export const StyledReturnButton = styled.div`
    border: ${BORDER.WIDTH.XS} solid ${colors.greyLight};
    height: fit-content;
    box-sizing: border-box;
    box-shadow: 0px ${BORDER.WIDTH.S} 0px ${colors.shadow};
    border-radius: ${BORDER.RADIUS.XS};
    padding: ${SPACING.XS} ${SPACING.XS};
    transform: rotate(90deg);
    &:hover {
        cursor: pointer;
    }
    & > svg {
        margin-bottom: -3px;
    }
`

export const StyledContent = styled.div`
    display: flex;
    width: 100%;
    margin-top: ${SPACING.XXXXL};
    flex: 1;
`

export const StyledOrderContainer = styled.div`
    width: 60%;
    height: fit-content;
    display: flex;
    flex-direction: column;
`

export const StyledInfoContainer = styled.div`
    background: ${colors.grey2};
    border-radius: ${BORDER.RADIUS.S};
    padding: 0 ${SPACING.S};
    margin-left: ${SPACING.L};
    box-sizing: border-box;
    width: 40%;
    height: fit-content;
`

export const StyledCardInfo = styled.div<{ vip?: boolean; borderColor?: string }>`
    background: ${colors.white};
    border-radius: ${BORDER.RADIUS.L};
    width: 100%;
    margin: ${SPACING.XS} 0;
    padding: ${SPACING.XS} ${SPACING.S};
    box-sizing: border-box;
    ${(props) =>
        props.vip
            ? `
    border: 2px solid ${colors.orange};
    `
            : props.borderColor &&
              `
    border: 2px solid ${props.borderColor};
    `}
`

export const StyledTotalContainer = styled.div`
    margin-top: ${SPACING.XXXXL};
`

export const StyledSeparator = styled.div`
    height: ${BORDER.WIDTH.XS};
    background-color: ${colors.shadowDark};
`

export const StyledInfoLineText = styled.div<{ first?: boolean }>`
    display: flex;
    flex-direction: column;
    ${(props) => !props.first && 'margin-top: 12px;'}
`

export const StyledStatusContainer = styled.div`
    display: flex;
    & > div {
        margin-left: ${SPACING.L};
    }
`

export const StyledCrownsContainer = styled.div`
    display: flex;
    align-items: center;
    & > svg {
        margin-left: ${SPACING.XXXS};
    }
`

export const StyledIframe = styled.iframe`
    width: 100%;
    height: 250px;
    border: solid ${colors.orange};
    border-radius: 1em;
`

export const StyledTypeContainer = styled.div`
    display: flex;
    padding: 0 0 0 10px;
    align-items: center;
    gap: 10px;
    & h4 {
        font-size: 15px;
        font-family: ${fonts.flameSans};
    }
`

export const StyledTypeIconContainer = styled.div`
    display: flex;
    width: ${SPACING.L};
    height: ${SPACING.L};
    justify-content: center;
    align-items: center;
`

export const StyledLeftPane = styled.div`
    width: 66%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const StyledMessageContainer = styled.div<{ isRestaurant?: boolean }>`
    padding: ${SPACING.XS} ${SPACING.S};
    border-radius: ${(props) =>
        props.isRestaurant
            ? `${BORDER.RADIUS.XXXXL} 0 ${BORDER.RADIUS.XXXXL} ${BORDER.RADIUS.XXXXL}`
            : `0 ${BORDER.RADIUS.XXXXL} ${BORDER.RADIUS.XXXXL} ${BORDER.RADIUS.XXXXL}`};
    background: ${(props) => (props.isRestaurant ? colors.greenLight : colors.grey2)};
    color: ${colors.blackLight};
    font-family: ${fonts.flameSans};
    font-size: 14px;
    line-height: 150%;
    max-width: 80%;
    width: fit-content;
    margin: ${(props) => (props.isRestaurant ? `0 0 ${SPACING.XS} auto` : `auto 0 ${SPACING.XS} 0`)};
`

export const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

export const StyledInputContainer = styled.div`
    background-color: ${colors.white};
    border-radius: 0 0 ${BORDER.RADIUS.S} ${BORDER.RADIUS.S};
`

export const StyledInput = styled.div<{ hasFocus: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: ${SPACING.XXS};
    border: ${BORDER.WIDTH.XS} solid ${(props) => (props.hasFocus ? colors.brown : colors.greyExtraLight)};
    border-radius: ${BORDER.RADIUS.XXXXL};
    padding: ${SPACING.XXS} ${SPACING.S};
    box-sizing: border-box;
`

export const StyledButton = styled(Button)`
    align-self: end;
    padding: 0;
`

export const StyledText = styled(Text)`
    white-space: pre-line;
`
