import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const BackIcon = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 10 15" {...otherProps}>
            <path
                d="M4.053 7l4.72-4.72A1.333 1.333 0 106.88.4L1.226 6.053a1.333 1.333 0 000 1.894l5.654 5.72c.251.25.592.388.946.386.355.002.695-.137.947-.386.517-.52.517-1.36 0-1.88L4.053 7z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
