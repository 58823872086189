import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Restaurant = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M19.003 7.072l-8.367.089-8.71.088c0 1.838-.144 4.182 0 5.91 0 .815-.066 1.64 0 2.455.067.814 0 1.783.067 2.531a1.193 1.193 0 00.387.909 1.21 1.21 0 00.943.313c4.244 0 7.291.242 11.081.099.853 0 1.706-.055 2.56-.132.62-.055 1.85.055 2.127-.705.074-.234.104-.48.089-.726v-6.098c0-1.54 0-3.093-.089-4.633v-.1h-.088zM9.329 14.47c-1.419 0-2.627.165-4.078.154-.454 0-.532-1.1-.532-2.135 0-.463-.055-1.002 0-1.464.055-.462 0-.804.355-.958.911-.11 1.83-.142 2.748-.099.446-.028.894-.028 1.34 0a.455.455 0 01.41.473v3.71a.274.274 0 01-.265.308l.022.01zm5.563-4.722c.217.011.436.011.653 0a.736.736 0 01.761.46.722.722 0 01.048.31v2.202c0 .43.166 1.442-.399 1.585v-.077a4.7 4.7 0 01-.964.099h-1.618c-.496.066-1 .066-1.496 0-.314-.069-.342-.338-.33-.632l.013-.198c.004-.066.007-.131.007-.194 0-1.1 0-2.201-.066-3.302a.175.175 0 01.155-.176c.532 0 1.108 0 1.65-.077.544-.077 1.054 0 1.586 0zM5.639 3c1.363 0 2.66.1 4.133.1h9.341c.477 0 .787.494.787.924.011.308.022.616.1.935a.854.854 0 01-.562.783.87.87 0 01-.336.054h-.365L2.18 5.972h-.454c-.831 0-.72-.936-.72-1.596C.94 3.166 1.36 3 2.48 3z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    )
}
