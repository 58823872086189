import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H1, H4 } from 'styles/typography'

export const StyledCollapsedCard = styled.div<{ isClickable: boolean }>`
    background: ${colors.grey};
    box-shadow: inset 0px -1px 0px ${colors.shadowDark};
    border-radius: 5px 5px 0px 0px;
    padding: 12px 16px;
    display: flex;
    margin: 8px 0px;
    justify-content: space-between;
    &:hover {
        ${(props) => props.isClickable && 'cursor: pointer'};
    }
    &:first-child {
        margin-top: 0;
    }
`

export const StyledH4 = styled(H4)`
    font-family: ${fonts.flameSans};
    margin: 0;
`

export const StyledH1 = styled(H1)`
    color: ${colors.brown};
    margin: 0;
`

export const StyledProductLabel = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledProductQuantity = styled.div`
    display: flex;
    align-items: center;
`

export const StyledIconContainer = styled.div<{ open: boolean; isClickable: boolean }>`
    margin-left: 15px;
    transition: 0.3s;
    ${(props) => props.open && 'transform: rotate(180deg)'};
    ${(props) => !props.isClickable && 'visibility: hidden'};
`

export const StyledOrderContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledContentContainer = styled.div`
    padding: 4px 16px 16px;
`

export const StyledFreeItem = styled.div`
    margin: 4px 0;
`

export const StyledRecipePrice = styled.div`
    display: flex;
    justify-content: space-between;
`
