import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { ComplaintStatus } from 'types/api.enum'

import { StyledFilterButton } from './style'

type Props = {
    values: ComplaintStatus[]
    selected: boolean
    onClick: (values: ComplaintStatus[]) => void
}

export const FilterButton = ({ values, selected, onClick }: Props) => {
    const { t } = useTranslation()
    return (
        <StyledFilterButton selected={selected} onClick={() => onClick(values)}>
            <Text color={selected ? colors.white : colors.brown}>{t(`complaints.status.${values[0]}`)}</Text>
        </StyledFilterButton>
    )
}
