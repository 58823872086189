import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Child = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 20 21" {...otherProps}>
            <path
                d="M8 8.583c0-.529-.448-.958-1-.958s-1 .43-1 .958c0 .53.448.959 1 .959s1-.43 1-.959zm4.5 3.891a1.038 1.038 0 00-.76-.1.996.996 0 00-.61.445 1.31 1.31 0 01-1.13.63 1.31 1.31 0 01-1.13-.63.996.996 0 00-.61-.445 1.038 1.038 0 00-.76.1.937.937 0 00-.37 1.303c.59.985 1.685 1.592 2.87 1.592s2.28-.607 2.87-1.591a.937.937 0 00-.37-1.304zm.5-4.849c-.552 0-1 .43-1 .958 0 .53.448.959 1 .959s1-.43 1-.959c0-.529-.448-.958-1-.958zM10 .917C4.477.917 0 5.207 0 10.5s4.477 9.583 10 9.583 10-4.29 10-9.583c0-2.542-1.054-4.98-2.929-6.776C15.196 1.926 12.652.917 10 .917zm0 17.25c-3.843.015-7.154-2.589-7.887-6.204C1.379 8.348 3.43 4.745 7 3.38c-.01.137-.01.274 0 .412 0 1.587 1.343 2.875 3 2.875.552 0 1-.43 1-.959 0-.529-.448-.958-1-.958s-1-.43-1-.958c0-.53.448-.959 1-.959 4.418 0 8 3.433 8 7.667s-3.582 7.667-8 7.667z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
