import { DayLabel, ErrorBirthday } from 'types/api.enum'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessage = (error: any): { message: string; day: string } => {
    const errorMessage: string = error?.response?.data?.message
    const day = errorMessage.split('_').pop()
    const defaultReturn = { message: 'error.birthdayService', day: '' }

    if (!errorMessage) return defaultReturn
    if (!day) return defaultReturn
    if (!Object.values(DayLabel).includes(day as DayLabel)) return { message: errorMessage, day: '' }

    const message = errorMessage.substring(0, errorMessage.lastIndexOf('_'))

    return {
        message: 'error.birthday.' + ErrorBirthday[message as ErrorBirthday],
        day: 'days.' + DayLabel[day as DayLabel],
    }
}
