export const colors = {
    beige: '#F5F3F0',
    black: '#000000',
    blackShadow: 'rgba(0, 0, 0, 0.48)',
    blackLight: '#3C3C3C',
    brownExtraLight: '#7D5D53',
    brownLight: '#87604F',
    brown: '#502314',
    brownDark: '#2B0200',
    defaultGrey: '#6C6C6C',
    defaultLightGrey: '#979797',
    green: '#198737',
    greenLight: '#E6FAEB',
    grey: '#F8F8F8',
    grey2: '#F7F7F7',
    greyLight: '#D8D8D8',
    greyExtraLight: '#D4D4D4',
    orange: '#FF8732',
    orangeLight: '#FAEEE6',
    orangeDark: '#D9732B',
    orangeShadow: 'rgba(250, 120, 63, 0.32)',
    red: '#D62300',
    redLight: '#FFEDEF',
    shadow: 'rgba(0, 0, 0, 0.04)',
    shadowDark: 'rgba(0, 0, 0, 0.16)',
    transparent: 'rgba(255, 255, 255, 0.12)',
    white: '#FFFFFF',
    yellowMelty: '#FFAA00',
    lightMayo: '#FAF3E8',
    lightBeige: '#CBBDB9',
}
