import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Motorbike = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M2.907 14.888l2.218.056h2.95a4.811 4.811 0 01-.676 1.294 4.253 4.253 0 01-.419.472c-2.17 2.021-4.235.312-4.073-1.822zm16.065-1.105c-.267 2.446-3.455 4.363-4.835 1.889a2.4 2.4 0 01-.152-1.596c.1-.086.216-.15.342-.19v-.027c1.209-.076 3.54-.076 4.645-.076zM15.632 4c.18.047.275.142.275.415.183.726.326 1.46.428 2.2 0 .142-.514.161-.894.057h-.162c.448.865.94 1.707 1.475 2.522h.133c.623.112 1.21.367 1.715.744a4.04 4.04 0 011.198 1.428l-.039.085c.533 1.435.115 1.312-.675 1.312h-4.863c-.971 0-1.304 1.01-2.256.944l-3.683.048H2.431a2.513 2.513 0 01-.647-.067.955.955 0 01-.647-.481 1.566 1.566 0 01-.067-1.105 5.335 5.335 0 011.447-2.474 4.038 4.038 0 012.113-1.02c-.343-.33-.029-1.2.609-1.2 1.642-.02 3.284.084 4.91.313a.637.637 0 01.377.401.628.628 0 01-.082.543 1.98 1.98 0 00-.495 2.578 2.002 2.002 0 001.98.538 5.697 5.697 0 012.598-2.266c-.057-.302-.219-.784-.342-1.436-.2-1.048-.571-1.633-1.085-1.765a.959.959 0 00-.552 0 .671.671 0 01-.305 0c-.533-.123-.6-1.01 0-1.294a2.819 2.819 0 011.494-.113c.1.021.194.063.276.122l.324.265C14.48 4.519 15.06 4 15.43 4z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    )
}
