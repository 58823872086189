import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Tab } from 'types/tab'
import { FilterType } from 'utils/enums'

export const useTabsFilter = (tabs: Array<Tab>) => {
    const [searchParams] = useSearchParams()
    const [tabSelectedIndex, setTabSelectedIndex] = useState<number>(0)

    useEffect(() => {
        const getFilterFromUrl = () => {
            if (tabs) {
                const filter = searchParams.get(FilterType.STATUS)
                if (filter) {
                    return tabs.findIndex((tab) => filter === tab.status)
                }
            }
            return 0
        }
        setTabSelectedIndex(getFilterFromUrl())
    }, [searchParams, tabs])

    return { tabSelectedIndex, setTabSelectedIndex }
}
