import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50vw;
    padding: 32px;
    background-color: ${colors.red};
    box-shadow: 0px 2px 16px ${colors.red};
    border-radius: 12px;
    & > h1 {
        margin: 20px 0 10px 0;
    }
`

export const StyledMessageContainer = styled.div`
    display: flex;
    border: 1px solid ${colors.white};
    padding: 15px;
    border-radius: 5px;
    justify-content: space-between;
    width: 300px;
    align-items: center;
    margin: 20px 0;
    & > svg {
        margin-right: 10px;
    }
`
