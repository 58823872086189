import { Button } from 'components/button/Button'
import { TextArea } from 'components/inputs'
import { Modal } from 'components/modal/Modal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H2, H4 } from 'styles/typography'

import { StyledButtonContainer, StyledButtonsContainer, StyledContainer, StyledErrorText } from './style'

interface TextModalProps {
    handleClose: () => void
    title: string
    subTitle: string
    onValidate: (value: string) => void
    defaultValue: string
}

export const TextModal = ({ handleClose, title, subTitle, onValidate, defaultValue }: TextModalProps) => {
    const { t } = useTranslation()
    const [value, setValue] = useState(defaultValue)
    const maxLength = 1000

    const onClick = () => {
        onValidate(value)
        handleClose()
    }

    return (
        <Modal handleClose={handleClose}>
            <StyledContainer>
                <H2 color={colors.brown}>{title}</H2>
                <H4 font={fonts.flameSans}>{subTitle}</H4>
                <TextArea value={value} onChange={(res) => setValue(res.target.value)} maxLength={maxLength} />
                <StyledErrorText visible={value.length >= maxLength}>
                    {t('error.textMaxLength', { maxLength: maxLength })}
                </StyledErrorText>
                <StyledButtonsContainer>
                    <StyledButtonContainer>
                        <Button
                            text={t('component.button.cancel')}
                            color={colors.black}
                            outlined
                            onClick={handleClose}
                        />
                    </StyledButtonContainer>
                    <StyledButtonContainer>
                        <Button text={t('component.button.validate')} color={colors.red} onClick={onClick} />
                    </StyledButtonContainer>
                </StyledButtonsContainer>
            </StyledContainer>
        </Modal>
    )
}
