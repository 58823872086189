import styled from 'styled-components'
import { colors } from 'styles/colors'
import { H4, SmallText, Text } from 'styles/typography'

export const StyledContainerButtons = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
`

export const StyledInfo = styled(SmallText)`
    white-space: pre;
    text-align: center;
    margin-top: 10px;
`

export const StyledInfoAccount = styled(SmallText)`
    margin-top: 24px;
    text-align: center;
`

export const StyledUpdatePasswordLink = styled(Text)`
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 24px;
`

export const StyledServicesContainer = styled.div`
    display: flex;
    min-height: 100%;
`

export const StyledTabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
`

export const StyledCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.white};
    padding: 24px;
    border-radius: 5px;
    width: 65%;
    margin-left: 24px;
    min-height: 100%;
`

export const StyledTab = styled.div<{ selected: boolean }>`
    padding: 24px;
    display: flex;
    background: ${(props) => (props.selected ? colors.greyLight : colors.white)};
    ${(props) => props.selected && `box-shadow: inset -4px 0px 0px ${colors.orange}`};
    &:hover {
        cursor: pointer;
    }
`

export const StyledTitleContainer = styled.div`
    margin-left: 10px;
`

export const StyledH4 = styled(H4)`
    margin: 0;
    color: ${colors.brown};
`

export const StyledCenterPage = styled.div`
    margin: 250px auto;
`

export const StyledLoaderContainer = styled.div`
    display: flex;
`
