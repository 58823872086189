import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Box = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M18.13 6.283a.179.179 0 010-.076.16.16 0 010-.067v-.086l-.057-.143a.459.459 0 00-.086-.105l-.086-.077h-.048L14.09 3.351l-3.553-2.197a.812.812 0 00-.277-.143h-.077a.783.783 0 00-.258 0H9.83a1.08 1.08 0 00-.315.124L2.379 5.576l-.085.067-.086.077-.096.067-.048.057-.057.143v.143a.659.659 0 000 .192v8.338c0 .33.169.637.449.812l7.164 4.432.143.058h.076a.822.822 0 00.497 0h.077l.143-.058 7.106-4.365a.955.955 0 00.45-.812V6.388s.019-.066.019-.105zm-8.109-3.2l1.7 1.051-5.34 3.305-1.71-1.05 5.35-3.306zm-.955 14.328l-5.254-3.21V8.099l5.254 3.248v6.065zm.955-7.746L8.197 8.566l5.339-3.314 1.834 1.137-5.349 3.276zm6.209 4.508l-5.254 3.267v-6.094l5.254-3.248v6.075z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
