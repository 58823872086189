import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Play = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M17.241 7.181L7.287 1.472A3.524 3.524 0 002 4.564v11.459a3.524 3.524 0 005.287 3.05l9.954-5.709a3.524 3.524 0 000-6.1V7.18zm-1.03 4.318L6.256 17.29a1.484 1.484 0 01-1.463 0 1.464 1.464 0 01-.732-1.267v-11.5c0-.523.279-1.006.732-1.268.224-.124.475-.192.731-.196.257.006.508.073.732.196l9.955 5.75a1.463 1.463 0 010 2.535V11.5z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
