import { CheckCircle, ClickAndCollectIcon, DeliveryIcon, Hourglass, ShopIcon, Warning } from 'assets/icons'
import noDataImage from 'assets/images/lemon-girl.webp'
import { ComplaintFilters } from 'components/complaint-filters/ComplaintsFilters'
import { ErrorBlock } from 'components/error-block/ErrorBlock'
import { Status } from 'components/status/Status'
import { Websocket } from 'components/websocket/Websocket'
import { WsStatus } from 'components/websocket-status/WsStatus'
import { useFeatureToggle } from 'hooks/useFeatureToggle'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useTabsFilter } from 'hooks/useTabsFilter'
import { wsTopics } from 'httpServices/wsTopics'
import React, { useEffect, useMemo, useState } from 'react'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { updateComplaint } from 'redux/complaint/complaintSlice'
import {
    selectComplaintsByStatus,
    selectComplaintsWithMultipleFilters,
    selectNumberOfComplaintsbyComplaintStatus,
} from 'redux/complaint/selectors'
import { fetchComplaintsList } from 'redux/complaint/thunks'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { NoPermissions } from 'screens/no-permissions/NoPermissions'
import { Tabs } from 'screens/tabs/Tabs'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { ComplaintLightDTO } from 'types/api'
import { ComplaintStatus, ComplaintType, Permissions } from 'types/api.enum'
import { DateRange } from 'types/date-range'
import { Tab } from 'types/tab'
import { FEATURE_TOGGLE_CODE, FilterType, StatusType } from 'utils/enums'
import {
    formatDate,
    formatNumberTime,
    formatPrice,
    formatTime,
    getComplaintStatusType,
    getDateRangeFromString,
    hasPermission,
    isMoneyOrCrown,
    isMoneyRefund,
    stringToDateDDMMYYYY,
} from 'utils/util'

import {
    Center,
    Column,
    ColumnTitle,
    Container,
    Grid,
    IconTextContainer,
    Row,
    StyledButton,
    StyledIconAndLabel,
    StyledIconContainer,
    TitleText,
} from './style'

export const Complaints = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const restaurantFrNumber = useAppSelector<string>((state) => state.restaurant.selectedFrNumber)
    const [wsStatus, setWsStatus] = useState<StatusType>(StatusType.ERROR)
    const [isLoading, setIsLoading] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const enabledFranchisedReclamation = useFeatureToggle([FEATURE_TOGGLE_CODE.FRANCHISED_RECLAMATION])

    const searchParamStatusFilter = searchParams.get(FilterType.STATUS)?.toUpperCase()
    const searchParamDateRangeFilter = getDateRangeFromString(searchParams.get(FilterType.DATE_RANGE))

    const searchParamStateFilterInProgress = searchParams.get(FilterType.STATE_IN_PROGRESS)?.toUpperCase().split('-')
    const searchParamStateFilterFinished = searchParams.get(FilterType.STATE_FINISHED)?.toUpperCase().split('-')

    const paramDateRangeStringFormat = JSON.stringify(searchParamDateRangeFilter)
    const paramDateRange: DateRange = {
        startDate: stringToDateDDMMYYYY(searchParamDateRangeFilter[0]),
        endDate: stringToDateDDMMYYYY(searchParamDateRangeFilter[1]),
    }

    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    const complaintsFinished: ComplaintLightDTO[] = useAppSelector((state) =>
        selectComplaintsByStatus(state, StatusType.FINISHED),
    )
    const complaintsInProgress: ComplaintLightDTO[] = useAppSelector((state) =>
        selectComplaintsByStatus(state, StatusType.IN_PROGRESS),
    )
    const complaintsData = useAppSelector((state) =>
        selectComplaintsWithMultipleFilters(state, {
            status: searchParamStatusFilter as StatusType,
            stateStatus:
                searchParamStatusFilter === StatusType.FINISHED
                    ? (searchParamStateFilterFinished as ComplaintStatus[])
                    : (searchParamStateFilterInProgress as ComplaintStatus[]),
            complaintType: enabledFranchisedReclamation ? undefined : ComplaintType.DELIVERY,
        }),
    )

    const complaintsSecondReminder = useAppSelector((state) =>
        selectNumberOfComplaintsbyComplaintStatus(state, ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER),
    )

    const onlyDeliveryComplaint = useMemo(
        () => complaintsInProgress.filter((complaint) => complaint.complaintType === ComplaintType.DELIVERY),
        [complaintsInProgress],
    )

    const complaintsDependingOnComplaintType = enabledFranchisedReclamation
        ? complaintsInProgress
        : onlyDeliveryComplaint

    useEffect(() => {
        setIsLoading(true)

        if (hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)) {
            dispatch(
                fetchComplaintsList({
                    frNumber: restaurantFrNumber,
                    dateRange: paramDateRange,
                }),
            )
                .then(() => setError(null))
                .catch((error: unknown) => setError(error))
                .finally(() => setIsLoading(false))
        }
    }, [restaurantFrNumber, dispatch, userPermissions, paramDateRangeStringFormat]) // eslint-disable-line

    const tabs: Tab[] = [
        {
            icon: <Warning size={15} />,
            label: t('page.complaints.tabs.current'),
            number: complaintsDependingOnComplaintType.length,
            status: StatusType.IN_PROGRESS,
            onClick: () => onFilterChange(FilterType.STATUS, StatusType.IN_PROGRESS),
        },
        {
            icon: <CheckCircle size={15} />,
            label: t('page.complaints.tabs.end'),
            number: complaintsFinished.length,
            status: StatusType.FINISHED,
            onClick: () => onFilterChange(FilterType.STATUS, StatusType.FINISHED),
        },
    ]

    const buttonsFilterInProgress = [
        [ComplaintStatus.ALL],
        [
            ComplaintStatus.WAITING_RESTAURANT,
            ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER,
            ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER,
            ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER,
        ],

        [ComplaintStatus.TO_REFUND, ComplaintStatus.REFUND_CONFIRMED],
    ]
    const buttonsFilterFinished = [
        [ComplaintStatus.ALL],
        [
            ComplaintStatus.WAITING_SUPPORT,
            ComplaintStatus.REFUND_VALIDATED,
            ComplaintStatus.SYSTEM_VALIDATE_REFUND,
            ComplaintStatus.SYSTEM_CONTEST_REFUND,
            ComplaintStatus.NOTIFY_CONTEST_REFUND,
            ComplaintStatus.NOTIFY_VALIDATE_REFUND,
        ],
        [ComplaintStatus.REFUNDED, ComplaintStatus.REFUNDED_MANUALLY],
        [ComplaintStatus.CLOSED, ComplaintStatus.CLOSED_MANUALLY],
    ]

    const onFilterChange = (label: string, value?: string) => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString())

        if (value) {
            updatedSearchParams.set(label, value)
        } else {
            updatedSearchParams.delete(label)
        }

        // BTR-360 since the date filter is only visible when the selected status type is FINISHED -> reset it when changing the status
        if (label === FilterType.STATUS && value !== StatusType.FINISHED) {
            updatedSearchParams.delete(FilterType.DATE_RANGE)
        }

        setSearchParams(updatedSearchParams)
    }

    type GridTitleProps = { radiusLeft?: boolean; radiusRight?: boolean; children?: React.ReactNode }

    const GridTitle = ({ children, radiusLeft, radiusRight }: GridTitleProps) => {
        return (
            <ColumnTitle radiusLeft={radiusLeft} radiusRight={radiusRight}>
                <TitleText>{children}</TitleText>
            </ColumnTitle>
        )
    }

    const ColumnTextItem = ({ value }: { value: string }) => (
        <Column>
            <Text color={colors.blackLight}>{value}</Text>
        </Column>
    )

    const ColumnIconTextItem = ({ value, icon }: { value: string; icon: JSX.Element }) => (
        <Column>
            <IconTextContainer>
                {icon}
                <Text color={colors.blackLight}>{value}</Text>
            </IconTextContainer>
        </Column>
    )

    const TimeRenderer = ({
        formatted,
        completed,
    }: {
        days: number
        completed: boolean
        formatted: { hours: string; minutes: string; seconds: string }
    }) => {
        const { hours, minutes } = formatted

        return (
            <>
                <ColumnIconTextItem
                    value={completed ? t('time.noTime') : `${formatNumberTime(hours)}:${formatNumberTime(minutes)}`}
                    icon={<Hourglass color={colors.red} />}
                />
            </>
        )
    }
    const { tabSelectedIndex } = useTabsFilter(tabs)

    if (!hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)) {
        return <NoPermissions />
    }

    if (isLoading) {
        return (
            <>
                <Tabs tabs={tabs} index={tabSelectedIndex} />
                <Container>
                    <Center>
                        <ClipLoader size={70} color={colors.orange} loading={true} />
                    </Center>
                </Container>
            </>
        )
    }

    return (
        <>
            <Tabs tabs={tabs} index={tabSelectedIndex} />
            <WsStatus status={wsStatus} />
            <Container error={complaintsSecondReminder > 0}>
                <Websocket
                    topics={[wsTopics.complaintsByRestaurant(restaurantFrNumber)]}
                    onMessage={(message) => {
                        const messageType = message as ComplaintLightDTO

                        dispatch(updateComplaint(messageType))
                    }}
                    onConnect={() => setWsStatus(StatusType.FINISHED)}
                    onConnectFailure={() => setWsStatus(StatusType.ERROR)}
                    onDisconnect={() => setWsStatus(StatusType.ERROR)}
                />
                <ComplaintFilters
                    onChangeFilter={onFilterChange}
                    isFinished={searchParamStatusFilter === StatusType.FINISHED}
                    buttons={
                        searchParamStatusFilter === StatusType.FINISHED
                            ? buttonsFilterFinished
                            : buttonsFilterInProgress
                    }
                />
                <Grid>
                    <tbody>
                        <Row>
                            {searchParamStatusFilter !== StatusType.FINISHED && (
                                <GridTitle radiusLeft>{t('complaints.timeLeft')}</GridTitle>
                            )}
                            <GridTitle>{t('complaints.status.title')}</GridTitle>
                            <GridTitle>{t('complaints.complaintNumber')}</GridTitle>
                            <GridTitle>{t('time.date')}</GridTitle>
                            <GridTitle>{t('time.hour')}</GridTitle>
                            <GridTitle>{t('complaints.price')}</GridTitle>
                            <GridTitle>
                                {enabledFranchisedReclamation
                                    ? t('complaints.complaintType')
                                    : t('complaints.responsability')}
                            </GridTitle>
                            <GridTitle radiusRight />
                        </Row>
                        {complaintsData.map((complaint: ComplaintLightDTO) => {
                            const {
                                id,
                                complaintNumber,
                                price,
                                status,
                                createdDate,
                                endDate,
                                refundType,
                                inCharge,
                                complaintType,
                            } = complaint
                            const statusType = getComplaintStatusType(complaint)

                            const hasCountdown =
                                (refundType && isMoneyRefund(refundType) && complaintType !== ComplaintType.DELIVERY) ||
                                (status &&
                                    [
                                        ComplaintStatus.REFUND_CONFIRMED,
                                        ComplaintStatus.WAITING_RESTAURANT,
                                        ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER,
                                        ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER,
                                        ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER,
                                    ].includes(status))

                            return (
                                <Row
                                    key={id}
                                    error={
                                        status &&
                                        [
                                            ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER,
                                            ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER,
                                            ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER,
                                        ].includes(status)
                                    }
                                >
                                    {searchParamStatusFilter !== StatusType.FINISHED &&
                                        (hasCountdown && !!endDate && new Date(endDate) > new Date() ? (
                                            <Countdown date={new Date(endDate)} renderer={TimeRenderer} daysInHours />
                                        ) : (
                                            <Column />
                                        ))}
                                    <Column>
                                        <Status
                                            type={statusType}
                                            text={t(`complaints.status.${status}`, {
                                                count: refundType ? isMoneyOrCrown(refundType) : 0, // Separate Money(1) and Crown(2) status
                                            })}
                                        />
                                    </Column>

                                    <ColumnTextItem value={complaintNumber?.toString() ?? ''} />
                                    <ColumnTextItem value={formatDate(new Date(createdDate ?? ''))} />
                                    <ColumnTextItem value={formatTime(new Date(createdDate ?? ''))} />
                                    <ColumnTextItem value={formatPrice(price ?? 0)} />
                                    {enabledFranchisedReclamation ? (
                                        <Column>
                                            <StyledIconAndLabel>
                                                <StyledIconContainer>
                                                    {complaintType === ComplaintType.DELIVERY && <DeliveryIcon />}
                                                    {complaintType === ComplaintType.RESTAURANT && <ShopIcon />}
                                                    {complaintType === ComplaintType.CLICK_AND_COLLECT && (
                                                        <ClickAndCollectIcon />
                                                    )}
                                                </StyledIconContainer>
                                                <Text color={colors.blackLight}>
                                                    {t(`complaints.complaintTypes.${complaintType}`)}
                                                </Text>
                                            </StyledIconAndLabel>
                                        </Column>
                                    ) : (
                                        <ColumnTextItem value={t(`complaints.inCharge.${inCharge}`)} />
                                    )}
                                    <Column>
                                        <StyledButton
                                            text={t('component.button.more')}
                                            color={colors.red}
                                            onClick={() => navigate(`${complaint.id}`)}
                                        />
                                    </Column>
                                </Row>
                            )
                        })}
                    </tbody>
                </Grid>
                {error ? (
                    <ErrorBlock errorCode={error?.response?.status} />
                ) : (
                    complaintsData.length === 0 && (
                        <ErrorBlock image={noDataImage} titleKey="orders.empty" descriptionKey="orders.noData" />
                    )
                )}
            </Container>
        </>
    )
}
