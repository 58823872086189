import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'

import { StyledH4Info } from './style'

type InfoStampProps = {
    icon?: JSX.Element
    text?: string
    children?: JSX.Element
    warning?: boolean
}

export const InfoStamp = ({ icon, text, warning = false, children }: InfoStampProps) => (
    <StyledInfoLine warning={warning}>
        {icon}
        {text && <StyledH4Info color={colors.brown}>{text}</StyledH4Info>}
        {children && <StyledChildren>{children}</StyledChildren>}
    </StyledInfoLine>
)

const StyledInfoLine = styled.div<{ warning?: boolean }>`
    display: flex;
    padding: 8px 0;
    ${(props) =>
        props.warning &&
        `
    background: ${colors.orangeLight};
    border-radius: 5px;
    padding: 8px;
    `}
`

const StyledChildren = styled.div`
    margin: -${BORDER.WIDTH.XS} 0 0 ${SPACING.XS};
`
