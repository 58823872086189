import AccordionDetails from 'components/accordion/AccordionDetails'
import { Spacer } from 'components/spacer'
import { Trans, useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'
import { Text } from 'styles/typography'
import { AccordionDetailsKey, TutorialTab } from 'utils/enums'

import { StyledAccordionContainer } from './styles'

export const DeliveryTab = () => {
    const { t } = useTranslation()

    const accordionsDetailsArray = [
        AccordionDetailsKey.VALIDATE_REFUND,
        AccordionDetailsKey.CONTEST_REFUND,
        AccordionDetailsKey.DELIVERY_CLAIM,
    ]

    return (
        <>
            <Text color={colors.brown}>
                <Trans i18nKey={`tutorial.${TutorialTab.KING_DELIVERY}.description`} />
            </Text>
            <Spacer s={SPACING.L} />

            <StyledAccordionContainer>
                {accordionsDetailsArray.map((section) => (
                    <AccordionDetails
                        key={section}
                        title={t(`tutorial.${TutorialTab.KING_DELIVERY}.${section}.title`)}
                        description={
                            <Trans i18nKey={t(`tutorial.${TutorialTab.KING_DELIVERY}.${section}.description`)} />
                        }
                    />
                ))}
            </StyledAccordionContainer>
        </>
    )
}
