import AccordionDetails from 'components/accordion/AccordionDetails'
import { Spacer } from 'components/spacer'
import { Trans, useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'
import { Text } from 'styles/typography'
import { AccordionDetailsKey, TutorialTab } from 'utils/enums'

import { StyledAccordionContainer } from './styles'

const accordionsDetailsArray = [
    AccordionDetailsKey.DECRYPT_COMPLAINT,
    AccordionDetailsKey.REFUND_CUSTOMER,
    AccordionDetailsKey.CLOSE_COMPLAINT,
    AccordionDetailsKey.EXCHANGE_CUSTOMER,
    AccordionDetailsKey.NOTIFICATIONS,
]

export const RestaurantAndCcTab = () => {
    const { t } = useTranslation()

    return (
        <>
            <Text color={colors.brown}>
                <Trans i18nKey={`tutorial.${TutorialTab.RESTAURANT_AND_CC}.description`} />
            </Text>
            <Spacer s={SPACING.L} />

            <StyledAccordionContainer>
                {accordionsDetailsArray.map((section) => (
                    <AccordionDetails
                        key={section}
                        title={t(`tutorial.${TutorialTab.RESTAURANT_AND_CC}.${section}.title`)}
                        description={
                            <Trans i18nKey={t(`tutorial.${TutorialTab.RESTAURANT_AND_CC}.${section}.description`)} />
                        }
                        descriptionMax={
                            <Trans i18nKey={t(`tutorial.${TutorialTab.RESTAURANT_AND_CC}.${section}.descriptionMax`)} />
                        }
                        button={AccordionDetailsKey.REFUND_CUSTOMER === section}
                    />
                ))}
            </StyledAccordionContainer>
        </>
    )
}
