import { CheckEmailIcon, DownloadIcon } from 'assets/icons'
import { Button } from 'components/button/Button'
import { useAppSelector } from 'hooks/useRedux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'
import { H2, Text } from 'styles/typography'

interface DownloadModalContentProps {
    loading: boolean
    onDownload: (downloadFile: boolean) => Promise<void>
}

export const DownloadModalContent = ({ loading, onDownload }: DownloadModalContentProps) => {
    const { t } = useTranslation()

    const { email } = useAppSelector((state) => state.user)

    return (
        <StyledContainer>
            <CheckEmailIcon size={122} color={colors.brown} />

            <StyledH2 color={colors.brown}>{t('services.kingTable.management.downloadModal.title')}</StyledH2>

            <Text>
                {t('services.kingTable.management.downloadModal.description', {
                    email,
                })}
            </Text>

            <StyledButtonsContainer>
                <Button
                    text={t('services.kingTable.management.downloadModal.buttons.confirm')}
                    color={colors.red}
                    icon={<DownloadIcon color={colors.white} size={18} />}
                    onClick={onDownload}
                    loading={loading}
                />
            </StyledButtonsContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    background-color: ${colors.white};
    border-radius: ${BORDER.RADIUS.XXXXL};
    display: flex;
    flex-direction: column;
    padding: ${SPACING.XXL};
    box-sizing: border-box;
    align-items: center;
    max-width: 560px;
    text-align: center;
`

const StyledH2 = styled(H2)`
    margin-top: ${SPACING.XXL};
    margin-bottom: ${SPACING.S};
`

const StyledButtonsContainer = styled.div`
    margin-top: ${SPACING.XXL};
`
