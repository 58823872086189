import difference from 'lodash/difference'
import React from 'react'
import SockJS from 'sockjs-client'
import Stomp, { Message } from 'stompjs'

interface Props {
    url: string
    options?: object
    topics: string[]
    onConnect?: () => void
    onDisconnect?: () => void
    getRetryInterval?: (retryCount: number) => number
    onMessage: (msg: string | object, topic: string) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headers?: Record<string, any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscribeHeaders: Record<string, any>
    autoReconnect?: boolean
    debug?: boolean
    heartbeat?: number
    heartbeatIncoming?: number
    heartbeatOutgoing?: number
    onConnectFailure?: (error: object) => void
}

//This component is extracted from the react-stomp package and has been modified
class SockJsClient extends React.Component<Props> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    client: any
    explicitDisconnect = false // False if disconnect method is called without a subsequent connect
    connected = false
    subscriptions = new Map()
    retryCount = 0
    timeoutId: ReturnType<typeof setTimeout> | null = null

    componentDidMount() {
        this.doConnect()
    }

    componentWillUnmount() {
        this.disconnect()
    }

    shouldComponentUpdate() {
        return false
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<Props>) {
        if (this.connected) {
            // Subscribe to new topics
            difference(nextProps.topics, this.props.topics).forEach((newTopic) => {
                this.doLog('Subscribing to topic: ' + newTopic)
                this.doSubscribe(newTopic)
            })

            // Unsubscribe from old topics
            difference(this.props.topics, nextProps.topics).forEach((oldTopic) => {
                this.doLog('Unsubscribing from topic: ' + oldTopic)
                this.doUnsubscribe(oldTopic)
            })
        }
    }

    doInitStompClient = () => {
        // Websocket held by stompjs can be opened only once
        this.client = Stomp.over(new SockJS(this.props.url, null, this.props.options))

        this.client.heartbeat.outgoing = this.props.heartbeat || 10000
        this.client.heartbeat.incoming = this.props.heartbeat || 10000

        if (Object.keys(this.props).includes('heartbeatIncoming')) {
            this.client.heartbeat.incoming = this.props.heartbeatIncoming
        }
        if (Object.keys(this.props).includes('heartbeatOutgoing')) {
            this.client.heartbeat.outgoing = this.props.heartbeatOutgoing
        }
        if (!this.props.debug) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.client.debug = () => {}
        }
    }

    doCleanUp = () => {
        this.connected = false
        this.retryCount = 0
        this.subscriptions.clear()
    }

    doLog = (msg: string) => {
        if (this.props.debug) {
            console.log(msg)
        }
    }

    doSubscribe = (topic: string) => {
        if (!this.subscriptions.has(topic)) {
            const subscribeHeaders = Object.assign({}, this.props.subscribeHeaders)
            const sub = this.client.subscribe(
                topic,
                (msg: Message) => {
                    //@ts-ignore
                    this.props.onMessage(this.doProcessMessage(msg.body), msg.headers.destination)
                },
                subscribeHeaders,
            )
            this.subscriptions.set(topic, sub)
        }
    }

    doProcessMessage = (msgBody: string) => {
        try {
            return JSON.parse(msgBody)
        } catch (_e) {
            return msgBody
        }
    }

    doUnsubscribe = (topic: string) => {
        const sub = this.subscriptions.get(topic)
        sub.unsubscribe()
        this.subscriptions.delete(topic)
    }

    doConnect = () => {
        const {
            autoReconnect = true,
            getRetryInterval = (count: number) => {
                return 1000 * count
            },
        } = this.props
        this.doInitStompClient()
        this.client.connect(
            this.props.headers,
            () => {
                this.connected = true
                if (this.explicitDisconnect) {
                    this.disconnect()
                } else {
                    this.props.topics.forEach((topic) => {
                        this.doSubscribe(topic)
                    })
                    this.props.onConnect?.()
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (error: any) => {
                if (error) {
                    if (Object.keys(this.props).includes('onConnectFailure')) {
                        this.props.onConnectFailure?.(error)
                    } else {
                        this.doLog(error.stack)
                    }
                }
                if (this.connected) {
                    this.doCleanUp()
                    // onDisconnect should be called only once per connect
                    this.props.onDisconnect?.()
                }
                if (error.headers?.message.includes('unauthorized')) {
                    this.explicitDisconnect = true
                }
                if (autoReconnect && !this.explicitDisconnect) {
                    this.timeoutId = setTimeout(this.doConnect, getRetryInterval(this.retryCount++))
                }
            },
        )
    }

    connect = () => {
        this.explicitDisconnect = false
        if (!this.connected) {
            this.doConnect()
        }
    }

    disconnect = () => {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
            this.timeoutId = null
        }
        this.explicitDisconnect = true
        if (this.connected) {
            this.subscriptions.forEach((subid, topic) => {
                this.doUnsubscribe(topic)
            })
            this.client.disconnect(() => {
                this.doCleanUp()
                this.props.onDisconnect?.()
                this.doLog('Stomp client is successfully disconnected!')
            })
        }
    }

    sendMessage = (topic: string, msg: string, optHeaders = {}) => {
        if (this.connected) {
            this.client.send(topic, optHeaders, msg)
        } else {
            throw new Error('Send error: SockJsClient is disconnected')
        }
    }

    render() {
        return null
    }
}

export { SockJsClient }
