import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledTextContainer = styled.div`
    margin: 0 auto;
`

export const StyledButton = styled.button<{ outlined: boolean; fullWidth: boolean }>`
    ${(props) => props.fullWidth && `width: 100%;`};
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 32px;
    border: ${(props) => `1px solid ${props.color}`};
    background: ${(props) => (props.outlined ? colors.white : props.color)};
    &:hover {
        ${(props) => !props.disabled && 'cursor: pointer;'}
    }
    &:active {
        ${(props) => !props.disabled && 'transform: scale(0.98);'}
    }
    ${(props) => props.disabled && 'opacity: 0.6;'}
`

export const StyledIconContainer = styled.span`
    padding: 0 5px 0 2px;
    display: flex;
    align-items: center;
`
