import { FingerUp } from 'assets/icons'
import { Button } from 'components/button/Button'
import { ParamApplication } from 'components/param-application/ParamApplication'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { setAuthorizationHeader } from 'httpServices/client'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { initServiceParams } from 'redux/user/thunks'
import { logout } from 'redux/user/userSlice'
import { NoPermissions } from 'screens/no-permissions/NoPermissions'
import { colors } from 'styles/colors'
import { H4 } from 'styles/typography'
import { ParameterProfileInfo, ServiceDTO } from 'types/api'
import { Permissions, ServiceCode } from 'types/api.enum'
import { hasPermission } from 'utils/util'

import packageJson from '../../../package.json'
import {
    StyledCardContainer,
    StyledCenterPage,
    StyledContainerButtons,
    StyledInfo,
    StyledInfoAccount,
    StyledLoaderContainer,
    StyledServicesContainer,
    StyledTabsContainer,
    StyledUpdatePasswordLink,
} from './style'
import { TabsParameters } from './TabsParameters'

export const Parameters = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { token, email, role } = useAppSelector((state) => state.user)
    const tokenWithoutBearer = token.split(' ')[1]
    const [selectedTabParam, setSelectedTabParam] = useState<ServiceDTO>()
    const [params, setParams] = useState<ServiceDTO[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [profileInfos, setProfileInfos] = useState<ParameterProfileInfo[]>([])

    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)

    const showParams: boolean =
        hasPermission(userPermissions, Permissions.MANAGE_RIGHT) ||
        hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)

    const handleLogout = () => {
        setAuthorizationHeader(undefined)
        dispatch(logout())
    }

    useEffect(() => {
        const app: ServiceDTO = {
            active: true,
            code: ServiceCode.APPLICATION,
            id: t(`services.${ServiceCode.APPLICATION}`),
            name: t(`services.${ServiceCode.APPLICATION}`),
        }

        setIsLoading(true)

        if (hasPermission(userPermissions, Permissions.MANAGE_RIGHT)) {
            dispatch(initServiceParams(restaurantFrNumber))
                .then((data) => setProfileInfos(data))
                .finally(() => setIsLoading(false))
        } else {
            setIsLoading(false)
        }

        setParams([app])
        setSelectedTabParam(app)

        return () => {
            setProfileInfos([])
        }
    }, [t, dispatch, restaurantFrNumber, userPermissions])

    if (isLoading) {
        return (
            <StyledLoaderContainer>
                <StyledCenterPage>
                    <ClipLoader size={70} color={colors.orange} loading={true} />
                </StyledCenterPage>
            </StyledLoaderContainer>
        )
    }

    const serviceSelectedByCode = (serviceParam: ServiceDTO | undefined) => {
        if (!serviceParam) return null
        const { code } = serviceParam
        switch (code) {
            case ServiceCode.APPLICATION:
                return {
                    icon: <FingerUp color={colors.brown} size={25} />,
                    content: showParams ? (
                        <ParamApplication
                            serviceParam={serviceParam}
                            restaurantFrNumber={restaurantFrNumber}
                            profileInfos={profileInfos}
                        />
                    ) : (
                        <NoPermissions />
                    ),
                    title: t(`services.servicesCode.${code}`),
                    subTitle: t('services.card') + t(`services.servicesCode.${code}`),
                }
            default:
                return null
        }
    }

    return (
        <StyledServicesContainer>
            <StyledTabsContainer>
                <TabsParameters
                    serviceSelectedByCode={serviceSelectedByCode}
                    parameters={params}
                    selectedTabParam={selectedTabParam}
                    onClick={(value) => setSelectedTabParam(value)}
                />
                <StyledContainerButtons>
                    <StyledUpdatePasswordLink
                        onClick={() =>
                            navigate(`${t('page.setPassword.route')}?token=${tokenWithoutBearer}&email=${email}`)
                        }
                    >
                        {t('page.parameters.updatePassword')}
                    </StyledUpdatePasswordLink>
                    <Button
                        text={t('component.button.logout')}
                        onClick={handleLogout}
                        color={colors.red}
                        TextStyle={H4}
                        style={{ width: '100%' }}
                    />
                    <StyledInfoAccount>
                        {t('page.parameters.infoAccount', { email, role: t(`role.${role}`) })}
                    </StyledInfoAccount>
                    <StyledInfo>
                        {t('page.parameters.info', {
                            version: packageJson.version,
                            year: new Date().getFullYear(),
                        })}
                    </StyledInfo>
                </StyledContainerButtons>
            </StyledTabsContainer>
            <StyledCardContainer>{serviceSelectedByCode(selectedTabParam)?.content}</StyledCardContainer>
        </StyledServicesContainer>
    )
}
