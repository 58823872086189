import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Calendar = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 14 16" {...otherProps}>
            <path
                d="M7 12.667a.667.667 0 100-1.333.667.667 0 000 1.333zm3.333 0a.667.667 0 100-1.333.667.667 0 000 1.333zm0-2.667a.667.667 0 100-1.333.667.667 0 000 1.333zM7 10a.667.667 0 100-1.333A.667.667 0 007 10zm4.666-8H11v-.667a.667.667 0 00-1.334 0V2H4.333v-.667a.667.667 0 00-1.333 0V2h-.667a2 2 0 00-2 2v9.333a2 2 0 002 2h9.333a2 2 0 002-2V4a2 2 0 00-2-2zm.667 11.334a.667.667 0 01-.667.666H2.333a.667.667 0 01-.667-.666v-6h10.667v6zm0-7.334H1.666V4c0-.368.299-.667.667-.667H3V4a.667.667 0 001.333 0v-.667h5.333V4A.667.667 0 1011 4v-.667h.666c.368 0 .667.299.667.667v2zm-8.667 4a.667.667 0 100-1.333.667.667 0 000 1.333zm0 2.667a.667.667 0 100-1.333.667.667 0 000 1.333z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
