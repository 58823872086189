import tabImg from 'assets/images/tab-img.webp'
import AccordionDetails from 'components/accordion/AccordionDetails'
import { TextList } from 'components/list-text/TextList'
import { Spacer } from 'components/spacer'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'
import { Text } from 'styles/typography'
import { AccordionDetailsKey, TutorialTab } from 'utils/enums'

import { StyledAccordionContainer } from './styles'

const HowItWorkDetails = () => {
    return (
        <>
            <StyledDetailsContainer>
                <Text>
                    <Trans
                        i18nKey={`tutorial.${TutorialTab.MANAGE_COMPLAINT}.${AccordionDetailsKey.HOW_IT_WORKS}.description`}
                    />
                </Text>
                <StyledImage src={tabImg} alt={'tab'} />
            </StyledDetailsContainer>
        </>
    )
}

const GoalDetails = () => {
    return (
        <StyledDetailsContainer>
            <Text>
                <Trans i18nKey={`tutorial.${TutorialTab.MANAGE_COMPLAINT}.${AccordionDetailsKey.GOAL}.description`} />
            </Text>
        </StyledDetailsContainer>
    )
}

export const ManageComplaintTab = () => {
    const { t } = useTranslation()

    return (
        <>
            <TextList
                backgroundColor={colors.lightMayo}
                textList={[t(`tutorial.${TutorialTab.MANAGE_COMPLAINT}.help`)]}
            />
            <Spacer s={SPACING.L} />
            <StyledAccordionContainer>
                <AccordionDetails
                    title={t(`tutorial.${TutorialTab.MANAGE_COMPLAINT}.${AccordionDetailsKey.GOAL}.title`)}
                    description={<GoalDetails />}
                    open
                />
                <AccordionDetails
                    title={t(`tutorial.${TutorialTab.MANAGE_COMPLAINT}.${AccordionDetailsKey.HOW_IT_WORKS}.title`)}
                    description={<HowItWorkDetails />}
                    open
                />
            </StyledAccordionContainer>
        </>
    )
}

const StyledDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: ${SPACING.XXXXL};
`

const StyledImage = styled.img`
    height: 234px;
    aspect-ratio: 1;
`
