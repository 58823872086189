import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const CommentInfo = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M9.0004 8.1665C8.54016 8.1665 8.16707 8.5396 8.16707 8.99984V11.4998C8.16707 11.9601 8.54016 12.3332 9.0004 12.3332C9.46064 12.3332 9.83374 11.9601 9.83374 11.4998V8.99984C9.83374 8.5396 9.46064 8.1665 9.0004 8.1665ZM9.0004 5.6665C8.54016 5.6665 8.16707 6.0396 8.16707 6.49984C8.16707 6.96008 8.54016 7.33317 9.0004 7.33317C9.46064 7.33317 9.83374 6.96008 9.83374 6.49984C9.83374 6.0396 9.46064 5.6665 9.0004 5.6665ZM9.0004 0.666504C4.39803 0.666504 0.667066 4.39746 0.667066 8.99984C0.659781 10.9241 1.32606 12.7903 2.5504 14.2748L0.883733 15.9415C0.647279 16.1811 0.578235 16.5395 0.708733 16.8498C0.85029 17.1565 1.16295 17.3474 1.5004 17.3332H9.0004C13.6028 17.3332 17.3337 13.6022 17.3337 8.99984C17.3337 4.39746 13.6028 0.666504 9.0004 0.666504ZM9.00039 15.6664H3.50872L4.28372 14.8914C4.44277 14.7355 4.5328 14.5224 4.53372 14.2997C4.53059 14.0799 4.44073 13.8703 4.28372 13.7164C2.03362 11.4688 1.68446 7.94478 3.44974 5.29934C5.21503 2.65389 8.60316 1.62373 11.5422 2.83883C14.4813 4.05392 16.1528 7.17588 15.5347 10.2956C14.9165 13.4153 12.1807 15.6639 9.00039 15.6664Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
