import React from 'react'

import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const ExclamationIcon2 = (props: IconProps) => {
    const { color = colors.brown, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" {...otherProps}>
            <path
                d="M24.8896 10.8672C23.9872 9.35681 22.7776 8.28801 21.2544 7.39201C19.6736 6.45761 18.2464 5.76001 16.2752 5.76001C14.304 5.76001 12.2048 6.28481 10.5984 7.25761C9.03681 8.20481 7.75041 9.53601 6.86081 11.1168C6.02881 12.6016 5.76001 14.2272 5.76001 16.032C5.76001 17.3632 6.04161 18.6624 6.50881 19.8336C7.06561 21.2352 8.05761 22.3488 9.13921 23.3792C10.336 24.5184 11.552 25.3824 13.1904 25.8688C14.1504 26.1504 15.36 26.2464 16.4096 26.2464C18.7008 26.2464 20.3904 25.5616 22.1184 24.3392C23.7248 23.2 24.8576 22.0416 25.6128 20.2176C26.112 19.008 26.2336 17.3888 26.2336 16.0064C26.2336 14.0928 25.8048 12.416 24.8832 10.88L24.8896 10.8672ZM16 22.9376C14.5088 22.9376 13.664 22.0352 13.664 20.9472C13.664 19.8592 14.5088 18.9568 16 18.9568C17.4912 18.9568 18.336 19.8592 18.336 20.9472C18.336 22.0352 17.4848 22.9376 16 22.9376ZM18.8224 12.2432C18.336 13.7152 17.92 15.2 17.8176 16.3456C17.696 17.664 17.0752 18.112 16.0704 18.112H15.9168C14.9312 18.112 14.3104 17.664 14.1888 16.3456C14.0864 15.2064 13.6704 13.7152 13.184 12.2432C13.152 12.1408 13.1328 12.0192 13.1328 11.9296C13.1328 10.944 14.3424 10.336 16.0064 10.336C17.6704 10.336 18.88 10.9568 18.88 11.9296C18.88 12.032 18.8608 12.1344 18.8288 12.2432H18.8224Z"
                fill={color}
            />
        </svg>
    )
}
