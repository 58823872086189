import styled from 'styled-components'

import { CircledIcon } from '../../../../../../components/circled-icon/CircledIcon'
import { colors } from '../../../../../../styles/colors'
import { SPACING } from '../../../../../../styles/tokens'
import { Text } from '../../../../../../styles/typography'

export type SmallCardProps = {
    icon: JSX.Element
    title: string
    description: string
}

export const SmallLinkCard = ({ icon, title, description }: SmallCardProps) => {
    return (
        <StyledTitleLinkContainer>
            <CircledIcon icon={icon} size={SPACING.XXS} />
            <StyledTextContainer>
                <Text color={colors.brown}>{title}</Text>
                <Text>{description}</Text>
            </StyledTextContainer>
        </StyledTitleLinkContainer>
    )
}

const StyledTitleLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
`
