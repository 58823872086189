import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

export const Paperclip = ({ color = colors.brownDark, size = 20, ...otherProps }: IconProps) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...otherProps}>
        <g id=" paperclip">
            <path
                fill={color}
                id="&#240;&#159;&#140;&#136; COLOR"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0658 10.3499L9.91575 15.5082C8.51417 16.7538 6.38462 16.6911 5.05873 15.3652C3.73284 14.0393 3.67015 11.9098 4.91575 10.5082L11.5824 3.84152C12.409 3.05633 13.7058 3.05633 14.5324 3.84152C15.3394 4.6593 15.3394 5.97374 14.5324 6.79152L8.78242 12.5332C8.6276 12.6999 8.3956 12.7714 8.17381 12.7207C7.95201 12.67 7.77412 12.5048 7.70714 12.2873C7.64016 12.0699 7.69427 11.8332 7.84909 11.6665L12.1241 7.39985C12.4509 7.07309 12.4509 6.54329 12.1241 6.21652C11.7973 5.88975 11.2675 5.88975 10.9408 6.21652L6.66575 10.4999C6.23369 10.9286 5.99068 11.512 5.99068 12.1207C5.99068 12.7293 6.23369 13.3128 6.66575 13.7415C7.57338 14.6061 8.9998 14.6061 9.90742 13.7415L15.6491 7.99152C17.0259 6.51397 16.9853 4.21147 15.5572 2.78341C14.1291 1.35535 11.8266 1.31473 10.3491 2.69152L3.68242 9.35819C1.81472 11.4268 1.9064 14.5991 3.89045 16.5563C5.8745 18.5136 9.04774 18.5622 11.0908 16.6665L16.2491 11.5165C16.4605 11.3051 16.543 10.997 16.4657 10.7083C16.3883 10.4195 16.1627 10.194 15.874 10.1166C15.5852 10.0393 15.2771 10.1218 15.0658 10.3332V10.3499Z"
            />
        </g>
    </svg>
)
