import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const ShoppingBag = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M7.084 3.448c.28-.703.77-1.31 1.409-1.739a3.965 3.965 0 012.173-.672 3.277 3.277 0 011.697.192 3.173 3.173 0 011.35 1.014c.223.376.387.782.485 1.205H12.37c-.286-.71-.846-.807-1.704-.771-.317.007-.628.08-.913.213a2.218 2.218 0 00-.741.558H7.084zM18.85 16.71c-.236-3.098-.684-11.633-.684-11.633H3.391l-.386 12.212c0 .977-.15 1.664 1.157 1.736 1.306.072 3.072.097 4.887.097h7.462c3.01 0 2.487-.664 2.4-2.412"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    )
}
