import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'

export const StyledContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: ${colors.shadowDark};
`

export const StyledContent = styled.div<{ center: boolean }>`
    position: absolute;
    box-shadow: 0px 2px 4px ${colors.shadow};
    width: fit-content;
    max-width: 100%;
    ${(props) =>
        props.center &&
        `
        margin-left: auto;
        margin-right: auto;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        top: 50%;
    `}
`

export const StyledIconContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: ${SPACING.M};
    cursor: pointer;
`
