import React from 'react'
import SwitchComponent from 'react-switch'

interface Props {
    onChange: () => void
    checked: boolean
    [key: string]: unknown
}

export const Switch = (props: Props) => {
    const { onChange, checked, ...otherProps } = props
    return (
        <SwitchComponent
            onChange={onChange}
            checked={checked}
            checkedIcon={false}
            uncheckedIcon={false}
            {...otherProps}
        />
    )
}
