import styled from 'styled-components'
import { colors } from 'styles/colors'
import { H2 } from 'styles/typography'

export const StyledParamContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0;
    box-shadow: inset 0px -1px 0px ${colors.shadow};
    box-sizing: border-box;
`

export const StyledToggleContainer = styled.div`
    display: flex;
    width: 110px;
    justify-content: space-between;
    align-items: center;
`

export const StyledH2 = styled(H2)`
    margin-bottom: 24px;
    color: ${colors.brown};
`

export const StyledTitleParameters = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledContentParameters = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
`
