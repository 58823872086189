import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledContainer = styled.div`
    width: 45vw;
    padding: 32px;
    background-color: ${colors.white};
    border-radius: 8px;
    & > h4 {
        margin-top: 15px;
    }
`

export const StyledButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: fit-content;
`

export const StyledButtonContainer = styled.div`
    margin: 30px 5px 10px;
`
