import { useAppSelector } from 'hooks/useRedux'

import { SockJsClient } from './SockJsClient'

interface Props {
    onMessage: (message: string | object, topic: string) => void
    onConnect?: () => void
    onConnectFailure?: () => void
    onDisconnect?: () => void
    topics: string[]
}

export const Websocket = (props: Props) => {
    const { onMessage, onConnect, onConnectFailure, onDisconnect, topics } = props
    const token = useAppSelector((state) => state.user.token)
    const headers = { Authorization: token }

    return (
        <SockJsClient
            url={process.env.REACT_APP_WEBSOCKET || ''}
            topics={topics}
            onMessage={onMessage}
            onConnect={onConnect}
            onConnectFailure={onConnectFailure}
            onDisconnect={onDisconnect}
            headers={headers}
            subscribeHeaders={headers}
        />
    )
}
