import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const CheckIcon = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M18.588 4.379a1.281 1.281 0 00-1.819 0l-9.542 9.555-4.009-4.022A1.309 1.309 0 101.4 11.795l4.918 4.918a1.28 1.28 0 001.818 0L18.588 6.262a1.28 1.28 0 000-1.883z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
