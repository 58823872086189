import { Button } from 'components/button/Button'
import styled from 'styled-components'
import { fonts } from 'styles/fonts'
import { SPACING } from 'styles/tokens'
import { H4 } from 'styles/typography'

export const StyledH4Info = styled(H4)`
    font-family: ${fonts.flameSans};
    margin: -1px 0 0 10px;
`

export const StyledIconContainer = styled.div`
    display: flex;
    align-items: center;
    padding: ${SPACING.XXS};
`

export const StyledButton = styled(Button)`
    padding: ${SPACING.XXS};
`
