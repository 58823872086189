import styled from 'styled-components'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'

export const StyledContainer = styled.div`
    width: 40vw;
    padding: 32px;
    background-color: ${colors.white};
    border-radius: 8px;
    & > h2 {
        margin-bottom: 8px;
    }
`

export const StyledButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    width: fit-content;
`

export const StyledButtonContainer = styled.div`
    margin: 30px 5px 10px;
`

export const StyledScheduleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 34px;
`

export const StyledLineContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px;
`

export const StyledDayContainer = styled.div`
    display: flex;
    align-items: center;
    & > span {
        margin-left: 15px;
    }
`

export const StyledTimeContainer = styled.div`
    display: flex;
    align-items: center;
    & > div {
        margin: 0 5px;
    }
`

export const StyledErrorText = styled(Text)<{ visible: boolean }>`
    margin-top: 10px;
    color: ${colors.red};
    ${(props) => !props.visible && 'visibility: hidden'};
`
