import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Phone = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M9.623 4.113c0 .188.06.385.103.573.084.382.195.756.333 1.121a1.711 1.711 0 01-.855 2.122l-.385.188a10.42 10.42 0 001.71 2.276 10.42 10.42 0 002.277 1.711l.24-.36a1.711 1.711 0 012.12-.855c.367.136.741.248 1.122.334.188.043.385.077.581.103A2.567 2.567 0 0119 13.892v2.567a2.567 2.567 0 01-2.917 2.566A16.255 16.255 0 012 4.892v-.325c0-.631.23-1.24.65-1.711A2.567 2.567 0 014.575 2h2.567a2.567 2.567 0 012.48 2.113zM4.49 3.685a.856.856 0 00-.642.291.898.898 0 00-.214.702A14.544 14.544 0 0016.28 17.263a.933.933 0 00.702-.214.855.855 0 00.29-.642v-2.566a.856.856 0 00-.675-.856 9.25 9.25 0 01-.693-.128 9.526 9.526 0 01-1.326-.394l-.557 1.198a.855.855 0 01-1.137.42 12.397 12.397 0 01-5.99-5.99.856.856 0 010-.65.856.856 0 01.446-.487l1.197-.53c-.158-.443-.29-.894-.393-1.353a9.275 9.275 0 01-.128-.693.856.856 0 00-.856-.675l-2.67-.018z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
