import { CheckCircle, CrownVIP, Play } from 'assets/icons'
import birthdayLogoImage from 'assets/images/bk-nniversaire.webp'
import noDataImage from 'assets/images/lemon-girl.webp'
import { BackButton } from 'components/back-button/BackButton'
import { BirthdayStatusFilters } from 'components/birthday-reservations/BirthdayStatusFilters'
import { ErrorBlock } from 'components/error-block/ErrorBlock'
import { useAppSelector } from 'hooks/useRedux'
import { useTabsFilter } from 'hooks/useTabsFilter'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { Tabs } from 'screens/tabs/Tabs'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { Text } from 'styles/typography'
import { BirthdayBookingListItemDTO } from 'types/api'
import { Tab } from 'types/tab'
import { FilterType, StatusType } from 'utils/enums'
import { combineDateAndTimeStringsIntoDate, formatBookingNumber, isPast } from 'utils/util'

import { Status } from '../../components/birthday-reservations/Status'
import {
    StyledCenterPage,
    StyledColumn,
    StyledColumnTitle,
    StyledContainer,
    StyledCrownContainer,
    StyledGrid,
    StyledH2,
    StyledImage,
    StyledManagementContainer,
    StyledManagementTitle,
    StyledManagementTitleContainer,
    StyledRow,
    StyledShowMoreButton,
    StyledTitleButtonContainer,
    StyledTitleText,
} from './style'

export const BirthdayManagement = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const allReservations = useAppSelector((state) => state.birthdays.birthdays)
    const [reservations, setReservations] = useState<BirthdayBookingListItemDTO[]>(allReservations)
    const [searchParams, setSearchParams] = useSearchParams()
    const [pastReservations, setPastReservations] = useState<BirthdayBookingListItemDTO[]>([])
    const [futureReservations, setFutureReservations] = useState<BirthdayBookingListItemDTO[]>([])

    const tabs: Tab[] = [
        {
            icon: <Play size={15} />,
            label: t('services.birthday.tabs.current'),
            number: futureReservations.length,
            status: StatusType.COMING_SOON,
            onClick: () => onFilterChange(FilterType.STATUS, StatusType.COMING_SOON),
        },
        {
            icon: <CheckCircle size={15} />,
            label: t('services.birthday.tabs.end'),
            number: pastReservations.length,
            status: StatusType.FINISHED,
            onClick: () => onFilterChange(FilterType.STATUS, StatusType.FINISHED),
        },
    ]

    const ColumnTextItem = ({ value }: { value: string }) => (
        <StyledColumn>
            <Text color={colors.blackLight}>{value}</Text>
        </StyledColumn>
    )

    type GridTitleProps = { radiusLeft?: boolean; radiusRight?: boolean; children?: React.ReactNode }

    const GridTitle = ({ children, radiusLeft, radiusRight }: GridTitleProps) => (
        <StyledColumnTitle radiusLeft={radiusLeft} radiusRight={radiusRight}>
            <StyledTitleText>{children}</StyledTitleText>
        </StyledColumnTitle>
    )

    const Row = ({ item, index }: { item: BirthdayBookingListItemDTO; index: number }) => {
        const {
            id = '',
            bookingNumber,
            vip,
            status,
            reservationDate = '',
            reservationHour = '',
            attendantPhoneNumber = '',
            gift = '',
        } = item
        const date = new Date(reservationDate ?? '')
        const formattedDate = date.toLocaleDateString('fr-FR')
        const [hours, minutes] = reservationHour.split(':').slice(0, 2)
        const formattedHour = `${hours}:${minutes}`
        return (
            <StyledRow key={index}>
                <StyledColumn>
                    {vip && (
                        <StyledCrownContainer>
                            <CrownVIP color={colors.orange} />
                            <Text color={colors.brown}>{t('orders.vip')}</Text>
                        </StyledCrownContainer>
                    )}
                </StyledColumn>
                <StyledColumn>{status && <Status birthdayStatus={status} />}</StyledColumn>
                <StyledColumn>
                    <Text color={colors.brown}>{formatBookingNumber(id, bookingNumber)}</Text>
                </StyledColumn>
                <ColumnTextItem value={formattedDate} />
                <ColumnTextItem value={formattedHour} />
                <ColumnTextItem value={attendantPhoneNumber} />
                <ColumnTextItem value={gift} />
                <StyledColumn>
                    <StyledShowMoreButton onClick={() => navigate(`${id}`)}>
                        <Text color={colors.white} font={fonts.flame}>
                            {t('component.button.more')}
                        </Text>
                    </StyledShowMoreButton>
                </StyledColumn>
            </StyledRow>
        )
    }

    const onFilterChange = (label: string, value?: string) => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString())
        if (value) {
            updatedSearchParams.set(label, value)
        } else {
            updatedSearchParams.delete(label)
        }
        setSearchParams(updatedSearchParams)
    }

    useEffect(() => {
        setPastReservations(
            reservations.filter((reservation) => {
                return (
                    reservation.reservationDate &&
                    reservation.reservationHour &&
                    isPast(combineDateAndTimeStringsIntoDate(reservation.reservationDate, reservation.reservationHour))
                )
            }),
        )
        setFutureReservations(
            reservations.filter((reservation) => {
                return (
                    reservation.reservationDate &&
                    reservation.reservationHour &&
                    !isPast(combineDateAndTimeStringsIntoDate(reservation.reservationDate, reservation.reservationHour))
                )
            }),
        )
    }, [reservations])

    const { tabSelectedIndex } = useTabsFilter(tabs)

    useEffect(() => {
        if (allReservations.length > 0) {
            setIsLoading(false)
            setReservations(allReservations)
        }
    }, [allReservations])

    if (isLoading) {
        return (
            <>
                <StyledContainer>
                    <StyledCenterPage>
                        <ClipLoader size={70} color={colors.orange} loading={true} />
                    </StyledCenterPage>
                </StyledContainer>
            </>
        )
    }

    return (
        <>
            <Tabs
                tabs={tabs}
                index={tabSelectedIndex}
                headerComponent={
                    <StyledManagementTitleContainer>
                        <StyledTitleButtonContainer>
                            <BackButton
                                onClick={() => {
                                    navigate(t('page.services.route'))
                                }}
                            />
                            <StyledManagementTitle>
                                <StyledH2>{t('services.birthday.management')}</StyledH2>
                            </StyledManagementTitle>
                        </StyledTitleButtonContainer>
                        <StyledImage src={birthdayLogoImage} />
                    </StyledManagementTitleContainer>
                }
            />
            <StyledContainer>
                <StyledManagementContainer>
                    <BirthdayStatusFilters reservations={reservations} setReservations={setReservations} />
                    <StyledGrid>
                        <tbody>
                            <StyledRow>
                                <GridTitle radiusLeft />
                                <GridTitle>{t('services.birthday.gridItems.status')}</GridTitle>
                                <GridTitle>{t('services.birthday.gridItems.number')}</GridTitle>
                                <GridTitle>{t('services.birthday.gridItems.date')}</GridTitle>
                                <GridTitle>{t('services.birthday.gridItems.hour')}</GridTitle>
                                <GridTitle>{t('services.birthday.gridItems.parentPhone')}</GridTitle>
                                <GridTitle>{t('services.birthday.gridItems.gift')}</GridTitle>
                                <GridTitle radiusRight />
                            </StyledRow>
                            {(searchParams.toString() === 'status=COMING_SOON' || searchParams.toString() === '') &&
                                futureReservations.map((item, index) => <Row item={item} index={index} key={index} />)}
                            {searchParams.toString() === 'status=FINISHED' &&
                                pastReservations.map((item, index) => <Row item={item} index={index} key={index} />)}
                        </tbody>
                    </StyledGrid>
                    {(searchParams.toString() === 'status=COMING_SOON' || searchParams.toString() === '') &&
                        futureReservations.length === 0 && (
                            <ErrorBlock
                                image={noDataImage}
                                titleKey="orders.empty"
                                descriptionKey="services.birthday.noData"
                            />
                        )}
                    {searchParams.toString() === 'status=FINISHED' && pastReservations.length === 0 && (
                        <ErrorBlock
                            image={noDataImage}
                            titleKey="orders.empty"
                            descriptionKey="services.birthday.noData"
                        />
                    )}
                </StyledManagementContainer>
            </StyledContainer>
        </>
    )
}
