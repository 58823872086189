import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const DownloadIcon = (props: IconProps) => {
    const { color = defaultGrey, size = 12, ...otherProps } = props
    return (
        <svg width={size} height={(size / 12) * 11} viewBox="0 0 12 11" fill="none" {...otherProps}>
            <g id="Calque_3">
                <path
                    id="Vector"
                    d="M5.85748 7.79691C5.97908 7.76171 6.27988 7.46411 6.35668 7.36491C7.06068 6.46891 7.48948 6.01131 8.19348 5.11851C8.42068 4.83051 8.74708 4.23851 8.42388 3.88971C8.17108 3.61451 7.78388 3.69771 7.36148 3.72011L6.91988 3.70411C6.92628 2.33451 6.85588 2.48171 6.82388 0.993708C6.81428 0.536108 6.40788 0.187308 5.95028 0.248108L5.59828 0.296108C5.20148 0.350508 4.91348 0.699308 4.93588 1.09931C5.00628 2.45611 4.99348 2.34411 4.98708 3.63691C4.75028 3.64971 4.17108 3.60171 3.93428 3.61131C3.66228 3.62411 3.24628 3.79371 3.25908 4.25451C3.27508 4.82731 4.73108 6.66091 5.28468 7.47691C5.41588 7.67211 5.63348 7.86731 5.85748 7.80011V7.79691Z"
                    fill={color}
                />
                <path
                    id="Vector_2"
                    d="M11.1028 6.5645H10.8756C10.6932 6.5549 10.3188 6.7021 10.3188 6.8845C10.3188 8.4397 10.3188 8.1261 10.3252 8.2765C10.3316 8.5709 10.4116 9.2685 10.0244 9.3645L9.76196 9.3933C9.55076 9.4477 9.33636 9.4221 9.11876 9.4317C8.75076 9.4477 8.37956 9.4221 8.01156 9.4317C7.68196 9.4413 2.04676 9.5117 1.72356 9.4381C1.41316 9.3677 1.51236 9.0125 1.51236 8.7373C1.51236 8.4941 1.51236 8.6893 1.50596 6.8749C1.50596 6.6893 1.08356 6.5293 0.917157 6.5293C0.757156 6.5293 0.149156 6.6957 0.152356 6.8845C0.171556 7.9885 0.139556 9.6109 0.174756 10.0429C0.229156 10.7277 0.686756 10.8813 1.08676 10.8813C3.97956 10.8813 6.05956 11.0477 8.61636 10.9517C9.19876 10.9293 9.58276 10.9101 10.162 10.8589C10.5844 10.8237 11.426 10.8973 11.6116 10.3789C11.6692 10.2189 11.6692 10.0461 11.6692 9.8765C11.6692 9.2237 11.6692 8.5293 11.6692 6.8845C11.6692 6.7117 11.2724 6.5709 11.0996 6.5613L11.1028 6.5645Z"
                    fill={color}
                />
            </g>
        </svg>
    )
}
