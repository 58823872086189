import { ArrowRightIcon, Cross } from 'assets/icons'
import { useClickOutside } from 'hooks/useClickOutside'
import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { DateRange } from 'types/date-range'
import { formatDate } from 'utils/util'

import { StyledCalendarButton, StyledCalendarContainer, StyledClearFilter } from './style'

interface DateRangePickerProps {
    onChange: (value: (Date | null)[]) => void
    dates: DateRange
    setIsOpen: (open: boolean) => void
    isCalendarOpen: boolean
    range?: number
    minDate?: Date
}

export const DateRangePicker = ({
    onChange,
    dates,
    setIsOpen,
    isCalendarOpen,
    range = 6,
    minDate,
}: DateRangePickerProps) => {
    const { t } = useTranslation()
    const startDate = dates.startDate
    const endDate = dates.endDate
    const interval = startDate ? new Date(startDate) : new Date()

    if (startDate) {
        interval.setDate(interval.getDate() + range)
    }

    const ref: React.RefObject<HTMLInputElement> = useRef(null)
    useClickOutside(ref, () => setIsOpen(false))

    const handleClick = () => {
        setIsOpen(!isCalendarOpen)
    }

    return (
        <>
            <StyledCalendarButton className="example-custom-input" onClick={handleClick}>
                {`${startDate ? formatDate(startDate) : t('time.start')}`}
                <ArrowRightIcon color={colors.blackLight} />
                {`${endDate ? formatDate(endDate) : t('time.end')}`}
            </StyledCalendarButton>
            {startDate && endDate && (
                <StyledClearFilter>
                    <Cross
                        color={colors.blackLight}
                        size={12}
                        onClick={() => {
                            onChange([null, null])
                        }}
                    />
                </StyledClearFilter>
            )}

            {isCalendarOpen && (
                <StyledCalendarContainer ref={ref}>
                    <DatePicker
                        locale={'fr'}
                        maxDate={interval > new Date() ? new Date() : interval}
                        closeOnScroll={(e) => {
                            setIsOpen(false)
                            return e.target === document
                        }}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            onChange(update)
                        }}
                        inline
                        minDate={minDate}
                    />
                </StyledCalendarContainer>
            )}
        </>
    )
}
