import { Calendar } from 'assets/icons'
import { useClickOutside } from 'hooks/useClickOutside'
import { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { H4, Text } from 'styles/typography'
import { formatDate } from 'utils/util'

interface DatePickerMeetingRestaurantProps {
    date: Date | null
    onClick: (date: Date) => void
}

export const DatePickerMeetingRestaurant = ({ date, onClick }: DatePickerMeetingRestaurantProps) => {
    const { t } = useTranslation()
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)
    const ref: React.RefObject<HTMLInputElement> = useRef(null)
    useClickOutside(ref, () => setIsCalendarOpen(false))

    const toggleOpenCalendar = () => {
        setIsCalendarOpen((x) => !x)
    }

    const handleDateChange = (date: Date) => {
        onClick(date)
        toggleOpenCalendar()
    }

    return (
        <StyledCalendarContainer>
            <H4 font={fonts.flameSans} color={colors.blackLight}>
                {t('complaints.messageModal.selectDate')}
            </H4>
            <div>
                <StyledCalendarButton className="example-custom-input" onClick={toggleOpenCalendar}>
                    <StyledLabel>{t('complaints.messageModal.label')}</StyledLabel>
                    <StyledDateInput>
                        <Text color={colors.blackLight}>
                            {date ? formatDate(date) : t('complaints.messageModal.initialValue')}
                        </Text>
                        <Calendar color={colors.brownDark} />
                    </StyledDateInput>
                </StyledCalendarButton>

                <StyledCalendar ref={ref}>
                    {isCalendarOpen && (
                        <DatePicker
                            locale={'fr'}
                            selected={date}
                            onChange={handleDateChange}
                            inline
                            minDate={new Date()}
                        />
                    )}
                </StyledCalendar>
            </div>
        </StyledCalendarContainer>
    )
}

const StyledCalendarContainer = styled.div`
    display: flex;
    margin-top: ${SPACING.S};
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${SPACING.S};
    position: relative;
    width: 100%;
`

const StyledCalendarButton = styled.div`
    &:hover {
        cursor: pointer;
    }
    display: flex;
    flex-direction: column;
    position: relative;
    vertical-align: top;
    width: 240px;
    box-sizing: border-box;
`

const StyledCalendar = styled.div`
    position: absolute;
    z-index: 1;
`

const StyledLabel = styled(Text)`
    color: ${colors.blackLight};
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(${SPACING.XS}, -${SPACING.XS}) scale(0.75);
    z-index: 1;
    background-color: ${colors.white};
    padding: 0 ${SPACING.XXS};
`

const StyledDateInput = styled.div`
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: ${BORDER.RADIUS.S};
    padding-left: ${SPACING.S};
    border: 1px solid ${colors.greyLight};
    padding: ${SPACING.XS};
    box-sizing: border-box;
    justify-content: space-between;
`
