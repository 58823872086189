import { Action, combineReducers, configureStore } from '@reduxjs/toolkit'
import { complaintReducer } from 'redux/complaint/complaintSlice'
import { orderReducer } from 'redux/order/orderSlice'
import { restaurantReducer } from 'redux/restaurant/restaurantSlice'
import { userReducer } from 'redux/user/userSlice'
import { persistStore } from 'redux-persist'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import { LocalStorageKey } from 'utils/enums'

import { birthdayReducer } from './birthday/BirthdaySlice'

export enum PersistSlice {
    USER = 'user',
    RESTAURANT = 'restaurant',
}

export enum ActionType {
    RESET_REDUX_CACHE = 'RESET_REDUX_CACHE',
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [PersistSlice.USER, PersistSlice.RESTAURANT],
}

const rootReducer = combineReducers({
    complaint: complaintReducer,
    order: orderReducer,
    restaurant: restaurantReducer,
    birthdays: birthdayReducer,
    user: userReducer,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appReducer = (state: any, action: Action) => {
    if (action.type === ActionType.RESET_REDUX_CACHE) {
        localStorage.removeItem(LocalStorageKey.PERSIST_ROOT)
        return rootReducer(undefined, action)
    }
    return rootReducer(state, action)
}

export const store = configureStore({
    reducer: persistReducer(persistConfig, appReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export const persistor = persistStore(store)
