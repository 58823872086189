import { createAsyncThunk } from '@reduxjs/toolkit'
import { services } from 'httpServices/services'
import { DateRange } from 'types/date-range'
import { AppDispatch } from 'types/redux'
import { sortByDate } from 'utils/util'

import { setCurrentOrder, setFinishedOrders, setPendingOrders } from './orderSlice'

export const fetchOrder = (orderId: string) => async (dispatch: AppDispatch) => {
    const response = await services.order.fetch(orderId)
    const order = response?.data?.order
    if (order) {
        dispatch(setCurrentOrder(order))
    }
    return order
}

type fetchOrdersListThunkArg = {
    frNumber: string
    dateRange?: DateRange
}

export const fetchPendingOrderList = createAsyncThunk(
    'orders/fetchPendingOrderList',
    async (thunkArg: fetchOrdersListThunkArg, { dispatch }) => {
        const { frNumber } = thunkArg
        const response = await services.order.fetchPendingOrderList(frNumber)
        const orders = response?.data?.orders ?? []
        if (orders) {
            orders.sort((a, b) => sortByDate(a.createdDate, b.createdDate))
        }
        dispatch(setPendingOrders(orders))
        return orders
    },
)

export const fetchFinishedOrderList = createAsyncThunk(
    'orders/fetchFinishedOrderList',
    async (thunkArg: fetchOrdersListThunkArg, { dispatch }) => {
        const { frNumber, dateRange } = thunkArg
        const response = await services.order.fetchFinishedOrderList(frNumber, dateRange)
        const orders = response?.data?.orders ?? []
        if (orders) {
            orders.sort((a, b) => sortByDate(a.createdDate, b.createdDate))
        }
        dispatch(setFinishedOrders(orders))
        return orders
    },
)

export const integrateOrder = (orderId: string) => async () => {
    const response = await services.order.integrate(orderId)
    return response.data
}
