import { CheckCircle } from 'assets/icons'
import { Modal } from 'components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { BirthdayStatus } from 'types/api.enum'

import { StyledH2, StyledModalContainer, StyledValidationButton, StyledWhiteH4 } from './style'

interface ConfirmModalProps {
    handleClose: () => void
    status: BirthdayStatus | undefined
}

export const ConfirmModal = ({ handleClose, status }: ConfirmModalProps) => {
    const { t } = useTranslation()

    const renderH2 = (status?: BirthdayStatus) => {
        switch (status) {
            case BirthdayStatus.STANDBY:
                return <StyledH2> {t('services.birthday.confirmModal.reservationModified')} </StyledH2>
            case BirthdayStatus.CONFIRMED:
                return <StyledH2>{t('services.birthday.confirmModal.reservationValidated')}</StyledH2>
            case BirthdayStatus.REFUSED:
                return <StyledH2>{t('services.birthday.confirmModal.reservationCancelled')}</StyledH2>
            default:
                return <></>
        }
    }

    const renderText = (status?: BirthdayStatus) => {
        switch (status) {
            case BirthdayStatus.STANDBY:
                return <Text>{t('services.birthday.confirmModal.modificationMail')}</Text>
            case BirthdayStatus.CONFIRMED:
                return <Text>{t('services.birthday.confirmModal.validationMail')}</Text>
            case BirthdayStatus.REFUSED:
                return <Text>{t('services.birthday.confirmModal.cancellationMail')}</Text>
            default:
                return <></>
        }
    }

    return (
        <Modal handleClose={handleClose} closeIconColor={colors.brown} canCloseOnClickOutside={false}>
            <StyledModalContainer>
                <CheckCircle color={colors.brown} size={150} />
                {renderH2(status)}
                {renderText(status)}
                <StyledValidationButton onClick={handleClose}>
                    <StyledWhiteH4>{t('component.button.understood')}</StyledWhiteH4>
                </StyledValidationButton>
            </StyledModalContainer>
        </Modal>
    )
}
