import { Paperclip } from 'assets/icons'
import styled from 'styled-components'
import { Text } from 'styles/typography'
import { ComplaintAttachmentDTO } from 'types/api'

type AttachmentDownloadProps = {
    attachment: ComplaintAttachmentDTO
} & Pick<AttachmentsListProps, 'complaintId'>

const AttachmentDownload = ({ attachment, complaintId }: AttachmentDownloadProps) => {
    const filename = attachment.extension ? `IMG${complaintId}.${attachment.extension}` : undefined
    return (
        <StyledLinkContainer>
            <StyledAttachmentsListLink href={attachment.url} download={filename}>
                <Paperclip />
                <Text color="#845f50">{filename}</Text>
            </StyledAttachmentsListLink>
        </StyledLinkContainer>
    )
}
type AttachmentsListProps = {
    complaintId?: string
    attachments?: ComplaintAttachmentDTO[]
}

export const AttachmentsList = ({ attachments, complaintId }: AttachmentsListProps) => {
    if (!attachments || attachments.length === 0) return null

    return (
        <>
            {attachments.map((attachment) => (
                <AttachmentDownload key={complaintId} attachment={attachment} complaintId={complaintId} />
            ))}
        </>
    )
}

const StyledLinkContainer = styled.div`
    margin-top: 18px;
    margin-bottom: 6px;
    display: flex;
`

const StyledAttachmentsListLink = styled.a`
    display: flex;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        color: #845f50;
    }
`
