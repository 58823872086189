import { Checkbox } from 'components/checkbox/Checkbox'
import { Notif } from 'components/notif/Notif'
import { SwitchText } from 'components/switch-text/SwitchText'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toggleService, toggleSwap } from 'redux/restaurant/thunks'
import { colors } from 'styles/colors'
import { H2, H4, Text } from 'styles/typography'
import { RestaurantDTO, ServiceDTO } from 'types/api'
import { ServiceCode } from 'types/api.enum'
import { NotyfType } from 'utils/enums'
import { formatTimeFromString, getNewServices } from 'utils/util'

import { StyledParamContainer } from './style'

interface ServiceParkingDriveProps {
    service: ServiceDTO
    restaurant: RestaurantDTO
    setServices: (services: ServiceDTO[]) => void
    setRestaurant: (restaurant: RestaurantDTO) => void
    selectedTabService: (service: ServiceDTO | undefined) => void
    disabled?: boolean
}

export const ServiceParkingDrive = ({
    service,
    restaurant,
    setServices,
    setRestaurant,
    selectedTabService,
    disabled = false,
}: ServiceParkingDriveProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [isDisabled, setDisabled] = useState(disabled)
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeService, setActiveService] = useState(service.code)
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const isParking = activeService === ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING
    // const [intervalParkingTime, setIntervalParkingTime] =
    //     useState<{ startTime: string; endTime: string }>();

    // useEffect(() => {
    //     dispatch(fetchIntervalParkingTime(restaurantFrNumber))
    //         .then((intervalParkingTime) => {
    //             setIntervalParkingTime(intervalParkingTime);
    //         })
    //         .catch((error: any) => console.error(error));
    // }, [dispatch, restaurantFrNumber, setIntervalParkingTime]);

    const switchArray = [
        {
            value: ServiceCode.NO_DRIVE_PARKING,
            label: t(`services.driveParking.${ServiceCode.NO_DRIVE_PARKING}`),
        },
        {
            value: ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING,
            label: t(`services.driveParking.${ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING}`),
        },
        {
            value: ServiceCode.CLICK_AND_COLLECT_DRIVE,
            label: t(`services.driveParking.${ServiceCode.CLICK_AND_COLLECT_DRIVE}`),
        },
    ]

    const onChangeMode = (serviceCode: ServiceCode) => {
        const oldValue = activeService
        const { serviceParking, serviceDrive, serviceNoDriveParking } = getNewServices(serviceCode, restaurantFrNumber)
        const serviceToSet = [
            {
                ...service,
                active: serviceParking.enabled,
                code: serviceParking.serviceCode,
            },
            {
                ...service,
                active: serviceDrive.enabled,
                code: serviceDrive.serviceCode,
            },
            {
                ...service,
                active: serviceNoDriveParking.enabled,
                code: serviceNoDriveParking.serviceCode,
            },
        ]

        setActiveService(serviceCode)
        setDisabled(true)
        dispatch(toggleService(serviceParking))
            .then(() => {
                dispatch(toggleService(serviceDrive))
                    .then(() => {
                        setServices(serviceToSet)
                        selectedTabService(serviceToSet.find((service) => service.active === true))
                    })
                    .catch(() => {
                        Notif({ type: NotyfType.ERROR, text: t('error.serviceActivate') })
                        setActiveService(oldValue)
                    })
                    .finally(() => {
                        setDisabled(false)
                    })
            })
            .catch(() => {
                Notif({ type: NotyfType.ERROR, text: t('error.serviceActivate') })
                setActiveService(oldValue)
                setDisabled(false)
            })
    }

    const onChangeActivationSwap = () => {
        setDisabled(true)
        dispatch(
            toggleSwap({
                activeDrive: !restaurant.swapDriveParkingActive,
                frNumber: restaurantFrNumber,
            }),
        )
            .then((newRestaurant) => {
                setRestaurant({ ...newRestaurant, services: restaurant.services })
            })
            .catch(() => {
                Notif({ type: NotyfType.ERROR, text: t('error.swapDriveParkingActivation') })
            })
            .finally(() => {
                setDisabled(false)
            })
    }

    // const onChangeCloseParkingTime = (time: string) => {
    //     setDisabled(true);
    //     dispatch(
    //         swapDriveParkingTime({
    //             swapTime: `${time}:00`,
    //             frNumber: restaurantFrNumber,
    //         })
    //     )
    //         .then((newRestaurant) => {
    //             setRestaurant({ ...newRestaurant, services: restaurant.services });
    //         })
    //         .catch(() => {
    //             Notif({ type: NotyfType.ERROR, text: t("error.swapDriveParkingTime") });
    //         })
    //         .finally(() => {
    //             setDisabled(false);
    //         });
    // };

    return (
        <>
            <H2 color={colors.brown}>{t(`services.servicesCode.${service.code}`)}</H2>
            <StyledParamContainer separator={!isParking}>
                <div>
                    <H4 color={colors.brown}>{t('services.driveParking.name')}</H4>
                    <Text>{t('services.driveParking.change')}</Text>
                </div>
                <div>
                    <SwitchText
                        selectedIndex={switchArray.findIndex((line) => line.value === activeService)}
                        buttons={switchArray}
                        onChange={(value) => !isDisabled && onChangeMode(value as ServiceCode)}
                        disabled={isDisabled}
                    />
                </div>
            </StyledParamContainer>
            {isParking && (
                <>
                    <StyledParamContainer>
                        <Text>
                            {t('services.driveParking.swapTime', {
                                time: formatTimeFromString(restaurant.swapDriveParkingTime) || t('error.noTime'),
                            })}
                        </Text>
                        {/* <StyledModalButton>
                            <Pencil
                                color={isDisabled ? colors.defaultGrey : colors.brown}
                                onClick={() => !isDisabled && setIsModalOpen(!isModalOpen)}
                            />
                        </StyledModalButton> */}
                    </StyledParamContainer>
                    <StyledParamContainer separator>
                        <Text>{t('services.driveParking.swapActive')}</Text>
                        <Checkbox
                            checked={restaurant.swapDriveParkingActive}
                            onClick={onChangeActivationSwap}
                            disabled={isDisabled}
                        />
                    </StyledParamContainer>
                </>
            )}
        </>
    )
}
