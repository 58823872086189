import { useAppSelector } from 'hooks/useRedux'
import React from 'react'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { Permissions } from 'types/api.enum'
import { hasPermission } from 'utils/util'

type ShouldDisplayProps = {
    children: React.ReactNode
    permission?: Permissions
    condition?: boolean
}

export const ShouldDisplay = ({ permission, condition = true, children }: ShouldDisplayProps) => {
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))

    if (!hasPermission(userPermissions, permission)) return null
    if (!condition) return null
    return <>{children}</>
}
