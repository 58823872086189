import { Calendar, Pencil, RedDot } from 'assets/icons'
import birthdayLogoImage from 'assets/images/bk-nniversaire.webp'
import { Notif } from 'components/notif/Notif'
import { TimeDaysModal } from 'components/time-days-modal/TimeDaysModal'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { getServiceSchedule, postServiceSchedule } from 'redux/restaurant/thunks'
import { colors } from 'styles/colors'
import { H4, Text } from 'styles/typography'
import { BirthdayBookingListItemDTO, ServiceDTO, ServiceOpeningsForm } from 'types/api'
import { BirthdayStatus, ServiceCode } from 'types/api.enum'
import { NotyfType } from 'utils/enums'
import { combineDateAndTimeStringsIntoDate, isPast } from 'utils/util'

import { Status } from '../../components/birthday-reservations/Status'
import { getErrorMessage } from './getErrorMsg'
import {
    StyledBirthdayTitleContainer,
    StyledButtonContainer,
    StyledDay,
    StyledH2,
    StyledH3,
    StyledImage,
    StyledModalButton,
    StyledNotifIconContainer,
    StyledParamContainer,
    StyledReservationButton,
    StyledScheduleContainer,
    StyledStatusContainer,
    StyledTitleParameters,
} from './style'

interface ServiceBirthdayProps {
    service: ServiceDTO
    disabled?: boolean
}

interface StatusCount {
    confirmed: number
    refused: number
    standby: number
}

export const ServiceBirthDay = ({ service, disabled = false }: ServiceBirthdayProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isDisabled, setDisabled] = useState(disabled)
    const [schedule, setSchedule] = useState<ServiceOpeningsForm>()
    const [statusCount, setStatusCount] = useState<StatusCount>()
    const reservations: BirthdayBookingListItemDTO[] = useAppSelector((state) => state.birthdays.birthdays)
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const displayNotificationSticker = useAppSelector((state) => state.birthdays.existsStandbyBirthday)

    const filteredReservations = useMemo(() => {
        return reservations.filter((reservation: BirthdayBookingListItemDTO) => {
            return (
                reservation.reservationDate &&
                reservation.reservationHour &&
                !isPast(combineDateAndTimeStringsIntoDate(reservation.reservationDate, reservation.reservationHour))
            )
        })
    }, [reservations])

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        // @ts-ignore
        dispatch(getServiceSchedule(restaurantFrNumber, ServiceCode.BIRTHDAY)).then((data) => setSchedule(data))
    }, [restaurantFrNumber, dispatch])

    const onChangeSchedule = (value: ServiceOpeningsForm) => {
        setDisabled(true)
        // @ts-ignore
        dispatch(postServiceSchedule(value))
            // @ts-ignore
            .then(() => setSchedule(value))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
                const { message, day } = getErrorMessage(error)
                Notif({ type: NotyfType.ERROR, text: t(message, { day: t(day) }) })
            })
            .finally(() => setDisabled(false))
    }

    const countByStatus = (reservations: BirthdayBookingListItemDTO[] | undefined) => {
        const statusCounter = {
            confirmed: 0,
            standby: 0,
            refused: 0,
        }
        if (reservations) {
            reservations.forEach((item) => {
                if (item.status === BirthdayStatus.CONFIRMED) {
                    statusCounter.confirmed++
                }
                if (item.status === BirthdayStatus.REFUSED) {
                    statusCounter.refused++
                }
                if (item.status === BirthdayStatus.STANDBY) {
                    statusCounter.standby++
                }
            })
        }
        return statusCounter
    }

    useEffect(() => {
        setStatusCount(countByStatus(filteredReservations))
    }, [filteredReservations])

    if (!schedule) {
        return <ClipLoader size={70} color={colors.orange} loading={true} />
    }

    return (
        <>
            <StyledBirthdayTitleContainer separator>
                <StyledH2>{t(`services.servicesCode.${service.code}`)}</StyledH2>
                <StyledImage src={birthdayLogoImage} />
            </StyledBirthdayTitleContainer>
            <StyledParamContainer separator>
                <StyledH3>{t('services.birthday.upcoming')}</StyledH3>
                <StyledStatusContainer>
                    <Status birthdayStatus={BirthdayStatus.CONFIRMED} statusCount={statusCount} />
                    <Status birthdayStatus={BirthdayStatus.STANDBY} statusCount={statusCount} />
                    <Status birthdayStatus={BirthdayStatus.REFUSED} statusCount={statusCount} />
                    <StyledButtonContainer>
                        <StyledReservationButton
                            onClick={() => {
                                navigate(t('page.birthday.route'))
                            }}
                        >
                            <StyledNotifIconContainer>
                                <Calendar color={colors.white} size={20} />
                                {displayNotificationSticker && <RedDot />}
                            </StyledNotifIconContainer>
                            <Text color={colors.white}> {t('services.birthday.manageReservations')} </Text>
                        </StyledReservationButton>
                    </StyledButtonContainer>
                </StyledStatusContainer>
            </StyledParamContainer>
            <StyledParamContainer separator>
                <StyledTitleParameters>
                    <H4 color={colors.brown}>{t('services.birthday.time')}</H4>
                    <StyledModalButton>
                        <Pencil
                            color={isDisabled ? colors.defaultGrey : colors.brown}
                            onClick={() => !isDisabled && setIsModalOpen(!isModalOpen)}
                        />
                    </StyledModalButton>
                </StyledTitleParameters>
                <Text>{t('services.birthday.timeInfo')}</Text>
                <StyledScheduleContainer>
                    {schedule &&
                        schedule.days.map((day) => (
                            <StyledDay key={day.day}>
                                <Text color={day.active && !isDisabled ? colors.defaultGrey : colors.greyLight}>
                                    {t(`days.${day.day}`)}
                                </Text>
                                <Text
                                    color={day.active && !isDisabled ? colors.defaultGrey : colors.greyLight}
                                >{`${day.start} - ${day.end}`}</Text>
                            </StyledDay>
                        ))}
                </StyledScheduleContainer>
            </StyledParamContainer>
            <StyledParamContainer>
                <Text>{t('services.birthday.info')}</Text>
            </StyledParamContainer>
            {isModalOpen && (
                <TimeDaysModal
                    handleClose={() => setIsModalOpen(!isModalOpen)}
                    title={t('services.birthday.time')}
                    subTitle={t('services.birthday.timeInfo')}
                    onValidate={onChangeSchedule}
                    schedule={schedule}
                />
            )}
        </>
    )
}
