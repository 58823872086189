import { DeliveryIcon, Exclamation, Restaurant } from 'assets/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs } from 'screens/tabs/Tabs'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'
import { H2 } from 'styles/typography'
import { Tab } from 'types/tab'
import { TutorialTab } from 'utils/enums'

import { DeliveryTab, ManageComplaintTab, RestaurantAndCcTab } from './components'

const Tutorial = () => {
    const { t } = useTranslation()
    const [currentTab, setCurrentTab] = useState<TutorialTab>(TutorialTab.MANAGE_COMPLAINT)
    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    const onTabPress = (name: TutorialTab, index: number) => {
        setCurrentTab(name)
        setCurrentTabIndex(index)
    }

    const tabs: Tab[] = [
        {
            icon: <Exclamation size={15} />,
            label: t('tutorial.tabs.manageComplaint'),
            tabName: TutorialTab.MANAGE_COMPLAINT,
            component: <ManageComplaintTab />,
            onClick: () => onTabPress(TutorialTab.MANAGE_COMPLAINT, 0),
        },
        {
            icon: <Restaurant size={15} />,
            label: t('tutorial.tabs.restaurantAndCc'),
            tabName: TutorialTab.RESTAURANT_AND_CC,
            component: <RestaurantAndCcTab />,
            onClick: () => onTabPress(TutorialTab.RESTAURANT_AND_CC, 1),
        },
        {
            icon: <DeliveryIcon size={15} />,
            label: t('tutorial.tabs.delivery'),
            tabName: TutorialTab.KING_DELIVERY,
            component: <DeliveryTab />,
            onClick: () => onTabPress(TutorialTab.KING_DELIVERY, 2),
        },
    ]

    return (
        <StyledMainContainer>
            <StyledTabs
                tabs={tabs}
                index={currentTabIndex}
                headerComponent={
                    <StyledTitleContainer>
                        <H2 color={colors.brown}>{t('tutorial.title')}</H2>
                    </StyledTitleContainer>
                }
            />
            <StyledTutorialTabContent>
                {tabs.map((tab) => tab.tabName === currentTab && <div key={tab.tabName}>{tab.component}</div>)}
            </StyledTutorialTabContent>
        </StyledMainContainer>
    )
}

export default Tutorial

const StyledTabs = styled(Tabs)`
    margin: 0 -${SPACING.L};
    position: initial;
`

const StyledMainContainer = styled.div`
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    max-height: 100%;
`

const StyledTutorialTabContent = styled.div`
    background-color: ${colors.grey};
    padding: ${SPACING.L};
    overflow: scroll;
`

const StyledTitleContainer = styled.div`
    padding: 0 0 ${SPACING.S} ${SPACING.L};
`
