import { colors } from 'styles/colors'

import { StyledInfoLineText } from '../style'
import { StyledH4Info } from './style'

type InfoTextProps = {
    title?: string
    text?: string | JSX.Element
    first?: boolean
    textColor?: string
}

export const InfoText = ({ title, text, first }: InfoTextProps) => (
    <StyledInfoLineText first={!!first}>
        <StyledH4Info color={colors.brown}>{title}</StyledH4Info>
        <StyledH4Info>{text}</StyledH4Info>
    </StyledInfoLineText>
)
