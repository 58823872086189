import { ArrowLeft } from 'assets/icons'
import { Button } from 'components/button/Button'
import { Coupon } from 'components/coupon/Coupon'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'
import { H2 } from 'styles/typography'
import { RefundType } from 'types/api.enum'

export type CouponType = {
    promotionId?: string
    label?: string
    imageUrl?: string
    icon?: ReactNode
    description?: string
    refundType: RefundType
}

type ListCouponModalContentProps = {
    coupons: CouponType[]
    onBackClick: () => void
    onConfirm: (id: string) => void
    refundType: string
}

export const ListCouponModalContent = ({
    onBackClick,
    onConfirm,
    coupons,
    refundType,
}: ListCouponModalContentProps) => {
    const { t } = useTranslation()
    const [isSelectedPromotion, setIsSelectedPromotion] = useState<string>('')

    const selectPromotionClickHandler = (id: string) => {
        setIsSelectedPromotion(id)
    }

    return (
        <StyledContent>
            <StyledFirstRow>
                <StyledButton
                    onClick={onBackClick}
                    icon={<ArrowLeft color={colors.brown} />}
                    color={colors.transparent}
                    borderColor={colors.transparent}
                />
                <StyledH2 color={colors.brown}>{t(`complaints.listCouponModal.title.${refundType}`)}</StyledH2>
            </StyledFirstRow>

            {coupons.length === 0 ? (
                <StyledLoaderContainer>
                    <ClipLoader size={70} color={colors.orange} loading={true} />
                </StyledLoaderContainer>
            ) : (
                <StyledCouponContainer>
                    {coupons.map((coupon) => (
                        <Coupon
                            key={coupon.promotionId}
                            nothingSelected={isSelectedPromotion === ''}
                            coupon={coupon}
                            selected={isSelectedPromotion === '' || coupon.promotionId === isSelectedPromotion}
                            onClick={() => selectPromotionClickHandler(coupon.promotionId || '')}
                            backgroundColor={
                                coupon.refundType === RefundType.CROWN ? colors.yellowMelty : colors.orange
                            }
                        />
                    ))}
                </StyledCouponContainer>
            )}

            <StyledButtonContainer>
                <Button
                    onClick={() => onConfirm(isSelectedPromotion)}
                    text={t('component.button.confirm')}
                    color={isSelectedPromotion === '' ? colors.greyLight : colors.red}
                    textColor={isSelectedPromotion === '' ? colors.blackLight : colors.white}
                    disabled={isSelectedPromotion === ''}
                />
            </StyledButtonContainer>
        </StyledContent>
    )
}

const StyledContent = styled.div`
    min-width: 45vw;
    box-sizing: border-box;
    max-width: 627px;
    padding: ${SPACING.XXL};
    background-color: ${colors.white};
    border-radius: 20px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const StyledH2 = styled(H2)`
    width: 100%;
    text-align: center;
`

const StyledCouponContainer = styled.div`
    display: grid;
    grid-column: 3;
    grid-template-columns: repeat(3, 177px);
    gap: ${SPACING.S};
    margin: auto;
    margin-bottom: ${SPACING.XXL};
`

const StyledLoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 516px;
    width: 100%;
`

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`

const StyledFirstRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXXS};
    align-items: center;
    margin-bottom: ${SPACING.XXL};
`

const StyledButton = styled(Button)`
    border: none;
    padding: 0;
`
