import { createSelector } from '@reduxjs/toolkit'
import { OrderLightDTO } from 'types/api'
import { PickUpType } from 'types/api.enum'
import { DateRange } from 'types/date-range'
import { RootState } from 'types/redux'
import { StatusType } from 'utils/enums'
import { getOrderStatusType, isClickAndCollect, isDriveAndParking, isKingTable } from 'utils/util'

export const selectOrdersSortByError = createSelector(
    [(state: RootState) => state, (state: RootState, statusType: string) => statusType],
    (orders: OrderLightDTO[] = [], statusType: string) => {
        const filters = statusType === StatusType.IN_PROGRESS ? [statusType, StatusType.ERROR] : [statusType]
        const ordersData = [...orders].filter((order) => filters.includes(getOrderStatusType(order)))
        ordersData.sort((a, b) => {
            const aStatus = getOrderStatusType(a)
            const bStatus = getOrderStatusType(b)
            if (aStatus === StatusType.ERROR && bStatus !== StatusType.ERROR) {
                return -1
            }
            if (aStatus !== StatusType.ERROR && bStatus === StatusType.ERROR) {
                return 1
            }
            return 0
        })
        return ordersData
    },
)

export const selectOrders = createSelector(
    [(state: RootState) => state, (state: RootState, statusType: string) => statusType],
    (orders: OrderLightDTO[] = [], statusType: string) => {
        const filters =
            statusType === StatusType.ALL
                ? [StatusType.IN_PROGRESS, StatusType.FINISHED, StatusType.ERROR]
                : [statusType]
        return orders.filter((order) => filters.includes(getOrderStatusType(order)))
    },
)

export const selectClickAndCollectOrders = createSelector(
    (state: RootState) => state,
    (orders: OrderLightDTO[]) => orders.filter((order) => order.pickUpType && isClickAndCollect(order.pickUpType)),
)

export const selectDriveAndParkingOrders = createSelector(
    (state: RootState) => state,
    (orders: OrderLightDTO[]) => orders.filter((order) => order.pickUpType && isDriveAndParking(order.pickUpType)),
)

export const selectOrdersByPickUpType = createSelector(
    [(state: RootState) => state, (state: RootState, pickUpType: PickUpType) => pickUpType],
    (orders: OrderLightDTO[], pickUpType: PickUpType) => orders.filter((order) => order.pickUpType === pickUpType),
)

export const selectOrdersByStatusType = createSelector(
    [(state: RootState) => state, (state: RootState, statusType: StatusType) => statusType],
    (orders: OrderLightDTO[], statusType: StatusType) =>
        orders.filter((order) => getOrderStatusType(order) === statusType),
)

export const selectDeliveryOrdersByStatusType = createSelector(
    [(state: RootState) => state, (state: RootState, statusType: StatusType) => statusType],
    (orders: OrderLightDTO[], statusType: StatusType) => {
        const filters = statusType === StatusType.IN_PROGRESS ? [statusType, StatusType.ERROR] : [statusType]

        return orders.filter(
            (order) => order.pickUpType === PickUpType.DELIVERY_IN_APP && filters.includes(getOrderStatusType(order)),
        )
    },
)

export const selectCcOrdersByStatusType = createSelector(
    [(state: RootState) => state, (state: RootState, statusType: StatusType) => statusType],
    (orders: OrderLightDTO[], statusType: StatusType) => {
        const filters = statusType === StatusType.IN_PROGRESS ? [statusType, StatusType.ERROR] : [statusType]

        return orders.filter(
            (order) =>
                order.pickUpType && isClickAndCollect(order.pickUpType) && filters.includes(getOrderStatusType(order)),
        ).length
    },
)

export const selectKingTableOrdersByStatusType = createSelector(
    [(state: RootState) => state, (state: RootState, statusType: StatusType) => statusType],
    (orders: OrderLightDTO[], statusType: StatusType) => {
        const filters = statusType === StatusType.IN_PROGRESS ? [statusType, StatusType.ERROR] : [statusType]

        return orders.filter(
            (order) => order.pickUpType && isKingTable(order.pickUpType) && filters.includes(getOrderStatusType(order)),
        ).length
    },
)

export const selectDriveAndParkingOrdersByStatusType = createSelector(
    [(state: RootState) => state, (state: RootState, statusType: StatusType) => statusType],
    (orders: OrderLightDTO[], statusType: StatusType) => {
        const filters = statusType === StatusType.IN_PROGRESS ? [statusType, StatusType.ERROR] : [statusType]

        return orders.filter(
            (order) =>
                order.pickUpType && isDriveAndParking(order.pickUpType) && filters.includes(getOrderStatusType(order)),
        ).length
    },
)

export const selectOrdersWithMultipleFilters = createSelector(
    [
        (state: RootState) => state,
        (
            state: RootState,
            filter: {
                status: StatusType
                pickUp?: PickUpType[]
                onlyError?: string
                dateRange?: DateRange
            },
        ) => filter,
    ],
    (state: RootState, filter) => {
        const { status, pickUp, onlyError, dateRange } = filter
        let filteredOrders =
            status === StatusType.FINISHED
                ? selectOrdersSortByError(state.order.finishedOrders, StatusType.FINISHED)
                : selectOrdersSortByError(state.order.pendingOrders, StatusType.IN_PROGRESS)
        if (pickUp) {
            filteredOrders = getOrderbyPickUpType(filteredOrders, pickUp)
        }
        if (onlyError === 'true') {
            filteredOrders = getOnlyErrorOrder(filteredOrders)
        }
        if (dateRange) {
            filteredOrders = getOrdersInDateRange(filteredOrders, dateRange)
        }
        return filteredOrders
    },
)

export const selectOrdersWithMultipleFiltersV2 = createSelector(
    [
        (state: RootState) => state,
        (
            state: RootState,
            filter: {
                status: StatusType
                pickUp?: PickUpType[]
                dateRange?: DateRange
            },
        ) => filter,
    ],
    (state: RootState, filter) => {
        const { status, pickUp, dateRange } = filter
        let filteredOrders
        switch (status) {
            case StatusType.FINISHED:
                filteredOrders = selectOrders(state.order.finishedOrders, StatusType.FINISHED)
                break
            case StatusType.ERROR:
                filteredOrders = selectOrders(state.order.pendingOrders, StatusType.ERROR)
                break
            case StatusType.IN_PROGRESS:
                filteredOrders = selectOrders(state.order.pendingOrders, StatusType.IN_PROGRESS)
                break
            case StatusType.ALL:
                filteredOrders = selectOrders(state.order.pendingOrders, StatusType.ALL).concat(
                    selectOrders(state.order.finishedOrders, StatusType.FINISHED),
                )
                break
            default:
                filteredOrders = selectOrders(state.order.pendingOrders, StatusType.ALL).concat(
                    selectOrders(state.order.finishedOrders, StatusType.FINISHED),
                )
        }
        if (pickUp) {
            filteredOrders = getOrderbyPickUpType(filteredOrders, pickUp)
        }
        if (dateRange) {
            filteredOrders = getOrdersInDateRange(filteredOrders, dateRange)
        }
        return filteredOrders
    },
)

const getOrderbyPickUpType = (orders: OrderLightDTO[], pickUpType: PickUpType[]) => {
    if (pickUpType.includes(PickUpType.ALL)) {
        return orders
    }
    return orders.filter((order) => pickUpType.includes(order.pickUpType))
}

const getOnlyErrorOrder = (orders: OrderLightDTO[]) => {
    return orders.filter((order) => getOrderStatusType(order) === StatusType.ERROR)
}

const getOrdersInDateRange = (orders: OrderLightDTO[], dateRange: DateRange) => {
    const { startDate, endDate } = dateRange
    if (!startDate || !endDate) return orders
    return orders.filter((order) => new Date(order.createdDate) >= startDate && new Date(order.createdDate) < endDate)
}
