import { BellIcon, CheckCircle, Play, Warning } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'

import { SPACING } from '../../styles/tokens'
import { StatusType } from '../../utils/enums'

interface PickUpTypeStampProps {
    statusType: StatusType
    color?: string
    isFilter?: boolean
}

export const StatusTypeStamp = ({ statusType, color = colors.blackLight, isFilter = false }: PickUpTypeStampProps) => {
    const { t } = useTranslation()

    let Icon
    switch (statusType) {
        case StatusType.IN_PROGRESS:
            Icon = Play
            break
        case StatusType.FINISHED:
            Icon = CheckCircle
            break
        case StatusType.ERROR:
            Icon = Warning
            break
        case StatusType.ALL:
            Icon = BellIcon
            break
        default:
            Icon = Play
    }

    return (
        <StyledContainer>
            <Icon size={14} color={color} style={{ marginRight: SPACING.XXS }} />
            <Text color={color}>{isFilter && t(`status.orders.${statusType}`)}</Text>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
`
