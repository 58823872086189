import { ChangeEvent, useState } from 'react'

export function useRangeInput() {
    const INITIAL_VALUE = 1
    const [min, setMin] = useState(INITIAL_VALUE)
    const [max, setMax] = useState(INITIAL_VALUE)

    const handleMinChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMin(parseInt(e.target.value))
    }

    const handleMaxChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMax(parseInt(e.target.value))
    }

    const handleInitialState = () => {
        setMin(INITIAL_VALUE)
        setMax(INITIAL_VALUE)
    }

    return { min, handleMinChange, max, handleMaxChange, handleInitialState }
}
