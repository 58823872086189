import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledSwitchText = styled.div`
    display: flex;
    background: ${colors.grey};
    border-radius: 8px;
`

export const StyledSwitchButton = styled.div<{ selected: boolean; disabled: boolean }>`
    display: flex;
    padding: 8px 10px;
    cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
    background: ${(props) =>
        props.disabled ? (props.selected ? colors.defaultGrey : colors.greyLight) : props.selected && colors.brown};

    &:first-child {
        border-radius: 8px 0px 0px 8px;
    }
    &:last-child {
        border-radius: 0px 8px 8px 0px;
    }
    & > svg {
        margin-right: 5px;
    }
`
