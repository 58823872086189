import { fonts } from 'styles/fonts'

import { StyledDrawerButton, StyledIconContainer, StyledText, StyledTextContainer } from './style'

interface DrawerButtonProps {
    selected?: boolean
    text: string
    icon: JSX.Element
    [key: string]: unknown
}

export const DrawerButton = ({ selected = false, text, icon, ...other }: DrawerButtonProps) => {
    return (
        <StyledDrawerButton selected={selected} {...other}>
            <StyledIconContainer>{icon}</StyledIconContainer>
            <StyledTextContainer>
                <StyledText font={fonts.flame}>{text}</StyledText>
            </StyledTextContainer>
        </StyledDrawerButton>
    )
}
