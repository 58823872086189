import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { logout } from 'redux/user/userSlice'
import { RestaurantLightDTO } from 'types/api'
import { ServiceOpeningsForm } from 'types/api'
import { slices } from 'utils/enums'

export interface RestaurantState {
    restaurants: RestaurantLightDTO[]
    restaurantsByFrNumber: Record<string, RestaurantLightDTO>
    selectedFrNumber?: string
    birthdaysSchedule?: ServiceOpeningsForm
}

const initialState: RestaurantState = {
    restaurants: [],
    restaurantsByFrNumber: {},
}

export const restaurantSlice = createSlice({
    name: slices.RESTAURANT,
    initialState,
    reducers: {
        setRestaurants: (state, action: PayloadAction<RestaurantLightDTO[]>) => {
            state.restaurants = action.payload.sort((a, b) => a.name.localeCompare(b.name))

            state.restaurantsByFrNumber = _.keyBy(action.payload, 'frNumber')
        },
        setSelectedRestaurant: (state, action: PayloadAction<string>) => {
            state.selectedFrNumber = action.payload
        },
        setStorePreparationTime: (state, action: PayloadAction<{ frNumber: string; preparationTime: number }>) => {
            const { frNumber, preparationTime } = action.payload
            const { restaurantsByFrNumber, restaurants } = state
            const index = _.findIndex(restaurants, { frNumber })

            if (restaurantsByFrNumber[frNumber]) {
                restaurantsByFrNumber[frNumber].preparationTime = preparationTime
            }
            if (index >= 0) {
                restaurants[index].preparationTime = preparationTime
            }
        },
        setBirthdaysSchedule: (state, action: PayloadAction<ServiceOpeningsForm>) => {
            state.birthdaysSchedule = action.payload
        },
        updateRestaurants: (state, action: PayloadAction<RestaurantLightDTO>) => {
            const index = state.restaurants.findIndex((restaurant) => restaurant.id === action.payload.id)

            if (index >= 0) {
                state.restaurants[index] = action.payload
            } else {
                state.restaurants = [action.payload, ...state.restaurants]
            }
            state.restaurantsByFrNumber[action.payload.frNumber] = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => {
            return initialState
        })
    },
})

const { actions, reducer } = restaurantSlice
export const {
    setRestaurants,
    setSelectedRestaurant,
    setStorePreparationTime,
    setBirthdaysSchedule,
    updateRestaurants,
} = actions
export { reducer as restaurantReducer }
