import styled from 'styled-components'
import { colors } from 'styles/colors'
import { H2 } from 'styles/typography'

import { BORDER, SPACING } from '../../styles/tokens'

export const StyledParamContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0;
    box-shadow: inset 0px -1px 0px ${colors.shadow};
    box-sizing: border-box;
`

export const StyledToggleContainer = styled.div`
    display: flex;
    width: 110px;
    justify-content: space-between;
    align-items: center;
`

export const StyledH2 = styled(H2)`
    margin: ${SPACING.XS} ${SPACING.NONE};
    color: ${colors.brown};
`

export const StyledInputContainer = styled.div`
    display: flex;
    margin-top: 24px;
    white-space: pre-line;
`

export const StyledIconContainer = styled.div`
    width: 10%;
    margin-left: 16px;
`

export const StyledTextContainer = styled.div`
    margin-left: 10px;
`

export const StyledModalButton = styled.div`
    display: flex;
    height: fit-content;
    &:hover {
        cursor: pointer;
    }
`

export const StyledTitleParameters = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledDeliveryPartnerContainer = styled.div`
    display: flex;
    align-content: center;
    border: ${BORDER.WIDTH.XS} solid ${colors.lightBeige};
    border-radius: ${BORDER.RADIUS.S};
    margin: ${SPACING.XS} ${SPACING.NONE};
    padding: ${SPACING.XXXS} ${SPACING.XS} ${SPACING.XXXS} ${SPACING.XS};
    gap: ${SPACING.XXS};
`
