import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const ArrowLeft = (props: IconProps) => {
    const { color = defaultGrey, size = 24, ...otherProps } = props

    return (
        <svg width={size} height={size + 1} viewBox="0 0 24 25" fill="none" {...otherProps}>
            <g id="Arrow Scroll Button">
                <path
                    id="Vector"
                    d="M16.7457 19.7478L15.4917 15.5478C14.9096 13.5738 14.9096 11.4738 15.4917 9.49983L16.7457 5.29983C16.7916 5.13743 16.7828 4.96442 16.7205 4.80754C16.6583 4.65067 16.5461 4.51866 16.4013 4.43193C16.2566 4.34519 16.0872 4.30857 15.9196 4.32771C15.7519 4.34685 15.5952 4.4207 15.4737 4.53783L15.4257 4.58583C12.7108 7.30295 9.69073 9.69686 6.42566 11.7198L5.99966 11.8998C5.89361 11.96 5.80542 12.0473 5.74407 12.1527C5.68271 12.2581 5.65039 12.3779 5.65039 12.4998C5.65039 12.6218 5.68271 12.7416 5.74407 12.847C5.80542 12.9524 5.89361 13.0396 5.99966 13.0998L6.38966 13.3398C9.65473 15.3628 12.6748 17.7567 15.3897 20.4738L15.4377 20.5218C15.5536 20.6653 15.7172 20.7623 15.8987 20.795C16.0802 20.8278 16.2675 20.7941 16.4262 20.7002C16.5849 20.6063 16.7046 20.4583 16.7632 20.2835C16.8219 20.1086 16.8156 19.9185 16.7457 19.7478Z"
                    fill={color}
                />
            </g>
        </svg>
    )
}
