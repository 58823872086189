import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

export const ClickAndCollectIcon = ({ color = colors.blackLight, size = 13, ...otherProps }: IconProps) => (
    <svg width={size} height={(15 / 13) * size} viewBox="0 0 13 15" fill="none" {...otherProps}>
        <g clipPath="url(#clip0_4981_21039)">
            <path
                fill={color}
                d="M3.28025 2.00006C3.50465 1.41651 3.89919 0.913835 4.41272 0.557219C4.92625 0.200602 5.53507 0.00649207 6.16025 5.81294e-05C6.62097 -0.0722305 7.0927 -0.01729 7.52448 0.158947C7.95626 0.335185 8.33169 0.626023 8.61025 1.00006C8.7899 1.31183 8.92139 1.64898 9.00025 2.00006H7.53025C7.30025 1.41006 6.85025 1.33006 6.16025 1.36006C5.9058 1.36605 5.65556 1.42623 5.42621 1.53659C5.19687 1.64696 4.99369 1.80496 4.83025 2.00006H3.28025ZM12.7402 13.0001C12.5502 10.4301 12.1902 3.35006 12.1902 3.35006H4.19025H0.310247L0.000247342 13.4801C0.000247342 14.2901 -0.119753 14.8601 0.930247 14.9201C1.98025 14.9801 3.40025 15.0001 4.86025 15.0001C6.99025 15.0001 9.25025 15.0001 10.8602 15.0001C13.2802 15.0001 12.8602 14.4501 12.7902 13.0001"
            />
        </g>
        <defs>
            <clipPath id="clip0_4981_21039">
                <rect width="12.83" height="15" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
