import { Exclamation, Phone } from 'assets/icons'
import { Modal } from 'components/modal/Modal'
import { useAppDispatch } from 'hooks/useRedux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { updateBirthdayById } from 'redux/birthday/thunks'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { BirthdayStatus } from 'types/api.enum'

import {
    StyledExclamationContainer,
    StyledH2,
    StyledH4,
    StyledIconsContainer,
    StyledModalContainer,
    StyledPhoneContainer,
    StyledPhoneNumberContainer,
    StyledValidationButton,
    StyledWhiteH4,
} from './style'

interface ForceCallModalProps {
    handleClose: () => void
    confirmCancellation: () => void
    confirmModification: () => void
    status: BirthdayStatus | undefined
    phoneNumber: string
    restaurantFrNumber: string
    birthdayId: string
}

export const ForceCallModal = ({
    handleClose,
    confirmCancellation,
    confirmModification,
    status,
    phoneNumber,
    restaurantFrNumber,
    birthdayId,
}: ForceCallModalProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)

    const updateStatusToRefused = async () => {
        setIsLoading(true)
        try {
            const response = await dispatch(
                // @ts-ignore
                updateBirthdayById(restaurantFrNumber, birthdayId, {
                    status: BirthdayStatus.REFUSED,
                }),
            )
            setIsLoading(false)
            return response
        } catch (error) {
            setError(error)
            setIsLoading(false)
            throw error
        }
    }

    const confirmCancellationFromModal = async () => {
        try {
            await updateStatusToRefused()
            confirmCancellation()
        } catch (error) {
            setError(error)
        }
    }

    const renderH2 = (status?: BirthdayStatus) => {
        switch (status) {
            case BirthdayStatus.STANDBY:
                return <StyledH2> {t('services.birthday.forceCall.inProgressTitle')} </StyledH2>
            case BirthdayStatus.REFUSED:
                return <StyledH2>{t('services.birthday.forceCall.cancellationTitle')}</StyledH2>
            default:
                return <></>
        }
    }

    const renderText = (status?: BirthdayStatus) => {
        switch (status) {
            case BirthdayStatus.STANDBY:
                return <Text>{t('services.birthday.forceCall.inProgressText')}</Text>
            case BirthdayStatus.REFUSED:
                return <Text> {t('services.birthday.forceCall.cancellationText')}</Text>
            default:
                return <></>
        }
    }

    const renderButton = (status?: BirthdayStatus) => {
        switch (status) {
            case BirthdayStatus.STANDBY:
                return (
                    <StyledValidationButton onClick={confirmModification}>
                        <StyledWhiteH4>{t('component.button.understood')}</StyledWhiteH4>
                    </StyledValidationButton>
                )

            case BirthdayStatus.REFUSED:
                return (
                    <StyledValidationButton onClick={confirmCancellationFromModal}>
                        <StyledWhiteH4>
                            {!isLoading ? (
                                t('services.birthday.forceCall.confirmCancellation')
                            ) : (
                                <ClipLoader size={20} color={colors.white} loading={true} />
                            )}
                        </StyledWhiteH4>
                    </StyledValidationButton>
                )
            default:
                return <></>
        }
    }

    return (
        <Modal handleClose={handleClose} closeIconColor={colors.brown} canCloseOnClickOutside={false}>
            <StyledModalContainer>
                <StyledIconsContainer>
                    <StyledPhoneContainer>
                        <Phone color={colors.brown} size={100} />
                    </StyledPhoneContainer>
                    <StyledExclamationContainer>
                        <Exclamation color={colors.brown} size={200} />
                    </StyledExclamationContainer>
                </StyledIconsContainer>
                {renderH2(status)}
                {renderText(status)}
                {!!error && <Text color={colors.red}>{t('error.birthdayStatus')}</Text>}
                <StyledPhoneNumberContainer>
                    <Phone color={colors.brown} />
                    <StyledH4>{phoneNumber}</StyledH4>
                </StyledPhoneNumberContainer>
                {renderButton(status)}
            </StyledModalContainer>
        </Modal>
    )
}
