import { Avatar, Cake, Calendar, Child, Clock, Crown, Mail, Pencil, Phone } from 'assets/icons'
import { BackButton } from 'components/back-button/BackButton'
import { CalendarSelectionModal } from 'components/birthday-reservations/CalendarSelectionModal'
import { ConfirmModal } from 'components/birthday-reservations/ConfirmModal'
import { DefineLongCommentModal } from 'components/birthday-reservations/DefineLongCommentModal'
import { DropDownStatus } from 'components/birthday-reservations/DropdownStatus'
import { ForceCallModal } from 'components/birthday-reservations/ForceCallModal'
import { OutlinedTextField } from 'components/outlined-text-field/OutlinedTextField'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { updateBirthday } from 'redux/birthday/BirthdaySlice'
import { fetchBirthdayById } from 'redux/birthday/thunks'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { BirthdayBookingDetailsDTO } from 'types/api'
import { BirthdayStatus } from 'types/api.enum'
import { formatBookingNumber, formatDate, formatTime, isPast } from 'utils/util'

import { FamilyInfoContainer } from './FamilyInfoContainer'
import { PriceElement } from './PriceElement'
import {
    StyledBirthdayInfosContainer,
    StyledCenterPage,
    StyledChildContainer,
    StyledContainer,
    StyledFamilyContainer,
    StyledFamilyInfoText,
    StyledH2,
    StyledH3,
    StyledHeaderContainer,
    StyledInfosContainer,
    StyledInfosRowContainer,
    StyledLastRowContainer,
    StyledModalContainer,
    StyledParentContainer,
    StyledPencilIconContainer,
    StyledReservationTitle,
    StyledStatusModifierContainer,
    StyledVIPInfoContainer,
} from './style'

const birthdayDefaultData: BirthdayBookingDetailsDTO = {
    id: '000000000000000',
    bookingNumber: '0000',
    reservationDate: '1970-01-01T00:00:00.000+00:00',
    guests: 0,
    name: '',
    birthDate: '1970-01-01T00:00:00.000+00:00',
    gender: '',
    attendantName: '',
    attendantEmail: '',
    attendantPhone: '',
    comments: '',
    restaurantName: '',
    cake: '',
    gift: '',
    vip: false,
    subtotalPrice: 0,
    tvaPrice: 0,
    totalPrice: 0,
    status: BirthdayStatus.REFUSED,
}

export const BirthdayManagementDetails = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { birthdayId = '' } = useParams()
    const dispatch = useAppDispatch()
    const [forceCallModalOpen, setForceCallModalOpen] = useState(false)
    const [forceCallModalStatus, setForceCallModalStatus] = useState<BirthdayStatus>()
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [confirmModalStatus, setConfirmModalStatus] = useState<BirthdayStatus>()
    const [calendarModalOpen, setCalendarModalOpen] = useState(false)
    const [defineLongCommentOpen, setDefineLongCommentOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>()
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const [birthday, setBirthday] = useState<BirthdayBookingDetailsDTO>(birthdayDefaultData)

    const handleForceCallModificationModalOpen = () => {
        setForceCallModalOpen(!forceCallModalOpen)
        setForceCallModalStatus(BirthdayStatus.STANDBY)
    }

    const handleForceCallCancellationModalOpen = () => {
        setForceCallModalOpen(!forceCallModalOpen)
        setForceCallModalStatus(BirthdayStatus.REFUSED)
        setConfirmModalStatus(BirthdayStatus.REFUSED)
    }

    const handleConfirmModalValidationOpen = () => {
        setConfirmModalOpen(!confirmModalOpen)
        setConfirmModalStatus(BirthdayStatus.CONFIRMED)
    }

    const handleConfirmModalModificationOpen = () => {
        setConfirmModalOpen(!confirmModalOpen)
        setForceCallModalOpen(!forceCallModalOpen)
        setConfirmModalStatus(BirthdayStatus.STANDBY)
    }

    const handleDefineLongCommentClick = () => {
        setDefineLongCommentOpen(!defineLongCommentOpen)
    }

    const confirmCancellation = () => {
        setForceCallModalOpen(!forceCallModalOpen)
        const updatedBirthday = {
            ...birthday,
            status: BirthdayStatus.REFUSED,
        }
        dispatch(updateBirthday(updatedBirthday))
        setBirthday(updatedBirthday)
        setConfirmModalOpen(!confirmModalOpen)
    }

    const confirmModification = () => {
        setForceCallModalOpen(!forceCallModalOpen)
        setCalendarModalOpen(!calendarModalOpen)
    }

    const confirmCalendarModification = () => {
        setCalendarModalOpen(!calendarModalOpen)
        setConfirmModalStatus(BirthdayStatus.STANDBY)
        setConfirmModalOpen(!confirmModalOpen)
    }

    const confirmValidation = () => {
        const updatedBirthday = {
            ...birthday,
            status: BirthdayStatus.CONFIRMED,
        }
        dispatch(updateBirthday(updatedBirthday))
        setBirthday(updatedBirthday)
    }

    const handleCloseForceCallModal = () => {
        setForceCallModalOpen(!forceCallModalOpen)
    }

    const handleCloseConfirmModal = () => {
        setConfirmModalOpen(!confirmModalOpen)
    }

    const handleCloseCalendarModal = () => {
        setCalendarModalOpen(!calendarModalOpen)
    }

    useEffect(() => {
        setIsLoading(true)
        // @ts-ignore
        dispatch(fetchBirthdayById(restaurantFrNumber, birthdayId))
            .then((birthday: BirthdayBookingDetailsDTO) => {
                setBirthday(birthday)
                setError(null)
            })
            .catch((error: unknown) => setError(error))
            .finally(() => setIsLoading(false))
    }, [dispatch, restaurantFrNumber, birthdayId])

    const {
        id,
        bookingNumber,
        reservationDate,
        guests,
        cake,
        gift,
        comments,
        attendantEmail,
        attendantPhone,
        attendantName,
        vip,
        name,
        birthDate,
        status,
        totalPrice,
        subtotalPrice,
        tvaPrice,
    } = birthday

    const reservationDay = formatDate(new Date(reservationDate ?? ''))
    const reservationTime = formatTime(new Date(reservationDate ?? ''))
    const guestBirthday = formatDate(new Date(birthDate ?? ''))

    const modificationForbideen = !!(reservationDate && isPast(new Date(reservationDate)))

    useEffect(() => {
        const totalPrice = (birthday.guests ?? 0) * 9
        const subtotalPrice = parseFloat((totalPrice * 0.8).toFixed(10))
        const tvaPrice = parseFloat((totalPrice * 0.2).toFixed(10))
        setBirthday({
            ...birthday,
            totalPrice: totalPrice,
            subtotalPrice: subtotalPrice,
            tvaPrice: tvaPrice,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [birthday.guests])

    if (isLoading) {
        return (
            <>
                <StyledContainer>
                    <StyledCenterPage>
                        <ClipLoader size={70} color={colors.orange} loading={true} />
                    </StyledCenterPage>
                </StyledContainer>
            </>
        )
    }

    if (error) {
        return <StyledContainer />
    }

    return (
        <StyledContainer>
            <StyledHeaderContainer>
                <BackButton
                    onClick={() => {
                        navigate(t('page.birthday.route'))
                    }}
                />
                <StyledReservationTitle>
                    <StyledH2>
                        {t('services.birthday.reservation.bookingNumber', {
                            bookingNumber: formatBookingNumber(id ?? '', bookingNumber),
                        })}
                    </StyledH2>
                </StyledReservationTitle>
                <StyledStatusModifierContainer>
                    {
                        <Text>
                            {modificationForbideen
                                ? t('services.birthday.reservation.status')
                                : t('services.birthday.reservation.statusModifier')}
                        </Text>
                    }
                    <DropDownStatus
                        menuDisplayStatus={status}
                        confirmValidation={confirmValidation}
                        handleForceCallModificationModalOpen={handleForceCallModificationModalOpen}
                        handleForceCallCancellationModalOpen={handleForceCallCancellationModalOpen}
                        handleConfirmModalModificationOpen={handleConfirmModalModificationOpen}
                        handleConfirmModalValidationOpen={handleConfirmModalValidationOpen}
                        restaurantFrNumber={restaurantFrNumber}
                        birthdayId={birthdayId}
                        disabled={modificationForbideen}
                    />
                </StyledStatusModifierContainer>
            </StyledHeaderContainer>
            <StyledInfosContainer>
                <StyledBirthdayInfosContainer>
                    <StyledInfosRowContainer>
                        <OutlinedTextField
                            label={t('services.birthday.gridItems.date')}
                            value={reservationDay}
                            width="31%"
                            icon={<Calendar color={colors.brown} />}
                            disabled
                            variant={'outlined'}
                            hiddenDisable
                        />
                        <OutlinedTextField
                            label={t('services.birthday.gridItems.hour')}
                            value={reservationTime}
                            width="31%"
                            icon={<Clock color={colors.brown} />}
                            disabled
                            variant={'outlined'}
                            hiddenDisable
                        />
                        <OutlinedTextField
                            label={t('services.birthday.reservation.numberOfChildren')}
                            value={guests?.toString()}
                            width="31%"
                            icon={<Child color={colors.brown} />}
                            disabled
                            variant={'outlined'}
                            hiddenDisable
                        />
                    </StyledInfosRowContainer>
                    <StyledInfosRowContainer>
                        <OutlinedTextField
                            label={t('services.birthday.reservation.gift')}
                            value={gift}
                            width="47%"
                            disabled
                            variant={'outlined'}
                            hiddenDisable
                        />
                        <OutlinedTextField
                            label={t('services.birthday.reservation.cake')}
                            value={cake}
                            width="47%"
                            disabled
                            variant={'outlined'}
                            hiddenDisable
                        />
                    </StyledInfosRowContainer>
                    <StyledLastRowContainer>
                        <OutlinedTextField
                            label={t('services.birthday.reservation.comments')}
                            value={comments}
                            width="80%"
                            multiline
                            disabled
                            variant={'outlined'}
                            hiddenDisable
                        />
                        {!modificationForbideen && (
                            <StyledPencilIconContainer onClick={handleDefineLongCommentClick}>
                                <Pencil color={colors.brown} size={30} />
                            </StyledPencilIconContainer>
                        )}
                    </StyledLastRowContainer>
                    <PriceElement
                        totalPrice={totalPrice ?? 0}
                        tvaPrice={tvaPrice ?? 0}
                        subtotalPrice={subtotalPrice ?? 0}
                    />
                </StyledBirthdayInfosContainer>

                <StyledFamilyContainer>
                    <StyledParentContainer>
                        <StyledH3>{t('services.birthday.reservation.parent')}</StyledH3>
                        {!!attendantName && (
                            <FamilyInfoContainer text={attendantName} icon={<Avatar color={colors.brown} />} />
                        )}
                        {!!attendantPhone && (
                            <FamilyInfoContainer text={attendantPhone} icon={<Phone color={colors.brown} />} />
                        )}
                        {!!attendantEmail && (
                            <FamilyInfoContainer text={attendantEmail} icon={<Mail color={colors.brown} />} />
                        )}
                        {vip && (
                            <StyledVIPInfoContainer>
                                <Crown color={colors.yellowMelty} />
                                <StyledFamilyInfoText>
                                    {' '}
                                    {t('services.birthday.reservation.VIPKingdom')}
                                </StyledFamilyInfoText>
                            </StyledVIPInfoContainer>
                        )}
                    </StyledParentContainer>
                    <StyledChildContainer>
                        <StyledH3>{t('services.birthday.reservation.child')}</StyledH3>
                        {!!name && <FamilyInfoContainer text={name} icon={<Avatar color={colors.brown} />} />}
                        <FamilyInfoContainer text={guestBirthday} icon={<Cake color={colors.brown} />} />
                    </StyledChildContainer>
                </StyledFamilyContainer>
            </StyledInfosContainer>
            <StyledModalContainer>
                {forceCallModalOpen && (
                    <ForceCallModal
                        handleClose={handleCloseForceCallModal}
                        phoneNumber={attendantPhone ?? ''}
                        status={forceCallModalStatus}
                        confirmCancellation={confirmCancellation}
                        confirmModification={confirmModification}
                        restaurantFrNumber={restaurantFrNumber}
                        birthdayId={birthdayId}
                    />
                )}
                {confirmModalOpen && <ConfirmModal handleClose={handleCloseConfirmModal} status={confirmModalStatus} />}
                {calendarModalOpen && (
                    <CalendarSelectionModal
                        handleClose={handleCloseCalendarModal}
                        confirmCalendarModification={confirmCalendarModification}
                        restaurantFrNumber={restaurantFrNumber}
                        birthdayId={birthdayId}
                        birthday={birthday}
                        setBirthday={setBirthday}
                    />
                )}
                {defineLongCommentOpen && (
                    <DefineLongCommentModal
                        handleClose={handleDefineLongCommentClick}
                        birthday={birthday}
                        setBirthday={setBirthday}
                        restaurantFrNumber={restaurantFrNumber}
                        birthdayId={birthdayId}
                    />
                )}
            </StyledModalContainer>
        </StyledContainer>
    )
}
