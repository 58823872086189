import axios, { AxiosResponse } from 'axios'
import { store } from 'redux/store'
import { logout, updateToken } from 'redux/user/userSlice'

export const client = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    timeout: 60000,
})

export const setAuthorizationHeader = (token: string | undefined) => {
    if (!token) {
        delete client.defaults.headers.common['Authorization']
    } else {
        client.defaults.headers.common['Authorization'] = token
    }
}

const updateTokenInterceptor = (response: AxiosResponse) => {
    const token = response?.headers?.authorization
    if (token) {
        setAuthorizationHeader(token)
        store.dispatch(updateToken(token))
    }
    return response
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unauthorizedInterceptor = (error: any) => {
    if (error?.response?.status === 401 && !!store.getState().user.token) {
        setAuthorizationHeader(undefined)
        store.dispatch(logout())
    }
    return Promise.reject(error)
}

client.interceptors.response.use(updateTokenInterceptor, unauthorizedInterceptor)
