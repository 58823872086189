import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'

import { StyledH2, StyledParamContainer, StyledPriceText, StyledPriceTextContainer } from './style'

interface PriceElementProps {
    totalPrice: number
    subtotalPrice: number
    tvaPrice: number
}

export const PriceElement = ({ totalPrice, subtotalPrice, tvaPrice }: PriceElementProps) => {
    const { t } = useTranslation()

    return (
        <>
            <StyledParamContainer separator>
                <StyledPriceTextContainer>
                    <StyledPriceText>{subtotalPrice} €</StyledPriceText>
                    <StyledPriceText>{tvaPrice} €</StyledPriceText>
                </StyledPriceTextContainer>
                <StyledPriceTextContainer>
                    <StyledPriceText color={colors.brown}>{t('orders.total')}</StyledPriceText>
                    <StyledPriceText color={colors.brown}>{t('orders.TVA')}</StyledPriceText>
                </StyledPriceTextContainer>
            </StyledParamContainer>
            <StyledParamContainer>
                <StyledPriceTextContainer>
                    <StyledPriceText>
                        <StyledH2>{totalPrice} €</StyledH2>
                    </StyledPriceText>
                </StyledPriceTextContainer>
                <StyledPriceTextContainer>
                    <StyledPriceText>
                        <StyledH2>{t('orders.finalTotal')}</StyledH2>
                    </StyledPriceText>
                </StyledPriceTextContainer>
            </StyledParamContainer>
        </>
    )
}
