import { DateRangePicker } from 'components/date-range-picker/DateRangePicker'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { PickUpType } from 'types/api.enum'
import { DateRange } from 'types/date-range'
import { FilterType, StatusType } from 'utils/enums'
import { formatDate, getDateRangeFromString, stringToDateDDMMYYYY } from 'utils/util'

import { StatusTypeStamp } from '../status-type-stamp/StatusTypeStamp'
import { StyledFilterButton, StyledFilterButtons, StyledFilterContainer, StyledFilterDate } from './style'

interface Props {
    buttons: Array<PickUpType[] | StatusType[]>
    onChangeFilter: (label: string, value?: string) => void
    isFinished: boolean
}

export const OrderFilters = ({ buttons, onChangeFilter, isFinished }: Props) => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)

    const minDate = new Date()
    minDate.setDate(minDate.getDate() - 7)

    const onClickFilter = (values: PickUpType[] | StatusType[]) => {
        const formatType = values.join('-')
        onChangeFilter(FilterType.STATUS, formatType)
    }

    const onClickDateRange = (value: (Date | null)[]) => {
        setStartDate(value[0])
        setEndDate(value[1])
        if (value[0] && value[1]) {
            const formatType = formatDate(value[0]) + '_' + formatDate(value[1])
            setIsCalendarOpen(!isCalendarOpen)
            onChangeFilter(FilterType.DATE_RANGE, formatType.replaceAll('/', '-'))
        } else if (!value[0] && !value[1]) {
            onChangeFilter(FilterType.DATE_RANGE)
        }
    }

    useEffect(() => {
        const getPickUpFilterFromUrl = () => {
            const filter = searchParams.get(FilterType.STATUS)
            if (filter) {
                return buttons.findIndex((button) => filter === button.join('-'))
            }
            return 0
        }

        const getDateRangeFilterFromUrl = (): DateRange => {
            const filter = searchParams.get(FilterType.DATE_RANGE)
            if (filter) {
                const dates = getDateRangeFromString(filter)
                return {
                    startDate: stringToDateDDMMYYYY(dates[0]),
                    endDate: stringToDateDDMMYYYY(dates[1]),
                }
            }
            return { startDate: null, endDate: null }
        }

        setSelectedIndex(getPickUpFilterFromUrl())
        if (!isCalendarOpen) {
            setStartDate(getDateRangeFilterFromUrl().startDate)
            setEndDate(getDateRangeFilterFromUrl().endDate)
        }
    }, [searchParams, buttons, isCalendarOpen])

    const FilterButton = ({ values, selected }: { values: PickUpType[] | StatusType[]; selected: boolean }) => (
        <StyledFilterButton selected={selected} onClick={() => onClickFilter(values)}>
            <StatusTypeStamp
                color={selected ? colors.white : colors.brown}
                statusType={values[0] as StatusType}
                isFilter
            />
        </StyledFilterButton>
    )

    return (
        <StyledFilterContainer>
            <StyledFilterButtons>
                {buttons.map((button, index) => (
                    <FilterButton key={button.toString()} values={button} selected={index === selectedIndex} />
                ))}
            </StyledFilterButtons>
            {!isFinished && (
                <StyledFilterDate>
                    <Text color={colors.brown}>{t('filters.date')}</Text>
                    <DateRangePicker
                        dates={{ startDate, endDate }}
                        isCalendarOpen={isCalendarOpen}
                        setIsOpen={setIsCalendarOpen}
                        onChange={onClickDateRange}
                        minDate={minDate}
                    />
                </StyledFilterDate>
            )}
        </StyledFilterContainer>
    )
}
