export const endpoints = {
    app: {
        init: '/init',
    },
    birthdays: {
        list: '/birthday/bookings',
        birthday: '/birthday/booking/',
    },
    complaint: {
        fetch: '/complaint',
        list: '/complaints',
        chatId: '/complaint/chat-id',
        contest: '/complaint/contest-refund',
        validate: '/complaint/confirm-refund',
        validateRefund: '/complaint/validate-refund',
        refundList: (id: string) => `/complaint/${id}/refund/promotions`,
        patch: (id: string) => `/complaint/${id}`,
        close: (id: string) => `/complaint/${id}/close`,
        report: (id: string) => `/complaint/${id}/report`,
        answer: (id: string) => `/complaint/${id}/messages`,
        refund: (id: string) => `/complaint/${id}/refund`,
    },
    order: {
        fetch: '/order',
        list: {
            pending: '/orders/pending',
            finished: '/orders/finished',
        },
        integrate: '/order/manual-integration',
    },
    parameters: {
        permissions: '/parameters/rights',
        setPermission: '/parameters/permission',
        fetch: '/parameters',
    },
    restaurant: {
        fetch: '/restaurant',
        preparationTime: '/restaurant/services/preparation-time',
        services: '/restaurant/services',
        swapDriveParking: '/restaurant/services/enable-swap-parking-to-drive',
        swapParkingTime: '/restaurant/services/swap-parking-time',
        setPickupInfo: '/restaurant/services/pickup-info',
        serviceSchedule: '/restaurant/services/openings',
        allEmployees: (frNumber: string) => `/restaurant/${frNumber}/employees`,
        employee: (frNumber: string, id: string) => `/restaurant/${frNumber}/employees/${id}`,
        resendMail: (frNumber: string, id: string) => `/restaurant/${frNumber}/employees/${id}/resend-activation-mail`,
    },
    user: {
        login: '/public/auth/login',
        permissions: '/auth/permissions',
        setPassword: '/auth/password/_set',
        forgottenPassword: '/public/auth/password/_forgotten',
    },
    kingTable: {
        post: '/table-order/qr-code/generate',
    },
    featureToggle: {
        get: '/features',
    },
}
