import React from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'

import { Button } from '../../../../../../components/button/Button'
import { colors } from '../../../../../../styles/colors'
import { SPACING } from '../../../../../../styles/tokens'
import { H3 } from '../../../../../../styles/typography'

type SmallErrorBlockProps = {
    isError: boolean
    handleClick: () => void
}

export const SmallErrorBlock = ({ isError, handleClick }: SmallErrorBlockProps) => {
    const { t } = useTranslation()

    return (
        <StyledErrorContainer>
            {isError ? (
                <>
                    <H3 color={colors.brown}>{t('error.unexpected')}</H3>
                    <Button
                        text={t('component.button.refreshContent')}
                        color={colors.red}
                        onClick={handleClick}
                        fullWidth={false}
                    />
                </>
            ) : (
                <ClipLoader size={30} color={colors.orange} loading={true} />
            )}
        </StyledErrorContainer>
    )
}

const StyledErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${SPACING.S};
    gap: ${SPACING.XXS};
    align-items: center;
`
