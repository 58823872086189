import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { Websocket } from 'components/websocket/Websocket'
import { WsStatus } from 'components/websocket-status/WsStatus'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { wsTopics } from 'httpServices/wsTopics'
import React, { useState } from 'react'
import { addBirthday } from 'redux/birthday/BirthdaySlice'
import { updateComplaint } from 'redux/complaint/complaintSlice'
import { updatePendingOrder } from 'redux/order/orderSlice'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import styled from 'styled-components'
import { BirthdayBookingDetailsDTO, ComplaintLightDTO, OrderLightDTO } from 'types/api'
import { Permissions } from 'types/api.enum'
import { StatusType } from 'utils/enums'
import { hasPermission } from 'utils/util'

import { Dashboard } from './components/dashboard/Dashboard'

export const Home = () => {
    const dispatch = useAppDispatch()
    const [wsStatus, setWsStatus] = useState<StatusType>(StatusType.ERROR)
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    const ordersPermissions = hasPermission(userPermissions, Permissions.SHOW_ORDER)
    const complaintsPermissions = hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)
    const hasOrderOrComplaintPermission = ordersPermissions || complaintsPermissions

    const ordersTopic = wsTopics.ordersByRestaurant(restaurantFrNumber)
    const complaintsTopic = wsTopics.complaintsByRestaurant(restaurantFrNumber)
    const birthdaysTopic = wsTopics.birthdayBookingsByRestaurant(restaurantFrNumber)
    const topics = [
        ...(ordersPermissions ? [ordersTopic] : []),
        ...(complaintsPermissions ? [complaintsTopic] : []),
        birthdaysTopic,
    ]

    return (
        <StyledPageContainer>
            <ShouldDisplay condition={hasOrderOrComplaintPermission}>
                <WsStatus status={wsStatus} />
                <Websocket
                    topics={topics}
                    onMessage={(message, topic) => {
                        if (topic === ordersTopic) {
                            dispatch(updatePendingOrder(message as OrderLightDTO))
                        } else if (topic === complaintsTopic) {
                            dispatch(updateComplaint(message as ComplaintLightDTO))
                        } else if (topic === birthdaysTopic) {
                            dispatch(addBirthday(message as BirthdayBookingDetailsDTO))
                        }
                    }}
                    onConnect={() => setWsStatus(StatusType.FINISHED)}
                    onConnectFailure={() => setWsStatus(StatusType.ERROR)}
                    onDisconnect={() => setWsStatus(StatusType.ERROR)}
                />
            </ShouldDisplay>
            <Dashboard />
        </StyledPageContainer>
    )
}

const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: column;
`
