import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Replay = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M18.977 12.194c-.123-1.372-1.504-2.071-2.31-1.235-.52.535-.533 1.77-.78 2.579a4.634 4.634 0 01-1.817 2.359 7.99 7.99 0 01-6.123.836 5.245 5.245 0 01-2.474-1.453 5.907 5.907 0 01-.642-6.858 5.313 5.313 0 011.846-1.894 5.29 5.29 0 012.527-.766c.02.37.02.74 0 1.11a.907.907 0 00.889.906 16.495 16.495 0 004.291-2.4c.26-.179.52-.48.424-.782a2.74 2.74 0 00-.601-.672c-1.244-.933-1.873-1.55-3.103-2.441-.41-.302-1.216-.741-1.681-.288-.465.452-.219.877-.219 1.453a8.829 8.829 0 00-5.576 2.469 8.945 8.945 0 00-2.624 6.595 8.967 8.967 0 002.993 6.434c4.374 3.744 12.78 2.318 14.665-3.291a6.59 6.59 0 00.315-2.634"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    )
}
