import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const KingTable = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            fillRule="nonzero"
            viewBox="0 0 18 18"
            {...otherProps}
        >
            <path d="M13.354 14.793c-.11.476-.234.942-.327 1.415-.067.334-.21.65-.152 1.016.035.242-.377.562-.647.523a20.691 20.691 0 01-1.55-.267 168.133 168.133 0 01-4.365-1.01 39.873 39.873 0 00-5.165-.945c-.089-.01-.174-.028-.263-.046-.618-.128-.782-.412-.505-.963 1.5-2.98 2.538-6.136 3.612-9.281.458-1.351.87-2.716 1.304-4.077.054-.167.11-.338.178-.502.139-.33.391-.458.74-.387 2.203.444 4.418.803 6.657 1.01 1.141.106 2.29.163 3.43.295.573.067.676.124.732.657.136 1.22.242 2.439.338 3.661.181 2.368.348 4.735.516 7.102.046.65.092 1.305.103 1.959.007.444-.125.615-.487.7a1.017 1.017 0 01-.427-.014 650.38 650.38 0 01-3.583-.835c-.039-.008-.078-.011-.142-.018l.003.007zM6.093.966a141.489 141.489 0 01-4.408 13.639c.089.02.139.035.192.046.92.167 1.848.305 2.762.508 1.962.434 3.917.9 5.875 1.347.502.114 1.01.2 1.472.288 1.255-4.859 2.502-9.686 3.75-14.502-3.206-.25-6.44-.587-9.643-1.323V.966z"></path>
            <path d="M12.565 6.852c.402.078.668.44.548.857a1.207 1.207 0 01-.235.398c-.27.327-.842.703-1.091.863-.068.043-.096.075-.114.132a.455.455 0 000 .156c.021.324.217.825.405 1.213.093.18.206.398.125.678-.128.445-.615.694-1.024.615-.249-.05-.448-.23-.576-.465-.21-.377-.291-1.063-.31-1.504-.003-.071-.02-.12-.084-.135-.057-.01-.093.028-.11.089l-.264.917c-.124.437-.504.732-.977.643-.473-.088-.654-.5-.53-.938l.95-3.32c.127-.44.497-.74.984-.643.487.096.65.494.526.938l-.206.725c-.014.05-.007.107.05.117.042.008.067 0 .138-.05.256-.191.626-.557.917-.906.32-.384.636-.43.878-.38zM7.902 8.473c.07.092.384.476.195 1.13-.234.821-1.038 1.361-2.047 1.166l-1.07-.21c-.604-.117-.79-.515-.661-.974l.931-3.26c.131-.458.515-.742 1.116-.625l.91.178c.953.185 1.429.778 1.177 1.664-.107.38-.32.618-.516.746-.057.036-.082.046-.092.085-.007.029.025.064.053.103l.004-.003zM6.067 9.6c.363.07.551-.182.605-.374.06-.213.021-.472-.341-.543l-.14-.025c-.127-.025-.205.039-.23.135l-.164.576c-.028.096.007.18.135.206l.14.025m.386-2.538l-.142.5c-.028.097.007.179.139.207l.1.018c.33.064.497-.167.55-.345.054-.192.022-.434-.309-.498l-.1-.017c-.13-.025-.205.039-.234.135"></path>
        </svg>
    )
}
