import { CheckIcon } from 'assets/icons'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'

import { StyledSwitchButton, StyledSwitchText } from './style'

interface Props {
    selectedIndex: number
    buttons: Array<{ value: unknown; label: string }>
    onChange: (value: unknown) => void
    disabled: boolean
}

export const SwitchText = (props: Props) => {
    const { selectedIndex, buttons, onChange, disabled } = props
    return (
        <StyledSwitchText>
            {buttons.map((button, index) => {
                const selected = selectedIndex === index
                return (
                    <StyledSwitchButton
                        key={button.label}
                        selected={selected}
                        disabled={disabled}
                        onClick={() => (selected && !disabled ? undefined : onChange(button.value))}
                    >
                        {selected && <CheckIcon color={colors.white} />}
                        <Text color={selected ? colors.white : colors.brown}>{button.label}</Text>
                    </StyledSwitchButton>
                )
            })}
        </StyledSwitchText>
    )
}
