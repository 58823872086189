export const regex = {
    EMAIL: /^[_a-zA-Z0-9-]+(.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+\.(.[a-zA-Z0-9-]+)*[.a-zA-Z]{2,3}$/,
    PASSWORD: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
}

export const botmindScriptKeys = {
    TOKEN: '20e3c270-5b75-4632-9083-f60423282e08',
}

export const env = {
    QA: 'QA',
    INT: 'INT',
    PPRD: 'PPRD',
    PRD: 'PRD',
}
