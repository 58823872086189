import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Avatar = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M13.372 10.93a5 5 0 10-6.184 0 8.333 8.333 0 00-5.183 6.816.838.838 0 001.667.184 6.667 6.667 0 0113.25 0 .833.833 0 00.833.741h.092a.833.833 0 00.733-.916 8.333 8.333 0 00-5.208-6.825zm-3.092-.592a3.333 3.333 0 110-6.667 3.333 3.333 0 010 6.667z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
