import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

export const TableIcon = (props: IconProps) => {
    const { color = colors.defaultGrey, size = 20, ...otherProps } = props
    return (
        <svg width={size} height={(size / 20) * 13} viewBox="0 0 20 13" fill="none" {...otherProps}>
            <g id="Calque_3">
                <g id="Groupe_566">
                    <g id="Groupe_566-2">
                        <path
                            id="Trac&#195;&#169;_1172"
                            d="M12.1077 11.7557C11.7957 11.7557 11.3733 11.3477 11.0613 11.2997C11.0469 10.7237 10.8693 5.78933 10.7877 3.72053L11.8437 3.72053C12.4533 3.72053 14.1189 3.61013 15.6261 3.61013C16.3509 3.61013 16.4949 3.18293 16.5189 2.79893V2.31893C16.5429 1.87733 16.2741 1.50293 15.9429 1.50293L11.3781 1.55573H8.98768L4.08688 1.66133C3.76528 1.66613 3.51568 2.02613 3.53968 2.44853V2.52053C3.53008 2.82293 3.66448 3.83573 4.11568 3.82613C4.51408 3.82613 4.98928 3.81173 5.17168 3.82613C5.22928 3.83093 8.24368 3.73973 9.03568 3.73013V6.62933C9.03568 6.94133 9.01168 10.7765 9.01168 10.9205C9.01168 11.1221 9.00208 11.3045 9.00208 11.3045C8.63248 11.4101 8.20528 11.7509 7.95568 11.7509C7.41808 11.7509 7.18768 12.6053 8.09488 12.6053C9.70768 12.6053 10.3749 12.5381 12.0117 12.5381C12.7941 12.5381 12.6549 11.7509 12.1173 11.7509"
                            fill={color}
                        />
                        <path
                            id="Trac&#195;&#169;_1173"
                            d="M18.8129 0.806818C18.3329 1.09482 17.9729 1.54602 17.7857 2.07402L17.4401 3.25962L17.1761 4.19082L17.0081 4.93482L16.0817 4.91082L15.1121 4.88682L13.5857 4.92042C12.6353 4.92042 12.6209 6.43242 13.2977 6.72042C13.3457 6.73482 13.3937 6.74442 13.4417 6.74922C13.5377 6.76362 13.6097 6.85002 13.6049 6.94602L13.4177 10.2868L13.2737 12.538C13.2641 12.6676 13.4129 12.778 13.5953 12.7876H13.6289C13.7585 12.802 13.9457 12.7636 13.9553 12.5812C13.9649 12.4324 14.6273 7.99242 14.7473 6.93162C14.7569 6.83082 14.8433 6.75402 14.9441 6.74922C15.2033 6.74442 15.4481 6.74922 15.5201 6.74922L16.1921 6.75882H16.3937C16.5905 6.75882 16.7633 6.88842 16.8161 7.07562C17.1377 8.23722 18.2273 12.1636 18.2369 12.178C18.2609 12.2452 18.3089 12.4324 18.3473 12.5908C18.3905 12.7684 18.5921 12.7732 18.7313 12.73H18.7649C18.9569 12.682 19.0913 12.5428 19.0625 12.418L17.9201 6.94602C17.9009 6.85002 17.9633 6.75882 18.0593 6.73962C18.0641 6.73962 18.0737 6.73962 18.0785 6.73962C18.3041 6.72042 18.3425 6.45642 18.3617 6.38922C18.3809 6.32202 18.4001 6.25962 18.4145 6.18762C18.4193 6.16362 18.8081 4.45962 18.8465 4.33482C18.8561 4.31082 19.1153 3.33642 19.1249 3.30282C19.1873 3.06762 19.4369 2.00682 19.5665 1.41162C19.6961 0.816418 19.3553 0.595618 18.8129 0.806818Z"
                            fill={color}
                        />
                        <path
                            id="Trac&#195;&#169;_1174"
                            d="M1.18249 0.806901C1.63849 0.984501 1.88809 1.7189 1.98889 2.0549L2.33449 3.2405L2.59849 4.1717L2.78089 4.8629H3.92329L4.89289 4.8293L6.41929 4.8629C7.46569 4.8629 7.19689 6.7637 6.46729 6.7637H6.38089L6.57289 10.2869L6.71689 12.5381C6.72649 12.6677 6.57769 12.7781 6.39529 12.7877H6.36649C6.23689 12.8021 6.04969 12.7637 6.04009 12.5813C6.03049 12.4229 6.01609 12.2309 6.00649 12.1589C6.00649 12.1349 5.90089 10.3925 5.89129 10.2629C5.89129 10.2341 5.75209 7.3349 5.71849 6.7733C5.40169 6.7637 4.96009 6.7445 4.87369 6.7493L3.80329 6.7637C3.66889 6.7637 3.28009 6.7637 2.85769 6.7541C2.71849 7.3397 1.77289 12.1589 1.76329 12.1829C1.73929 12.2501 1.69129 12.4373 1.65289 12.5957C1.60969 12.7733 1.40809 12.7781 1.26889 12.7349H1.23529C1.04329 12.6869 0.908894 12.5477 0.937694 12.4229L2.12329 6.7397C2.02249 6.7541 1.92169 6.7493 1.82089 6.7253H1.81129C1.75369 6.7109 1.71049 6.6677 1.69609 6.6101C1.65769 6.4565 1.60969 6.3365 1.58089 6.1973C1.57609 6.1733 1.18729 4.4693 1.14889 4.3445C1.13929 4.3157 0.880094 3.3413 0.870494 3.3077C0.808095 3.0725 0.558495 2.0117 0.428895 1.4165C0.299295 0.821301 0.640095 0.600501 1.18249 0.811701"
                            fill={color}
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
