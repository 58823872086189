import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledDrawer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.brownDark};
    height: 100%;
    position: fixed;
    z-index: 1;
`

export const StyledDrawerContainer = styled.div`
    display: flex;
    height: 100%;
`

export const StyledDrawerButtonContainer = styled.div<{ place: boolean }>`
    margin: 6px 9px;
    ${(props) => props.place && `margin-top: auto;`}
    &:first-child {
        margin-top: 12px;
    }
    &:last-child {
        margin-bottom: 12px;
    }
`
