import { Text } from 'styles/typography'
import { ServiceDTO } from 'types/api'

import { StyledH4, StyledTab, StyledTitleContainer } from './style'

interface TabsParametersProps {
    parameters: ServiceDTO[]
    serviceSelectedByCode: (serviceParam: ServiceDTO | undefined) => {
        icon: JSX.Element
        content: JSX.Element
        title: string
        subTitle: string
    } | null
    selectedTabParam: ServiceDTO | undefined
    onClick: (value: ServiceDTO) => void
}

export const TabsParameters = ({
    parameters,
    serviceSelectedByCode,
    selectedTabParam,
    onClick,
}: TabsParametersProps) => {
    return (
        <>
            {parameters.map((serviceParam) => {
                const serviceProps = serviceSelectedByCode(serviceParam)
                if (!serviceProps || !selectedTabParam) {
                    return null
                }
                return (
                    <StyledTab
                        key={serviceParam.code}
                        selected={serviceParam.code === selectedTabParam.code}
                        onClick={() => onClick(serviceParam)}
                    >
                        {serviceProps.icon}
                        <StyledTitleContainer>
                            <StyledH4>{serviceProps.title}</StyledH4>
                            <Text>{serviceProps.subTitle}</Text>
                        </StyledTitleContainer>
                    </StyledTab>
                )
            })}
        </>
    )
}
