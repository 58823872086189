import { Button } from 'components/button/Button'
import { FeatureToggle } from 'components/feature-toggle/FeatureToggle'
import { Notif } from 'components/notif/Notif'
import { Switch } from 'components/switch/Switch'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toggleService } from 'redux/restaurant/thunks'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { colors } from 'styles/colors'
import { H4, Text } from 'styles/typography'
import { ServiceDTO } from 'types/api'
import { Permissions } from 'types/api.enum'
import { FEATURE_TOGGLE_CODE, NotyfType } from 'utils/enums'
import { hasPermission } from 'utils/util'

import {
    StyledButtonContainer,
    StyledH2,
    StyledParamContainer,
    StyledQrCodeContainer,
    StyledQrCodeTitleParameters,
    StyledTitleParameters,
    StyledToggleContainer,
} from './style'

interface ServiceKingTableProps {
    service: ServiceDTO
    setServices: (service: ServiceDTO[]) => void
    disabled: boolean
}

export const ServiceKingTable = ({ service, setServices, disabled }: ServiceKingTableProps) => {
    const [isActive, setActive] = useState(service.active)
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))

    // This service cannot be activated from BOB since this component won't appear on page reload
    // so the disabled attribute will be set to isDisabled || !isActive
    const [isDisabled, setDisabled] = useState(disabled)

    const restaurantFrNumber = useAppSelector<string>((state) => state.restaurant.selectedFrNumber)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const kingTableQrCodePermission = hasPermission(userPermissions, Permissions.MANAGE_TABLE_ORDER_QR_CODES)

    const onChangeActive = async () => {
        setActive(!isActive)
        setDisabled(true)
        const basicParams = {
            enabled: !isActive,
            frNumber: restaurantFrNumber,
        }
        try {
            await Promise.all(
                [
                    // BTR-500 special case: also disable sub-services
                    // but BTR-8032 cancel BTR-500 : disable only KING_TABLE global service
                    // { ...basicParams, serviceCode: ServiceCode.TABLE_ORDER_INDOOR },
                    // { ...basicParams, serviceCode: ServiceCode.TABLE_ORDER_OUTDOOR },
                    { ...basicParams, serviceCode: service.code },
                ].map((params) => dispatch(toggleService(params))),
            )
            // no need to update the other services on the front-end
            setServices([{ ...service, active: !isActive }])
        } catch (_err) {
            Notif({ type: NotyfType.ERROR, text: t('error.serviceActivate') })
            setActive(isActive)
        } finally {
            setDisabled(false)
        }
    }

    return (
        <>
            <StyledH2>{t(`services.servicesCode.${service.code}`)}</StyledH2>
            <StyledParamContainer>
                <StyledTitleParameters>
                    <H4 color={colors.brown}>{t('services.kingTable.name')}</H4>
                    <StyledToggleContainer>
                        <H4 color={colors.brown}>{t(isActive ? 'component.button.on' : 'component.button.off')}</H4>
                        <Switch onChange={onChangeActive} checked={isActive} disabled={isDisabled || !isActive} />
                    </StyledToggleContainer>
                </StyledTitleParameters>
                <Text>{t('services.kingTable.toggle')}</Text>
            </StyledParamContainer>
            <FeatureToggle features={[FEATURE_TOGGLE_CODE.TABLE_ORDER_QR_CODE]}>
                <>
                    {kingTableQrCodePermission && (
                        <StyledQrCodeContainer>
                            <StyledQrCodeTitleParameters>
                                <H4 color={colors.brown}>{t('services.kingTable.qrCode')}</H4>
                                <Text>{t('services.kingTable.qrCodeDescription')}</Text>
                            </StyledQrCodeTitleParameters>
                            <StyledButtonContainer>
                                <Button
                                    text={t('services.kingTable.button')}
                                    color={colors.brown}
                                    onClick={() => navigate(t('page.kingTable.route'))}
                                />
                            </StyledButtonContainer>
                        </StyledQrCodeContainer>
                    )}
                </>
            </FeatureToggle>
        </>
    )
}
