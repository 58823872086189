import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { RestaurantPermissionDTO } from 'types/api'
import { Role } from 'types/api.enum'
import { UserInfo } from 'types/user'
import { slices } from 'utils/enums'

export interface UserState {
    token: string
    email?: string
    firstName?: string
    lastName?: string
    role?: Role
    permissions: RestaurantPermissionDTO[] //TODO: type permissions with enum
}

const initialState: UserState = {
    token: '',
    permissions: [],
}

export const userSlice = createSlice({
    name: slices.USER,
    initialState,
    reducers: {
        login: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        logout: () => {
            return initialState
        },
        updateToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        setUserInfo: (state, action: PayloadAction<UserInfo>) => {
            state.email = action.payload.userEmail
            state.firstName = action.payload.userFirstName
            state.lastName = action.payload.userLastName
            state.role = action.payload.userProfile
        },
        setPermissions: (state, action: PayloadAction<RestaurantPermissionDTO[]>) => {
            state.permissions = action.payload
        },
    },
})

const { actions, reducer } = userSlice
export const { login, logout, setUserInfo, updateToken, setPermissions } = actions
export { reducer as userReducer }
