import 'react-datepicker/dist/react-datepicker.css'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Modal } from 'components/modal/Modal'
import { StyledButtonsContainer } from 'components/order-preparation-time-modal/style'
import { enGB } from 'date-fns/locale'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { updateBirthday } from 'redux/birthday/BirthdaySlice'
import { updateBirthdayById } from 'redux/birthday/thunks'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { BirthdayBookingDetailsDTO, ServiceOpeningsForm } from 'types/api'
import { BirthdayStatus, DayLabel } from 'types/api.enum'
import { createISODateTimeString, formatDate, formatTime, getHalfHourIntervals } from 'utils/util'

import {
    StyledCancellationButton,
    StyledDatePicker,
    StyledForm,
    StyledFormContainer,
    StyledH2,
    StyledH4,
    StyledModalContainer,
    StyledValidationButton,
    StyledWhiteH4,
} from './style'

type CalendarSelectionModalProps = {
    handleClose: () => void
    confirmCalendarModification: () => void
    birthday: BirthdayBookingDetailsDTO
    setBirthday: Dispatch<SetStateAction<BirthdayBookingDetailsDTO>>
    restaurantFrNumber: string
    birthdayId: string
}

interface SentData {
    reservationDate: string
    reservationHour: string
    guests: number
}

export const CalendarSelectionModal = ({
    handleClose,
    confirmCalendarModification,
    birthday,
    setBirthday,
    restaurantFrNumber,
    birthdayId,
}: CalendarSelectionModalProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [dayIsCorrect, setDayIsCorrect] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const [selectedDate, setSelectedDate] = useState(new Date(birthday.reservationDate ?? ''))
    const [sentData, setSentData] = useState<SentData>({
        reservationDate: formatDate(new Date(birthday.reservationDate ?? '')),
        reservationHour: formatTime(new Date(birthday.reservationDate ?? '')),
        guests: birthday.guests ?? 0,
    })
    const [displayedHour, setDisplayedHour] = useState(formatTime(new Date(birthday.reservationDate ?? '')))
    const schedule: ServiceOpeningsForm = useAppSelector((state) => state.restaurant.birthdaysSchedule)

    const possibleNumbersOfChildren = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
    const possibleHours = useMemo(() => {
        const dayIndex = selectedDate.getDay()
        const daysOfWeek: DayLabel[] = [
            DayLabel.SUNDAY,
            DayLabel.MONDAY,
            DayLabel.TUESDAY,
            DayLabel.WEDNESDAY,
            DayLabel.THURSDAY,
            DayLabel.FRIDAY,
            DayLabel.SATURDAY,
        ]
        const { active, start, end } = schedule.days.find((day) => day.day === daysOfWeek[dayIndex]) || {
            active: true,
            start: null,
            end: null,
        }
        if (!active) {
            setDayIsCorrect(false)
            return []
        }
        setDayIsCorrect(true)
        return getHalfHourIntervals(start, end)
    }, [selectedDate, schedule.days])

    const isSelectedHourAvailable = useMemo(() => {
        return possibleHours.includes(sentData.reservationHour)
    }, [possibleHours, sentData])

    const handleDateChange = (date: Date) => {
        setSelectedDate(date)
        setSentData({
            ...sentData,
            reservationDate: formatDate(date),
        })
    }

    const handleHourChange = (event: SelectChangeEvent) => {
        setSentData({
            ...sentData,
            reservationHour: event.target.value,
        })
        setDisplayedHour(event.target.value)
    }

    const handleNumberOfChildrenChange = (event: SelectChangeEvent) => {
        setSentData({
            ...sentData,
            guests: event.target.value as unknown as number,
        })
    }

    const setBirthdayToModifiedData = () => {
        const updatedBirthday = {
            ...birthday,
            guests: sentData.guests,
            reservationDate: createISODateTimeString(sentData.reservationDate, sentData.reservationHour),
            status: BirthdayStatus.CONFIRMED,
        }
        setBirthday(updatedBirthday)
        dispatch(updateBirthday(updatedBirthday))
    }

    const updateStatus = async () => {
        setIsLoading(true)
        try {
            const [response1, response2] = await Promise.all([
                dispatch(
                    // @ts-ignore
                    updateBirthdayById(restaurantFrNumber, birthdayId, {
                        status: BirthdayStatus.CONFIRMED,
                    }),
                ),
                dispatch(
                    // @ts-ignore
                    updateBirthdayById(restaurantFrNumber, birthdayId, {
                        // Backend requires hour and date to be separated, to the format "dd/mm/yyyy" and "hh:mm:ss". Seconds are set to 00 to match this format
                        ...sentData,
                        reservationHour: sentData.reservationHour + ':00',
                    }),
                ),
            ])
            setIsLoading(false)
            return { response1, response2 }
        } catch (error) {
            setError(error)
            setIsLoading(false)
            throw error
        }
    }

    const confirmModificationFromModal = async () => {
        if (dayIsCorrect) {
            try {
                await updateStatus()
                confirmCalendarModification()
                setBirthdayToModifiedData()
            } catch (error) {
                setError(error)
            }
        }
    }

    return (
        <Modal handleClose={handleClose} closeIconColor={colors.brown} canCloseOnClickOutside={false}>
            <StyledModalContainer>
                <StyledH2>{t('services.birthday.dropdown.modification')}</StyledH2>
                <Text>{t('services.birthday.calendarSelection.text')}</Text>
                <StyledDatePicker inline onChange={handleDateChange} locale={enGB} />
                <StyledFormContainer>
                    <StyledForm>
                        <InputLabel>
                            <Text>{t('services.birthday.calendarSelection.hourLabel')}</Text>
                        </InputLabel>
                        <Select
                            value={displayedHour}
                            onChange={handleHourChange}
                            label="Horaire"
                            disabled={!dayIsCorrect}
                        >
                            {possibleHours.map((item) => {
                                return (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </StyledForm>
                    <StyledForm>
                        <InputLabel>
                            <Text>{t('services.birthday.calendarSelection.numberOfChildrenLabel')}</Text>
                        </InputLabel>
                        <Select
                            value={dayIsCorrect ? sentData.guests.toString() : ''}
                            onChange={handleNumberOfChildrenChange}
                            label="Nombredenfant"
                            disabled={!dayIsCorrect}
                        >
                            {possibleNumbersOfChildren.map((item) => {
                                return (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </StyledForm>
                </StyledFormContainer>

                {error && <Text color={colors.red}>{t('error.birthdayStatus')}</Text>}
                {!dayIsCorrect && (
                    <Text color={colors.red}>{t('services.birthday.calendarSelection.notAvailable')}</Text>
                )}
                {!isSelectedHourAvailable && dayIsCorrect && (
                    <Text color={colors.red}>{t('services.birthday.calendarSelection.select')}</Text>
                )}

                <StyledButtonsContainer>
                    <StyledCancellationButton>
                        <StyledH4 onClick={handleClose}>
                            {!isLoading ? (
                                t('component.button.cancel')
                            ) : (
                                <ClipLoader size={20} color={colors.brown} loading={true} />
                            )}
                        </StyledH4>
                    </StyledCancellationButton>
                    <StyledValidationButton
                        onClick={confirmModificationFromModal}
                        disabled={!(dayIsCorrect && isSelectedHourAvailable)}
                    >
                        <StyledWhiteH4>
                            {!isLoading ? (
                                t('component.button.confirm')
                            ) : (
                                <ClipLoader size={20} color={colors.white} loading={true} />
                            )}
                        </StyledWhiteH4>
                    </StyledValidationButton>
                </StyledButtonsContainer>
            </StyledModalContainer>
        </Modal>
    )
}
