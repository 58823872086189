import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledCheckbox = styled.div<{ checked: boolean; disabled?: boolean }>`
    display: flex;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    border: solid ${colors.greyLight};
    background-color: ${(props) => (props.disabled ? colors.greyLight : props.checked ? colors.orange : colors.white)};
    ${(props) => !props.disabled && '&:hover {cursor: pointer;}'}
    & > svg {
        margin: 2px 0 0 3px;
    }
`
