import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SmallText, Text } from 'styles/typography'

export const StyledPageContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    max-height: 100%;
`

export const StyledImageContainer = styled.div<{ img: string }>`
    width: 80%;
    background: center/100% no-repeat url(${(props) => props.img});
    background-size: contain;
    background-color: ${colors.orange};
`

export const StyledGreyBackground = styled.div`
    width: 20%;
    background-color: ${colors.beige};
`

export const StyledDivLogin = styled.div`
    position: absolute;
    background: ${colors.white};
    box-shadow: 0px 4px 12px ${colors.shadowDark};
    border-radius: 8px;
    padding: 32px;
    top: 50%;
    transform: translateY(-50%);
    left: calc(75% - (20% / 2) - 32px); //calc(image - (size / 2) - padding)
    height: fit-content;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 20%;
    min-width: 300px;
`

export const StyledTitleContainer = styled.div`
    margin: 0 auto;
    & > h1 {
        margin: 10px 0 15px 0;
    }
`

export const StyledButtonContainer = styled.div`
    margin: 0 auto;
    margin-top: 15px;
    width: 100%;
`

export const StyledLogo = styled.img`
    margin: 0 auto;
    object-fit: contain;
`

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: 25px;
`

export const StyledInput = styled.input`
    border: 1px solid ${colors.greyLight};
    box-sizing: border-box;
    border-radius: 5px;
    padding: 8px 12px;
`

export const StyledInputsContainer = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 100%;
`

export const StyledField = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 3px;
    width: 100%;
`

export const StyledErrorText = styled(SmallText)`
    color: ${colors.red};
`

export const StyledErrorContainer = styled.div`
    min-height: 20px;
`

export const StyledUpdatePasswordLink = styled(Text)`
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 24px;
    margin-top: 5px;
`
