import { CouponSeparator } from 'assets/icons'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledCouponContainer = styled.div<{ selected: boolean; nothingSelected: boolean }>`
    width: 177px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.lightMayo};
    border-radius: 10px;
    overflow: hidden;
    ${(props) => !props.selected && 'opacity: 0.5;'};
    border: 2px solid ${colors.transparent};

    ${(props) =>
        !props.nothingSelected &&
        `
        border-color: ${props.selected && colors.brownExtraLight}`};
    box-sizing: border-box;

    & :hover {
        cursor: pointer;
    }
`

export const StyledImage = styled.img`
    height: 100%;
    margin: auto;
`

export const StyledTopContainer = styled.div<{ backgroundColor: string }>`
    background-color: ${(props) => props.backgroundColor};
    width: 100%;
    height: 120px;
    text-align: center;
`

export const StyledRelativePosition = `
    position: relative;
    top: -4px;
`

export const StyledBottomContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 120px;
    text-align: center;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    flex-direction: column;

    ${StyledRelativePosition}
`

export const StyledCouponSeparator = styled(CouponSeparator)`
    ${StyledRelativePosition}
`
