import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ArrowRightIcon } from '../../../../../../assets/icons'
import { CircledIcon } from '../../../../../../components/circled-icon/CircledIcon'
import { colors } from '../../../../../../styles/colors'
import { fonts } from '../../../../../../styles/fonts'
import { SPACING } from '../../../../../../styles/tokens'
import { Text } from '../../../../../../styles/typography'
import { SmallCardProps } from '../../../../../../types/card'

export type LittleCardProps = {
    element: SmallCardProps
    icon: JSX.Element
    warning?: boolean
}

export const SmallCard = ({ element, icon, warning = false }: LittleCardProps) => {
    const navigate = useNavigate()

    return (
        <StyledElement>
            <CircledIcon icon={icon} size={SPACING.XXS} color={warning ? colors.orange : colors.beige} />
            <StyledLittleCardContainer onClick={() => navigate(element.url)}>
                <StyledBelowContainer>
                    <Text color={colors.brown} size={SPACING.S}>
                        {element.textKey}
                    </Text>
                    <ArrowRightIcon size={25} color={colors.brown} />
                </StyledBelowContainer>
                <Text color={colors.brown} size={SPACING.XL} font={fonts.flame}>
                    {element.element}
                </Text>
            </StyledLittleCardContainer>
        </StyledElement>
    )
}

const StyledElement = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXXS};
    align-items: center;
`

const StyledLittleCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.XXXS};
    padding-bottom: ${SPACING.XXS};
    width: 100%;
    &:hover {
        cursor: pointer;
    }
`

const StyledBelowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
