import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Crown = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M9.731 15.258l.34.01a10.855 10.855 0 015.674 1.431 7.786 7.786 0 01-5.247 1.742h-.25c-2.48 0-4.557-.752-5.305-1.814.301-.13.591-.285.866-.465a8.665 8.665 0 014.262-.894zm.976-13.256h.171a1.384 1.384 0 011.187.716c.178.311.355.642.53.973l.212.396c.6 1.113 1.202 2.126 1.858 2.21.961 0 1.733-1.455 2.92-2.815a1.248 1.248 0 01.937-.477 1.716 1.716 0 011.2.769 1.734 1.734 0 01.213 1.414c-.761 3.003-.984 4.582-1.237 6.075l-.077.447a29.419 29.419 0 01-.692 3.02c-.119.382-.546.99-.914.99a.296.296 0 01-.19-.059 11.956 11.956 0 00-6.754-1.837 9.86 9.86 0 00-4.962 1.157 2.462 2.462 0 01-1.187.49c-.51 0-.475-.705-.76-1.528A66.712 66.712 0 001.121 5.69c-.416-1.229.308-1.825 1.175-1.837.524.007 1.03.201 1.424.548 1.223 1.098 2.267 2.911 3.146 2.911h.071c1.187-.346 1.531-2.732 2.28-4.33a1.75 1.75 0 011.661-.978z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    )
}
