import { CommentInfo, Pencil, StuartLogo, UberDirectLogo } from 'assets/icons'
import { Notif } from 'components/notif/Notif'
import { Switch } from 'components/switch/Switch'
import { TextModal } from 'components/text-modal/TextModal'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { changePickupInfo, toggleService } from 'redux/restaurant/thunks'
import { colors } from 'styles/colors'
import { H4, Text } from 'styles/typography'
import { RestaurantDTO, ServiceDTO } from 'types/api'
import { NotyfType } from 'utils/enums'

import {
    StyledDeliveryPartnerContainer,
    StyledH2,
    StyledIconContainer,
    StyledInputContainer,
    StyledModalButton,
    StyledParamContainer,
    StyledTextContainer,
    StyledTitleContainer,
    StyledTitleParameters,
    StyledToggleContainer,
} from './style'

interface ServiceDeliveryProps {
    service: ServiceDTO
    restaurant: RestaurantDTO
    setServices: (service: ServiceDTO[]) => void
    setRestaurant: (restaurant: RestaurantDTO) => void
    disabled?: boolean
}

const IS_HIDDEN_DELIVERY_PARTNER = true
const IS_STUART_PARTNER = true

export const ServiceDelivery = ({
    service,
    setServices,
    restaurant,
    setRestaurant,
    disabled = false,
}: ServiceDeliveryProps) => {
    const [isActive, setActive] = useState(service.active)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isDisabled, setDisabled] = useState(disabled)
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const pickupInfo = restaurant.pickupInfo
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const onChangeActive = () => {
        setActive(!isActive)
        setDisabled(true)
        dispatch(
            toggleService({
                enabled: !isActive,
                frNumber: restaurantFrNumber,
                serviceCode: service.code,
            }),
        )
            .then(() => {
                setServices([{ ...service, active: !isActive }])
            })
            .catch(() => {
                Notif({ type: NotyfType.ERROR, text: t('error.serviceActivate') })
                setActive(isActive)
            })
            .finally(() => {
                setDisabled(false)
            })
    }

    const onChangePickupInfo = (value: string) => {
        setDisabled(true)
        dispatch(
            changePickupInfo({
                frNumber: restaurantFrNumber,
                pickupInfo: value,
            }),
        )
            .then((newRestaurant) => {
                setRestaurant({ ...newRestaurant, services: restaurant.services })
            })
            .catch(() => {
                Notif({ type: NotyfType.ERROR, text: t('error.pickupInfoChange') })
            })
            .finally(() => {
                setDisabled(false)
            })
    }

    return (
        <>
            <StyledTitleContainer>
                <StyledH2>{t(`services.servicesCode.${service.code}`)}</StyledH2>
                {!IS_HIDDEN_DELIVERY_PARTNER && (
                    <StyledDeliveryPartnerContainer>
                        <CommentInfo color={colors.brown} size={20} viewBox="-1 -1 19 19" />
                        <Text color={colors.brown}>{t('services.delivery.deliveryPartner')}</Text>
                        {IS_STUART_PARTNER ? <StuartLogo /> : <UberDirectLogo />}
                    </StyledDeliveryPartnerContainer>
                )}
            </StyledTitleContainer>
            <StyledParamContainer>
                <StyledTitleParameters>
                    <H4 color={colors.brown}>{t('services.delivery.name')}</H4>
                    <StyledToggleContainer>
                        <H4 color={colors.brown}>{t(isActive ? 'component.button.on' : 'component.button.off')}</H4>
                        <Switch onChange={onChangeActive} checked={isActive} disabled={isDisabled} />
                    </StyledToggleContainer>
                </StyledTitleParameters>
                <Text>{t('services.delivery.activate')}</Text>
            </StyledParamContainer>
            <StyledParamContainer>
                <StyledTitleParameters>
                    <H4 color={colors.brown}>{t('services.delivery.pickupInfo')}</H4>
                    <StyledModalButton>
                        <Pencil
                            color={isDisabled ? colors.defaultGrey : colors.brown}
                            onClick={() => !isDisabled && setIsModalOpen(!isModalOpen)}
                        />
                    </StyledModalButton>
                </StyledTitleParameters>
                <StyledInputContainer>
                    <StyledIconContainer>
                        <CommentInfo color={colors.brown} />
                    </StyledIconContainer>
                    <StyledTextContainer>
                        <Text>{pickupInfo || t('services.delivery.pickupInfoDefault')}</Text>
                    </StyledTextContainer>
                </StyledInputContainer>
            </StyledParamContainer>
            {isModalOpen && (
                <TextModal
                    handleClose={() => setIsModalOpen(!isModalOpen)}
                    title={t('services.delivery.modalPickupInfoTitle')}
                    subTitle={t('services.delivery.modalPickupInfoSubTitle')}
                    onValidate={onChangePickupInfo}
                    defaultValue={pickupInfo || t('services.delivery.pickupInfoDefault')}
                />
            )}
        </>
    )
}
