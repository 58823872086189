import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { DropDownItem } from 'types/dropdown-item'

import { StyledDropdownList, StyledText } from './style'

type DropdownItemProps = {
    item: DropDownItem
    index: number
    onSelectItem: (item: DropDownItem) => void
}

export const DropdownItem = ({ index, item, onSelectItem }: DropdownItemProps) => {
    return (
        <StyledDropdownList key={index} onClick={() => onSelectItem(item)}>
            <StyledText font={fonts.flameSans} color={colors.blackLight}>
                {item.label}
            </StyledText>
        </StyledDropdownList>
    )
}
