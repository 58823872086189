import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledParamContainer = styled.div<{ separator?: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 24px;
    ${(props) =>
        props.separator &&
        `box-shadow: inset 0px -1px 0px ${colors.shadow};
        padding-bottom: 24px;`}
    box-sizing: border-box;
`

export const StyledModalButton = styled.div`
    display: flex;
    height: fit-content;
    &:hover {
        cursor: pointer;
    }
`
