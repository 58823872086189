import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const CheckCircle = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M13.084 7.45l-4.075 4.085L7.44 9.968a.95.95 0 10-1.34 1.34l2.233 2.242a.95.95 0 001.34 0l4.75-4.75a.95.95 0 10-1.34-1.35zM10.5 1a9.5 9.5 0 100 19 9.5 9.5 0 000-19zm0 17.1a7.6 7.6 0 110-15.2 7.6 7.6 0 010 15.2z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
