import { MinusCircle, PlusCircle } from 'assets/icons'
import { useState } from 'react'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'

import {
    StyledIconContainer,
    StyledNumber,
    StyledNumberContainer,
    StyledNumberSelector,
    StyledTextBelowNumber,
} from './style'

interface NumberSelectorProps {
    min?: number
    max?: number
    initValue?: number
    range?: number
    unit?: string
    onChange: (value: number) => void
}

export const NumberSelector = ({ min, max, initValue = min, range = 1, unit, onChange }: NumberSelectorProps) => {
    const [value, setValue] = useState(initValue || 0)
    const minReached = !!min || min === 0 ? value - range < min : false
    const maxReached = !!max || max === 0 ? value + range > max : false

    const onClickPlus = () => {
        if (!maxReached) {
            setValue(value + range)
            onChange(value + range)
        }
    }

    const onClickMinus = () => {
        if (!minReached) {
            setValue(value - range)
            onChange(value - range)
        }
    }

    return (
        <StyledNumberSelector>
            <StyledIconContainer onClick={onClickMinus} visible={!minReached}>
                <MinusCircle color={colors.red} size={40} />
            </StyledIconContainer>
            <StyledNumberContainer>
                <StyledNumber>{value}</StyledNumber>
                {unit && <StyledTextBelowNumber font={fonts.flameSans}>{unit}</StyledTextBelowNumber>}
            </StyledNumberContainer>
            <StyledIconContainer onClick={onClickPlus} visible={!maxReached}>
                <PlusCircle color={colors.red} size={40} />
            </StyledIconContainer>
        </StyledNumberSelector>
    )
}
