import './index.css'
// Set the libs style
import 'notyf/notyf.min.css'
import 'react-datepicker/dist/react-datepicker.css'

import fr from 'date-fns/locale/fr'
import { queryClient } from 'httpServices/query/queryClient'
import React from 'react'
// Set the date-picker language
import { registerLocale } from 'react-datepicker'
import ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { persistor, store } from 'redux/store'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import reportWebVitals from './reportWebVitals'

registerLocale('fr', fr)
ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App />
                    </PersistGate>
                </Provider>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
