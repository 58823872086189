import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const CheckBoldIcon = (props: IconProps) => {
    const { color = colors.brown, size = 21, ...otherProps } = props

    return (
        <svg width={size} height={size} viewBox="0 0 13 13" {...otherProps}>
            <g clipPath="url(#clip0_8377_69301)">
                <path
                    d="M9.27922 12.022C8.95235 12.5352 8.62567 12.8 8.07327 12.8C7.52087 12.8 7.1906 12.5352 6.867 12.022C6.05965 10.7407 5.48101 10.1786 4.73249 8.98878C4.62575 8.85301 4.54917 8.69616 4.50745 8.52857C4.46573 8.36098 4.45991 8.18645 4.49053 8.01649C4.52116 7.84652 4.58766 7.68496 4.68525 7.54247C4.78283 7.39998 4.90939 7.27981 5.0568 7.18983C5.20421 7.09985 5.36905 7.04212 5.54039 7.02045C5.71173 6.99879 5.88563 7.01365 6.0508 7.0641C6.21597 7.11456 6.36865 7.19941 6.49864 7.31312C6.62863 7.42683 6.7331 7.56683 6.80507 7.72382C7.12527 8.3372 7.47994 8.93194 7.86738 9.50521C8.03082 9.7569 8.11899 9.7569 8.27916 9.50521C8.72042 8.8286 10.5965 4.71992 11.0214 3.91257C11.0944 3.75721 11.1995 3.61895 11.3295 3.50682C11.4594 3.39469 11.6116 3.31119 11.776 3.26175C11.9404 3.21232 12.1133 3.19805 12.2836 3.2199C12.4538 3.24175 12.6174 3.29922 12.764 3.38856C12.9105 3.47789 13.0368 3.59709 13.1342 3.73841C13.2317 3.87972 13.2982 4.03999 13.3296 4.20875C13.361 4.37751 13.3566 4.551 13.3165 4.71792C13.2764 4.88484 13.2018 5.04144 13.0972 5.17753C12.3454 6.36732 10.0898 10.7407 9.27922 12.022Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_8377_69301">
                    <rect width={size} height={size} fill="white" transform="translate(4.47461 3.19995)" />
                </clipPath>
            </defs>
        </svg>
    )
}
