import burgerLogo from 'assets/images/burger-king-logo.webp'
import backgroundImage from 'assets/images/nuggets-eating.webp'
import { Button } from 'components/button/Button'
import { useFormik } from 'formik'
import { useAppDispatch } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { forgottenPassword } from 'redux/user/thunks'
import { logout } from 'redux/user/userSlice'
import { colors } from 'styles/colors'
import { H2, Text } from 'styles/typography'
import { Errors } from 'types/api.enum'
import * as Yup from 'yup'

import {
    StyledButtonContainer,
    StyledDivForm,
    StyledErrorContainer,
    StyledErrorText,
    StyledField,
    StyledForm,
    StyledGreyBackground,
    StyledImageContainer,
    StyledInput,
    StyledInputsContainer,
    StyledLogo,
    StyledPageContainer,
    StyledTitleContainer,
} from './style'

export const ForgottenPassword = () => {
    const { t } = useTranslation()
    const [error, setError] = useState<string>()
    const [updatedSuccess, setUpdateSuccess] = useState(false)
    const [currentEmail, setCurrentEmail] = useState('')
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        document.getElementById('botmind-webchat-js')?.remove()
        document.getElementById('botmind-iframe')?.remove()
    }, [])

    const schema = Yup.object().shape({
        email: Yup.string().email(),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: schema,
        onSubmit: (values, actions) => {
            dispatch(forgottenPassword(values))
                .then(() => {
                    setError(undefined)
                    setCurrentEmail(values.email)
                    setUpdateSuccess(true)
                })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .catch((error: any) => {
                    const errorMessage = error?.response?.data?.message
                    if (errorMessage === Errors.USER_NOT_FOUND) {
                        setError(t('error.userNotFound'))
                    } else {
                        setError(t('error.default'))
                    }
                })
                .finally(() => {
                    actions.setSubmitting(false)
                    dispatch(logout())
                })
        },
    })

    const { values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting } = formik

    return (
        <StyledPageContainer>
            <StyledImageContainer img={backgroundImage} />
            <StyledGreyBackground />
            {updatedSuccess ? (
                <StyledDivForm>
                    <StyledLogo src={burgerLogo} alt="logo" width={65} />
                    <StyledTitleContainer>
                        <H2 color={colors.brown}>{t('forgottenPassword.titleValidated')}</H2>
                        <Text>{t('forgottenPassword.textValidated', { email: currentEmail })}</Text>
                    </StyledTitleContainer>

                    <StyledButtonContainer>
                        <Button
                            text={t('component.button.home')}
                            color={colors.red}
                            style={{ width: '100%' }}
                            onClick={() => navigate(t('page.login.route'))}
                        />
                    </StyledButtonContainer>
                </StyledDivForm>
            ) : (
                <StyledDivForm>
                    <StyledLogo src={burgerLogo} alt="logo" width={65} />
                    <StyledTitleContainer>
                        <H2 color={colors.brown}>{t('forgottenPassword.title')}</H2>
                        <Text>{t('forgottenPassword.text')}</Text>
                    </StyledTitleContainer>

                    <StyledForm onSubmit={handleSubmit}>
                        <StyledInputsContainer>
                            <StyledField>
                                <Text color={colors.brown}>{t('forgottenPassword.email')}</Text>
                                <StyledInput
                                    type={'email'}
                                    name={'email'}
                                    placeholder={t('forgottenPassword.email')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                <StyledErrorContainer>
                                    {errors.email && touched.email && <StyledErrorText>{errors.email}</StyledErrorText>}
                                </StyledErrorContainer>
                            </StyledField>
                        </StyledInputsContainer>
                        <StyledErrorContainer>
                            {!!error && <StyledErrorText>{error}</StyledErrorText>}
                        </StyledErrorContainer>
                        <StyledButtonContainer>
                            <Button
                                type={'submit'}
                                disabled={isSubmitting}
                                text={t('component.button.confirm')}
                                color={colors.red}
                                style={{ width: '100%' }}
                            />
                        </StyledButtonContainer>
                    </StyledForm>
                </StyledDivForm>
            )}
        </StyledPageContainer>
    )
}
