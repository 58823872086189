import { Pencil } from 'assets/icons'
import { OrderPreparationTimeModal } from 'components/order-preparation-time-modal/OrderPreparationTimeModal'
import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'

import {
    StyledButtonContainer,
    StyledH1,
    StyledH4,
    StyledNumberContainer,
    StyledOrderTimeContainer,
    StyledTextContainer,
} from './style'

interface OrderPreparationTimeProps {
    preparationTime: number
    isClickable: boolean
}

export const OrderPreparationTime = ({ preparationTime, isClickable }: OrderPreparationTimeProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()

    const toggleModalPreparationTime = () => {
        if (isClickable) {
            setIsOpen(!isOpen)
        }
    }

    return (
        <>
            <StyledOrderTimeContainer onClick={toggleModalPreparationTime} isClickable={isClickable}>
                <StyledTextContainer>
                    <StyledH4 color={colors.brown}>{t('component.timeSelector.timePreparation')}</StyledH4>
                    <Text>{t('component.timeSelector.changePreparationTime')}</Text>
                </StyledTextContainer>
                <StyledNumberContainer>
                    <StyledH1 color={colors.brown}>{preparationTime}</StyledH1>
                </StyledNumberContainer>
                <ShouldDisplay condition={isClickable}>
                    <StyledButtonContainer>
                        <Pencil color={colors.orange} />
                    </StyledButtonContainer>
                </ShouldDisplay>
            </StyledOrderTimeContainer>
            {isOpen && (
                <OrderPreparationTimeModal preparationTime={preparationTime} handleClose={toggleModalPreparationTime} />
            )}
        </>
    )
}
