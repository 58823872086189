import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const SendIcon = (props: IconProps) => {
    const { color = colors.brown, size = 24, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...otherProps}>
            <g id="Frame 3466996">
                <path
                    id="Vector"
                    d="M7.55038 6.10073L8.25988 8.00959C9.23111 10.6152 9.21091 13.4876 8.21258 16.0873L7.52734 17.8713C7.14931 18.8455 8.15481 19.7687 9.09403 19.3204L22.1727 13.0448C23.0473 12.6261 23.051 11.3833 22.1847 10.9638L9.1315 4.64819C8.19495 4.19374 7.18397 5.12289 7.54454 6.09489L7.55038 6.10073Z"
                    fill={color}
                />
            </g>
        </svg>
    )
}

export { SendIcon }
