import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

export const Crown2 = ({ color = colors.yellowMelty, size = 17, ...otherProps }: IconProps) => (
    <svg width={size} height={(size / 17) * 12} viewBox="0 0 17 12" {...otherProps}>
        <g id="Frame 3465692">
            <g id="Group">
                <path
                    fill={color}
                    id="Vector"
                    d="M2.66357 11.6312C2.11393 11.6312 1.62896 11.2433 1.53197 10.6936L0.109386 3.0634C0.0123915 2.51377 0.65902 2.12579 1.07933 2.4491L4.53879 5.06795C4.82977 5.29427 5.28241 5.19728 5.44407 4.87396L7.96592 0.315231C8.19224 -0.105077 8.80654 -0.105077 9.06519 0.315231L11.5547 4.87396C11.7487 5.19728 12.169 5.29427 12.46 5.06795L15.9195 2.48144C16.3721 2.12579 16.9864 2.51377 16.8894 3.09573L15.4345 10.7259C15.3375 11.2756 14.8525 11.6636 14.3029 11.6636L2.66357 11.6312Z"
                />
            </g>
        </g>
    </svg>
)
