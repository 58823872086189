import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Cog = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M19.354 8.173l-1.796-.599.846-1.691a.95.95 0 00-.18-1.083L16.2 2.777a.95.95 0 00-1.093-.181l-1.69.846-.599-1.796A.95.95 0 0011.925 1h-2.85a.95.95 0 00-.902.646l-.599 1.796-1.691-.846a.95.95 0 00-1.083.18L2.777 4.8a.95.95 0 00-.181 1.093l.846 1.69-1.796.599A.95.95 0 001 9.075v2.85a.95.95 0 00.646.903l1.796.598-.846 1.691a.95.95 0 00.18 1.083L4.8 18.224a.95.95 0 001.093.18l1.69-.845.599 1.795a.95.95 0 00.903.646h2.85a.95.95 0 00.902-.646l.598-1.795 1.691.845a.95.95 0 001.074-.18l2.023-2.024a.95.95 0 00.181-1.092l-.846-1.691 1.796-.599a.95.95 0 00.646-.893v-2.85a.95.95 0 00-.646-.902zM18.1 11.24l-1.14.38a1.9 1.9 0 00-1.102 2.679l.541 1.083-1.045 1.045-1.054-.57a1.9 1.9 0 00-2.65 1.102l-.38 1.14H9.758l-.38-1.14A1.9 1.9 0 006.7 15.858l-1.083.541-1.045-1.045.57-1.054a1.9 1.9 0 00-1.102-2.68l-1.14-.38V9.76l1.14-.38a1.9 1.9 0 001.102-2.68L4.6 5.646 5.645 4.6l1.055.542A1.9 1.9 0 009.379 4.04l.38-1.14h1.482l.38 1.14A1.9 1.9 0 0014.3 5.142l1.083-.542 1.045 1.045-.57 1.055a1.9 1.9 0 001.102 2.65l1.14.38v1.51zM10.5 6.7a3.8 3.8 0 100 7.6 3.8 3.8 0 000-7.6zm0 5.7a1.9 1.9 0 110-3.8 1.9 1.9 0 010 3.8z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
