import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Clock = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M10.5 1a9.5 9.5 0 100 19 9.5 9.5 0 000-19zm0 17.1a7.6 7.6 0 110-15.2 7.6 7.6 0 010 15.2zm2.945-7.001l-1.995-1.15V5.75a.95.95 0 00-1.9 0v4.864c.006.066.022.13.047.19.02.056.046.11.076.162a.817.817 0 00.095.152l.152.123.086.086 2.47 1.425a.95.95 0 00.475.123.95.95 0 00.475-1.776h.019z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
