import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const FingerUp = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M17.039 8.36899C16.7602 7.26129 15.1802 6.1536 14.6071 6.70745C14.4031 6.32163 14.0713 6.01755 13.6678 5.84656C13.2642 5.67558 12.8136 5.64818 12.392 5.76899C12.2723 5.78863 12.158 5.83301 12.0566 5.89926C11.9552 5.96552 11.869 6.05216 11.8034 6.1536C11.6367 5.81051 11.3865 5.51409 11.0755 5.29114C10.7645 5.06819 10.4024 4.92575 10.022 4.87668C9.74173 4.8287 9.45383 4.84991 9.18372 4.93844C8.9136 5.02697 8.66954 5.18011 8.47304 5.38437C8.47458 4.55205 8.43322 3.72019 8.34912 2.89206C8.24069 1.01514 7.41972 -0.431013 5.87071 0.107449C3.98093 0.768987 4.44563 2.73822 4.647 5.23053C4.647 6.41514 5.01876 11.6459 4.27524 10.6613C3.97045 9.98742 3.53336 9.3807 2.98957 8.87668C2.27703 8.32283 0.867436 8.19975 0.356265 8.87668C-0.449216 10.0151 0.449205 11.369 0.882926 12.5536C1.98272 15.4767 2.67977 17.9228 5.40601 19.2921C7.02696 20.0684 8.8893 20.1843 10.5952 19.6151C11.6141 19.7678 12.6557 19.6255 13.5952 19.2053C14.5348 18.7851 15.3325 18.1048 15.8928 17.2459C17.3488 14.5237 17.7563 11.368 17.039 8.36899Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
