import { createAsyncThunk } from '@reduxjs/toolkit'
import { services } from 'httpServices/services'
import {
    ChatIdForm,
    ComplaintContestForm,
    ComplaintFranchisedRestaurantCloseRequest,
    ComplaintFranchisedRestaurantRefundRequest,
    ComplaintFranchisedRestaurantReportRequest,
    PatchComplaintForm,
} from 'types/api'
import { DateRange } from 'types/date-range'
import { AppDispatch } from 'types/redux'
import { sortByDate } from 'utils/util'

import { setComplaints, setCurrentComplaint } from './complaintSlice'

export const fetchComplaint = (complaintId: string) => async (dispatch: AppDispatch) => {
    const response = await services.complaint.fetch(complaintId)
    const complaint = response?.data?.complaint
    if (complaint) {
        dispatch(setCurrentComplaint(complaint))
    }
    return complaint
}

export const validateComplaint = (complaintId: string) => async () => {
    const response = await services.complaint.validate(complaintId)
    return response.data
}

export const contestComplaint = (form: ComplaintContestForm) => async () => {
    const response = await services.complaint.contest(form)
    return response.data
}

export const validateRefundComplaint = (complaintId: string) => async () => {
    const response = await services.complaint.validateRefund(complaintId)
    return response.data
}

export const setComplaintChatId = (form: ChatIdForm) => async () => {
    const response = await services.complaint.setChatId(form)
    return response.data
}

export const patchComplaint = (complaintId: string, form: PatchComplaintForm) => async () => {
    const response = await services.complaint.patch(complaintId, form)
    return response.data
}

type CloseComplaintThunkArg = {
    id: string
} & ComplaintFranchisedRestaurantCloseRequest

export const closeComplaint = createAsyncThunk('complaints/close', async (thunkArg: CloseComplaintThunkArg) => {
    const { id, ...form } = thunkArg
    const response = await services.complaint.close(id, form)
    return response.data
})

type ReportComplaintThunkArg = {
    id: string
} & ComplaintFranchisedRestaurantReportRequest

export const reportComplaint = createAsyncThunk('complaints/report', async (thunkArg: ReportComplaintThunkArg) => {
    const { id, ...form } = thunkArg
    const response = await services.complaint.report(id, form)
    return response.data
})

type fetchComplaintsListThunkArg = {
    frNumber: string
    dateRange?: DateRange
}

export const fetchComplaintsList = createAsyncThunk(
    'complaints/fetchList',
    async (thunkArg: fetchComplaintsListThunkArg, { dispatch }) => {
        const { frNumber, dateRange } = thunkArg
        const response = await services.complaint.fetchList(frNumber, dateRange)
        const complaints = response?.data?.complaints
        if (complaints) {
            complaints.sort((a, b) => sortByDate(a.createdDate ?? '', b.createdDate ?? ''))
            dispatch(setComplaints(complaints))
        }
        return complaints
    },
)

export const fetchCouponsList = (complaintId: string) => async () => {
    const response = await services.complaint.fetchRefundList(complaintId)
    return response.data
}

export const answerComplaint = (complaintId: string, message: string) => async () => {
    const response = await services.complaint.answer(complaintId, message)
    return response.data
}

export const refundCustomer = (complaintId: string, refund: ComplaintFranchisedRestaurantRefundRequest) => async () => {
    const response = await services.complaint.refund(complaintId, refund)
    return response.data
}
