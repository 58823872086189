export const botmindChatScript = (
    token: string,
    email?: string,
    firstName?: string,
    lastName?: string,
    restaurants?: string[],
): string => `
    window.botmindWidget = null;
    function initBotmindChat() {
      if (window.botmindWidget?.works && window.botmindWidget.init) {
        window.botmindWidget.init(
          {
            token: '${token}',
            host: 'https://api.widget.botmind.io',
            language: 'fr'
          },
          {
            email: '${email}',
            firstName: '${firstName}',
            lastName: '${lastName}',
            customData: [{key: 'restaurantsList', value: ${JSON.stringify(restaurants)}}]
          },
          {
            startHidden: false,
            v2: true
          }
        );
      }
    }

    function BCinitialize(i, t) {
      let n;
      i.getElementById(t)
        ? initBotmindChat()
        : (((n = i.createElement('script')).id = t),
          (n.async = !0),
          (n.src = 'https://widget.botmind.io' + '/public/widget.js'),
          (n.onload = initBotmindChat),
          i.head.appendChild(n));
    }

    function BCinitiateCall() {
      BCinitialize(document, 'botmind-webchat-js');
    }
    if (document.readyState === 'loading') {
      window.addEventListener
        ? window.addEventListener('load', BCinitiateCall, !1)
        : window.attachEvent('load', BCinitiateCall, !1);
    } else {
      BCinitiateCall();
    }
`
