import { Status } from 'components/status/Status'
import { useTranslation } from 'react-i18next'
import { StatusType } from 'utils/enums'

import { StyledContainer } from './style'

interface WsStatusProps {
    status: StatusType
}

export const WsStatus = ({ status }: WsStatusProps) => {
    const { t } = useTranslation()

    return (
        <StyledContainer>
            <Status
                type={status}
                text={t(status === StatusType.FINISHED ? 'status.realTimeActive' : 'status.realTimeInactive')}
            />
        </StyledContainer>
    )
}
