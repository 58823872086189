import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const ArrowRightIcon = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={(size / 24) * 25} viewBox="0 0 24 25" fill="none" {...otherProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.92 12.1202C17.8724 11.9975 17.801 11.8853 17.71 11.7902L12.71 6.79021C12.4563 6.53655 12.0866 6.43748 11.7401 6.53033C11.3936 6.62318 11.123 6.89383 11.0301 7.24033C10.9373 7.58683 11.0363 7.95655 11.29 8.21021L14.59 11.5002H7C6.44772 11.5002 6 11.9479 6 12.5002C6 13.0525 6.44772 13.5002 7 13.5002H14.59L11.29 16.7902C11.1007 16.978 10.9942 17.2336 10.9942 17.5002C10.9942 17.7668 11.1007 18.0224 11.29 18.2102C11.4778 18.3995 11.7334 18.506 12 18.506C12.2666 18.506 12.5222 18.3995 12.71 18.2102L17.71 13.2102C17.801 13.1151 17.8724 13.003 17.92 12.8802C18.02 12.6367 18.02 12.3637 17.92 12.1202Z"
                fill={color}
            />
        </svg>
    )
}
