import { useCallback, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useClickOutside = (ref: any, callback?: () => any) => {
    const handleClick = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback?.()
            }
        },
        [ref, callback],
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [handleClick])
}
