import { Notyf } from 'notyf'
import { NotyfType } from 'utils/enums'

interface Props {
    text: string
    type: NotyfType
}

export const Notif = (props: Props) => {
    const { text, type } = props
    const notyf = new Notyf()

    return notyf[type]({
        message: text,
        dismissible: true,
        duration: 3500,
    })
}
