import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'

import { BoldCross } from '../../assets/icons'
import { Modal } from '../../components/modal/Modal'
import { useAppSelector } from '../../hooks/useRedux'
import { useGetEmployeeData, useUpdateEmployee } from '../../redux/collaborators/collaborators'
import { colors } from '../../styles/colors'
import { H2 } from '../../styles/typography'
import { CrmEmployeeForm, EmployeeWithCouponDTO } from '../../types/api'
import { FormCollaborator } from './components/FormCollaborator'
import { StyledContainer, StyledLoaderContainer, StyledMainContainer, StyledModalContainer } from './style'

export const UpdateCollaborator = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const { id = '' } = useParams()
    const { data: employee } = useGetEmployeeData(restaurantFrNumber, id)
    const [consultationMode, setConsultationMode] = useState(true)

    const handleBackPress = () => {
        if (consultationMode) {
            navigate(t('page.collaborators.route'))
        } else {
            setConsultationMode(true)
        }
    }

    const {
        mutate: updateEmployeeMutation,
        isLoading,
        isError,
        reset,
    } = useUpdateEmployee({
        onSuccess: handleBackPress,
    })

    const onSubmit = (values: EmployeeWithCouponDTO) => {
        if (consultationMode) {
            setConsultationMode(false)
        } else {
            const employeeDataUpdate = {
                lastName: values.lastName,
                firstName: values.firstName,
                email: values.email,
                employeeId: values.employeeId,
            } as CrmEmployeeForm
            updateEmployeeMutation({
                frNumber: restaurantFrNumber,
                id,
                employeeData: employeeDataUpdate,
            })
        }
    }

    return (
        <>
            {!employee ? (
                <StyledMainContainer>
                    <StyledContainer>
                        <StyledLoaderContainer>
                            <ClipLoader size={70} color={colors.orange} loading={true} />
                        </StyledLoaderContainer>
                    </StyledContainer>
                </StyledMainContainer>
            ) : (
                <FormCollaborator
                    onSubmit={onSubmit}
                    initialValues={{ ...employee, frNumber: restaurantFrNumber }}
                    handleBackPress={handleBackPress}
                    isLoading={isLoading || isError}
                    title={
                        consultationMode
                            ? t('page.collaborators.detailsEmployee.title')
                            : t('page.collaborators.updateEmployee.title')
                    }
                    disable={consultationMode}
                    displayCodeCoupon
                    displayResendMailButton={consultationMode}
                    disableMail
                />
            )}
            {isError && (
                <Modal handleClose={reset} closeIconColor={colors.brown} center>
                    <StyledModalContainer>
                        <BoldCross color={colors.red} size={50} />
                        <H2 color={colors.brown}>{t('page.collaborators.deleteModal.error.other')}</H2>
                    </StyledModalContainer>
                </Modal>
            )}
        </>
    )
}
