import { Modal } from 'components/modal/Modal'
import { useAppDispatch } from 'hooks/useRedux'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { updateBirthdayById } from 'redux/birthday/thunks'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { BirthdayBookingDetailsDTO } from 'types/api'

import {
    StyledCancellationButton,
    StyledCommentButtonsContainer,
    StyledCommentModalContainer,
    StyledH2,
    StyledH4,
    StyledTextField,
    StyledValidationButton,
    StyledWhiteH4,
} from './style'

interface DefineLongCommentModalProps {
    handleClose: () => void
    birthday: BirthdayBookingDetailsDTO
    setBirthday: Dispatch<SetStateAction<BirthdayBookingDetailsDTO>>
    restaurantFrNumber: string
    birthdayId: string
}

export const DefineLongCommentModal = ({
    handleClose,
    birthday,
    setBirthday,
    restaurantFrNumber,
    birthdayId,
}: DefineLongCommentModalProps) => {
    const [newBirthday, setNewBirthday] = useState<BirthdayBookingDetailsDTO>(birthday)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewBirthday({
            ...birthday,
            comments: event.target.value,
        })
    }

    const updateComment = async () => {
        setIsLoading(true)
        try {
            const response = await dispatch(
                // @ts-ignore
                updateBirthdayById(restaurantFrNumber, birthdayId, {
                    comments: newBirthday.comments,
                }),
            )
            setIsLoading(false)
            return response
        } catch (error) {
            setError(error)
            setIsLoading(false)
            throw error
        }
    }

    const handleSave = async () => {
        try {
            await updateComment()
            setBirthday(newBirthday)
            handleClose()
        } catch (error) {
            setError(error)
        }
    }

    return (
        <Modal handleClose={handleClose} canCloseOnClickOutside={false}>
            <StyledCommentModalContainer>
                <StyledH2>{t('services.birthday.defineLongComment.title')}</StyledH2>
                <Text>{t('services.birthday.defineLongComment.description')}</Text>
                <StyledTextField
                    defaultValue={newBirthday.comments}
                    onChange={onInputChange}
                    multiline
                    minRows={3}
                    maxRows={3}
                />
                {error && <Text color={colors.red}>{t('error.birthdayStatus')}</Text>}
                <StyledCommentButtonsContainer>
                    <StyledCancellationButton>
                        <StyledH4 onClick={handleClose}>
                            {!isLoading ? (
                                t('component.button.cancel')
                            ) : (
                                <ClipLoader size={20} color={colors.brown} loading={true} />
                            )}
                        </StyledH4>
                    </StyledCancellationButton>
                    <StyledValidationButton onClick={handleSave}>
                        <StyledWhiteH4>
                            {!isLoading ? (
                                t('component.button.confirm')
                            ) : (
                                <ClipLoader size={20} color={colors.white} loading={true} />
                            )}
                        </StyledWhiteH4>
                    </StyledValidationButton>
                </StyledCommentButtonsContainer>
            </StyledCommentModalContainer>
        </Modal>
    )
}
