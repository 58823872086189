import { Chevron, Search } from 'assets/icons'
import { useClickOutside } from 'hooks/useClickOutside'
import { isNumber } from 'lodash'
import { ChangeEvent, CSSProperties, RefObject, useCallback, useMemo, useRef, useState } from 'react'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { DropDownItem } from 'types/dropdown-item'

import { DropdownItem } from './DropdownItem'
import {
    StyledDropDownContainer,
    StyledDropdownModal,
    StyledIconContainer,
    StyledInput,
    StyledInputContainer,
    StyledMainContainer,
    StyledText,
} from './style'

interface DropdownProps {
    placeholder?: string
    list: DropDownItem[]
    initIndex?: number
    onChange: (item: DropDownItem) => void
    searchBar?: boolean
    searchBarPlaceholder?: string
    isInsideModal?: boolean
    disabled?: boolean
    specialStyle?: CSSProperties
    dialogVariant?: boolean
}

export const Dropdown = ({
    placeholder,
    list,
    initIndex,
    onChange,
    searchBar = false,
    searchBarPlaceholder = '',
    isInsideModal = false,
    disabled = list?.length <= 0,
    specialStyle,
    dialogVariant,
}: DropdownProps) => {
    const ref: RefObject<HTMLInputElement> = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const initialValue =
        list && isNumber(initIndex) && initIndex > -1 && list.length > initIndex ? list[initIndex] : undefined
    const [selectedValue, setSelectedValue] = useState<DropDownItem | undefined>(initialValue)
    const [filterText, setFilterText] = useState('')
    useClickOutside(ref, () => setIsOpen(false))

    const listFiltered = useMemo<DropDownItem[]>(
        () => list.filter((item) => item.label.toLowerCase().includes(filterText.toLowerCase())),
        [list, filterText],
    )

    const toggleDropdown = useCallback(() => {
        if (!disabled) {
            setIsOpen(!isOpen)
            setFilterText('')
        }
    }, [isOpen, disabled])

    const selectItem = useCallback(
        (item: DropDownItem) => {
            setSelectedValue(item)
            setIsOpen(false)
            onChange(item)
        },
        [onChange],
    )

    const onChangeSearch = useCallback((filter: ChangeEvent<HTMLInputElement>) => {
        const value = filter.target.value
        setFilterText(value)
    }, [])

    return (
        <StyledMainContainer>
            <StyledDropDownContainer
                onClick={toggleDropdown}
                ref={ref}
                isInsideModal={isInsideModal}
                disabled={disabled}
                style={specialStyle}
            >
                <DisplayValueOrPlaceholder
                    value={selectedValue}
                    placeholder={placeholder}
                    dialogVariant={dialogVariant}
                    isInsideModal={isInsideModal}
                    isOpen={isOpen}
                    disabled={disabled}
                />
            </StyledDropDownContainer>
            {isOpen && (
                <StyledDropdownModal ref={ref} isInsideModal={isInsideModal}>
                    {searchBar && (
                        <StyledInputContainer>
                            <Search />
                            <StyledInput
                                placeholder={searchBarPlaceholder}
                                onChange={onChangeSearch}
                                value={filterText}
                            />
                        </StyledInputContainer>
                    )}
                    {listFiltered.map((item, index) => (
                        <DropdownItem key={index} item={item} index={index} onSelectItem={selectItem} />
                    ))}
                </StyledDropdownModal>
            )}
        </StyledMainContainer>
    )
}

interface DisplayValueOrPlaceholderProps {
    value: DropDownItem | undefined
    placeholder?: string
    dialogVariant?: boolean
    isInsideModal?: boolean
    isOpen?: boolean
    disabled?: boolean
}
const DisplayValueOrPlaceholder = ({
    value,
    placeholder,
    isInsideModal,
    isOpen,
    disabled,
    dialogVariant,
}: DisplayValueOrPlaceholderProps) => {
    if (!value && !placeholder) {
        return null
    }

    let displayedValue = placeholder
    if (value) {
        displayedValue = value.labelOnSelect || value.label
    }

    return (
        <StyledText
            font={fonts.flameSans}
            color={colors.orange}
            focus={isOpen}
            isTitle
            disabled={disabled}
            dialogVariant={dialogVariant}
            isInsideModal={isInsideModal}
        >
            {displayedValue}
            {!disabled && (
                <StyledIconContainer>
                    <Chevron color={dialogVariant ? colors.brown : colors.orange} size={12} />
                </StyledIconContainer>
            )}
        </StyledText>
    )
}
