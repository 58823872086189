import styled from 'styled-components'

export const StyledHeaderCard = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const StyledTitle = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: end;
`

export const StyledStamp = styled.div`
    display: flex;
    & > svg {
        margin-left: 15px;
    }
`
