import { Chevron } from 'assets/icons'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BigText } from 'styles/typography'
import { CacOrderContent, CacOrderContentIngredient, CacOrderSubContent, ClickAndCollectPromotion } from 'types/api'
import { PromoType } from 'types/api.enum'
import { formatPrice, percentageOff } from 'utils/util'

import {
    StyledCollapsedCard,
    StyledContentContainer,
    StyledFreeItem,
    StyledH1,
    StyledH4,
    StyledIconContainer,
    StyledOrderContainer,
    StyledProductLabel,
    StyledProductQuantity,
    StyledRecipePrice,
} from './style'

type CollapsedOrderCardProps = {
    item: CacOrderContent
}

type RecipeLineProps = {
    item: CacOrderContentIngredient
    color?: string
}

export const CollapsedOrderCard = ({ item }: CollapsedOrderCardProps) => {
    const [open, setOpen] = useState(true)
    const { t } = useTranslation()
    const isClickableMenu = !!item.subContent && item.subContent.length > 0
    const isClickableRecipe = !!item.recipe && item.recipe.length > 0
    const isClickableProduct = !!item.freeItems && item.freeItems.length > 0
    const isClickable = isClickableMenu || isClickableProduct || isClickableRecipe

    const RecipeLine = ({ item, color }: RecipeLineProps) => {
        const more = item.quantity > item.initialQuantity
        const diff = item.quantity - item.initialQuantity
        const renderText = more
            ? t('orders.product', {
                  number: diff !== 1 ? `x${diff}` : '',
                  operator: t('orders.extra'),
                  label: item.label,
                  price: `(+${formatPrice(item.price)})`,
              })
            : t('orders.product', {
                  number: '',
                  operator: t('orders.without'),
                  label: item.label,
                  price: '',
              })

        return (
            <StyledRecipePrice>
                <StyledH4 color={color}>{renderText}</StyledH4>
                {more && <StyledH4 color={color}>{formatPrice(item.price * diff)}</StyledH4>}
            </StyledRecipePrice>
        )
    }

    const OrderProduct = ({
        item,
        color,
    }: {
        item?: CacOrderContent | CacOrderSubContent
        color?: string
    }): JSX.Element => {
        return (
            <>
                <StyledH4 color={color}>{`x${item?.quantity} ${item?.label}`}</StyledH4>
                {item?.noIce && (
                    <StyledH4 color={colors.defaultGrey}>{item.noIce ? t('orders.noIce') : t('orders.ice')}</StyledH4>
                )}
            </>
        )
    }

    const renderContent = () => {
        if (isClickableMenu && !!item.subContent) {
            const CollapsedCardContent = item.subContent.map((menuItem, index) => {
                const menuItemDetails: JSX.Element[] = []
                if (menuItem.freeItems) {
                    menuItem.freeItems.forEach((freeItem, index) =>
                        menuItemDetails.push(<OrderProduct key={freeItem.id + index.toString()} item={freeItem} />),
                    )
                }
                if (menuItem.recipe) {
                    menuItem.recipe.forEach((recipeItem, index) =>
                        menuItemDetails.push(<RecipeLine key={index} item={recipeItem} />),
                    )
                }
                return (
                    <StyledContentContainer key={index}>
                        <OrderProduct item={menuItem} color={colors.brown} />
                        <StyledFreeItem>{menuItemDetails}</StyledFreeItem>
                    </StyledContentContainer>
                )
            })
            return CollapsedCardContent
        }
        if (isClickableRecipe && !!item.recipe) {
            return (
                <StyledContentContainer>
                    {item.recipe.map((recipeItem, index) => (
                        <RecipeLine key={index} item={recipeItem} />
                    ))}
                </StyledContentContainer>
            )
        }
        if (isClickableProduct && !!item.freeItems) {
            return (
                <StyledContentContainer>
                    {item.freeItems.map((freeItem, index) => (
                        <OrderProduct key={freeItem.id + index.toString()} item={freeItem} />
                    ))}
                </StyledContentContainer>
            )
        }
        return []
    }

    const renderPromotionType = (promotions: ClickAndCollectPromotion[]) => {
        return promotions.map((promotion) => {
            switch (promotion.promoType) {
                case PromoType.ITEM_VALUE_FIXED:
                    return promotion.promoValue === 0 ? `(${t('orders.free')})` : null
                case PromoType.ITEM_PERCENT_DISCOUNT:
                    return `(-${promotion.promoValue}%)`
                case PromoType.ITEM_PERCENT_FIXED:
                    return `(${promotion.promoValue}%)`
                case PromoType.ITEM_VALUE_DISCOUNT:
                    return `(-${formatPrice(promotion.promoValue ?? 0)})`
                default:
                    return null
            }
        })
    }

    const Price = ({ value }: { value: string }) => (
        <>
            <del>{formatPrice(item.originalPrice)}</del> {value}
        </>
    )

    const renderPrice = () => {
        const { promotions, finalPrice, quantity } = item
        const priceUpdated = finalPrice * quantity
        if (promotions && promotions.length !== 0 && promotions[0].promoType !== PromoType.NONE) {
            return promotions.map((promotion, index) => {
                switch (promotion.promoType) {
                    case PromoType.ITEM_VALUE_FIXED:
                        return <Price key={index} value={formatPrice(promotion.promoValue ?? 0)} />
                    case PromoType.ITEM_PERCENT_DISCOUNT:
                        return (
                            <Price
                                key={index}
                                value={formatPrice(percentageOff(priceUpdated, promotion.promoValue ?? 0))}
                            />
                        )
                    case PromoType.ITEM_PERCENT_FIXED:
                        return (
                            <Price
                                key={index}
                                value={formatPrice(
                                    priceUpdated - percentageOff(priceUpdated, promotion.promoValue ?? 0),
                                )}
                            />
                        )
                    case PromoType.ITEM_VALUE_DISCOUNT:
                        return <Price key={index} value={formatPrice(priceUpdated - (promotion.promoValue ?? 0))} />
                    default:
                        return <Fragment key={index}>{formatPrice(priceUpdated)}</Fragment>
                }
            })
        }
        return formatPrice(priceUpdated)
    }

    return (
        <>
            <StyledCollapsedCard isClickable={isClickable} onClick={() => (isClickable ? setOpen(!open) : undefined)}>
                <StyledProductLabel>
                    <BigText color={colors.brown} font={fonts.flame}>
                        {item.label}
                        {item.promotions && renderPromotionType(item.promotions)}
                    </BigText>
                    <StyledH4>{renderPrice()}</StyledH4>
                </StyledProductLabel>
                <StyledProductQuantity>
                    <StyledH1 font={fonts.flameSans}>{`x${item.quantity}`}</StyledH1>
                    <StyledIconContainer isClickable={isClickable} open={open}>
                        <Chevron color={colors.brown} />
                    </StyledIconContainer>
                </StyledProductQuantity>
            </StyledCollapsedCard>
            {open && <StyledOrderContainer>{renderContent()}</StyledOrderContainer>}
        </>
    )
}
