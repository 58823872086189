import burgerLogo from 'assets/images/burger-king-logo.webp'
import backgroundImage from 'assets/images/nuggets-eating.webp'
import { Button } from 'components/button/Button'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { appInitialization, getPermissions, getUserToken } from 'redux/user/thunks'
import { colors } from 'styles/colors'
import { H1, Text } from 'styles/typography'
import { Errors } from 'types/api.enum'
import { LocalStorageKey } from 'utils/enums'
import * as Yup from 'yup'

import {
    StyledButtonContainer,
    StyledDivLogin,
    StyledErrorContainer,
    StyledErrorText,
    StyledField,
    StyledForm,
    StyledGreyBackground,
    StyledImageContainer,
    StyledInput,
    StyledInputsContainer,
    StyledLogo,
    StyledPageContainer,
    StyledTitleContainer,
    StyledUpdatePasswordLink,
} from './style'

export const Login = () => {
    const { t } = useTranslation()
    const [error, setError] = useState<string>()
    const token = useAppSelector((state) => state.user.token)
    const restaurants = useAppSelector((state) => state.restaurant.restaurants)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        document.getElementById('botmind-webchat-js')?.remove()
        document.getElementById('botmind-iframe')?.remove()
    }, [])

    const schema = Yup.object().shape({
        password: Yup.string().required(t('error.required')),
        email: Yup.string().email(t('error.mail')).required(t('error.required')),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: schema,
        onSubmit: async (values, actions) => {
            try {
                setError(undefined)
                // @ts-ignore
                await dispatch(getUserToken(values))
                // @ts-ignore
                await dispatch(getPermissions())
                // @ts-ignore
                await dispatch(appInitialization())
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const errorMessage = error?.response?.data?.message
                if (errorMessage === Errors.USER_NOT_FOUND) {
                    setError(t('error.credentials'))
                } else {
                    setError(t('error.default'))
                }
            } finally {
                actions.setSubmitting(false)
            }
        },
    })

    const { values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting } = formik

    if (!!token && restaurants?.length > 0) {
        const entryUri = localStorage.getItem(LocalStorageKey.KING_TABLE_ROUTING) || t('page.home.route')
        if (entryUri === 'true') {
            return <Navigate to={t('page.kingTable.route')} replace />
        }
        return <Navigate to={t('page.home.route')} replace />
    }

    return (
        <StyledPageContainer>
            <StyledImageContainer img={backgroundImage} />
            <StyledGreyBackground />
            <StyledDivLogin>
                <StyledLogo src={burgerLogo} alt="logo" width={65} />
                <StyledTitleContainer>
                    <H1 color={colors.brown}>{t('login.title')}</H1>
                    <Text>{t('login.text')}</Text>
                </StyledTitleContainer>

                <StyledForm onSubmit={handleSubmit}>
                    <StyledInputsContainer>
                        <StyledField>
                            <Text color={colors.brown}>{t('login.login')}</Text>
                            <StyledInput
                                type={'email'}
                                name={'email'}
                                placeholder={t('login.login')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                            />
                            <StyledErrorContainer>
                                {errors.email && touched.email && <StyledErrorText>{errors.email}</StyledErrorText>}
                            </StyledErrorContainer>
                        </StyledField>
                        <StyledField>
                            <Text color={colors.brown}>{t('login.password')}</Text>
                            <StyledInput
                                type="password"
                                name="password"
                                placeholder={t('login.password')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                            />

                            <StyledErrorContainer>
                                {errors.password && touched.password && (
                                    <StyledErrorText>{errors.password}</StyledErrorText>
                                )}
                            </StyledErrorContainer>
                        </StyledField>
                    </StyledInputsContainer>
                    <StyledErrorContainer>{!!error && <StyledErrorText>{error}</StyledErrorText>}</StyledErrorContainer>
                    <StyledButtonContainer>
                        <Button
                            type={'submit'}
                            disabled={isSubmitting}
                            text={t('component.button.login')}
                            color={colors.red}
                            style={{ width: '100%' }}
                        />
                    </StyledButtonContainer>
                    <StyledUpdatePasswordLink onClick={() => navigate(t('page.forgottenPassword.route'))}>
                        {t('forgottenPassword.button')}
                    </StyledUpdatePasswordLink>
                </StyledForm>
            </StyledDivLogin>
        </StyledPageContainer>
    )
}
