import { Exclamation } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER } from 'styles/tokens'
import { H4, Text } from 'styles/typography'
import { FraudScoring } from 'types/api.enum'

import { StyledCardInfo } from '../style'
import { InfoStamp } from './InfoStamp'

type FraudCardProps = {
    fraudScoring: FraudScoring
}

type ScoringTextProps = {
    fraudScoring: FraudScoring
    textColor: string
}

const ScoringText = ({ fraudScoring, textColor }: ScoringTextProps) => {
    const { t } = useTranslation()
    return (
        <StyledTextContainer>
            <H4 font={fonts.flameSans} color={colors.brown}>
                {t(`complaints.fraudScoring.${fraudScoring}.title`)}
            </H4>
            <Text color={textColor}>{t(`complaints.fraudScoring.${fraudScoring}.subTitle`)}</Text>
        </StyledTextContainer>
    )
}

export const FraudCard = ({ fraudScoring }: FraudCardProps) => {
    const COLOR =
        fraudScoring === FraudScoring.MEDIUM
            ? colors.orange
            : fraudScoring === FraudScoring.HIGH
              ? colors.red
              : colors.white

    const ICON_COLOR = fraudScoring === FraudScoring.LOW ? colors.green : COLOR

    return (
        <StyledCardInfo borderColor={COLOR}>
            <InfoStamp icon={<Exclamation color={ICON_COLOR} pointColor={ICON_COLOR} />}>
                <ScoringText fraudScoring={fraudScoring} textColor={ICON_COLOR} />
            </InfoStamp>
        </StyledCardInfo>
    )
}

const StyledTextContainer = styled.div`
    margin: -${BORDER.WIDTH.XS} 0 0 0;
`
