import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'

export const StyledCalendarContainer = styled.div`
    position: absolute;
    top: 230px;
    right: 45px;
    display: flex;
    align-items: center;
`

export const StyledCalendarButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5px 10px;
    margin-left: 5px;
    border: 1px solid ${colors.greyLight};
    border-radius: 5px;
    font-family: ${fonts.flameSans};
    font-size: 14px;
    color: ${colors.blackLight};

    &:hover {
        cursor: pointer;
    }
`

export const StyledClearFilter = styled.div`
    margin-left: 10px;
    &:hover {
        cursor: pointer;
    }
`
