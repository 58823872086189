import { ClipLoader } from 'react-spinners'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { Text } from 'styles/typography'

import { StyledButton, StyledIconContainer, StyledTextContainer } from './style'

interface ButtonProps {
    text?: string
    color: string
    textColor?: string
    icon?: JSX.Element
    outlined?: boolean
    disabled?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TextStyle?: any
    loading?: boolean
    fullWidth?: boolean
    [key: string]: unknown
}

export const Button = ({
    text,
    color,
    textColor = colors.white,
    outlined = false,
    icon,
    disabled,
    TextStyle = Text,
    loading = false,
    fullWidth = false,
    ...otherProps
}: ButtonProps) => {
    return (
        <StyledButton color={color} outlined={outlined} disabled={disabled} fullWidth={fullWidth} {...otherProps}>
            {!loading && icon && <StyledIconContainer>{icon}</StyledIconContainer>}
            <StyledTextContainer>
                {loading ? (
                    <ClipLoader size={16} color={colors.white} loading={true} />
                ) : (
                    <>
                        {text && (
                            <TextStyle color={outlined ? color : textColor} font={fonts.flame}>
                                {text}
                            </TextStyle>
                        )}
                    </>
                )}
            </StyledTextContainer>
        </StyledButton>
    )
}
