import { colors } from './colors'

export enum SPACING {
    XXXXL = '40px',
    XXXL = '36px',
    XXL = '32px',
    XL = '28px',
    L = '24px',
    M = '20px',
    S = '16px',
    XS = '12px',
    XXS = '8px',
    XXXS = '4px',
    XXXXS = '2px',
    NONE = '0',
}

export const BORDER = {
    RADIUS: {
        XXXXL: '20px',
        XXXL: '18px',
        XL: '14px',
        L: '12px',
        M: '10px',
        S: '8px',
        XS: '6px',
        NONE: '0',
    },
    WIDTH: {
        M: '4px',
        S: '2px',
        XS: '1px',
        XXXXL: '20px',
        NONE: '0',
    },
    SHADOW_BOX: `box-shadow: 0 2px 4px ${colors.shadow};`,
} as const

export const TEXT = {
    FONT_SIZE: {
        XL: '20px',
        L: '18px',
        M: '16px',
        S: '14px',
        XS: '12px',
    },
} as const
