import { QueryOptions, useMutation, UseMutationOptions, useQuery } from 'react-query'

import { services } from '../../httpServices/services'
import { CrmEmployeeDTO, CrmEmployeeForm, EmployeeWithCouponDTO } from '../../types/api'

export const COLLABORATORS_KEYS = {
    GET_ALL: 'getAllCollaborators',
    POST_COLLABORATOR: 'postCollaborator',
    DELETE_COLLABORATOR: 'deleteCollaborator',
    UPDATE_COLLABORATOR: 'updateCollaborator',
    GET_COLLABORATOR: 'getCollaborator',
    RESEND_MAIL: 'resendMail',
}

export const useGetAllEmployeesData = (frNumber: string, options?: QueryOptions<CrmEmployeeDTO[]>) => {
    return useQuery({
        queryKey: [COLLABORATORS_KEYS.GET_ALL, frNumber],
        queryFn: () => services.collaborators.getAllEmployees(frNumber),
        ...options,
    })
}

export const useGetEmployeeData = (frNumber: string, id: string, options?: QueryOptions<EmployeeWithCouponDTO>) => {
    return useQuery({
        queryKey: [COLLABORATORS_KEYS.GET_COLLABORATOR, id],
        queryFn: () => services.collaborators.getEmployee(frNumber, id),
        ...options,
    })
}

export const usePostEmployee = (
    options?: UseMutationOptions<unknown, unknown, { frNumber: string; employeeData: CrmEmployeeForm }>,
) => {
    return useMutation({
        mutationKey: [COLLABORATORS_KEYS.POST_COLLABORATOR],
        mutationFn: (data) => services.collaborators.postEmployee(data.frNumber, data.employeeData),
        ...options,
    })
}

export const useDeleteEmployee = (options?: UseMutationOptions<unknown, unknown, { frNumber: string; id: string }>) => {
    return useMutation({
        mutationKey: [COLLABORATORS_KEYS.DELETE_COLLABORATOR],
        mutationFn: (data) => services.collaborators.deleteEmployee(data.frNumber, data.id),
        ...options,
    })
}

export const useUpdateEmployee = (
    options?: UseMutationOptions<unknown, unknown, { frNumber: string; id: string; employeeData: CrmEmployeeForm }>,
) => {
    return useMutation({
        mutationKey: [COLLABORATORS_KEYS.UPDATE_COLLABORATOR],
        mutationFn: (data) => services.collaborators.updateEmployee(data.frNumber, data.id, data.employeeData),
        ...options,
    })
}

export const useResendMail = (options?: UseMutationOptions<unknown, unknown, { frNumber: string; id: string }>) => {
    return useMutation({
        mutationKey: [COLLABORATORS_KEYS.RESEND_MAIL],
        mutationFn: (data) => services.collaborators.resendMail(data.frNumber, data.id),
        ...options,
    })
}
