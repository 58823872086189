import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H2, H3, H4 } from 'styles/typography'

export const StyledContainer = styled.div`
    background-color: ${colors.white};
    border-radius: 5px;

    padding: 24px;
    min-height: calc(100% - 50px);
    height: fit-content;
`

export const StyledH2 = styled(H2)`
    margin-bottom: 24px;
    color: ${colors.brown};
`

export const StyledH3 = styled(H3)`
    color: ${colors.brown};
`

export const StyledH4 = styled(H4)`
    margin-bottom: 24px;
    color: ${colors.brown};
`

export const StyledHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const StyledInfosContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const StyledBirthdayInfosContainer = styled.div`
    display: grid;
    width: 45%;
    gap: 5%;
    margin-top: 20px;
`

export const StyledInfosRowContainer = styled.div`
    display: flex;
    gap: 10px;
`

export const StyledLastRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`

export const StyledFamilyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    width: 50%;
    padding: 16px;
    background-color: ${colors.grey};
    border-radius: 5px;
`

export const StyledReservationTitle = styled.div`
    margin-top: 10px;
    margin-left: 20px;
`

export const StyledStatusModifierContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-left: auto;
`

export const StyledPriceText = styled.span`
    color: ${colors.brown};
    font-family: ${fonts.flameSans};
    font-size: 20px;
`

export const StyledPriceTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

export const StyledPencilIconContainer = styled.div`
    align-self: center;
    &:hover {
        cursor: pointer;
    }
`

export const StyledParamContainer = styled.div<{ separator?: boolean }>`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    gap: 30px;
    ${(props) =>
        props.separator &&
        `box-shadow: inset 0px -1px 0px ${colors.blackShadow};
        padding-bottom: 24px;`}
`

export const StyledParentContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: 15px;
    border: 2px solid ${colors.yellowMelty};
    border-radius: 12px;
    width: 95%;
    padding: 12px 16px;
`

export const StyledChildContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    width: 95%;
    padding: 12px 16px;
    gap: 15px;
`

export const StyledFamilyInfoText = styled.span`
    color: ${colors.brown};
    font-family: ${fonts.flameSans};
    font-size: 18px;
`

export const StyledFamilyInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`

export const StyledVIPInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    border-radius: 1px;
    background-color: ${colors.orangeLight};
    padding: 10px;
`

export const StyledCenterPage = styled.div`
    justify-content: center;
    margin-top: 20%;
    margin-left: 50%;
`

export const StyledModalContainer = styled.div`
    justify-content: center;
`
