import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { BirthdayBookingListItemDTO } from 'types/api'
import { BirthdayStatus } from 'types/api.enum'

import { StyledFilterButton, StyledFiltersContainer } from './style'

interface BirthdayStatusFiltersProps {
    reservations: BirthdayBookingListItemDTO[]
    setReservations: Dispatch<SetStateAction<BirthdayBookingListItemDTO[]>>
}

export const BirthdayStatusFilters = ({ reservations, setReservations }: BirthdayStatusFiltersProps) => {
    const { t } = useTranslation()

    const [selectedStatus, setSelectedStatus] = useState<BirthdayStatus | undefined>(BirthdayStatus.ALL)
    const [allReservations] = useState(reservations)

    useEffect(() => {
        if (selectedStatus === BirthdayStatus.ALL) {
            setReservations(allReservations)
        } else {
            const filteredReservations = allReservations.filter((reservation) => reservation.status === selectedStatus)
            setReservations(filteredReservations)
        }
    }, [selectedStatus, allReservations, setReservations])

    const handleFilterClick = (status: BirthdayStatus) => {
        setSelectedStatus(status === selectedStatus ? undefined : status)
    }

    const ButtonText = ({ selected, status }: { selected: boolean; status: BirthdayStatus }) => {
        switch (status) {
            case BirthdayStatus.STANDBY:
                return (
                    <Text color={selected ? colors.white : colors.brown}>
                        {t('services.birthday.status.inProgress')}
                    </Text>
                )
            case BirthdayStatus.REFUSED:
                return (
                    <Text color={selected ? colors.white : colors.brown}>{t('services.birthday.status.denied')}</Text>
                )
            case BirthdayStatus.CONFIRMED:
                return (
                    <Text color={selected ? colors.white : colors.brown}>
                        {t('services.birthday.status.confirmed')}
                    </Text>
                )
            default:
                return <Text color={selected ? colors.white : colors.brown}>Tous</Text>
        }
    }

    const FilterButton = ({ status }: { status: BirthdayStatus }) => (
        <StyledFilterButton selected={selectedStatus === status} onClick={() => handleFilterClick(status)}>
            <Text color={colors.white}>
                <ButtonText selected={selectedStatus === status} status={status} />
            </Text>
        </StyledFilterButton>
    )

    return (
        <StyledFiltersContainer>
            {Object.values(BirthdayStatus).map((status) => {
                return <FilterButton key={status} status={status} />
            })}
        </StyledFiltersContainer>
    )
}
