import { useAppSelector } from 'hooks/useRedux'
import { useScript } from 'hooks/useScript'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { selectRestaurantsbyName } from 'redux/restaurant/selectors'
import { botmindChatScript } from 'scripts/botmind'
import { botmindScriptKeys } from 'utils/constants'

type AuthRequiredProps = { children: React.ReactNode }

export const AuthRequired = ({ children }: AuthRequiredProps) => {
    const { t } = useTranslation()
    const token = useAppSelector((state) => state.user.token)
    const email = useAppSelector((state) => state.user.email)
    const firstName = useAppSelector((state) => state.user.firstName)
    const lastName = useAppSelector((state) => state.user.lastName)
    const botmindChatToken = botmindScriptKeys.TOKEN
    const restaurants: string[] = useAppSelector((state) => selectRestaurantsbyName(state))

    useScript({
        innerHTML: botmindChatScript(botmindChatToken, email, firstName, lastName, restaurants),
        async: true,
    })

    return <>{token ? children : <Navigate to={t('page.login.route')} replace />}</>
}
