import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

export const BoldBackIcon = (props: IconProps) => {
    const { color = colors.brown, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={(23 / 16) * size} viewBox="0 0 16 23" {...otherProps}>
            <path
                d="M15.3281 21.164L13.6561 15.564C12.88 12.932 12.88 10.132 13.6561 7.49999L15.3281 1.89999C15.3894 1.68346 15.3776 1.45278 15.2946 1.24361C15.2116 1.03444 15.0621 0.858435 14.869 0.742792C14.676 0.62715 14.4502 0.578315 14.2266 0.603838C14.0031 0.629361 13.7941 0.727819 13.6321 0.883993L13.5681 0.947993C9.94832 4.57082 5.92155 7.7627 1.56811 10.46L1.00011 10.7C0.858716 10.7803 0.741128 10.8966 0.659324 11.0372C0.57752 11.1777 0.534424 11.3374 0.534424 11.5C0.534424 11.6626 0.57752 11.8223 0.659324 11.9628C0.741128 12.1034 0.858716 12.2197 1.00011 12.3L1.52011 12.62C5.87355 15.3173 9.90032 18.5092 13.5201 22.132L13.5841 22.196C13.7386 22.3873 13.9569 22.5166 14.1989 22.5603C14.4409 22.6039 14.6905 22.559 14.9022 22.4338C15.1138 22.3086 15.2733 22.1113 15.3515 21.8782C15.4297 21.645 15.4214 21.3915 15.3281 21.164Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
