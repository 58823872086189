import { CloseComplaintModalContent } from 'components/close-complaint-modal-content/CloseComplaintModalContent'
import { Modal } from 'components/modal/Modal'
import { useState } from 'react'
import { BeginComplaintProcessModalContent } from 'screens/complaint-detail/components/complaint-answer-process/BeginProcessComplaintModalContent'
import { colors } from 'styles/colors'
import { ComplaintDTO, StoreLocRestaurant } from 'types/api'

type ButtonsRenderProps = {
    onClose: () => void
    complaint: ComplaintDTO
    currentRestaurant: StoreLocRestaurant
}

enum ContestProcessStep {
    BEGIN_COMPLAINT = 'BEGIN_COMPLAINT',
    CONTEST_MODAL = 'CONTEST_MODAL',
}

export const CloseComplaintProcess = ({ onClose, complaint, currentRestaurant }: ButtonsRenderProps) => {
    const [currentStep, setCurrentStep] = useState<ContestProcessStep>(ContestProcessStep.BEGIN_COMPLAINT)

    const handleModalClose = () => {
        onClose()
        setCurrentStep(ContestProcessStep.BEGIN_COMPLAINT)
    }

    const onConfirm = () => {
        setCurrentStep(ContestProcessStep.CONTEST_MODAL)
    }

    const RestaurantComplaintsContestStepModal = ({ step }: { step: ContestProcessStep }) => {
        switch (step) {
            case ContestProcessStep.BEGIN_COMPLAINT:
                return (
                    <BeginComplaintProcessModalContent
                        complaint={complaint}
                        onConfirm={onConfirm}
                        onCancel={handleModalClose}
                        isCloseModal={true}
                    />
                )
            case ContestProcessStep.CONTEST_MODAL:
                return <CloseComplaintModalContent onClose={handleModalClose} currentRestaurant={currentRestaurant} />
            default:
                return <></>
        }
    }

    return (
        <Modal handleClose={handleModalClose} closeIconColor={colors.brown}>
            <RestaurantComplaintsContestStepModal step={currentStep} />
        </Modal>
    )
}
