import styled from 'styled-components'

import { colors } from '../../styles/colors'
import { SPACING } from '../../styles/tokens'

export type CircledIconProps = {
    icon: JSX.Element
    color?: string
    size?: string
}

export const CircledIcon = ({ icon, color = colors.beige, size = SPACING.S }: CircledIconProps) => {
    return (
        <StyledIcon color={color} size={size}>
            {icon}
        </StyledIcon>
    )
}

const StyledIcon = styled.div<{
    color: string
    size: string
}>`
    background-color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: ${SPACING.XXL};
    height: ${SPACING.XXL};
    padding: ${(props) => props.size};
    margin: ${SPACING.XS};
`
