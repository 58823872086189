import { Notif } from 'components/notif/Notif'
import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { changePermission, getPermissions } from 'redux/user/thunks'
import { ParameterPermissionForm, ParameterProfileInfo, ServiceDTO } from 'types/api'
import { Permissions, Role } from 'types/api.enum'
import { NotyfType } from 'utils/enums'
import { hasPermission, isPermissionActive } from 'utils/util'

import { ParametersSwitch } from './ParametersSwitch'
import { StyledH2 } from './style'

interface ParamApplicationProps {
    serviceParam: ServiceDTO
    restaurantFrNumber: string
    profileInfos: ParameterProfileInfo[]
}

export const ParamApplication = ({ serviceParam, restaurantFrNumber, profileInfos }: ParamApplicationProps) => {
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    const [isComplaintActive, setComplaintService] = useState<boolean>(
        isPermissionActive(profileInfos, Role.RESTAURANT, Permissions.MANAGE_COMPLAINT),
    )
    const [isRefundActive, setRefundService] = useState<boolean>(
        isPermissionActive(profileInfos, Role.RESTAURANT, Permissions.MANAGE_REFUND),
    )
    const [isReceiveComplaintActive, setReceiveComplaintService] = useState<boolean>(
        hasPermission(userPermissions, Permissions.RECEIVE_COMPLAINT_MAIL),
    )
    const [isReceiveRefundActive, setReceiveRefundService] = useState<boolean>(
        hasPermission(userPermissions, Permissions.RECEIVE_REFUND_MAIL),
    )
    const [isDisabled, setDisabled] = useState<boolean>(false)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const onChangeService = (permission: Permissions) => {
        let setService: ((value: boolean) => void) | null = null
        let serviceActive = false
        const profileCode: Role = Role.RESTAURANT

        if (permission === Permissions.MANAGE_COMPLAINT) {
            setService = setComplaintService
            serviceActive = isComplaintActive
        } else if (permission === Permissions.MANAGE_REFUND) {
            setService = setRefundService
            serviceActive = isRefundActive
        } else if (permission === Permissions.RECEIVE_COMPLAINT_MAIL) {
            setService = setReceiveComplaintService
            serviceActive = isReceiveComplaintActive
        } else if (permission === Permissions.RECEIVE_REFUND_MAIL) {
            setService = setReceiveRefundService
            serviceActive = isReceiveRefundActive
        }

        const form: ParameterPermissionForm = {
            frNumber: restaurantFrNumber,
            permissionCode: permission,
            active: !serviceActive,
            profileCode,
        }

        setService?.(!serviceActive)
        setDisabled(true)
        dispatch(changePermission(form))
            // @ts-ignore
            .then(() => dispatch(getPermissions()))
            .catch(() => {
                setService?.(serviceActive)
                Notif({ type: NotyfType.ERROR, text: t('error.serviceActivate') })
            })
            .finally(() => {
                setDisabled(false)
            })
    }

    return (
        <>
            <StyledH2>{t(`services.servicesCode.${serviceParam.code}`)}</StyledH2>
            <ShouldDisplay permission={Permissions.MANAGE_RIGHT}>
                <ParametersSwitch
                    titleKey={'page.parameters.manageComplaint.name'}
                    parameters={[
                        {
                            isActive: isComplaintActive,
                            textKey: 'page.parameters.manageComplaint.activate',
                            disabled: isDisabled,
                            onChange: () => onChangeService(Permissions.MANAGE_COMPLAINT),
                        },
                    ]}
                />
                <ParametersSwitch
                    titleKey={'page.parameters.manageRefund.name'}
                    parameters={[
                        {
                            isActive: isRefundActive,
                            textKey: 'page.parameters.manageRefund.activate',
                            disabled: isDisabled,
                            onChange: () => onChangeService(Permissions.MANAGE_REFUND),
                        },
                    ]}
                />
            </ShouldDisplay>

            {/* Relates to BE-9215. Currently, there is a fix in prod to force restaurant receive email restaurant. Optin must be hide.
            <ShouldDisplay permission={Permissions.SHOW_COMPLAINT}>
                <ParametersSwitch
                    titleKey={"page.parameters.mail"}
                    parameters={[
                        {
                            isActive: isReceiveComplaintActive,
                            textKey: "page.parameters.receiveComplaint.activate",
                            disabled: isDisabled,
                            onChange: () => onChangeService(Permissions.RECEIVE_COMPLAINT_MAIL),
                        },
                        {
                            isActive: isReceiveRefundActive,
                            textKey: "page.parameters.receiveRefund.activate",
                            disabled: isDisabled,
                            onChange: () => onChangeService(Permissions.RECEIVE_REFUND_MAIL),
                        },
                    ]}
                />
            </ShouldDisplay> */}
        </>
    )
}
