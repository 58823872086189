import { Exclamation, RedDot } from 'assets/icons'
import { Button } from 'components/button/Button'
import { TextList } from 'components/list-text/TextList'
import { Modal } from 'components/modal/Modal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'
import { H2 } from 'styles/typography'

type ComplaintReminderModalProps = {
    handleClose: () => void
    onClick: () => void
}

export const ComplaintReminderModal = ({ handleClose, onClick }: ComplaintReminderModalProps) => {
    const { t } = useTranslation()

    return (
        <Modal handleClose={handleClose} canCloseOnClickOutside={false}>
            <StyledContainer>
                <StyledIconContainer>
                    <StyledRedDot size={24} />
                    <Exclamation size={112} color={colors.red} bubbleColor={colors.brown} />
                </StyledIconContainer>
                <StyledH2 color={colors.brown}>{t('complaints.modal.title')}</StyledH2>

                <StyledTextList
                    backgroundColor={colors.redLight}
                    borderColor={colors.red}
                    textList={[t('complaints.modal.maxDelay'), t('complaints.modal.automaticRefund')]}
                />

                <Button color={colors.red} text={t('complaints.modal.button')} onClick={onClick} />
            </StyledContainer>
        </Modal>
    )
}

const StyledContainer = styled.div`
    background-color: ${colors.white};
    border-radius: ${BORDER.RADIUS.XXXXL};
    display: flex;
    flex-direction: column;
    padding: ${SPACING.XXL};
    box-sizing: border-box;
    align-items: center;
`

const StyledTextList = styled(TextList)`
    margin-top: ${SPACING.L};
    margin-bottom: ${SPACING.XXL};
`

const StyledH2 = styled(H2)`
    margin-top: ${SPACING.XXXXL};
`

const StyledRedDot = styled(RedDot)`
    margin-left: auto;
`

const StyledIconContainer = styled.div`
    display: flex;
    flex-direction: column;
`
