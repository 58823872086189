import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Cross = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M12.113 11.068l6.444-6.48a1.525 1.525 0 000-2.144 1.496 1.496 0 00-2.128 0L10 8.94 3.571 2.444a1.496 1.496 0 00-2.128 0 1.525 1.525 0 000 2.145l6.444 6.48-6.444 6.479a1.517 1.517 0 000 2.145 1.493 1.493 0 002.128 0L10 13.198l6.429 6.495a1.493 1.493 0 002.128 0 1.516 1.516 0 000-2.145l-6.444-6.48z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
