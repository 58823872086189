import { Button } from 'components/button/Button'
import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    border-radius: 5px;
    margin-top: 43px;
    padding: 24px;
    min-height: calc(100% - 93px);
    height: fit-content;
`

export const StyledImage = styled.img`
    height: 24px;
`

export const StyledGrid = styled.table`
    width: 100%;
    border-collapse: collapse;
`

export const StyledRow = styled.tr<{ error?: boolean }>`
    ${(props) => props.error && `background-color: ${colors.redLight}`};
`

export const StyledCrownContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledCenterPage = styled.div`
    margin: 0 auto;
    margin-top: 200px;
`

export const StyledButton = styled(Button)`
    float: right;
`
