import { Avatar, Box, ClickAndCollectDetailIcon, Clock, CrownVIP, Euro, Phone } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { H4 } from 'styles/typography'
import { ComplaintDTO } from 'types/api'
import { ComplaintStatus, ComplaintType } from 'types/api.enum'
import { SenderType } from 'utils/enums'
import { formatDateTime, formatPrice } from 'utils/util'

import {
    AttachmentsList,
    CaptionLine,
    ExchangeDiscussionComplaint,
    InfoStamp,
    InfoText,
    IssueAndSubIssueEditor,
    RefundInfoCard,
} from './components'
import { FraudCard } from './components/FraudCard'
import {
    StyledCardInfo,
    StyledInfoContainer,
    StyledLeftPane,
    StyledMessageContainer,
    StyledText,
    StyledTypeContainer,
    StyledTypeIconContainer,
} from './style'

const INVISIBLE_KINGDOM_ID = '******'

const ISSUES = {
    myCurrentOrder: 'complaints.issues.myCurrentOrder',
    myPastOrders: 'complaints.issues.myPastOrders',
}

const SUB_ISSUES = {
    myCurrentOrder: {
        paymentIssue: 'complaints.subIssues.paymentIssue',
        productsOutOfStock: 'complaints.subIssues.productsOutOfStock',
        promoCode: 'complaints.subIssues.promoCode',
        loyaltyProgram: 'complaints.subIssues.loyaltyProgram',
        appTechnicalIssue: 'complaints.subIssues.appTechnicalIssue',
    },
    myPastOrders: {
        paymentIssue: 'complaints.subIssues.paymentIssue',
        dysfuncProducts: 'complaints.subIssues.dysfuncProducts',
        missingProducts: 'complaints.subIssues.missingProducts',
        promoCode: 'complaints.subIssues.promoCode',
        loyaltyProgram: 'complaints.subIssues.loyaltyProgram',
        appTechnicalIssue: 'complaints.subIssues.appTechnicalIssue',
        orderNotReceived: 'complaints.subIssues.orderNotReceived',
        wrongProduct: 'complaints.subIssues.wrongProduct',
        orderQuality: 'complaints.subIssues.orderQuality',
    },
}

type ClickAndCollectComplaintDetailProps = {
    complaint: ComplaintDTO
    updateRequest: () => void
}

export const ClickAndCollectComplaintDetail = ({ complaint, updateRequest }: ClickAndCollectComplaintDetailProps) => {
    const { t } = useTranslation()

    const {
        status,
        createdDate,
        issue,
        subIssue,
        order,
        clientFirstName,
        clientLastName,
        clientPhone,
        description,
        messages,
        refundType,
        fraudLevel,
    } = complaint
    const toVerify = status === ComplaintStatus.WAITING_RESTAURANT
    const {
        orderNumber = `${t(`complaints.emptyField`)}`,
        clientId = `${t(`complaints.emptyField`)}`,
        clientVIP = false,
        createdDate: orderCreatedDate,
        content = [],
        finalPrice = 0,
    } = order || {}
    const clientFullname = `${clientFirstName} ${clientLastName || ''}`

    const closedComplaintStatus: string[] = [
        ComplaintStatus.REFUNDED,
        ComplaintStatus.CLOSED,
        ComplaintStatus.TO_REFUND,
        ComplaintStatus.REFUND_VALIDATED,
    ]
    const isRefunded = !!(status && closedComplaintStatus.includes(status))
    const isClosed = isRefunded && !!refundType

    return (
        <>
            <StyledLeftPane>
                <div>
                    <CaptionLine date={createdDate} />
                    <StyledMessageContainer>
                        <StyledText>{description || t(`complaints.emptyField`)}</StyledText>
                        <AttachmentsList attachments={complaint.attachments} complaintId={complaint.id} />
                    </StyledMessageContainer>

                    {messages &&
                        messages.map((messageObj, idx) => (
                            <StyledMessageContainer
                                key={`${idx}-${messageObj.message}`}
                                isRestaurant={messageObj.sender === SenderType.RESTAURANT}
                            >
                                {isRefunded && idx === 0 && isClosed && (
                                    <StyledCardInfo>
                                        <RefundInfoCard complaint={complaint} />
                                    </StyledCardInfo>
                                )}
                                <StyledText>{messageObj.message}</StyledText>
                            </StyledMessageContainer>
                        ))}
                </div>

                <ExchangeDiscussionComplaint complaint={complaint} />
            </StyledLeftPane>

            <StyledInfoContainer>
                <StyledCardInfo>
                    <StyledTypeContainer>
                        <StyledTypeIconContainer>
                            <ClickAndCollectDetailIcon size={22} />
                        </StyledTypeIconContainer>
                        <H4 color={colors.brown}>
                            {t(`complaints.complaintTypes.${ComplaintType.CLICK_AND_COLLECT}`)}
                        </H4>
                    </StyledTypeContainer>
                </StyledCardInfo>
                {isRefunded && (
                    <StyledCardInfo>
                        <RefundInfoCard complaint={complaint} />
                    </StyledCardInfo>
                )}
                <StyledCardInfo>
                    <IssueAndSubIssueEditor
                        complaintId={complaint.id ?? ''}
                        issueMap={ISSUES}
                        subIssueMap={SUB_ISSUES}
                        issue={issue || ''}
                        subIssue={subIssue || ''}
                        editable={toVerify}
                        updateRequest={updateRequest}
                    />
                    <InfoText title={t('complaints.orderNumber')} text={orderNumber} />
                </StyledCardInfo>
                <StyledCardInfo vip={clientVIP}>
                    <InfoStamp icon={<Avatar color={colors.brown} />} text={clientFullname} />
                    <InfoStamp icon={<Phone color={colors.brown} />} text={clientPhone} />
                    {clientId !== INVISIBLE_KINGDOM_ID && (
                        <InfoText title={t('complaints.kingdomID')} text={clientId} />
                    )}
                    {clientVIP && (
                        <InfoStamp icon={<CrownVIP color={colors.orange} />} text={t('orders.vipId')} warning />
                    )}
                </StyledCardInfo>
                {fraudLevel && <FraudCard fraudScoring={fraudLevel} />}
                <StyledCardInfo>
                    <InfoStamp
                        icon={<Clock color={colors.brown} />}
                        text={
                            order && orderCreatedDate
                                ? formatDateTime(new Date(orderCreatedDate))
                                : t(`complaints.emptyField`)
                        }
                    />
                    <InfoStamp
                        icon={<Box color={colors.brown} />}
                        text={t('orders.article', {
                            count: content?.length || 0,
                        })}
                    />
                    <InfoStamp icon={<Euro color={colors.brown} />} text={formatPrice(finalPrice)} />
                </StyledCardInfo>
            </StyledInfoContainer>
        </>
    )
}
