import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const BellIcon = (props: IconProps) => {
    const { color = colors.brown, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" {...otherProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0001 8.78683V6.66683C11.9981 4.71648 10.5898 3.05189 8.66675 2.72683V2.00016C8.66675 1.63197 8.36827 1.3335 8.00008 1.3335C7.63189 1.3335 7.33341 1.63197 7.33341 2.00016V2.72683C5.41035 3.05189 4.00206 4.71648 4.00008 6.66683V8.78683C3.20293 9.06866 2.66912 9.82132 2.66675 10.6668V12.0002C2.66675 12.3684 2.96522 12.6668 3.33341 12.6668H5.42675C5.74235 13.8281 6.79665 14.6342 8.00008 14.6342C9.20351 14.6342 10.2578 13.8281 10.5734 12.6668H12.6667C13.0349 12.6668 13.3334 12.3684 13.3334 12.0002V10.6668C13.331 9.82132 12.7972 9.06866 12.0001 8.78683ZM5.33342 6.66666C5.33342 5.1939 6.52733 4 8.00009 4C9.47285 4 10.6668 5.1939 10.6668 6.66666V8.66666H5.33342V6.66666ZM8.00008 13.3334C7.5266 13.3306 7.09016 13.0768 6.85342 12.6667H9.14675C8.91001 13.0768 8.47357 13.3306 8.00008 13.3334ZM12.0001 11.3336H4.00008V10.6669C4.00008 10.2987 4.29855 10.0002 4.66674 10.0002H11.3334C11.7016 10.0002 12.0001 10.2987 12.0001 10.6669V11.3336Z"
                fill={color}
            />
        </svg>
    )
}
