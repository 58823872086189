import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

export const CouponSeparator = (props: IconProps) => {
    const { color = colors.white, size = 177, ...otherProps } = props
    return (
        <svg width={size} height={8} viewBox="0 0 177 8" {...otherProps}>
            <g clipPath="url(#separator_svg__clip0_6006_29271)" fill={color}>
                <circle cx={178} cy={4} r={4} transform="rotate(-180 178 4)" />
                <circle cx={169} cy={4} r={2} transform="rotate(-180 169 4)" />
                <circle cx={162} cy={4} r={2} transform="rotate(-180 162 4)" />
                <circle cx={155} cy={4} r={2} transform="rotate(-180 155 4)" />
                <circle cx={148} cy={4} r={2} transform="rotate(-180 148 4)" />
                <circle cx={141} cy={4} r={2} transform="rotate(-180 141 4)" />
                <circle cx={134} cy={4} r={2} transform="rotate(-180 134 4)" />
                <circle cx={127} cy={4} r={2} transform="rotate(-180 127 4)" />
                <circle cx={120} cy={4} r={2} transform="rotate(-180 120 4)" />
                <circle cx={113} cy={4} r={2} transform="rotate(-180 113 4)" />
                <circle cx={106} cy={4} r={2} transform="rotate(-180 106 4)" />
                <circle cx={99} cy={4} r={2} transform="rotate(-180 99 4)" />
                <circle cx={92} cy={4} r={2} transform="rotate(-180 92 4)" />
                <circle cx={85} cy={4} r={2} transform="rotate(-180 85 4)" />
                <circle cx={78} cy={4} r={2} transform="rotate(-180 78 4)" />
                <circle cx={71} cy={4} r={2} transform="rotate(-180 71 4)" />
                <circle cx={64} cy={4} r={2} transform="rotate(-180 64 4)" />
                <circle cx={57} cy={4} r={2} transform="rotate(-180 57 4)" />
                <circle cx={50} cy={4} r={2} transform="rotate(-180 50 4)" />
                <circle cx={43} cy={4} r={2} transform="rotate(-180 43 4)" />
                <circle cx={36} cy={4} r={2} transform="rotate(-180 36 4)" />
                <circle cx={29} cy={4} r={2} transform="rotate(-180 29 4)" />
                <circle cx={22} cy={4} r={2} transform="rotate(-180 22 4)" />
                <circle cx={15} cy={4} r={2} transform="rotate(-180 15 4)" />
                <circle cx={8} cy={4} r={2} transform="rotate(-180 8 4)" />
                <circle cx={-1} cy={4} r={4} transform="rotate(-180 -1 4)" />
            </g>
            <defs>
                <clipPath id="separator_svg__clip0_6006_29271">
                    <path fill={color} transform="rotate(-180 88.5 4)" d="M0 0h177v8H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}
