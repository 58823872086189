import React from 'react'
import styled from 'styled-components'

import { colors } from '../../styles/colors'
import { fonts } from '../../styles/fonts'
import { Text } from '../../styles/typography'

type GridTitleProps = { radiusLeft?: boolean; radiusRight?: boolean; children?: React.ReactNode }

export const GridTitle = ({ children, radiusLeft, radiusRight }: GridTitleProps) => {
    return (
        <StyledColumnTitle radiusLeft={radiusLeft} radiusRight={radiusRight}>
            <StyledTitleText>{children}</StyledTitleText>
        </StyledColumnTitle>
    )
}

export const StyledTitleText = styled(Text)`
    font-family: ${fonts.flame};
    color: ${colors.brownDark};
`

export const StyledColumnTitle = styled.td<{ radiusRight?: boolean; radiusLeft?: boolean }>`
    background-color: ${colors.beige};
    padding: 10px;
    ${(props) => props.radiusRight && 'border-radius: 0px 5px 0px 0px;'}
    ${(props) => props.radiusLeft && 'border-radius: 5px 0px 0px 0px;'}
`
