import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { SmallText, Text } from 'styles/typography'

import { CouponType } from '../../screens/complaint-detail/components/complaint-answer-process/ListCouponModal'
import {
    StyledBottomContainer,
    StyledCouponContainer,
    StyledCouponSeparator,
    StyledImage,
    StyledTopContainer,
} from './style'

type CouponProps = {
    coupon: CouponType
    selected: boolean
    onClick: () => void
    nothingSelected: boolean
    backgroundColor?: string
}
export const Coupon = ({
    coupon,
    selected,
    onClick,
    nothingSelected,
    backgroundColor = colors.orange,
}: CouponProps) => {
    const { label, imageUrl, icon, description } = coupon
    return (
        <StyledCouponContainer onClick={onClick} selected={selected} nothingSelected={nothingSelected}>
            <StyledTopContainer backgroundColor={backgroundColor}>
                {imageUrl && <StyledImage src={imageUrl} alt="burger or promo" />}
                {icon}
            </StyledTopContainer>

            <StyledCouponSeparator />

            <StyledBottomContainer>
                <Text font={fonts.flame} color={colors.brown}>
                    {label}
                </Text>
                <SmallText color={colors.brownLight}>{description}</SmallText>
            </StyledBottomContainer>
        </StyledCouponContainer>
    )
}
