import styled from 'styled-components'
import { colors } from 'styles/colors'

export const StyledFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
`

export const StyledFilterButtons = styled.div`
    display: flex;
`

export const StyledFilterButton = styled.div<{ selected: boolean }>`
    background: ${(props) => (props.selected ? colors.brown : colors.beige)};
    border-radius: 8px;
    margin-left: 8px;
    padding: 6px 10px;
    &:first-child {
        margin-left: 0;
    }
    &:hover {
        cursor: pointer;
    }
`

export const StyledFilterDate = styled.div`
    display: flex;
    align-items: center;
    & > input {
        width: 200px;
    }
`
