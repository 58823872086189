import styled, { css } from 'styled-components'
import { SPACING } from 'styles/tokens'

interface SpacerProps {
    s?: SPACING
}
export const Spacer = styled.div<SpacerProps>`
    ${(props) => {
        const { s = SPACING.S } = props
        return css`
            padding-bottom: ${s};
        `
    }}
`
