import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { logout } from 'redux/user/userSlice'
import { ComplaintDTO, ComplaintLightDTO } from 'types/api'
import { slices } from 'utils/enums'
import { sortByDate } from 'utils/util'

export interface ComplaintState {
    complaints: ComplaintLightDTO[]
    complaintsById: Record<string, ComplaintLightDTO>
    currentComplaint?: ComplaintDTO
}

const initialState: ComplaintState = {
    complaints: [],
    complaintsById: {},
}

export const complaintSlice = createSlice({
    name: slices.COMPLAINT,
    initialState,
    reducers: {
        setCurrentComplaint: (state, action: PayloadAction<ComplaintDTO | undefined>) => {
            state.currentComplaint = action.payload
        },
        setComplaints: (state, action: PayloadAction<ComplaintLightDTO[]>) => {
            state.complaints = action.payload
            state.complaintsById = _.keyBy(action.payload, 'id')
        },
        updateComplaint: (state, action: PayloadAction<ComplaintLightDTO>) => {
            const index = state.complaints.findIndex((complaint) => complaint.id === action.payload.id)
            if (index >= 0) {
                state.complaints[index] = action.payload
            } else {
                state.complaints = [action.payload, ...state.complaints].sort((a, b) =>
                    sortByDate(a.createdDate ?? '', b.createdDate ?? ''),
                )
            }
            state.complaintsById[action.payload.id ?? 0] = action.payload

            if (state.currentComplaint?.id === action.payload.id) {
                state.currentComplaint = { ...state.currentComplaint, ...action.payload }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => {
            return initialState
        })
    },
})

const { actions, reducer } = complaintSlice
export const { setComplaints, setCurrentComplaint, updateComplaint } = actions
export { reducer as complaintReducer }
