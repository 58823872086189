import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { brown } = colors

export const FlagIcon = (props: IconProps) => {
    const { color = brown, size = 16, ...otherProps } = props

    return (
        <svg width={size} height={size / 16 + 22} viewBox="0 0 16 22" fill="none" {...otherProps}>
            <g id="Frame 3467007">
                <path
                    id="Vector"
                    d="M0.574501 2.4805C0.574501 1.41223 1.42671 0.536009 2.49498 0.5L14.1979 0.776069C15.1101 0.74006 15.6383 1.78432 15.0621 2.4925L11.8933 5.56527C11.5813 5.94936 11.5813 6.5135 11.8933 6.8976L15.0621 10.3185C15.6383 11.0266 15.1101 12.0829 14.1979 12.0349L2.62702 11.9149V12.8151L1.67878 12.359C0.994606 12.0229 0.5625 11.3387 0.5625 10.5825V2.4805H0.574501Z"
                    fill={color}
                />
                <rect id="Rectangle 1781" x="2.64453" y="8.5" width="3" height="13" rx="1" fill={color} />
            </g>
        </svg>
    )
}
