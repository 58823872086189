import { Car, CrownVIP, KingTable, Motorbike, Play, ShoppingBag } from 'assets/icons'
import burgerKingLogo from 'assets/images/burger-king-logo.webp'
import noDataImage from 'assets/images/lemon-girl.webp'
import { ErrorBlock } from 'components/error-block/ErrorBlock'
import { OrderFilters } from 'components/order-filters/OrderFilters'
import { OrderModalError } from 'components/order-modal-error/OrderModalError'
import { PickUpTypeStamp } from 'components/pick-up-type-stamp/PickUpTypeStamp'
import { Status } from 'components/status/Status'
import { Websocket } from 'components/websocket/Websocket'
import { WsStatus } from 'components/websocket-status/WsStatus'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { wsTopics } from 'httpServices/wsTopics'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { setAmountErrors, updatePendingOrder } from 'redux/order/orderSlice'
import { selectOrdersSortByError, selectOrdersWithMultipleFiltersV2 } from 'redux/order/selectors'
import { fetchFinishedOrderList, fetchPendingOrderList } from 'redux/order/thunks'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { NoPermissions } from 'screens/no-permissions/NoPermissions'
import { OrderDetail } from 'screens/order-detail/OrderDetail'
import { Tabs } from 'screens/tabs/Tabs'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { OrderLightDTO } from 'types/api'
import { Permissions, PickUpType, ServiceCode } from 'types/api.enum'
import { DateRange } from 'types/date-range'
import { Tab } from 'types/tab'
import { FilterType, StatusType } from 'utils/enums'
import {
    formatDate,
    formatPrice,
    formatTime,
    getDateRangeFromString,
    getOrderStatusType,
    hasPermission,
    isOrderNewError,
    stringToDateDDMMYYYY,
} from 'utils/util'

import { GridColumnText, StyledColumn } from '../../components/grid-column-text/GridColumnText'
import { GridTitle } from '../../components/grid-title/GridTitle'
import { useTabsFilterPickUpType } from '../../hooks/useTabsFilterPickUpType'
import {
    StyledButton,
    StyledCenterPage,
    StyledContainer,
    StyledCrownContainer,
    StyledGrid,
    StyledImage,
    StyledRow,
} from './style'

export const Orders = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [currentOrderId, setCurrentOrderId] = useState<string>()
    const [orderError, setOrderError] = useState<{ orderNumber: string; id: string }>()
    const [wsStatus, setWsStatus] = useState<StatusType>(StatusType.ERROR)
    const [searchParams, setSearchParams] = useSearchParams()

    const searchParamStatusFilter = searchParams.get(FilterType.STATUS)?.toUpperCase()
    const isFinished = searchParamStatusFilter === StatusType.FINISHED || searchParamStatusFilter === StatusType.ALL
    const searchParamPickUpFilter = searchParams.get(FilterType.PICKUP)?.toUpperCase().split('-')
    const searchParamDateRangeFilter = getDateRangeFromString(searchParams.get(FilterType.DATE_RANGE))
    const paramDateRangeStringFormat = JSON.stringify(searchParamDateRangeFilter)
    const paramDateRange: DateRange = {
        startDate: stringToDateDDMMYYYY(searchParamDateRangeFilter[0]),
        endDate: stringToDateDDMMYYYY(searchParamDateRangeFilter[1]),
    }

    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const amountErrors = useAppSelector((state) => state.order.amountErrors)
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))

    const ordersInProgress: OrderLightDTO[] = useAppSelector((state) =>
        selectOrdersSortByError(state.order.pendingOrders, StatusType.IN_PROGRESS),
    )
    const ordersInError: OrderLightDTO[] = useAppSelector((state) =>
        selectOrdersSortByError(state.order.pendingOrders, StatusType.ERROR),
    )

    const ordersData = useAppSelector((state) =>
        selectOrdersWithMultipleFiltersV2(state, {
            status: searchParamStatusFilter as StatusType,
            pickUp: searchParamPickUpFilter as PickUpType[],
        }),
    )

    useEffect(() => {
        if (ordersInError.length > amountErrors) {
            setOrderError({
                orderNumber: ordersInError[0].orderNumber,
                id: ordersInError[0].id,
            })
        }
        dispatch(setAmountErrors(ordersInError.length))
    }, [ordersInError.length, amountErrors, dispatch, ordersInError])

    useEffect(() => {
        setIsLoading(true)
        if (hasPermission(userPermissions, Permissions.SHOW_ORDER)) {
            dispatch(
                fetchFinishedOrderList({
                    frNumber: restaurantFrNumber,
                    dateRange: paramDateRange,
                }),
            )
                .then(() => setError(null))
                .catch((error: unknown) => setError(error))
                .finally(() => setIsLoading(false))
            dispatch(
                fetchPendingOrderList({
                    frNumber: restaurantFrNumber,
                }),
            )
                .then(() => setError(null))
                .catch((error: unknown) => setError(error))
                .finally(() => setIsLoading(false))
        }
    }, [restaurantFrNumber, dispatch, userPermissions, paramDateRangeStringFormat]) //eslint-disable-line

    const tabs: Tab[] = [
        {
            icon: <Play size={15} />,
            label: t(`orders.pickUpType.${PickUpType.ALL}`),
            number: useAppSelector((state) =>
                selectOrdersWithMultipleFiltersV2(state, {
                    status: searchParamStatusFilter as StatusType,
                    pickUp: [PickUpType.ALL],
                }),
            ).length,
            pickUp: PickUpType.ALL,
            onClick: () => onFilterChange(FilterType.PICKUP, PickUpType.ALL),
        },
        {
            icon: <ShoppingBag size={15} />,
            label: t(`orders.pickUpType.${ServiceCode.CLICK_AND_COLLECT}`),
            number: useAppSelector((state) =>
                selectOrdersWithMultipleFiltersV2(state, {
                    status: searchParamStatusFilter as StatusType,
                    pickUp: [PickUpType.ON_SITE, PickUpType.DRIVE, PickUpType.PICK_UP, PickUpType.TABLE_SERVICE],
                }),
            ).length,
            pickUp: `${PickUpType.ON_SITE}-${PickUpType.DRIVE}-${PickUpType.PICK_UP}-${PickUpType.TABLE_SERVICE}`,
            onClick: () =>
                onFilterChange(
                    FilterType.PICKUP,
                    `${PickUpType.ON_SITE}-${PickUpType.DRIVE}-${PickUpType.PICK_UP}-${PickUpType.TABLE_SERVICE}`,
                ),
        },
        {
            icon: <Motorbike size={15} />,
            label: t(`orders.pickUpType.${PickUpType.DELIVERY_IN_APP}`),
            number: useAppSelector((state) =>
                selectOrdersWithMultipleFiltersV2(state, {
                    status: searchParamStatusFilter as StatusType,
                    pickUp: [PickUpType.DELIVERY_IN_APP],
                }),
            ).length,
            pickUp: PickUpType.DELIVERY_IN_APP,
            onClick: () => onFilterChange(FilterType.PICKUP, PickUpType.DELIVERY_IN_APP),
        },
        {
            icon: <Car size={15} />,
            label: t(`orders.pickUpType.${PickUpType.PARKING}`),
            number: useAppSelector((state) =>
                selectOrdersWithMultipleFiltersV2(state, {
                    status: searchParamStatusFilter as StatusType,
                    pickUp: [PickUpType.PARKING],
                }),
            ).length,
            pickUp: PickUpType.PARKING,
            onClick: () => onFilterChange(FilterType.PICKUP, PickUpType.PARKING),
        },
        {
            icon: <KingTable size={15} />,
            label: t(`orders.pickUpType.${PickUpType.TABLE_ORDER}`),
            number: useAppSelector((state) =>
                selectOrdersWithMultipleFiltersV2(state, {
                    status: searchParamStatusFilter as StatusType,
                    pickUp: [PickUpType.TABLE_ORDER],
                }),
            ).length,
            pickUp: PickUpType.TABLE_ORDER,
            onClick: () => onFilterChange(FilterType.PICKUP, PickUpType.TABLE_ORDER),
        },
    ]

    const onFilterChange = (label: string, value?: string) => {
        const updatedSearchParams = new URLSearchParams(searchParams.toString())

        if (value) {
            updatedSearchParams.set(label, value)
        } else {
            updatedSearchParams.delete(label)
        }

        setSearchParams(updatedSearchParams)
    }

    const onClickOrderDetail = (orderId: string) => {
        setCurrentOrderId(orderId)
    }

    const buttonsFilter = [[StatusType.ALL], [StatusType.IN_PROGRESS], [StatusType.FINISHED], [StatusType.ERROR]]

    const { tabSelectedIndex } = useTabsFilterPickUpType(tabs)

    if (!hasPermission(userPermissions, Permissions.SHOW_ORDER)) {
        return <NoPermissions />
    }

    if (isLoading) {
        return (
            <>
                <Tabs tabs={tabs} index={tabSelectedIndex} />
                <StyledContainer>
                    <StyledCenterPage>
                        <ClipLoader size={70} color={colors.orange} loading={true} />
                    </StyledCenterPage>
                </StyledContainer>
            </>
        )
    }

    return (
        <>
            <Tabs tabs={tabs} index={tabSelectedIndex} />
            <WsStatus status={wsStatus} />
            <StyledContainer>
                <Websocket
                    topics={[wsTopics.ordersByRestaurant(restaurantFrNumber)]}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onMessage={(message: any) => {
                        const messageType = message as OrderLightDTO
                        if (isOrderNewError(messageType, ordersInProgress)) {
                            setOrderError({
                                orderNumber: messageType.orderNumber,
                                id: messageType.id,
                            })
                        }
                        dispatch(updatePendingOrder(messageType))
                    }}
                    onConnect={() => setWsStatus(StatusType.FINISHED)}
                    onConnectFailure={() => setWsStatus(StatusType.ERROR)}
                    onDisconnect={() => setWsStatus(StatusType.ERROR)}
                />
                <OrderFilters buttons={buttonsFilter} onChangeFilter={onFilterChange} isFinished={isFinished} />
                <StyledGrid>
                    <tbody>
                        <StyledRow>
                            <GridTitle radiusLeft />
                            <GridTitle />
                            <GridTitle>{t('status.title')}</GridTitle>
                            <GridTitle>{t('orders.number')}</GridTitle>
                            <GridTitle>{t('time.date')}</GridTitle>
                            <GridTitle>{t('time.hour')}</GridTitle>
                            <GridTitle>{t('orders.price')}</GridTitle>
                            <GridTitle>{t('orders.pickUpType.title')}</GridTitle>
                            <GridTitle radiusRight />
                        </StyledRow>
                        {ordersData.map((order) => {
                            const { id, orderNumber, createdDate, finalPrice, pickUpType, clientVIP } = order
                            const statusType = getOrderStatusType(order)

                            return (
                                <StyledRow error={statusType === StatusType.ERROR} key={id}>
                                    <StyledColumn>
                                        <StyledImage alt={t('burgerKingFrance')} src={burgerKingLogo} />
                                    </StyledColumn>
                                    <StyledColumn>
                                        {clientVIP && (
                                            <StyledCrownContainer>
                                                <CrownVIP color={colors.orange} />
                                                <Text color={colors.brown}>{t('orders.vip')}</Text>
                                            </StyledCrownContainer>
                                        )}
                                    </StyledColumn>
                                    <StyledColumn>
                                        <Status type={statusType} text={t(`status.orders.${statusType}`)} />
                                    </StyledColumn>
                                    <GridColumnText value={orderNumber} color={colors.blackLight} />
                                    <GridColumnText
                                        value={formatDate(new Date(createdDate))}
                                        color={colors.blackLight}
                                    />
                                    <GridColumnText
                                        value={formatTime(new Date(createdDate))}
                                        color={colors.blackLight}
                                    />
                                    <GridColumnText value={formatPrice(finalPrice)} color={colors.blackLight} />
                                    <StyledColumn>
                                        {!!pickUpType && <PickUpTypeStamp pickUpType={pickUpType} />}
                                    </StyledColumn>
                                    <StyledColumn>
                                        <StyledButton
                                            text={t('component.button.more')}
                                            color={colors.red}
                                            onClick={() => onClickOrderDetail(order.id)}
                                        />
                                    </StyledColumn>
                                </StyledRow>
                            )
                        })}
                    </tbody>
                </StyledGrid>
                {currentOrderId && <OrderDetail orderId={currentOrderId} onClose={() => setCurrentOrderId('')} />}
                {!!orderError && (
                    <OrderModalError
                        order={orderError}
                        handleClose={() => setOrderError(undefined)}
                        onValidate={() => {
                            setOrderError(undefined)
                            setCurrentOrderId(orderError.id)
                        }}
                    />
                )}
                {error ? (
                    <ErrorBlock errorCode={error?.response?.status} />
                ) : (
                    ordersData.length === 0 && (
                        <ErrorBlock image={noDataImage} titleKey="orders.empty" descriptionKey="orders.noData" />
                    )
                )}
            </StyledContainer>
        </>
    )
}
