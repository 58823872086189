import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'
import { H2 } from 'styles/typography'

export const StyledParamContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${SPACING.L} ${SPACING.NONE};
    box-shadow: inset 0px -1px 0px ${colors.shadow};
    box-sizing: border-box;
`

export const StyledToggleContainer = styled.div`
    display: flex;
    width: 110px;
    justify-content: space-between;
    align-items: center;
`

export const StyledH2 = styled(H2)`
    margin-bottom: ${SPACING.L};
    color: ${colors.brown};
`

export const StyledTitleParameters = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const StyledQrCodeContainer = styled(StyledParamContainer)`
    flex-direction: row;
    justify-content: space-between;
`

export const StyledQrCodeTitleParameters = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.XXXS};
`
