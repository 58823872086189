import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Euro = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M17.348 15.649a5.314 5.314 0 01-4.198 2.294c-2.374 0-4.428-1.772-5.314-4.429h4.907a.886.886 0 000-1.771h-5.27c0-.293-.044-.594-.044-.886 0-.292 0-.593.044-.886h5.27a.886.886 0 000-1.771H7.845c.885-2.657 2.931-4.429 5.314-4.429 1.68.06 3.235.91 4.19 2.294a.886.886 0 101.416-1.045A7.015 7.015 0 0013.15 2c-3.33 0-6.2 2.515-7.147 6.2H3.886a.886.886 0 100 1.771H5.7v1.772H3.886a.886.886 0 000 1.771h2.117c.965 3.685 3.817 6.2 7.147 6.2a7.015 7.015 0 005.615-3.02.886.886 0 00-1.417-1.045z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
