import burgerKingOgnionImage from 'assets/images/burger-king-ognion.webp'
import image from 'assets/images/complaint-answer-modal.webp'
import { Button } from 'components/button/Button'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { SPACING } from 'styles/tokens'
import { H2, H4 } from 'styles/typography'
import { ComplaintDTO } from 'types/api'

import { DisplayPhoneNumber } from './DisplayPhoneNumber'

type BeginComplaintProcessModalContentProps = {
    complaint: ComplaintDTO
    onConfirm: () => void
    isCloseModal?: boolean
    onCancel?: () => void
}

export const BeginComplaintProcessModalContent = ({
    complaint,
    onConfirm,
    isCloseModal = false,
    onCancel,
}: BeginComplaintProcessModalContentProps) => {
    const { t } = useTranslation()
    const { clientVIP = null } = complaint.order || {}
    const { clientPhone = null } = complaint

    return (
        <StyledContainer>
            <img
                width={200}
                height={200}
                src={isCloseModal ? burgerKingOgnionImage : image}
                alt="Hand holding tomato"
            />
            <H2 color={colors.brown}>
                {isCloseModal ? t('complaints.beginContestModal.title') : t('complaints.beginProcessModal.title')}
            </H2>
            <StyledH4 font={fonts.flameSans}>
                {isCloseModal ? t('complaints.beginContestModal.text') : t('complaints.beginProcessModal.text')}
            </StyledH4>

            <StyledDisplayPhoneNumber clientVip={!!clientVIP} clientPhone={clientPhone} />

            <StyledButtonsContainer>
                {isCloseModal && (
                    <Button
                        text={t('complaints.beginContestModal.cancel')}
                        onClick={onCancel}
                        outlined={true}
                        color={colors.brown}
                    />
                )}
                <Button text={t('complaints.beginProcessModal.confirm')} color={colors.red} onClick={onConfirm} />
            </StyledButtonsContainer>
        </StyledContainer>
    )
}

const StyledContainer = styled.div`
    width: 45vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: ${SPACING.XXL};
    background-color: ${colors.white};
    border-radius: 20px;
`

const StyledButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: fit-content;
    gap: ${SPACING.XXS};
    margin-top: ${SPACING.XXL};
`

const StyledH4 = styled(H4)`
    margin-top: ${SPACING.S};
`

const StyledDisplayPhoneNumber = styled(DisplayPhoneNumber)`
    margin-top: ${SPACING.XXL};
`
