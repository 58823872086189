import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H2, H4 } from 'styles/typography'

import { StyledButtonContainer, StyledButtonsContainer, StyledContainer } from './style'

interface ContestComplaintModalProps {
    handleClose: () => void
    complaintNumber?: number
    issue?: string
    onValidate: () => void
}

export const ContestComplaintModal = ({
    handleClose,
    complaintNumber,
    issue,
    onValidate,
}: ContestComplaintModalProps) => {
    const { t } = useTranslation()

    const onSubmit = () => {
        onValidate()
        handleClose()
    }

    return (
        <Modal handleClose={handleClose}>
            <StyledContainer>
                <H2 color={colors.brown}>{t('complaints.contestTitle')}</H2>
                <H4 font={fonts.flameSans}>{t('complaints.contestText', { id: complaintNumber, issue: issue })}</H4>
                <StyledButtonsContainer>
                    <StyledButtonContainer>
                        <Button
                            text={t('component.button.cancel')}
                            color={colors.brown}
                            outlined
                            onClick={handleClose}
                        />
                    </StyledButtonContainer>
                    <StyledButtonContainer>
                        <Button text={t('component.button.validate')} color={colors.red} onClick={onSubmit} />
                    </StyledButtonContainer>
                </StyledButtonsContainer>
            </StyledContainer>
        </Modal>
    )
}
