import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const BackCar = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" {...otherProps}>
            <path
                d="M5.516 10.9a.75.75 0 00-.45.45.833.833 0 001.084 1.084.958.958 0 00.275-.175.958.958 0 00.175-.275.7.7 0 00.066-.317.875.875 0 00-.241-.592.833.833 0 00-.909-.175zm10.95-3.333l-1.133-3.358a2.5 2.5 0 00-2.367-1.667H7.034a2.5 2.5 0 00-2.367 1.667L3.534 7.592A2.5 2.5 0 001.667 10v3.334a2.5 2.5 0 001.666 2.35v.983a.833.833 0 001.667 0v-.833h10v.833a.833.833 0 001.666 0v-.983a2.5 2.5 0 001.667-2.35V10a2.5 2.5 0 00-1.866-2.408v-.025zM6.242 4.734a.833.833 0 01.791-.567h5.933a.833.833 0 01.834.567l.875 2.766h-9.35l.917-2.766zm10.424 8.6c0 .46-.373.833-.833.833H4.167a.833.833 0 01-.834-.833V10c0-.46.373-.833.833-.833h11.667c.46 0 .833.373.833.833v3.334zM13.85 10.9a.75.75 0 00-.45.45.833.833 0 001.083 1.084.75.75 0 00.45-.45.7.7 0 00.067-.317.875.875 0 00-.242-.592.833.833 0 00-.908-.175zm-3.017-.066H9.166a.833.833 0 000 1.666h1.667a.833.833 0 000-1.666z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
