import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Car = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M19.614 10.461c-.57-1.092-1.443-1.193-2.193-1.597a12.52 12.52 0 01-2.847-1.973 8 8 0 00-3.92-1.836 9.069 9.069 0 00-4.043.47 8.816 8.816 0 00-3.39 2.183 6.915 6.915 0 00-2.21 4.91c-.027.215-.002.434.074.638.076.204.2.388.362.537.21.12.449.184.693.184.314.05.635.05.949 0a2.834 2.834 0 01.389-1.524c.255-.432.624-.79 1.069-1.039a2.972 2.972 0 011.465-.374c.274-.003.547.038.807.119.18.051.353.125.513.22.406.28.722.663.914 1.108.19.444.25.932.168 1.407l4.812-.12c.03-.473.169-.934.408-1.348a2.87 2.87 0 011.086-1.034c.45-.245.96-.37 1.477-.362.274-.003.546.038.807.12.184.057.36.137.522.238.357.244.645.571.836.952.191.38.279.801.255 1.224.503.073 1.377.018 1.377-.35a7.9 7.9 0 00-.38-2.753zM7.864 8.48c0 .569-1.9.358-2.602.33-.702-.027-.844-.468-.208-1.239a3.988 3.988 0 012.6-1.267c.409.028.304.937.21 2.176zm7.56 3.606a2.018 2.018 0 011.248-.063c.105.036.207.082.303.137.312.264.533.614.633 1.003.1.389.075.798-.073 1.173a1.512 1.512 0 01-.152.358c-.117.28-.312.522-.563.7-.252.178-.55.285-.86.31a1.81 1.81 0 01-.96-.27 1.725 1.725 0 01-.654-.731 1.881 1.881 0 01.123-1.836 1.94 1.94 0 01.954-.781zm-10.019-.083a1.96 1.96 0 011.11.029c.107.035.209.08.304.138.31.257.53.6.63.983.1.383.076.786-.07 1.155-.032.125-.08.245-.142.358a1.55 1.55 0 01-.54.672c-.242.17-.529.271-.827.292a1.945 1.945 0 01-.965-.286 1.859 1.859 0 01-.677-.724 1.784 1.784 0 01.24-2.04c.246-.279.573-.48.937-.577zm7.688-5.029c1.035.872 1.462 1.653.256 1.726-3.97.138-4.34.187-4.36-.457v-.153l.003-.176V7.78c-.009-1.12-.151-1.836.56-1.836a5.248 5.248 0 013.541 1.028z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    )
}
