import { Menu, MenuItem } from '@mui/material'
import { CheckIcon, Cross, DownIcon, Replay } from 'assets/icons'
import { useAppDispatch } from 'hooks/useRedux'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { updateBirthdayById } from 'redux/birthday/thunks'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { BirthdayStatus } from 'types/api.enum'

import { StyledDot, StyledDropdownChoiceContainer, StyledH4, StyledStatusContainer } from './style'

interface DropDownStatusProps {
    handleForceCallModificationModalOpen: () => void
    handleForceCallCancellationModalOpen: () => void
    handleConfirmModalValidationOpen: () => void
    handleConfirmModalModificationOpen: () => void
    confirmValidation: () => void
    menuDisplayStatus: BirthdayStatus | undefined
    restaurantFrNumber: string
    birthdayId: string
    disabled?: boolean
}

export const DropDownStatus = ({
    handleForceCallModificationModalOpen,
    handleForceCallCancellationModalOpen,
    handleConfirmModalValidationOpen,
    confirmValidation,
    menuDisplayStatus,
    restaurantFrNumber,
    birthdayId,
    disabled,
}: DropDownStatusProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleConfirmationChoice = async () => {
        handleClose()
        await updateStatusToConfirmed()
        handleConfirmModalValidationOpen()
        confirmValidation()
    }

    const handleModificationChoice = () => {
        handleForceCallModificationModalOpen()
        handleClose()
    }

    const handleCancellationChoice = () => {
        handleForceCallCancellationModalOpen()
        handleClose()
    }

    const updateStatusToConfirmed = async () => {
        setIsLoading(true)
        try {
            const response = await dispatch(
                // @ts-ignore
                updateBirthdayById(restaurantFrNumber, birthdayId, {
                    status: BirthdayStatus.CONFIRMED,
                }),
            )
            setIsLoading(false)
            return response
        } catch (error) {
            setIsLoading(false)
            throw error
        }
    }

    const StatusRender = (status: BirthdayStatus | undefined) => {
        switch (status) {
            case BirthdayStatus.REFUSED:
                return (
                    <StyledStatusContainer status={BirthdayStatus.REFUSED} onClick={handleClick}>
                        <StyledDot color={colors.red} />
                        <StyledH4> {t('services.birthday.status.denied')} </StyledH4>
                        <DownIcon color={colors.brown} size={10} />
                    </StyledStatusContainer>
                )
            case BirthdayStatus.CONFIRMED:
                return (
                    <StyledStatusContainer status={BirthdayStatus.CONFIRMED} onClick={handleClick}>
                        <StyledDot color={colors.green} />
                        <StyledH4> {t('services.birthday.status.confirmed')} </StyledH4>
                        <DownIcon color={colors.brown} size={10} />
                    </StyledStatusContainer>
                )
            case BirthdayStatus.STANDBY:
                return (
                    <StyledStatusContainer status={BirthdayStatus.STANDBY} onClick={handleClick}>
                        <StyledDot color={colors.orange} />
                        <StyledH4> {t('services.birthday.status.inProgress')} </StyledH4>
                        <DownIcon color={colors.brown} size={10} />
                    </StyledStatusContainer>
                )
            default:
                return null
        }
    }

    const DisabledStatusRender = (status: BirthdayStatus) => {
        switch (status) {
            case BirthdayStatus.REFUSED:
                return (
                    <StyledStatusContainer status={BirthdayStatus.REFUSED}>
                        <StyledDot color={colors.red} />
                        <StyledH4> {t('services.birthday.status.denied')} </StyledH4>
                    </StyledStatusContainer>
                )
            case BirthdayStatus.CONFIRMED:
                return (
                    <StyledStatusContainer status={BirthdayStatus.CONFIRMED}>
                        <StyledDot color={colors.green} />
                        <StyledH4> {t('services.birthday.status.confirmed')} </StyledH4>
                    </StyledStatusContainer>
                )
            case BirthdayStatus.STANDBY:
                return (
                    <StyledStatusContainer status={BirthdayStatus.STANDBY}>
                        <StyledDot color={colors.orange} />
                        <StyledH4> {t('services.birthday.status.inProgress')} </StyledH4>
                    </StyledStatusContainer>
                )
            default:
                return null
        }
    }

    if (disabled && menuDisplayStatus) {
        return DisabledStatusRender(menuDisplayStatus)
    }

    return (
        <div>
            {!isLoading ? StatusRender(menuDisplayStatus) : <ClipLoader />}
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleConfirmationChoice}>
                    <StyledDropdownChoiceContainer>
                        <CheckIcon color={colors.green} />
                        <Text color={colors.black}>{t('services.birthday.dropdown.confirmation')}</Text>
                    </StyledDropdownChoiceContainer>
                </MenuItem>
                <MenuItem onClick={handleModificationChoice}>
                    <StyledDropdownChoiceContainer>
                        <Replay />
                        <Text color={colors.black}>{t('services.birthday.dropdown.modification')}</Text>
                    </StyledDropdownChoiceContainer>
                </MenuItem>
                <MenuItem onClick={handleCancellationChoice}>
                    <StyledDropdownChoiceContainer>
                        <Cross color={colors.red} />
                        <Text color={colors.black}>{t('services.birthday.dropdown.cancellation')}</Text>
                    </StyledDropdownChoiceContainer>
                </MenuItem>
            </Menu>
        </div>
    )
}
