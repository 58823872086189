import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ArrowRightIcon } from '../../../../../../assets/icons'
import { colors } from '../../../../../../styles/colors'
import { fonts } from '../../../../../../styles/fonts'
import { BORDER, SPACING } from '../../../../../../styles/tokens'
import { Text } from '../../../../../../styles/typography'
import { CardProps } from '../../../../../../types/card'

export type ColoredCardsProps = {
    color: string
    element: CardProps
    dotColor: string
}

export const ColoredCard = ({ color, element, dotColor }: ColoredCardsProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <StyledColoredCard color={color} onClick={() => navigate(element.url)}>
            <StyledNumberContainer>
                <Text color={colors.brown} size={SPACING.XL} font={fonts.flame}>
                    {element.number}
                </Text>
                <StyledDot color={dotColor} />
            </StyledNumberContainer>
            <StyledBelowContainer>
                <Text color={colors.brown}>{t(element.textKey, { count: element.number })}</Text>
                <ArrowRightIcon size={25} color={colors.brown} />
            </StyledBelowContainer>
        </StyledColoredCard>
    )
}

const StyledColoredCard = styled.div`
    background: ${(props) => props.color};
    display: flex;
    flex-direction: column;
    padding: ${SPACING.M};
    gap: ${SPACING.XXS};
    width: 50%;
    box-shadow: ${SPACING.NONE} ${SPACING.XXXXS} ${SPACING.XXXS} ${colors.shadow};
    border-radius: ${BORDER.RADIUS.S};
    &:hover {
        cursor: pointer;
    }
`

const StyledDot = styled.span`
    width: ${SPACING.XXS};
    height: ${SPACING.XXS};
    border-radius: ${BORDER.RADIUS.XS};
    background: ${(props) => props.color};
    box-shadow: ${SPACING.NONE} ${SPACING.NONE} ${SPACING.XXXS} ${(props) => props.color};
    margin: ${SPACING.XXXXS} 0 0 ${SPACING.XXS};
`

const StyledNumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXXS};
    padding: ${SPACING.XXXS} 0;
    align-items: center;
`

const StyledBelowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
