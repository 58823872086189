import { TextField } from '@mui/material'
import { Button } from 'components/button/Button'
import { Dropdown } from 'components/dropdown/Dropdown'
import { Input } from 'components/inputs'
import { TextList } from 'components/list-text/TextList'
import { Modal } from 'components/modal/Modal'
import { Notif } from 'components/notif/Notif'
import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { Spacer } from 'components/spacer'
import { useCurrentComplaint } from 'hooks/useCurrentComplaint'
import { useAppDispatch } from 'hooks/useRedux'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateComplaint } from 'redux/complaint/complaintSlice'
import { reportComplaint } from 'redux/complaint/thunks'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { H2, Text } from 'styles/typography'
import { ComplaintReportReason, ComplaintStatus } from 'types/api.enum'
import { DropDownItem } from 'types/dropdown-item'
import { NotyfType } from 'utils/enums'

type ReportComplaintProcessProps = {
    handleClose: () => void
}

export const ReportComplaintProcess = ({ handleClose }: ReportComplaintProcessProps) => {
    const { t } = useTranslation()
    const [reportMessage, setReportMessage] = useState('')
    const [reportReason, setReportReason] = useState<ComplaintReportReason | undefined>()
    const [otherReason, setOtherReason] = useState<string>()
    const [pending, setPending] = useState(false)

    const complaint = useCurrentComplaint()
    const { id: complaintId } = complaint
    const dispatch = useAppDispatch()

    const complaintReportReasonsList = useMemo(
        () =>
            Object.values(ComplaintReportReason).map((reason) => ({
                value: reason,
                label: t(`complaints.reportModal.reportReasons.${reason}`),
            })) as DropDownItem[],
        [t],
    )

    const isFormValid =
        !!reportReason &&
        !!reportMessage &&
        (reportReason !== ComplaintReportReason.OTHER ||
            (reportReason === ComplaintReportReason.OTHER && !!otherReason))
    const isValidateButtonDisabled = !isFormValid || pending

    const handleReasonSelected = useCallback((e: DropDownItem) => {
        setReportReason(e.value)
    }, [])

    const handleMessage = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setReportMessage(e.target.value)
    }, [])

    const handleOtherReasonChanged = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOtherReason(e.target.value)
    }, [])

    const handleSubmit = useCallback(async () => {
        if (!isFormValid) {
            return
        }
        setPending(true)
        try {
            await dispatch(
                reportComplaint({
                    id: complaintId ?? '',
                    reportReason,
                    otherReason,
                    restaurantMessage: reportMessage,
                }),
            ).unwrap()

            Notif({
                type: NotyfType.SUCCESS,
                text: t('complaints.reportModal.successNotif'),
            })

            const newComplaint = {
                complaintNumber: complaint.complaintNumber || 0,
                createdDate: complaint.createdDate || '',
                endDate: complaint.endDate,
                id: complaint.id,
                inCharge: complaint.inCharge,
                price: complaint.price || 0,
                messages: complaint.messages,
                status: ComplaintStatus.WAITING_SUPPORT,
                isReported: true,
                refundType: undefined,
            }
            dispatch(updateComplaint(newComplaint))
        } catch (_error) {
            Notif({ type: NotyfType.ERROR, text: t('error.reportComplaint') })
        } finally {
            handleClose()
            setPending(false)
        }
    }, [
        complaint.complaintNumber,
        complaint.createdDate,
        complaint.endDate,
        complaint.id,
        complaint.inCharge,
        complaint.messages,
        complaint.price,
        complaintId,
        dispatch,
        handleClose,
        isFormValid,
        otherReason,
        reportMessage,
        reportReason,
        t,
    ])

    return (
        <Modal handleClose={handleClose} closeIconColor={colors.brown}>
            <StyledModalContainer>
                <StyledModalBody>
                    <H2 color={colors.brown}>{t('complaints.reportModal.title')}</H2>
                    <Spacer />
                    <Text>{t('complaints.reportModal.desc')}</Text>
                    <TextList
                        textList={[t('complaints.reportModal.highlighText')]}
                        borderColor={colors.yellowMelty}
                        backgroundColor={colors.lightMayo}
                    />
                    <Dropdown
                        list={complaintReportReasonsList}
                        placeholder={t('complaints.reportModal.reasonPlaceholder')}
                        onChange={handleReasonSelected}
                        dialogVariant
                        isInsideModal
                    />
                    <Spacer />

                    <ShouldDisplay condition={reportReason === ComplaintReportReason.OTHER}>
                        <Input
                            placeholder={t('complaints.reportModal.otherReasonPlaceholder')}
                            value={otherReason}
                            onChange={handleOtherReasonChanged}
                            type="search"
                        />
                        <Spacer />
                    </ShouldDisplay>

                    <TextField
                        key={`reportMessage`}
                        minRows={7}
                        multiline
                        fullWidth
                        InputProps={{
                            style: {
                                fontFamily: fonts.flameSans,
                                margin: `${SPACING.XXXS} 0`,
                            },
                        }}
                        placeholder={t('complaints.reportModal.message')}
                        value={reportMessage}
                        onChange={handleMessage}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: '8px',
                                },
                            },
                        }}
                    />

                    <Spacer />
                </StyledModalBody>
                <StyledModalFooter>
                    <Button text={t('component.button.cancel')} color={colors.brown} outlined onClick={handleClose} />
                    <Button
                        text={t('component.button.send')}
                        color={colors.red}
                        onClick={handleSubmit}
                        disabled={isValidateButtonDisabled}
                    />
                </StyledModalFooter>
            </StyledModalContainer>
        </Modal>
    )
}

const StyledModalContainer = styled.div`
    width: 45vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: ${SPACING.XXL};
    background-color: ${colors.white};
    border-radius: ${BORDER.WIDTH.XXXXL};
`

const StyledModalBody = styled.div`
    width: 100%;
`

const StyledModalFooter = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XS};
`
