import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'
import { H4 } from 'styles/typography'

export const StyledMainContainer = styled.div`
    position: relative;
`

export const StyledText = styled(H4)<{
    focus?: boolean
    isTitle?: boolean
    disabled?: boolean
    dialogVariant?: boolean
    isInsideModal?: boolean
}>`
    ${(props) =>
        props.focus &&
        `color: ${colors.orangeDark};
        div {
            background: ${colors.shadow};
            path {
                fill: ${colors.orangeDark};
            }
        }`}
    ${(props) =>
        props.isTitle &&
        !props.disabled &&
        `cursor: pointer;
        &:hover {
            color: ${colors.orangeDark};
            div {
                background: ${colors.shadow};
                path {
                    fill: ${colors.orangeDark};
                }
            }
        }`}
    ${(props) =>
        props.dialogVariant &&
        props.isTitle &&
        !props.disabled &&
        `&:hover {
            color: ${colors.brown};
            div path {
                fill: ${colors.brown};
            }
        }`}
    ${(props) =>
        props.dialogVariant &&
        `
        justify-content: space-between;
        color: ${colors.defaultGrey};
        border-radius: ${BORDER.RADIUS.S};
        border: ${BORDER.WIDTH.XS} solid ${colors.greyExtraLight};
        display: flex;
        padding: ${SPACING.XXXS} ${SPACING.XS};
        `}
         ${(props) =>
        props.isInsideModal &&
        `
        justify-content: space-between;
        border: 0;
        width: 100%;
        padding : 0;
        `}
`

export const StyledIconContainer = styled.div`
    padding: 1px ${SPACING.XXXS};
    border-radius: ${BORDER.RADIUS.XS};
    margin: 0 0 ${SPACING.NONE} ${SPACING.XXXS};
    display: inline;
`

export const StyledDropDownContainer = styled.div<{ disabled: boolean; isInsideModal?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    ${(props) =>
        props.isInsideModal
            ? `display: flex;
            flex-direction: row;
            align-items: center;
            padding: ${SPACING.XXS} ${SPACING.XS};
            border: ${BORDER.WIDTH.XS} solid ${colors.greyExtraLight};
            border-radius: ${BORDER.RADIUS.XS};
            background: ${props.disabled ? colors.grey : colors.white};`
            : `padding: 0;
            border-style: none;
            text-align: left;
            background: none;`}
    cursor:${(props) => (props.disabled ? `auto` : `pointer`)};
`

export const StyledDropdownList = styled.span`
    display: block;
    padding: ${SPACING.XS} ${SPACING.L};
    &:hover {
        background: ${colors.shadow};
        cursor: pointer;
    }
`

export const StyledDropdownModal = styled.div<{ isInsideModal: boolean }>`
    z-index: 2;
    background: ${colors.white};
    color: ${colors.black};
    box-shadow: 0px 2px 24px ${colors.shadowDark};
    border-radius: ${BORDER.RADIUS.XS};
    width: ${(props) => (props.isInsideModal ? '100%' : '500px')};

    max-height: 240px;
    overflow-y: scroll;
    margin-top: ${SPACING.XS};
    padding: ${SPACING.XS} ${SPACING.NONE};
    position: absolute;
`

export const StyledInputContainer = styled.div`
    display: flex;
    align-items: center;
    padding: ${SPACING.XXS} ${SPACING.XS};
    border: ${BORDER.WIDTH.XS} solid ${colors.greyLight};
    border-radius: ${BORDER.RADIUS.XS};
    width: 90%;
    margin: 0 auto;
`

export const StyledInput = styled.input`
    border: none;
    box-sizing: border-box;
    border-radius: ${BORDER.RADIUS.XS};
    padding: ${SPACING.XXS} ${SPACING.XS};
    width: 100%;
    &:focus {
        outline: none;
    }
    font-size: 14px;
`
