import { ArrowLeft, Crown2, PlusCircle, RestaurantManIcon } from 'assets/icons'
import { CouponIcon } from 'assets/icons/CouponIcon'
import { Button } from 'components/button/Button'
import { TextList } from 'components/list-text/TextList'
import { Notif } from 'components/notif/Notif'
import { useCurrentComplaint } from 'hooks/useCurrentComplaint'
import { useAppDispatch } from 'hooks/useRedux'
import { ChangeEvent, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { updateComplaint } from 'redux/complaint/complaintSlice'
import { refundCustomer } from 'redux/complaint/thunks'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { SPACING } from 'styles/tokens'
import { H2, Text } from 'styles/typography'
import { ComplaintMessageDTO, StoreLocRestaurant } from 'types/api'
import { ComplaintStatus, RefundType } from 'types/api.enum'
import { NotyfType, SenderType } from 'utils/enums'
import { cleanRestaurantName, formatDate, formatPascalCase, longFormatDateTime } from 'utils/util'

import { CombinedTextField } from './CombinedTextField'
import { DatePickerMeetingRestaurant } from './DatePickerMeetingRestaurant'
import { CouponType } from './ListCouponModal'

type ComplaintMessageModalContentProps = {
    onCompensation: () => void
    selectedPromotion?: CouponType
    onClose: () => void
    currentRestaurant?: StoreLocRestaurant
}

type RefundCardProps = {
    icon: ReactNode
    text: string | undefined
}

const RefundCard = ({ icon, text }: RefundCardProps) => {
    return (
        <StyledCompensationCard>
            <StyledTextContainer>
                <StyledIcon>{icon}</StyledIcon>

                {text && (
                    <Text color={colors.brown} font={fonts.flame}>
                        {text}
                    </Text>
                )}
            </StyledTextContainer>
        </StyledCompensationCard>
    )
}

export const ComplaintMessageModalContent = ({
    onCompensation,
    selectedPromotion,
    onClose,
    currentRestaurant,
}: ComplaintMessageModalContentProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { complaintId = '' } = useParams()
    const [loading, setLoading] = useState(false)

    const currentComplaint = useCurrentComplaint()
    const { id, messages, createdDate, endDate, price, inCharge, complaintNumber, clientFirstName, clientLastName } =
        currentComplaint
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    const clientFullname = `${clientFirstName} ${clientLastName || ''}`

    const date = createdDate ? longFormatDateTime(new Date(createdDate)) : t('complaints.templateMail.reformulate')

    const partOneMessage = t(`complaints.templateMail.template.firstPart`, {
        date,
        clientFullname,
        restaurantName: cleanRestaurantName(currentRestaurant?.name),
    })

    const lastPartMessage =
        (selectedPromotion &&
            t(`complaints.templateMail.template.${selectedPromotion.refundType}`, {
                amount: selectedPromotion.label,
                restaurantName: formatPascalCase(currentRestaurant?.name),
                meetingDate: selectedDate ? formatDate(selectedDate) : t('complaints.messageModal.initialValue'),
                interpolation: { escapeValue: false },
            })) ||
        ''

    const initialEditableMessage = t(`complaints.templateMail.template.editable`)
    const [editableMessage, setEditableMessage] = useState(initialEditableMessage || '')

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditableMessage(event.target.value)
    }

    const handleCompensation = () => {
        onCompensation()
    }

    const handleConfirmation = async () => {
        if (!selectedPromotion || editableMessage === '') {
            return
        }
        if (selectedPromotion.refundType === RefundType.RESTAURANT_MEETING && !selectedDate) {
            return
        }
        try {
            setLoading(true)

            const isInitialValue = editableMessage.trim() === initialEditableMessage.trim()
            const fullMessage = `${partOneMessage} \n${isInitialValue ? '' : editableMessage} \n\n${lastPartMessage}`

            const refund = {
                refundType: selectedPromotion.refundType,
                ...(selectedPromotion.refundType === RefundType.COUPON && {
                    promotionId: selectedPromotion.promotionId,
                }),
                ...(selectedPromotion.refundType === RefundType.CROWN && {
                    refundAmountCrown: Number(selectedPromotion.promotionId),
                }),
                ...(selectedPromotion.refundType === RefundType.RESTAURANT_MEETING && {
                    restaurantMeetingDate: selectedDate?.toISOString().substring(0, 10),
                }),
                restaurantMessage: isInitialValue ? '' : editableMessage,
                message: fullMessage,
            }
            await dispatch(refundCustomer(complaintId, refund))

            Notif({
                type: NotyfType.SUCCESS,
                text: t('complaints.messageModal.successNotif'),
            })

            const newMessage: ComplaintMessageDTO = {
                date: new Date().toLocaleDateString(),
                message: fullMessage,
                sender: SenderType.RESTAURANT,
            }
            const newMessages = messages && messages.length > 0 ? [...messages, newMessage] : [newMessage]

            const newRefund = {
                refundType: selectedPromotion.refundType,
                ...(selectedPromotion.refundType === RefundType.COUPON && {
                    couponPromotionLabel: selectedPromotion.label,
                }),
                ...(selectedPromotion.refundType === RefundType.CROWN && {
                    refundAmountCrown: Number(selectedPromotion.promotionId),
                }),
            }
            const newStatus =
                selectedPromotion.refundType === RefundType.CROWN ? ComplaintStatus.TO_REFUND : ComplaintStatus.CLOSED
            const newComplaint = {
                complaintNumber: complaintNumber || 0,
                createdDate: createdDate || '',
                endDate: endDate,
                id: id,
                inCharge: inCharge,
                price: price || 0,
                status: newStatus,
                messages: newMessages,
                ...newRefund,
            }
            dispatch(updateComplaint(newComplaint))
        } catch (_error) {
            Notif({ type: NotyfType.ERROR, text: t('error.refundCustomer') })
        } finally {
            setLoading(false)
            onClose()
        }
    }

    const SelectedPromotion = ({ promotionType }: { promotionType: string }) => {
        switch (promotionType) {
            case RefundType.RESTAURANT_MEETING:
                return (
                    <RefundCard
                        icon={<RestaurantManIcon color={colors.red} size={32} />}
                        text={t('complaints.refundChoiceModal.restaurant')}
                    />
                )
            case RefundType.CROWN:
                return (
                    <RefundCard
                        icon={<Crown2 color={colors.yellowMelty} size={32} />}
                        text={selectedPromotion?.label}
                    />
                )
            case RefundType.COUPON:
                return (
                    <RefundCard
                        icon={<CouponIcon color={colors.yellowMelty} size={32} />}
                        text={t('complaints.refund.coupon', {
                            label: selectedPromotion?.label,
                            interpolation: { escapeValue: false },
                        })}
                    />
                )
            default:
                return <></>
        }
    }

    return (
        <StyledContent>
            {selectedPromotion?.refundType === RefundType.RESTAURANT_MEETING ? (
                <>
                    <StyledFirstRow>
                        <StyledButton
                            onClick={handleCompensation}
                            icon={<ArrowLeft color={colors.brown} />}
                            color={colors.transparent}
                            borderColor={colors.transparent}
                        />

                        <StyledH2 color={colors.brown}>{t('complaints.messageModal.restaurantMeeting.title')}</StyledH2>
                    </StyledFirstRow>

                    <TextList
                        textList={[
                            t('complaints.messageModal.restaurantMeeting.adviceDate'),
                            t('complaints.messageModal.restaurantMeeting.adviceManager'),
                            t('complaints.messageModal.restaurantMeeting.adviceAddress'),
                        ]}
                    />
                </>
            ) : (
                <StyledFirstRow>
                    <StyledButton
                        onClick={handleCompensation}
                        icon={<ArrowLeft color={colors.brown} />}
                        color={colors.transparent}
                        borderColor={colors.transparent}
                    />

                    <StyledH2 color={colors.brown}>{t('complaints.messageModal.title')}</StyledH2>
                </StyledFirstRow>
            )}
            <CombinedTextField
                parts={[
                    {
                        message: partOneMessage,
                        editable: false,
                    },
                    {
                        message: editableMessage,
                        editable: true,
                        handleChange: handleInputChange,
                    },
                    {
                        message: lastPartMessage,
                        editable: false,
                    },
                ]}
            />
            {selectedPromotion?.refundType === RefundType.RESTAURANT_MEETING && (
                <DatePickerMeetingRestaurant date={selectedDate} onClick={(date) => setSelectedDate(date)} />
            )}
            {selectedPromotion ? (
                <SelectedPromotion promotionType={selectedPromotion.refundType} />
            ) : (
                <RefundCard
                    icon={<PlusCircle color={colors.red} size={32} />}
                    text={t('complaints.messageModal.compensation')}
                />
            )}
            <StyledButtonsContainer>
                <StyledButtonContainer>
                    <Button text={t('component.button.cancel')} color={colors.brown} outlined onClick={onClose} />
                </StyledButtonContainer>
                <StyledButtonContainer>
                    <Button
                        text={t('component.button.send')}
                        color={colors.red}
                        onClick={handleConfirmation}
                        disabled={
                            !editableMessage ||
                            loading ||
                            (selectedPromotion?.refundType === RefundType.RESTAURANT_MEETING && !selectedDate)
                        }
                        loading={loading}
                    />
                </StyledButtonContainer>
            </StyledButtonsContainer>
        </StyledContent>
    )
}

const StyledContent = styled.div`
    box-sizing: border-box;
    padding: ${SPACING.XXL};
    background-color: ${colors.white};
    border-radius: 20px;
    width: 55vw;
    max-height: 90vh;
    overflow-y: scroll;
`

const StyledButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: fit-content;
`

const StyledButtonContainer = styled.div`
    margin: ${SPACING.XL} ${SPACING.XXXS} ${SPACING.XS};
`

const StyledH2 = styled(H2)`
    width: 100%;
    text-align: center;
`

const StyledCompensationCard = styled.div`
    width: 100%;
    margin-top: ${SPACING.L};
    display: flex;
    justify-content: space-between;
    gap: ${SPACING.XS};
    box-sizing: border-box;
    padding: ${SPACING.S};
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${colors.greyLight};
`

const StyledIcon = styled.div`
    flex-shrink: 0;
`

const StyledTextContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: ${SPACING.XS};
`

const StyledFirstRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXXS};
    align-items: center;
    margin-bottom: ${SPACING.XXL};
`

const StyledButton = styled(Button)`
    border: none;
    padding: 0;
`
