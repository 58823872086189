import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

export const RedDot = (props: IconProps) => {
    const { color = colors.red, size = 12, ...otherProps } = props

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 12 12"
            {...otherProps}
        >
            <rect width="11" height="11" x="0.5" y="0.5" fill={color} rx="5.5"></rect>
            <rect width="11" height="11" x="0.5" y="0.5" stroke="#fff" rx="5.5"></rect>
        </svg>
    )
}
