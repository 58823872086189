import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

interface DiagramProps extends IconProps {
    secondColor?: string
    thirdColor?: string
}

export const DiagramIcon = (props: DiagramProps) => {
    const {
        color = colors.brown,
        secondColor = colors.orange,
        thirdColor = colors.red,
        size = 21,
        ...otherProps
    } = props

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" {...otherProps}>
            <g clipPath="url(#clip0_8546_5955)">
                <path
                    d="M15.408 15.6384C15.1632 15.8688 14.8944 16.0704 14.616 16.2576C14.1408 16.5792 13.5888 16.8 13.0224 16.92C12.7488 16.9728 12.4704 16.9968 12.1872 16.9968C11.9328 16.9968 11.6832 16.9776 11.4384 16.9488C10.9248 16.8816 10.4352 16.7952 9.95999 16.5792C9.53279 16.3872 9.16319 16.104 8.82239 15.8016C8.25119 15.2928 7.74239 14.7072 7.43999 14.0016C7.31039 13.6992 7.21439 13.3872 7.14719 13.0656C7.04639 12.5616 6.95039 12.048 7.00319 11.5248C7.06079 11.0016 7.30559 10.5264 7.50239 10.0608C7.67519 9.66716 7.85759 9.25436 8.12159 8.89916C7.21919 8.14076 6.27839 7.43036 5.35679 6.69116C5.02079 7.06076 4.72319 7.46396 4.44959 7.88636C3.97439 8.64956 3.75839 9.59516 3.55679 10.4448C3.47999 10.7616 3.42719 11.0688 3.38879 11.376C3.32639 11.8944 3.31679 12.4032 3.34559 12.96C3.40799 13.8576 3.79679 14.808 4.14719 15.6C4.49759 16.4064 4.96799 17.1504 5.56319 17.7984C6.15359 18.4512 6.93119 18.9168 7.67039 19.3536C8.40959 19.7808 9.16799 20.2944 10.0368 20.496C10.9056 20.688 11.7936 20.6304 12.6672 20.5632C13.2192 20.52 13.7712 20.4144 14.304 20.2608C15.5568 19.896 16.656 19.1616 17.6256 18.2928C17.7216 18.2112 17.8128 18.1248 17.904 18.0384C17.0976 17.208 16.2576 16.4064 15.4032 15.6192L15.408 15.6384Z"
                    fill={secondColor}
                />
                <path
                    d="M18.0528 5.96639C17.3376 5.28479 16.5936 4.53119 15.6432 4.16159C14.8368 3.84479 13.8864 3.61439 13.0512 3.40319C13.032 3.39839 12.9984 3.39359 12.9408 3.38879C12.1104 3.32639 11.3472 3.36959 10.5264 3.46079C9.69596 3.55679 8.74076 4.00799 7.99196 4.32959C7.22396 4.66079 6.81116 5.27519 6.17276 5.75999C5.96636 5.93279 5.76956 6.11999 5.58716 6.31199C6.52796 7.06559 7.48316 7.79519 8.40956 8.56319C8.71196 8.24159 9.06236 7.96799 9.44156 7.74239C9.88316 7.47839 10.4064 7.18559 10.9248 7.08959C11.2464 7.03199 11.5488 7.00319 11.8512 7.00319C12.0336 7.00319 12.216 7.01279 12.4032 7.03679C12.912 7.09439 13.4832 7.15199 13.9584 7.37759C14.3952 7.58879 14.8032 7.84319 15.1488 8.17439C15.4752 8.51519 15.864 8.71199 16.1088 9.13439C16.1664 9.23519 16.2336 9.34559 16.3008 9.46079C16.5312 9.35039 16.7664 9.24479 16.9968 9.13439C17.7168 8.78879 18.4272 8.42399 19.1328 8.04959C19.2432 7.99199 19.3584 7.92959 19.4688 7.87199C19.08 7.17599 18.6336 6.52319 18.0432 5.96639H18.0528Z"
                    fill={color}
                />
                <path
                    d="M19.8192 8.53441C19.7664 8.42881 19.7088 8.32801 19.656 8.22241C19.4544 8.32801 19.248 8.43361 19.0464 8.53921C18.2064 8.98081 17.3568 9.39361 16.4976 9.79201C16.6272 10.0416 16.7472 10.3008 16.8048 10.5456C16.8336 10.68 16.8624 10.8096 16.8912 10.9392C17.3808 10.8624 17.8704 10.7856 18.3648 10.704C18.984 10.6032 19.6128 10.5216 20.2272 10.3872C20.304 10.3728 20.376 10.3536 20.4528 10.3392C20.3232 9.71041 20.1168 9.09601 19.8288 8.52961L19.8192 8.53441Z"
                    fill={secondColor}
                />
                <path
                    d="M20.6543 11.4384C20.6303 11.1744 20.5919 10.9104 20.5439 10.6464C20.4047 10.6752 20.2655 10.704 20.1263 10.7376C19.8047 10.8096 19.4687 10.8528 19.1423 10.9056C18.8015 10.9632 18.4607 11.016 18.1247 11.0688C17.7311 11.1312 17.3375 11.1936 16.9391 11.256C16.9727 11.4864 17.0015 11.7168 17.0111 11.9568C17.0111 11.9904 17.0111 12.0096 17.0111 12.0192C16.9487 12.5232 16.8815 13.0896 16.7519 13.584C16.6031 14.1648 16.2143 14.6496 15.8687 15.1152C15.7967 15.216 15.7151 15.3072 15.6335 15.3984C15.8063 15.5568 15.9743 15.7104 16.1471 15.8736C16.8767 16.5552 17.5871 17.2464 18.2831 17.9616C18.7247 17.5392 19.1327 17.0736 19.4543 16.5456C19.9151 15.7824 20.1647 14.976 20.3807 14.1216C20.4767 13.7184 20.5583 13.296 20.6111 12.864C20.6687 12.3888 20.6879 11.904 20.6543 11.4336V11.4384Z"
                    fill={thirdColor}
                />
            </g>
            <defs>
                <clipPath id="clip0_8546_5955">
                    <rect width={size} height={size} />
                </clipPath>
            </defs>
        </svg>
    )
}
