import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'
import { Text } from 'styles/typography'

type TextListProps = {
    backgroundColor?: string
    borderColor?: string
    textList: string[]
    className?: string
}

export const TextList = ({
    textList,
    backgroundColor = colors.orangeLight,
    borderColor = colors.orange,
    className,
}: TextListProps) => {
    return (
        <StyledUl backgroundColor={backgroundColor} borderColor={borderColor} className={className}>
            {textList.map((text, idx) => (
                <StyledLi key={`${idx}-${text}`}>
                    <Trans i18nKey={text}>
                        <Text color={colors.brown}>{text}</Text>
                    </Trans>
                </StyledLi>
            ))}
        </StyledUl>
    )
}

const StyledUl = styled.ul<{ backgroundColor: string; borderColor: string }>`
    padding: ${SPACING.S} ${SPACING.L};
    border-left: 3px solid ${(props) => props.borderColor};
    background-color: ${(props) => props.backgroundColor};
    border-top-right-radius: ${SPACING.XS};
    border-bottom-right-radius: ${SPACING.XS};
`

const StyledLi = styled.li`
    margin-left: ${SPACING.S};
`
