import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const NewsPaperIcon = (props: IconProps) => {
    const { color = colors.brown, size = 21, ...otherProps } = props

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" {...otherProps}>
            <g clipPath="url(#clip0_8377_69574)">
                <path
                    d="M6.98402 18.2016C6.45602 18.2352 5.93762 18.2544 5.41922 18.3024C5.05442 18.336 4.68002 18.288 4.32482 18.4608C4.08482 18.576 3.62882 18.2448 3.58562 17.952C3.49922 17.3952 3.42242 16.8336 3.37922 16.2672C3.26402 14.6592 3.14402 13.8816 3.06722 12.2736C2.98082 10.3824 2.77922 8.50561 2.44322 6.64801C2.42402 6.55201 2.41922 6.45601 2.40962 6.36481C2.35202 5.68801 2.59202 5.42881 3.24962 5.54401C6.79202 6.17281 10.3728 6.26401 13.9536 6.39361C15.4896 6.45121 17.0304 6.45121 18.5664 6.48001C18.7584 6.48001 18.9504 6.48961 19.1376 6.50881C19.5216 6.55201 19.7328 6.76801 19.7664 7.15201C19.992 9.56641 20.3088 11.9664 20.7888 14.3424C21.0336 15.5568 21.3312 15.9312 21.5472 17.1456C21.6576 17.7552 21.6288 17.88 21.096 18.1104C19.6992 18.2016 6.98402 18.2016 6.98402 18.2016ZM19.0128 7.24321C13.8384 7.40161 8.70242 7.28161 3.55682 6.92161C3.56162 7.01761 3.56162 7.07521 3.56642 7.13761C3.67682 8.14081 3.82082 9.14401 3.89762 10.1472C4.06082 12.3072 4.18082 13.6416 4.32962 15.8016C4.36802 16.3536 4.44002 16.9056 4.48802 17.4144C9.89762 17.2032 15.2688 16.9968 20.6304 16.7904C19.8912 13.4016 19.2384 10.7856 19.008 7.24801L19.0128 7.24321Z"
                    fill={color}
                />
                <path
                    d="M10.4592 9.14882C10.4448 8.94722 10.2624 8.78402 10.0608 8.78882C7.52162 8.85602 6.25442 8.80802 5.69282 8.76962C5.49122 8.75522 5.32802 8.93282 5.35682 9.13442C5.43842 9.70562 5.52482 10.1232 5.57282 10.7808C5.70242 12.4992 5.60642 12.2064 5.72162 13.9248C5.74562 14.2512 5.77922 14.5728 5.81282 14.8848C5.83202 15.0672 5.99522 15.2016 6.17762 15.1968C8.03042 15.12 9.66722 15.0336 11.16 14.9712C11.2944 14.9664 11.3856 14.8368 11.3568 14.7072C10.8384 12.336 10.6272 11.5728 10.4544 9.14882H10.4592Z"
                    fill={color}
                />
                <path
                    d="M17.7456 10.0704C18.2784 10.0368 18.1152 9.33599 17.712 9.37439C17.5056 9.39359 17.0592 9.43199 17.04 9.43679C16.1808 9.49919 13.7904 9.64319 11.9424 9.52799C11.7792 9.51839 11.6544 9.66718 11.6976 9.82558C11.7216 9.91198 11.7456 10.2528 11.7744 10.3392C11.808 10.4352 11.904 10.4976 12.0096 10.4976C14.7312 10.4352 15.0192 10.2384 17.7456 10.0704Z"
                    fill={color}
                />
                <path
                    d="M18.0576 12.3552C18.5904 12.3216 18.4272 11.6208 18.024 11.6592C17.8176 11.6784 17.3712 11.7168 17.352 11.7216C16.4928 11.784 14.1024 11.928 12.2544 11.8128C12.0912 11.8032 11.9664 11.952 12.0096 12.1104C12.0336 12.1968 12.0576 12.5376 12.0864 12.624C12.12 12.72 12.216 12.7824 12.3216 12.7824C15.0432 12.72 15.3312 12.5232 18.0576 12.3552Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_8377_69574">
                    <rect width={size} height={size} />
                </clipPath>
            </defs>
        </svg>
    )
}
