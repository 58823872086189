import {
    Avatar,
    Box,
    Clock,
    Crown,
    CrownVIP,
    DeliveryIcon,
    Euro,
    Motorbike,
    Phone,
    ShoppingCartIcon,
} from 'assets/icons'
import { CollapsedOrderCard } from 'components/collapsed-order-card/CollapsedOrderCard'
import { useFeatureToggle } from 'hooks/useFeatureToggle'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { H4 } from 'styles/typography'
import { ComplaintDTO, OrderDTO } from 'types/api'
import { ComplaintType, PickUpType, RefundType } from 'types/api.enum'
import { FEATURE_TOGGLE_CODE } from 'utils/enums'
import { formatDateTime, formatPrice, formatTime, getDeliveryPrice } from 'utils/util'

import { InfoStamp, InfoText, PriceLine } from './components'
import { FraudCard } from './components/FraudCard'
import {
    StyledCardInfo,
    StyledCrownsContainer,
    StyledIframe,
    StyledInfoContainer,
    StyledOrderContainer,
    StyledSeparator,
    StyledTotalContainer,
    StyledTypeContainer,
    StyledTypeIconContainer,
} from './style'

type DeliveryComplaintDetailProps = {
    complaint: ComplaintDTO
}

export const DeliveryComplaintDetail = ({ complaint }: DeliveryComplaintDetailProps) => {
    const { t } = useTranslation()
    const { order, issue, refundAmountMoney, refundAmountCrown, refundType, inCharge, description, fraudLevel } =
        complaint

    const enabledFranchisedReclamation = useFeatureToggle([FEATURE_TOGGLE_CODE.FRANCHISED_RECLAMATION])

    return (
        <>
            {order && <OrderContainer order={order} />}
            <StyledInfoContainer>
                {enabledFranchisedReclamation && (
                    <StyledCardInfo>
                        <StyledTypeContainer>
                            <StyledTypeIconContainer>
                                <DeliveryIcon color={colors.brown} size={22} />
                            </StyledTypeIconContainer>
                            <H4 color={colors.brown}>{t(`complaints.complaintTypes.${ComplaintType.DELIVERY}`)}</H4>
                        </StyledTypeContainer>
                    </StyledCardInfo>
                )}
                <StyledCardInfo>
                    <InfoText title={t('complaints.issue')} text={issue} first />
                    <InfoText
                        title={t('complaints.proposedRefund')}
                        text={
                            refundType === RefundType.MONEY ? (
                                formatPrice(refundAmountMoney || 0)
                            ) : (
                                <StyledCrownsContainer>
                                    {refundAmountCrown?.toString()}
                                    <Crown color={colors.orange} />
                                </StyledCrownsContainer>
                            )
                        }
                    />
                    <InfoText title={t('complaints.description')} text={description} />
                    <InfoText title={t('complaints.responsability')} text={t(`complaints.inCharge.${inCharge}`)} />
                </StyledCardInfo>

                {order && (
                    <>
                        <DeliveryInfoCard order={order} />
                        <ClientInfoCard order={order} />
                    </>
                )}
                {fraudLevel && enabledFranchisedReclamation && <FraudCard fraudScoring={fraudLevel} />}
                {order && <PickupInfoCards order={order} />}
            </StyledInfoContainer>
        </>
    )
}

const OrderContainer = ({ order }: { order: OrderDTO }) => {
    const { t } = useTranslation()
    const { content, deliveryFees, finalPrice, originalPrice, pickUpType, promotions } = order
    const deliveryPrice = getDeliveryPrice(deliveryFees)
    return (
        <StyledOrderContainer>
            {content?.map((item) => <CollapsedOrderCard key={item.id} item={item} />)}
            <StyledTotalContainer>
                <PriceLine text={t('orders.total')} price={finalPrice - deliveryPrice} />
                {promotions?.map((promotion) => (
                    <PriceLine
                        key={promotion.promotionId}
                        text={promotion.promotionName}
                        price={finalPrice - originalPrice}
                    />
                ))}
                {pickUpType === PickUpType.DELIVERY_IN_APP && (
                    <PriceLine text={t('orders.deliveryFees')} price={deliveryPrice} />
                )}
                <StyledSeparator />
                <PriceLine text={t('orders.finalTotal')} price={finalPrice} bold />
            </StyledTotalContainer>
        </StyledOrderContainer>
    )
}

const DeliveryInfoCard = ({ order }: { order: OrderDTO }) => {
    const { t } = useTranslation()
    const { content, createdDate, finalPrice, orderNumber } = order
    return (
        <StyledCardInfo>
            <InfoStamp icon={<Clock color={colors.brown} />} text={formatDateTime(new Date(createdDate))} />
            <InfoStamp
                icon={<Box color={colors.brown} />}
                text={t('orders.article', {
                    count: content?.length || 0,
                })}
            />
            <InfoStamp icon={<Euro color={colors.brown} />} text={formatPrice(finalPrice)} />
            <InfoStamp icon={<ShoppingCartIcon color={colors.brown} />} text={orderNumber} />
        </StyledCardInfo>
    )
}

const ClientInfoCard = ({ order }: { order: OrderDTO }) => {
    const { t } = useTranslation()
    const { clientFirstName, clientLastName, clientPhone, clientVIP } = order
    const clientNameLabeled = clientLastName ? clientFirstName + ' ' + clientLastName[0] : clientFirstName
    return (
        <StyledCardInfo vip={clientVIP}>
            <InfoStamp icon={<Avatar color={colors.brown} />} text={clientNameLabeled} />
            <InfoStamp icon={<Phone color={colors.brown} />} text={clientPhone} />
            {clientVIP && <InfoStamp icon={<CrownVIP color={colors.orange} />} text={t('orders.vipId')} warning />}
        </StyledCardInfo>
    )
}

const PickupInfoCards = ({ order }: { order: OrderDTO }) => {
    const { t } = useTranslation()
    const { driverName, driverPhone, pickUpType, dlvDropoffETA, dlvPickupETA, trackingDriverUrl } = order
    return (
        <>
            {pickUpType === PickUpType.DELIVERY_IN_APP && (
                <StyledCardInfo>
                    {!!driverName && <InfoStamp icon={<Motorbike color={colors.brown} />} text={driverName} />}
                    {!!dlvPickupETA && (
                        <InfoStamp text={`${t('complaints.take')} ${formatTime(new Date(dlvPickupETA))}`} />
                    )}
                    {!!dlvDropoffETA && (
                        <InfoStamp text={`${t('complaints.deliver')} ${formatTime(new Date(dlvDropoffETA))}`} />
                    )}
                    {!!driverPhone && <InfoStamp icon={<Phone color={colors.brown} />} text={driverPhone} />}
                </StyledCardInfo>
            )}
            {!!trackingDriverUrl && (
                <StyledCardInfo>
                    <StyledIframe src={trackingDriverUrl} />
                </StyledCardInfo>
            )}
        </>
    )
}
