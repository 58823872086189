import React from 'react'

import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const TrashIcon = (props: IconProps) => {
    const { color = colors.defaultGrey, size = 22, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 18 21" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
            <path
                fillRule="evenodd"
                d="M7 16.5005C7.55228 16.5005 8 16.0528 8 15.5005V9.5005C8 8.94822 7.55228 8.50051 7 8.50051C6.44771 8.50051 6 8.94822 6 9.5005V15.5005C6 16.0528 6.44771 16.5005 7 16.5005ZM17 4.50026H13V3.50026C13 1.84341 11.6569 0.500259 10 0.500259H8C6.34315 0.500259 5 1.84341 5 3.50026V4.50026H1C0.447715 4.50026 0 4.94797 0 5.50026C0 6.05254 0.447715 6.50026 1 6.50026H2V17.5003C2 19.1571 3.34315 20.5003 5 20.5003H13C14.6569 20.5003 16 19.1571 16 17.5003V6.50026H17C17.5523 6.50026 18 6.05254 18 5.50026C18 4.94797 17.5523 4.50026 17 4.50026ZM7.00001 3.50051C7.00001 2.94822 7.44772 2.50051 8.00001 2.50051H10C10.5523 2.50051 11 2.94822 11 3.50051V4.50051H7.00001V3.50051ZM14 17.5003C14 18.0525 13.5523 18.5003 13 18.5003H5.00001C4.44772 18.5003 4.00001 18.0525 4.00001 17.5003V6.50026H14V17.5003ZM11 16.5005C11.5523 16.5005 12 16.0528 12 15.5005V9.5005C12 8.94822 11.5523 8.50051 11 8.50051C10.4477 8.50051 10 8.94822 10 9.5005V15.5005C10 16.0528 10.4477 16.5005 11 16.5005Z"
                fill={color}
            />
        </svg>
    )
}
