import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

export const DeliveryIcon = ({ color = colors.blackLight, size = 18, ...otherProps }: IconProps) => (
    <svg width={size} height={(14 / 18) * size} viewBox="0 0 18 14" fill="none" {...otherProps}>
        <g clipPath="url(#clip0_4981_32031)">
            <path
                fill={color}
                d="M1.79945 10.413C1.64645 12.447 3.59945 14.076 5.65145 12.15C5.79439 12.01 5.92675 11.8595 6.04745 11.7C6.32163 11.3237 6.53709 10.9079 6.68645 10.467H3.89645L1.79945 10.413Z"
            />
            <path
                fill={color}
                d="M12.599 9.45899C12.4797 9.49586 12.3694 9.55716 12.275 9.63899C12.1486 10.1476 12.1994 10.6841 12.419 11.16C13.724 13.518 16.739 11.691 16.991 9.35999C15.947 9.35999 13.742 9.35999 12.599 9.43199"
            />
            <path
                fill={color}
                d="M17.7747 7.05601C17.5084 6.52046 17.1208 6.05447 16.6427 5.69511C16.1645 5.33575 15.6092 5.09294 15.0207 4.98601H14.8947C14.3889 4.2094 13.9233 3.40735 13.4997 2.58301H13.6527C14.0127 2.68201 14.4987 2.66401 14.4987 2.52901C14.4017 1.82321 14.2665 1.12319 14.0937 0.432011C14.0937 0.171011 14.0037 0.0810107 13.8327 0.0360107H13.6437C13.2927 0.0360107 12.7437 0.531011 12.6087 1.26901L12.3027 1.01701C12.2246 0.960512 12.1358 0.920686 12.0417 0.900011C11.5696 0.806868 11.0811 0.844207 10.6287 1.00801C10.0617 1.27801 10.1247 2.12401 10.6287 2.24101C10.7234 2.26325 10.822 2.26325 10.9167 2.24101C11.0869 2.18944 11.2685 2.18944 11.4387 2.24101C11.9247 2.36701 12.2757 2.92501 12.4647 3.92401C12.5817 4.54501 12.7347 5.00401 12.7887 5.29201C11.7661 5.74064 10.9076 6.49528 10.3317 7.45201C10.0024 7.54377 9.65446 7.54452 9.32481 7.45418C8.99516 7.36384 8.69618 7.1858 8.45969 6.93901C8.22687 6.54226 8.14598 6.07448 8.23206 5.62258C8.31814 5.17068 8.56532 4.76539 8.92769 4.48201C8.97851 4.40712 9.01181 4.32175 9.02514 4.23223C9.03846 4.14272 9.03147 4.05135 9.00467 3.9649C8.97787 3.87846 8.93196 3.79916 8.87033 3.73287C8.80871 3.66659 8.73296 3.61503 8.64869 3.58201C7.11054 3.36461 5.558 3.26532 4.00469 3.28501C3.40169 3.28501 3.10469 4.11301 3.42869 4.42801C2.68086 4.54613 1.98522 4.88455 1.43069 5.40001C0.768938 6.04713 0.296002 6.86233 0.0626925 7.75801C-0.0442176 8.10509 -0.0218382 8.47915 0.125693 8.81101C0.187724 8.92778 0.274721 9.02944 0.380497 9.10877C0.486273 9.1881 0.608229 9.24315 0.737693 9.27001C0.938066 9.31765 1.14382 9.33883 1.34969 9.33301H3.41969H4.69769H6.88469L10.3677 9.28801C11.2677 9.35101 11.5827 8.38801 12.5007 8.38801H12.8967H17.0997C17.8467 8.38801 18.2427 8.50501 17.7387 7.13701"
            />
        </g>
        <defs>
            <clipPath id="clip0_4981_32031">
                <rect width="18" height="13.014" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
