import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Gift = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M15 6.00003H14.65C14.8782 5.53279 14.9979 5.02002 15 4.50003C15.0062 3.07473 14.1451 1.78889 12.8247 1.25197C11.5044 0.715043 9.9903 1.03494 9 2.06003C8.0097 1.03494 6.49556 0.715043 5.17525 1.25197C3.85495 1.78889 2.99381 3.07473 3 4.50003C3.00213 5.02002 3.12178 5.53279 3.35 6.00003H3C1.34315 6.00003 0 7.34318 0 9.00003V11C0 11.5523 0.447715 12 1 12H2V18C2 19.6569 3.34315 21 5 21H13C14.6569 21 16 19.6569 16 18V12H17C17.5523 12 18 11.5523 18 11V9.00003C18 7.34318 16.6569 6.00003 15 6.00003ZM7.99999 19H4.99998C4.4477 19 3.99998 18.5523 3.99998 18V12H7.99999V19ZM8.00002 10H2.00002V9.00003C2.00002 8.44775 2.44773 8.00003 3.00002 8.00003H8.00002V10ZM8.00002 6.00003H6.50002C5.67159 6.00003 5.00002 5.32845 5.00002 4.50003C5.00002 3.6716 5.67159 3.00003 6.50002 3.00003C7.32844 3.00003 8.00002 3.6716 8.00002 4.50003V6.00003ZM10 4.50003C10 3.6716 10.6716 3.00003 11.5 3.00003C12.3284 3.00003 13 3.6716 13 4.50003C13 5.32845 12.3284 6.00003 11.5 6.00003H10V4.50003ZM14 18C14 18.5523 13.5523 19 13 19H10V12H14V18ZM16 10H10V8.00003H15C15.5523 8.00003 16 8.44775 16 9.00003V10Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
