import { CheckIcon } from 'assets/icons'
import { colors } from 'styles/colors'

import { StyledCheckbox } from './style'

interface CheckboxProps {
    onClick: (value: boolean) => void
    checked: boolean
    disabled?: boolean
}

export const Checkbox = ({ onClick, checked, disabled }: CheckboxProps) => {
    const handleClick = () => {
        if (!disabled) {
            onClick(!checked)
        }
    }

    return (
        <StyledCheckbox onClick={handleClick} checked={checked} disabled={disabled}>
            {checked && <CheckIcon color={colors.white} size={20} />}
        </StyledCheckbox>
    )
}
