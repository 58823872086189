import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Hourglass = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M15.257 5.965V2.802h1.41V1H4.018v1.802h1.41v3.143c0 1.241.556 2.403 1.52 3.103l1.91 1.462-1.928 1.442c-.946.7-1.52 1.861-1.52 3.103v3.143H4V20h12.648v-1.802h-1.41v-3.143c0-1.242-.556-2.403-1.52-3.103l-1.892-1.442 1.929-1.442c.946-.7 1.502-1.861 1.502-3.103zm-2.448 7.468c.501.36.78.981.78 1.622v3.143H7.096v-3.143c0-.641.297-1.262.78-1.622l2.466-1.822 2.466 1.822zm.78-7.468c0 .64-.298 1.262-.78 1.622l-2.466 1.822-2.467-1.842c-.5-.36-.779-.981-.779-1.622V2.802h6.491v3.163z"
                fill={color}
                fillRule="nonzero"
            />
        </svg>
    )
}
