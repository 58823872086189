import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const MinusCircle = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M10.5 1a9.5 9.5 0 100 19 9.5 9.5 0 000-19zm0 17.1a7.6 7.6 0 110-15.2 7.6 7.6 0 010 15.2zm3.8-8.55H6.7a.95.95 0 100 1.9h7.6a.95.95 0 000-1.9z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
