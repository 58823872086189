import { ReactElement } from 'react'
import { Tab } from 'types/tab'

import { StyledH4, StyledTab, StyledTabContainer, StyledTabIcon, StyledTabNumber, StyledTabsContainer } from './style'

interface TabsProps {
    tabs: Array<Tab>
    className?: string
    index: number
    headerComponent?: ReactElement
}

export const Tabs = ({ tabs, className, index: tabSelectedIndex, headerComponent }: TabsProps) => {
    return (
        <StyledTabsContainer className={className}>
            {headerComponent}
            <StyledTabContainer>
                {tabs.map((tab, index) => {
                    const isSelected = tabSelectedIndex === index
                    return (
                        <StyledTab key={index} selected={isSelected} onClick={tab.onClick}>
                            <StyledTabIcon selected={isSelected}>{tab.icon}</StyledTabIcon>
                            <StyledH4 selected={isSelected}>{tab.label}</StyledH4>
                            {tab.number !== undefined && (
                                <StyledTabNumber selected={isSelected}>{tab.number}</StyledTabNumber>
                            )}
                        </StyledTab>
                    )
                })}
            </StyledTabContainer>
        </StyledTabsContainer>
    )
}
