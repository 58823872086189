import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'

import { BoldCross } from '../../assets/icons'
import { Modal } from '../../components/modal/Modal'
import { useAppSelector } from '../../hooks/useRedux'
import { usePostEmployee } from '../../redux/collaborators/collaborators'
import { colors } from '../../styles/colors'
import { H2 } from '../../styles/typography'
import { CrmEmployeeForm, EmployeeWithCouponDTO } from '../../types/api'
import { FormCollaborator } from './components/FormCollaborator'
import { StyledContainer, StyledLoaderContainer, StyledMainContainer, StyledModalContainer } from './style'

const CONFLICT_ERROR_CODE = 409

export const AddCollaborator = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)

    const handleBackPress = () => {
        navigate(t('page.collaborators.route'))
    }

    const {
        mutate: postEmployeeMutation,
        isLoading,
        isError,
        reset,
    } = usePostEmployee({
        onSuccess: handleBackPress,
        onError: (error) => setError(error),
    })

    const initialValues = {
        lastName: '',
        firstName: '',
        email: '',
        frNumber: restaurantFrNumber.toString(),
        employeeId: '',
    }

    const onSubmit = (values: EmployeeWithCouponDTO) => {
        const employeeData = {
            lastName: values.lastName,
            firstName: values.firstName,
            email: values.email,
            employeeId: values.employeeId,
        } as CrmEmployeeForm
        postEmployeeMutation({ frNumber: restaurantFrNumber.toString(), employeeData })
    }

    return (
        <>
            {isLoading ? (
                <StyledMainContainer>
                    <StyledContainer>
                        <StyledLoaderContainer>
                            <ClipLoader size={70} color={colors.orange} loading={true} />
                        </StyledLoaderContainer>
                    </StyledContainer>
                </StyledMainContainer>
            ) : (
                <FormCollaborator
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    handleBackPress={handleBackPress}
                    isLoading={isError}
                    title={t('page.collaborators.addEmployee.title')}
                />
            )}
            {isError && (
                <Modal handleClose={reset} closeIconColor={colors.brown} center>
                    <StyledModalContainer>
                        <BoldCross color={colors.red} size={50} />
                        <H2 color={colors.brown}>
                            {error.response.status && error.response.status === CONFLICT_ERROR_CODE
                                ? t('page.collaborators.deleteModal.error.conflict')
                                : t('page.collaborators.deleteModal.error.other')}
                        </H2>
                    </StyledModalContainer>
                </Modal>
            )}
        </>
    )
}
