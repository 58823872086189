import { ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'
import { H4, Text } from 'styles/typography'

type ComplaintCardProps = {
    icon: ReactNode
    text: string
    title: string
    onClick: () => void
}

export const ComplaintCard = ({ icon, text, title, onClick }: ComplaintCardProps) => {
    return (
        <StyledCompensationCard onClick={onClick}>
            <StyledIcon>{icon}</StyledIcon>
            <div>
                <H4 color={colors.brown}>{title}</H4>
                <Text>{text}</Text>
            </div>
        </StyledCompensationCard>
    )
}

const StyledCompensationCard = styled.div`
    width: 100%;
    margin-top: ${SPACING.S};
    gap: ${SPACING.S};
    display: flex;
    box-sizing: border-box;
    padding: ${SPACING.L};
    border-radius: ${BORDER.RADIUS.S};
    border: ${BORDER.WIDTH.XS} solid ${colors.greyLight};

    & :hover {
        cursor: pointer;
    }
`

const StyledIcon = styled.div`
    flex-shrink: 0;
`
