import styled from 'styled-components'
import { colors } from 'styles/colors'
import { H2, H3 } from 'styles/typography'

export const StyledParamContainer = styled.div<{ separator?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 24px;
    ${(props) =>
        props.separator &&
        `box-shadow: inset 0px -1px 0px ${colors.shadow};
        padding-bottom: 24px;`}

    box-sizing: border-box;
`

export const StyledScheduleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 15px;
`

export const StyledDay = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px;
`

export const StyledH2 = styled(H2)`
    margin-bottom: 24px;
    color: ${colors.brown};
`

export const StyledH3 = styled(H3)`
    margin-bottom: 24px;
    color: ${colors.brown};
`

export const StyledIconContainer = styled.div`
    width: 10%;
    margin-left: 16px;
`

export const StyledTextContainer = styled.div`
    overflow: scroll;
    margin-left: 10px;
`

export const StyledModalButton = styled.div`
    display: flex;
    height: fit-content;
    &:hover {
        cursor: pointer;
    }
`

export const StyledReservationButton = styled.div`
    display: flex;
    padding: 10px;
    border-radius: 50px;
    gap: 10px;
    &:hover {
        cursor: pointer;
    }
    background-color: ${colors.brown};
`

export const StyledTitleParameters = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledStatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 10px;
    }
`

export const StyledButtonContainer = styled.div`
    margin-left: auto;
`

export const StyledImage = styled.img`
    width: 150px;
    max-width: 50%;
`

export const StyledBirthdayTitleContainer = styled.div<{ separator?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${(props) =>
        props.separator &&
        `box-shadow: inset 0px -1px 0px ${colors.shadow};
        padding-bottom: 24px;`}
`

export const StyledContainer = styled.div`
    width: 50vw;
    padding: 32px;
    background-color: ${colors.white};
    border-radius: 8px;
    & > h2 {
        margin-bottom: 8px;
    }
`

export const StyledNotifIconContainer = styled.div`
    position: relative;
    & > :nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(30%, -30%);
    }
`
