import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { BirthdayStatus } from 'types/api.enum'

import { StyledDot, StyledH4, StyledStatusContainer } from '../../screens/birthday-management/style'

interface StatusCount {
    confirmed: number
    refused: number
    standby: number
}

interface StatusProps {
    birthdayStatus: BirthdayStatus
    statusCount?: StatusCount
    isLoading?: boolean
}

export const Status = ({ birthdayStatus, statusCount, isLoading }: StatusProps) => {
    const { t } = useTranslation()
    switch (birthdayStatus) {
        case BirthdayStatus.REFUSED:
            return (
                <StyledStatusContainer status={BirthdayStatus.REFUSED}>
                    <StyledDot color={colors.red} />
                    {isLoading && <ClipLoader size={15} color={colors.brown} />}
                    {!isLoading && <Text color={colors.brown}> {t('services.birthday.status.denied')} </Text>}
                    {!isLoading && statusCount && <StyledH4>{statusCount.refused}</StyledH4>}
                </StyledStatusContainer>
            )
        case BirthdayStatus.CONFIRMED:
            return (
                <StyledStatusContainer status={BirthdayStatus.CONFIRMED}>
                    <StyledDot color={colors.green} />
                    {isLoading && <ClipLoader size={15} color={colors.brown} />}
                    {!isLoading && <Text color={colors.brown}> {t('services.birthday.status.confirmed')} </Text>}
                    {!isLoading && statusCount && <StyledH4>{statusCount.confirmed}</StyledH4>}
                </StyledStatusContainer>
            )
        case BirthdayStatus.STANDBY:
            return (
                <StyledStatusContainer status={BirthdayStatus.STANDBY}>
                    <StyledDot color={colors.orange} />
                    {isLoading && <ClipLoader size={15} color={colors.brown} />}
                    {!isLoading && <Text color={colors.brown}> {t('services.birthday.status.inProgress')} </Text>}
                    {!isLoading && statusCount && <StyledH4>{statusCount.standby}</StyledH4>}
                </StyledStatusContainer>
            )
        default:
            return null
    }
}
