import styled from 'styled-components'
import { colors } from 'styles/colors'
import { H1, H4 } from 'styles/typography'

export const StyledH1 = styled(H1)`
    margin: 0;
    margin-top: 2px;
`

export const StyledH4 = styled(H4)`
    margin: 0;
`

export const StyledTextContainer = styled.div`
    text-align: end;
    margin: 5px;
`

export const StyledNumberContainer = styled.div`
    margin: 0 5px;
    border: 3px solid ${colors.orange};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 2px;
`

export const StyledButtonContainer = styled.div`
    margin: 0 5px;
    border: 1px solid ${colors.greyLight};
    height: fit-content;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px ${colors.shadow};
    border-radius: 5px;
    padding: 11px 13px;
`

export const StyledOrderTimeContainer = styled.div<{ isClickable: boolean }>`
    margin: 20px;
    display: flex;
    ${(props) => props.isClickable && 'cursor: pointer'};
`
