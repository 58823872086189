import { colors } from 'styles/colors'
import { H4, Text } from 'styles/typography'

interface KingTableFormSectionTitleProps {
    title: string
    description: string
}

export const KingTableFormSectionTitle = ({ title, description }: KingTableFormSectionTitleProps) => {
    return (
        <div>
            <H4 color={colors.brown}>{title}</H4>
            <Text>{description}</Text>
        </div>
    )
}
