import { IconButton } from '@mui/material'
import { Formik } from 'formik'
import i18n from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { BoldBackIcon } from '../../../assets/icons'
import { Button } from '../../../components/button/Button'
import { Notif } from '../../../components/notif/Notif'
import { OutlinedTextField, TextFieldVariant } from '../../../components/outlined-text-field/OutlinedTextField'
import { useAppSelector } from '../../../hooks/useRedux'
import { useResendMail } from '../../../redux/collaborators/collaborators'
import { colors } from '../../../styles/colors'
import { H2 } from '../../../styles/typography'
import { EmployeeWithCouponDTO, RestaurantLightDTO } from '../../../types/api'
import { NotyfType } from '../../../utils/enums'
import { StyledErrorContainer, StyledErrorText, StyledField } from '../../login/style'
import {
    StyledButton,
    StyledContainer,
    StyledFormContainer,
    StyledFormTitleContainer,
    StyledHeaderContainer,
    StyledInputsContainer,
    StyledMainContainer,
} from '../style'

export type FormCollaboratorProps = {
    onSubmit: (values: EmployeeWithCouponDTO & { frNumber: string }) => void
    initialValues: EmployeeWithCouponDTO & { frNumber: string }
    handleBackPress: () => void
    isLoading: boolean
    title: string
    disable?: boolean
    displayCodeCoupon?: boolean
    displayResendMailButton?: boolean
    disableMail?: boolean
}

export const validationSchemaCollaborator = Yup.object().shape({
    lastName: Yup.string().required(i18n.t('error.required')),
    firstName: Yup.string().required(i18n.t('error.required')),
    email: Yup.string().email(i18n.t('error.mail')).required(i18n.t('error.required')),
    employeeId: Yup.string().required(i18n.t('error.required')),
})

export const FormCollaborator = ({
    onSubmit,
    initialValues,
    handleBackPress,
    isLoading,
    title,
    disable = false,
    displayCodeCoupon = false,
    displayResendMailButton = false,
    disableMail = false,
}: FormCollaboratorProps) => {
    const { t } = useTranslation()
    const restaurants: RestaurantLightDTO[] = useAppSelector((state) => state.restaurant.restaurants)
    const restaurantName = restaurants.find((restaurant) => restaurant.frNumber === initialValues.frNumber)?.name ?? ''

    const { mutate: resendMail, isLoading: resendMailLoading } = useResendMail({
        onSuccess: () => {
            Notif({
                type: NotyfType.SUCCESS,
                text: t('page.collaborators.updateEmployee.successResendMail'),
            })
        },
        onError: () => {
            Notif({
                type: NotyfType.ERROR,
                text: t('page.collaborators.updateEmployee.errorResendMail'),
            })
        },
    })

    return (
        <StyledMainContainer>
            <StyledContainer>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchemaCollaborator}
                >
                    {(props) => {
                        const { handleChange, handleSubmit, handleBlur, values, errors, touched } = props
                        return (
                            <StyledFormContainer onSubmit={handleSubmit}>
                                <StyledHeaderContainer>
                                    <StyledFormTitleContainer>
                                        <IconButton onClick={handleBackPress}>
                                            <BoldBackIcon />
                                        </IconButton>
                                        <H2 color={colors.brown}>{title}</H2>
                                    </StyledFormTitleContainer>
                                    <Button
                                        color={colors.red}
                                        text={
                                            disable
                                                ? t('page.collaborators.employeePromotion.cta.updateEmployee')
                                                : t('page.collaborators.addEmployee.cta')
                                        }
                                        type={'submit'}
                                        disabled={isLoading || resendMailLoading}
                                    />
                                </StyledHeaderContainer>
                                <StyledInputsContainer>
                                    <StyledField>
                                        <OutlinedTextField
                                            label={t('page.collaborators.employeePromotion.employeeList.lastName')}
                                            value={values.lastName ?? ''}
                                            width="100%"
                                            variant={TextFieldVariant.OUTLINED}
                                            name={'lastName'}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={disable}
                                            hiddenDisable={disable}
                                        />
                                        <StyledErrorContainer>
                                            {errors.lastName && touched.lastName && (
                                                <StyledErrorText>{errors.lastName}</StyledErrorText>
                                            )}
                                        </StyledErrorContainer>
                                    </StyledField>
                                    <StyledField>
                                        <OutlinedTextField
                                            label={t('page.collaborators.employeePromotion.employeeList.firstName')}
                                            value={values.firstName ?? ''}
                                            width="100%"
                                            variant={TextFieldVariant.OUTLINED}
                                            name={'firstName'}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={disable}
                                            hiddenDisable={disable}
                                        />
                                        <StyledErrorContainer>
                                            {errors.firstName && touched.firstName && (
                                                <StyledErrorText>{errors.firstName}</StyledErrorText>
                                            )}
                                        </StyledErrorContainer>
                                    </StyledField>
                                </StyledInputsContainer>
                                <StyledInputsContainer>
                                    <StyledField>
                                        <OutlinedTextField
                                            label={t('page.collaborators.employeePromotion.employeeList.mail')}
                                            value={values.email ?? ''}
                                            width="100%"
                                            variant={TextFieldVariant.OUTLINED}
                                            name={'email'}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={!!disableMail || disable}
                                            hiddenDisable={disable}
                                        />
                                        <StyledErrorContainer>
                                            {errors.email && touched.email && (
                                                <StyledErrorText>{errors.email}</StyledErrorText>
                                            )}
                                        </StyledErrorContainer>
                                    </StyledField>
                                </StyledInputsContainer>
                                <StyledInputsContainer>
                                    <StyledField>
                                        <OutlinedTextField
                                            label={t(
                                                'page.collaborators.employeePromotion.employeeList.restaurantName',
                                            )}
                                            value={restaurantName}
                                            width="100%"
                                            variant={TextFieldVariant.OUTLINED}
                                            disabled
                                            hiddenDisable={disable}
                                        />
                                    </StyledField>
                                    <StyledField>
                                        <OutlinedTextField
                                            label={t('page.collaborators.employeePromotion.employeeList.employeeId')}
                                            value={values.employeeId ?? ''}
                                            width="100%"
                                            variant={TextFieldVariant.OUTLINED}
                                            name={'employeeId'}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={disable}
                                            hiddenDisable={disable}
                                        />
                                        <StyledErrorContainer>
                                            {errors.employeeId && touched.employeeId && (
                                                <StyledErrorText>{errors.employeeId}</StyledErrorText>
                                            )}
                                        </StyledErrorContainer>
                                    </StyledField>
                                </StyledInputsContainer>
                                {displayCodeCoupon && (
                                    <StyledInputsContainer>
                                        <StyledField>
                                            <OutlinedTextField
                                                label={t('page.collaborators.employeePromotion.employeeList.code')}
                                                value={values.couponCode ?? ''}
                                                width="100%"
                                                variant={TextFieldVariant.OUTLINED}
                                                name={'code'}
                                                disabled
                                                hiddenDisable={disable}
                                            />
                                        </StyledField>
                                    </StyledInputsContainer>
                                )}
                                {displayResendMailButton && (
                                    <StyledButton
                                        color={colors.red}
                                        text={t('page.collaborators.employeePromotion.cta.resendMail')}
                                        onClick={() =>
                                            resendMail({
                                                frNumber: values.frNumber,
                                                id: values.id ?? '',
                                            })
                                        }
                                        disabled={resendMailLoading}
                                    />
                                )}
                            </StyledFormContainer>
                        )
                    }}
                </Formik>
            </StyledContainer>
        </StyledMainContainer>
    )
}
