import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H2, H4, Text } from 'styles/typography'
import { BirthdayStatus } from 'types/api.enum'

import { BORDER, SPACING } from '../../styles/tokens'

interface StatusContainerProps {
    status: BirthdayStatus
}

export const StyledContainer = styled.div`
    background-color: ${colors.white};
    border-radius: ${BORDER.RADIUS.XS};
    margin-top: 130px;
    padding: ${SPACING.L};
    min-height: calc(100% - 180px);
    height: fit-content;
`

export const StyledH2 = styled(H2)`
    margin-bottom: 24px;
    color: ${colors.brown};
`

export const StyledH4 = styled(H4)`
    color: ${colors.brown};
`

export const StyledStatusContainer = styled.div<StatusContainerProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    background-color: ${({ status }) => {
        switch (status) {
            case BirthdayStatus.REFUSED:
                return colors.redLight
            case BirthdayStatus.CONFIRMED:
                return colors.greenLight
            case BirthdayStatus.STANDBY:
                return colors.orangeLight
            default:
                return colors.greyLight
        }
    }};
    padding: 2px;
    gap: 5px;
    border-radius: 5px;
`

export const StyledShowMoreButton = styled.div`
    display: flex;
    padding: 7px 16px;
    justify-content: center;
    border-radius: 25px;
    gap: 10px;
    &:hover {
        cursor: pointer;
    }
    background-color: ${colors.red};
`

export const StyledManagementContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledManagementTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.white};
    padding: 0 ${SPACING.XXXXL} ${SPACING.XXL};
    margin: -${SPACING.S} 0 -${SPACING.XXL};
`

export const StyledTitleButtonContainer = styled.div`
    display: flex;
    align-items: center;
`

export const StyledManagementTitle = styled.div`
    margin-top: 10px;
`

export const StyledGrid = styled.table`
    width: 100%;
    border-collapse: collapse;
`

export const StyledRow = styled.tr<{ error?: boolean }>`
    ${(props) => props.error && `background-color: ${colors.redLight}`};
`

export const StyledColumnTitle = styled.td<{ radiusRight?: boolean; radiusLeft?: boolean }>`
    background-color: ${colors.beige};
    padding: 10px;
    ${(props) => props.radiusRight && 'border-radius: 0px 5px 0px 0px;'}
    ${(props) => props.radiusLeft && 'border-radius: 5px 0px 0px 0px;'}
`

export const StyledTitleText = styled(Text)`
    font-family: ${fonts.flame};
    color: ${colors.brownDark};
`

export const StyledColumn = styled.td`
    padding: 10px;
    border: 1px solid ${colors.greyLight};
    border-left: none;
    border-right: none;
`

export const StyledCrownContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledImage = styled.img`
    width: 150px;
    max-width: 50%;
`

export const StyledDot = styled.span`
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background: ${(props) => props.color};
`

export const StyledCenterPage = styled.div`
    justify-content: center;
    margin-top: 20%;
    margin-left: 50%;
`
