import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Mail = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 18 14" {...otherProps}>
            <path
                d="M3.167.333h11.667a2.5 2.5 0 012.5 2.5v8.334a2.5 2.5 0 01-2.5 2.5H3.167a2.5 2.5 0 01-2.5-2.5V2.833a2.5 2.5 0 012.5-2.5zM3.51 2l4.9 4.9a.833.833 0 001.183 0l4.9-4.9H3.51zm-1.175 9.167c0 .46.373.833.833.833h11.667c.46 0 .833-.373.833-.833V3.175l-4.9 4.9a2.5 2.5 0 01-3.533 0l-4.9-4.9v7.992z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
