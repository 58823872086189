import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Pencil = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M3.85 16.206h4.028a.95.95 0 00.675-.276l6.573-6.583 2.699-2.641a.95.95 0 000-1.35l-4.028-4.075a.95.95 0 00-1.35 0L9.77 3.97l-6.593 6.583a.95.95 0 00-.276.675v4.028c0 .524.425.95.95.95zm9.272-12.911l2.688 2.688-1.349 1.35-2.688-2.69 1.349-1.348zM4.8 11.617l5.634-5.633 2.688 2.688-5.633 5.634H4.8v-2.689zm14.25 6.489H1.95a.95.95 0 100 1.9h17.1a.95.95 0 000-1.9z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
