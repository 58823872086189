import { useEffect } from 'react'

//TODO: type properties keys with HTMLScriptElement

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useScript = (properties: Record<string, any>) => {
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const script: any = document.createElement('script')
        for (const [key, value] of Object.entries(properties)) {
            //@ts-ignore
            script[key] = value
        }
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
