import { round } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Cake, Exclamation, ExclamationIcon2 } from '../../../../../../assets/icons'
import { useAppSelector } from '../../../../../../hooks/useRedux'
import {
    selectClickAndCollectOrders,
    selectDriveAndParkingOrders,
    selectOrdersByPickUpType,
    selectOrdersByStatusType,
} from '../../../../../../redux/order/selectors'
import { useGetListStorage } from '../../../../../../redux/storage/storage'
import { colors } from '../../../../../../styles/colors'
import { BORDER, SPACING } from '../../../../../../styles/tokens'
import { BirthdayBookingListItemDTO } from '../../../../../../types/api'
import { PickUpType, ServiceCode } from '../../../../../../types/api.enum'
import { StatusType } from '../../../../../../utils/enums'
import { combineDateAndTimeStringsIntoDate, isPast } from '../../../../../../utils/util'
import { SmallCard } from './SmallCard'
import { SmallErrorBlock } from './SmallErrorBlock'

type SmallCardsContainerProps = {
    isOrderDataLoading: boolean
    isOrderDataError: boolean
    handleRefresh: () => void
}

export const SmallCardsContainer = ({
    isOrderDataLoading,
    isOrderDataError,
    handleRefresh,
}: SmallCardsContainerProps) => {
    const { t } = useTranslation()
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const { data: allServiceRestaurants, isLoading, isError } = useGetListStorage()
    const reservations: BirthdayBookingListItemDTO[] = useAppSelector((state) => state.birthdays.birthdays)

    const upcommingReservations = useMemo(() => {
        return reservations.filter((reservation: BirthdayBookingListItemDTO) => {
            return (
                reservation.reservationDate &&
                reservation.reservationHour &&
                !isPast(combineDateAndTimeStringsIntoDate(reservation.reservationDate, reservation.reservationHour))
            )
        })
    }, [reservations])

    const numberOfErrorOrders = useAppSelector((state) =>
        selectOrdersByStatusType(state.order.pendingOrders, StatusType.ERROR),
    ).length

    const totalNumberOrders =
        useAppSelector((state) => selectClickAndCollectOrders(state.order.pendingOrders)).length +
        useAppSelector((state) => selectClickAndCollectOrders(state.order.finishedOrders)).length +
        useAppSelector((state) => selectDriveAndParkingOrders(state.order.pendingOrders)).length +
        useAppSelector((state) => selectDriveAndParkingOrders(state.order.finishedOrders)).length +
        useAppSelector((state) => selectOrdersByPickUpType(state.order.pendingOrders, PickUpType.TABLE_ORDER)).length +
        useAppSelector((state) => selectOrdersByPickUpType(state.order.finishedOrders, PickUpType.TABLE_ORDER)).length +
        useAppSelector((state) => selectOrdersByPickUpType(state.order.pendingOrders, PickUpType.DELIVERY_IN_APP))
            .length +
        useAppSelector((state) => selectOrdersByPickUpType(state.order.finishedOrders, PickUpType.DELIVERY_IN_APP))
            .length

    const errorOrders = {
        textKey: t('page.home.dashboard.services.errorOrders'),
        element: numberOfErrorOrders.toString(),
        url: `${t('page.orders.route')}?status=${StatusType.ERROR}`,
    }

    const averageError = {
        textKey: t('page.home.dashboard.services.averageError'),
        element: t('page.home.dashboard.services.percentage', {
            number: totalNumberOrders !== 0 ? round((numberOfErrorOrders / totalNumberOrders) * 100) / 100 : 0,
        }),
        url: errorOrders.url,
    }

    const upcomingBirthday = {
        textKey: t('page.home.dashboard.services.upcomingBirthday'),
        element: upcommingReservations.length.toString(),
        url: t('page.birthday.route'),
    }

    const restaurant = isLoading || isError ? null : allServiceRestaurants.restaurants[restaurantFrNumber]
    const currentRestaurantService = restaurant || null

    return (
        <StyledContainer>
            {currentRestaurantService ? (
                currentRestaurantService.services.includes(ServiceCode.BIRTHDAY) && (
                    <StyledCard>
                        <SmallCard element={upcomingBirthday} icon={<Cake color={colors.brown} size={32} />} />
                    </StyledCard>
                )
            ) : (
                <StyledCard>
                    <SmallErrorBlock isError={isError || !restaurant} handleClick={handleRefresh} />
                </StyledCard>
            )}
            {!isOrderDataLoading && !isOrderDataError ? (
                <>
                    <StyledCard warning={numberOfErrorOrders > 0}>
                        <SmallCard
                            element={errorOrders}
                            icon={
                                <ExclamationIcon2
                                    color={numberOfErrorOrders > 0 ? colors.white : colors.brown}
                                    size={32}
                                />
                            }
                            warning={numberOfErrorOrders > 0}
                        />
                    </StyledCard>
                    <StyledCard>
                        <SmallCard
                            element={averageError}
                            icon={<Exclamation color={colors.brown} pointColor={colors.brown} size={32} />}
                        />
                    </StyledCard>
                </>
            ) : (
                <>
                    <StyledCard>
                        <SmallErrorBlock isError={isOrderDataError} handleClick={handleRefresh} />
                    </StyledCard>
                    <StyledCard>
                        <SmallErrorBlock isError={isOrderDataError} handleClick={handleRefresh} />
                    </StyledCard>
                </>
            )}
        </StyledContainer>
    )
}

const StyledCard = styled.div<{
    warning?: boolean
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${colors.white};
    padding: ${SPACING.XXS};
    border-radius: ${BORDER.RADIUS.S};
    box-sizing: border-box;
    ${BORDER.SHADOW_BOX};
    ${(props) => props.warning && `border: ${BORDER.WIDTH.S} solid ${colors.orange};`};
`

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.M};
    width: 100%;
`
