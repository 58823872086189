import styled from 'styled-components'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'

export const StyledTextStatus = styled(Text)`
    color: ${colors.black};
    margin-left: 5px;
`

export const StyledTile = styled.div<{ isText: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: ${(props) => (props.isText ? '1px 9px 1px 8px' : '5px 5px')};
    background: ${(props) => props.color};
    border-radius: 5px;
`

export const StyledDot = styled.span`
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background: ${(props) => props.color};
`
