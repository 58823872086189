import styled from 'styled-components'
import { colors } from 'styles/colors'
import { H4 } from 'styles/typography'

export const StyledServicesContainer = styled.div`
    display: flex;
    min-height: 100%;
`

export const StyledTabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 35%;
`

export const StyledCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.white};
    padding: 24px;
    border-radius: 5px;
    width: 65%;
    margin-left: 24px;
    min-height: 100%;
`

export const StyledGridContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.white};
    padding: 24px;
    border-radius: 5px;
    width: 100%;
    margin-left: 24px;
    min-height: 100%;
`

export const StyledTab = styled.div<{ selected: boolean }>`
    padding: 24px;
    display: flex;
    background: ${(props) => (props.selected ? colors.greyLight : colors.white)};
    ${(props) => props.selected && `box-shadow: inset -4px 0px 0px ${colors.orange}`};
    &:hover {
        cursor: pointer;
    }
`

export const StyledTitleContainer = styled.div`
    margin-left: 10px;
`

export const StyledH4 = styled(H4)`
    margin: 0;
    color: ${colors.brown};
`

export const StyledNotifIconContainer = styled.div`
    position: relative;
    & > :nth-child(2) {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(30%, -30%);
    }
`
