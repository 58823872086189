import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { logout } from 'redux/user/userSlice'
import { BirthdayBookingDetailsDTO, BirthdayBookingListItemDTO } from 'types/api'
import { BirthdayStatus } from 'types/api.enum'
import { slices } from 'utils/enums'
import { createBirthdayFromDetails, isPast } from 'utils/util'

export interface BirthdayState {
    birthdays: BirthdayBookingListItemDTO[]
    existsStandbyBirthday: boolean
}

const initialState: BirthdayState = {
    birthdays: [],
    existsStandbyBirthday: false,
}

export const BirthdaySlice = createSlice({
    name: slices.BIRTHDAY,
    initialState,
    reducers: {
        setBirthdays: (state, action: PayloadAction<BirthdayBookingListItemDTO[]>) => {
            state.birthdays = action.payload
            state.existsStandbyBirthday = action.payload.some((birthday) => {
                return (
                    birthday.status === BirthdayStatus.STANDBY &&
                    birthday.reservationDate &&
                    !isPast(new Date(birthday.reservationDate))
                )
            })
        },
        addBirthday: (state, action: PayloadAction<BirthdayBookingDetailsDTO>) => {
            const birthdayData = action.payload
            const birthdayToAdd = createBirthdayFromDetails(birthdayData)
            const indexToChange = state.birthdays.findIndex((birthday) => birthday.id === birthdayData.id)
            if (indexToChange === -1) {
                state.birthdays.push(birthdayToAdd)
                if (birthdayToAdd.status === BirthdayStatus.STANDBY) {
                    state.existsStandbyBirthday = true
                }
            } else {
                state.birthdays[indexToChange] = birthdayToAdd
            }
        },
        updateBirthday: (state, action: PayloadAction<BirthdayBookingDetailsDTO>) => {
            const birthdayData = action.payload
            const birthdayId = birthdayData.id
            const indexToChange = state.birthdays.findIndex((birthday) => birthday.id === birthdayId)
            if (indexToChange !== -1) {
                const updatedBirthday = createBirthdayFromDetails(birthdayData)
                state.birthdays[indexToChange] = updatedBirthday
                state.existsStandbyBirthday = state.birthdays.some((birthday) => {
                    return (
                        birthday.status === BirthdayStatus.STANDBY &&
                        birthday.reservationDate &&
                        !isPast(new Date(birthday.reservationDate))
                    )
                })
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => {
            return initialState
        })
    },
})

const { actions, reducer } = BirthdaySlice
export const { setBirthdays, addBirthday, updateBirthday } = actions
export { reducer as birthdayReducer }
