import { QueryOptions, useQuery } from 'react-query'

import { services } from '../../httpServices/storage/services'

export const STORAGE_KEYS = {
    GET_LIST: 'getListStorage',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGetListStorage = (options?: QueryOptions<any>) => {
    return useQuery({
        queryKey: [STORAGE_KEYS.GET_LIST],
        queryFn: () => services.restaurant.fetchList(),
        ...options,
    })
}
