import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const Warning = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M10.479 14.08a.859.859 0 100 1.716.859.859 0 000-1.717zm9.16 1.261l-6.911-12.02a2.576 2.576 0 00-4.499 0L1.36 15.341a2.576 2.576 0 002.198 3.89h13.84a2.576 2.576 0 002.242-3.89zm-1.485 1.718a.859.859 0 01-.755.438H3.559a.859.859 0 01-.756-.438.859.859 0 010-.859L9.67 4.18a.859.859 0 011.529 0L18.11 16.2c.174.262.19.598.043.876v-.017zM10.48 7.21a.859.859 0 00-.86.86v3.433a.859.859 0 101.718 0V8.07a.859.859 0 00-.858-.859z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
