import axios from 'axios'
import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: (failureCount, error) => {
                if (failureCount > 3) {
                    return false
                }

                if (axios.isAxiosError(error)) {
                    if (error.response?.status) {
                        // if the response was received
                        // we only retry on server errors, or 429 (rate limit)
                        return error.response.status >= 500 || error.response.status === 429
                    }
                }

                return true
            },
        },
    },
})
