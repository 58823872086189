import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const BagClickAndCollectIcon = (props: IconProps) => {
    const { color = colors.brown, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" {...otherProps}>
            <path
                d="M3.73205 5.064C3.64205 4.932 3.58805 4.794 3.60005 4.716C3.75605 3.846 3.97205 2.856 4.62005 2.058C4.88405 1.74 5.18405 1.578 5.51405 1.578C5.56205 1.578 5.61005 1.578 5.65805 1.59L6.31205 1.68C7.21805 1.8 8.16005 1.926 9.07205 2.082C9.39005 2.136 9.70205 2.19 10.02 2.244C11.562 2.514 13.164 2.79 14.754 2.91C15.684 2.982 16.626 3.012 17.538 3.042C17.814 3.048 18.09 3.06 18.372 3.072C18.672 3.084 18.972 3.078 19.29 3.072C19.386 3.072 19.488 3.072 19.59 3.072C19.482 3.294 19.374 3.51 19.272 3.726C18.666 4.956 18.096 6.114 17.712 7.368C15.774 6.948 13.8 6.69 11.778 6.444C10.788 6.324 9.78005 6.174 8.80205 6.03C8.19605 5.94 7.59005 5.856 6.99005 5.772C6.51005 5.706 6.02405 5.646 5.55005 5.592C5.23805 5.556 4.92005 5.514 4.60805 5.478C4.27205 5.436 3.93005 5.37 3.72005 5.07L3.73205 5.064Z"
                fill={color}
            />
            <path
                d="M19.5121 4.87804C19.4041 5.46604 19.2661 6.16204 19.1221 6.85804C19.0561 7.17604 18.8221 7.30804 18.4741 7.40404L19.5001 4.84204C19.5001 4.84204 19.5121 4.86604 19.5121 4.87204V4.87804Z"
                fill={color}
            />
            <path
                d="M20.4001 20.25C19.8781 20.586 19.3681 20.97 18.8761 21.336C18.6301 21.522 18.3841 21.708 18.1381 21.888C18.0541 21.948 17.9521 21.99 17.8441 22.014C17.7841 22.026 17.7241 22.014 17.6881 21.984C17.6521 21.948 17.6341 21.888 17.6401 21.822C17.6761 21.468 17.7181 21.12 17.7601 20.766C17.8141 20.304 17.8681 19.836 17.9161 19.374C18.0121 18.468 18.0961 17.562 18.1801 16.716C18.2881 15.6 18.3901 14.484 18.4861 13.368C18.5221 12.96 18.5581 12.552 18.5941 12.138C18.6541 11.436 18.7141 10.74 18.7801 10.038C18.8041 9.77997 18.8401 9.52197 18.8761 9.26997C18.9121 8.99397 18.9541 8.69997 18.9781 8.41797C18.9961 8.18397 19.0441 8.06397 19.2541 7.97997C19.4941 7.87797 19.6561 7.65597 19.7101 7.35597C19.7521 7.10997 19.8001 6.86397 19.8481 6.62397C19.9201 6.22797 19.9981 5.81397 20.0641 5.41197C20.1181 5.05197 20.1181 4.64997 19.7221 4.39797L20.2741 3.44397C20.2741 3.44397 20.2921 3.48597 20.2981 3.50397C20.3401 3.61797 20.3821 3.72597 20.4121 3.83997C20.6221 4.69797 20.6461 5.57997 20.6581 6.40797L20.6701 7.42797C20.6881 9.20397 20.7001 11.04 20.7481 12.846C20.7721 13.758 20.8201 14.682 20.8741 15.576C20.9041 16.116 20.9341 16.656 20.9581 17.196C20.9881 17.862 21.0061 18.552 21.0061 19.242C21.0061 19.686 20.8201 20.004 20.4061 20.268L20.4001 20.25Z"
                fill={color}
            />
            <path
                d="M8.63404 6.62998C7.39204 6.47398 6.10804 6.30598 4.84804 6.12598V6.20398C4.83004 6.31198 4.82404 6.41398 4.81204 6.50998L4.73404 7.13398C4.60204 8.18398 4.47004 9.23398 4.33204 10.284L4.26004 10.848C4.08004 12.222 3.89404 13.644 3.70204 15.036C3.57004 15.966 3.43804 16.902 3.30004 17.832C3.20404 18.51 3.10204 19.188 3.00604 19.872C2.94604 20.286 3.19204 20.616 3.59404 20.676C5.29804 20.934 6.79204 21.156 8.17204 21.354C10.416 21.678 12.81 22.02 15.222 22.35C15.618 22.404 16.032 22.41 16.434 22.422H16.626C16.8 22.434 16.836 22.284 16.848 22.2C16.884 21.912 16.926 21.618 16.968 21.33C17.064 20.658 17.16 19.968 17.232 19.284C17.31 18.552 17.376 17.802 17.436 17.076C17.472 16.674 17.508 16.266 17.544 15.864C17.586 15.438 17.628 15.006 17.67 14.58C17.724 14.004 17.784 13.434 17.832 12.858C17.898 12.09 17.964 11.322 18.024 10.554C18.066 10.068 18.102 9.57598 18.144 9.08998C18.162 8.84398 18.192 8.60398 18.216 8.37598L18.24 8.16598C15.06 7.45198 11.79 7.03798 8.62804 6.62998H8.63404ZM13.722 14.928C13.38 15.084 12.534 15.006 12.21 15.192C13.044 16.824 13.05 16.866 13.722 18.27C13.818 18.474 13.74 18.702 13.542 18.768C12.948 18.984 12.648 19.086 12.054 19.302C11.862 19.368 11.634 19.266 11.538 19.074C10.908 17.826 10.596 17.202 9.96604 15.954C9.96604 15.954 8.64604 16.932 8.13004 16.686C7.47604 16.38 7.58404 15.426 7.59004 14.898C7.60804 13.11 7.53004 11.214 7.83604 9.63598C7.87204 9.46198 8.05804 8.90398 8.19004 8.78998C8.43604 8.57998 8.85004 8.73598 9.15604 8.93398C10.434 9.75598 13.752 12.93 14.166 13.704C14.496 14.322 14.07 14.772 13.728 14.928H13.722Z"
                fill={color}
            />
        </svg>
    )
}
