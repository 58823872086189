import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const BoldCross = (props: IconProps) => {
    const { color = defaultGrey, size = 14, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...otherProps}>
            <g id="Close">
                <path
                    id="Vector"
                    d="M1.19732 9.36442C2.02503 8.68859 2.71605 8.01276 3.47541 7.35971C3.56614 7.2615 3.61652 7.13271 3.61652 6.99901C3.61652 6.86531 3.56614 6.73652 3.47541 6.63831C2.71605 5.98526 2.00225 5.30943 1.19732 4.63359C0.210154 3.78311 0.0430945 2.45423 1.25048 1.24684C2.45787 0.0394543 3.78675 0.214108 4.62964 1.20128C5.30547 2.02139 5.98131 2.72 6.63436 3.47937C6.6833 3.53083 6.7422 3.57181 6.80747 3.59981C6.87274 3.62781 6.94302 3.64225 7.01404 3.64225C7.08506 3.64225 7.15534 3.62781 7.22061 3.59981C7.28588 3.57181 7.34478 3.53083 7.39372 3.47937C8.04677 2.72 8.7226 2.0062 9.39843 1.20128C10.2413 0.214108 11.5702 0.0394543 12.7776 1.24684C13.985 2.45423 13.8179 3.78311 12.8308 4.63359C12.003 5.30943 11.312 5.98526 10.5527 6.63831C10.4617 6.73186 10.4109 6.85717 10.4109 6.98762C10.4109 7.11807 10.4617 7.24338 10.5527 7.33692C11.312 7.98998 12.0258 8.66581 12.8763 9.36442C13.8635 10.2149 14.0305 11.5438 12.8232 12.7512C11.6158 13.9586 10.2869 13.7915 9.444 12.7967C8.76816 11.9766 8.09233 11.278 7.43928 10.5187C7.3383 10.4185 7.20183 10.3623 7.0596 10.3623C6.91737 10.3623 6.7809 10.4185 6.67992 10.5187C6.02687 11.278 5.35104 11.9918 4.6752 12.7967C3.83231 13.7915 2.50343 13.9586 1.29604 12.7512C0.0886558 11.5438 0.210154 10.2149 1.19732 9.36442Z"
                    fill={color}
                />
            </g>
        </svg>
    )
}
