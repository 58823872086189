import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const House = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" {...otherProps}>
            <g clipPath="url(#clip0_9828_55382)">
                <path
                    d="M17.7312 12.0096L17.7408 12L11.808 12.0624L5.61122 12.1296C5.45282 12.1296 5.32322 12.2592 5.32322 12.4176C5.30402 13.7808 5.22242 14.8944 5.29442 16.1328C5.32802 16.7664 5.24162 17.4 5.30882 18.0288C5.36642 18.5952 5.30882 19.032 5.35202 19.6032C5.41442 20.3712 5.92802 20.544 6.37442 20.544C7.54562 20.544 8.59682 20.568 9.59522 20.592C9.87362 20.6016 10.0896 20.3664 10.0704 20.0928C10.0128 19.3344 9.99842 18.8784 9.99362 18.4512C9.99362 18.0336 9.95042 17.544 9.96482 17.1264C9.98402 16.7184 9.98882 16.4016 10.2816 16.2624C10.6272 16.0944 12.2448 16.2096 12.552 16.1808C13.0464 16.1328 13.1616 16.1904 13.5696 16.1904C13.776 16.1904 13.9392 16.3824 13.9344 16.608C13.9152 17.4384 13.92 18.8544 13.9536 20.1696C13.9584 20.4288 14.1744 20.6304 14.4336 20.6256H14.4384C15.0912 20.6016 15.744 20.5776 16.3968 20.52C16.872 20.4816 17.8128 20.5632 18.024 19.9824C18.0864 19.8048 18.0912 19.608 18.0912 19.416V15.6192C18.0912 14.5008 18.0912 13.3824 18.0336 12.2688C17.9184 12.1632 17.8512 12.1008 17.736 11.9904L17.7312 12.0096Z"
                    fill={color}
                />
                <path
                    d="M18.5856 9.74397C16.2048 7.31517 12.8112 3.38877 11.6784 3.35997C10.6368 3.33117 5.54877 8.96157 4.62717 9.98877C4.28637 10.3728 4.53597 11.2128 5.16957 11.2128H5.51997C9.72477 11.1648 13.5648 11.1216 17.7696 11.0784H18.048C18.3792 11.0736 18.7488 10.8144 18.7296 10.4304C18.7152 10.1904 18.7536 9.91197 18.5856 9.73917V9.74397Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_9828_55382">
                    <rect width={size} height={size} fill={color} />
                </clipPath>
            </defs>
        </svg>
    )
}
