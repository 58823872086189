import { Chevron } from 'assets/icons'
import { Button } from 'components/button/Button'
import { Spacer } from 'components/spacer'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'
import { H4, Text } from 'styles/typography'

type AccordionDetailsProps = {
    title: string
    description: ReactNode
    descriptionMax?: ReactNode
    button?: boolean
    open?: boolean
}

const AccordionDetails = ({
    title,
    description,
    descriptionMax,
    button = false,
    open = false,
}: AccordionDetailsProps) => {
    const { t } = useTranslation()
    const [showCompleteDescription, setShowCompleteDescription] = useState(false)

    return (
        <StyledDetails open={open}>
            <StyledSummary>
                <H4 color={colors.brown}>{title}</H4>
                <StyledArrowLeft color={colors.brown} size={14} />
            </StyledSummary>
            <StyledDescription>
                <Text>{description}</Text>
                {showCompleteDescription && <Text>{descriptionMax}</Text>}
                {button && !showCompleteDescription && (
                    <>
                        <Spacer s={SPACING.S} />
                        <StyledButtonContainer>
                            <Button
                                color={colors.brown}
                                text={t('tutorial.button')}
                                onClick={() => setShowCompleteDescription(true)}
                            />
                        </StyledButtonContainer>
                    </>
                )}
            </StyledDescription>
        </StyledDetails>
    )
}

export default AccordionDetails

const StyledArrowLeft = styled(Chevron)`
    pointer-events: none;
    position: absolute;
    top: ${SPACING.M};
    right: ${SPACING.M};
    background: ${colors.white};
    transform: rotate(180deg);

    svg {
        display: block;
    }
`

const StyledDetails = styled.details`
    padding: 0px ${SPACING.S};
    width: 100%;
    background: ${colors.white};
    border-bottom: ${BORDER.WIDTH.XS} solid ${colors.brownExtraLight};
    position: relative;
    box-sizing: border-box;

    &[open] {
        ${StyledArrowLeft} {
            transform: rotate(0deg);
        }
    }
`

const StyledSummary = styled.summary`
    list-style: none;
    padding: ${SPACING.S} 0;

    &:focus {
        outline: none;
    }

    &&::-webkit-details-marker {
        display: none;
    }
`

const StyledDescription = styled.div`
    cursor: default;
    padding: 0 0 ${SPACING.L} 0;
`

const StyledButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
