import styled from 'styled-components'
import { colors } from 'styles/colors'
import { H1 } from 'styles/typography'

export const StyledContainer = styled.div`
    background: ${colors.white};
    width: 100%;
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledTitleContainer = styled.div`
    margin: 20px 25px;
`

export const StyledH1 = styled(H1)`
    margin-bottom: 6px;
`
