import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { logout } from 'redux/user/userSlice'
import { OrderDTO, OrderLightDTO } from 'types/api'
import { slices } from 'utils/enums'
import { sortByDate } from 'utils/util'

export interface OrderState {
    pendingOrders: OrderLightDTO[]
    finishedOrders: OrderLightDTO[]
    ordersById: Record<string, OrderLightDTO>
    currentOrder?: OrderDTO
    amountErrors: number
}

const initialState: OrderState = {
    pendingOrders: [],
    finishedOrders: [],
    ordersById: {},
    amountErrors: 0,
}

export const orderSlice = createSlice({
    name: slices.ORDER,
    initialState,
    reducers: {
        setAmountErrors: (state, action: PayloadAction<number>) => {
            state.amountErrors = action.payload
        },
        setCurrentOrder: (state, action: PayloadAction<OrderDTO | undefined>) => {
            state.currentOrder = action.payload
        },
        setPendingOrders: (state, action: PayloadAction<OrderLightDTO[]>) => {
            state.pendingOrders = action.payload
            state.ordersById = _.keyBy(action.payload, 'id')
        },
        setFinishedOrders: (state, action: PayloadAction<OrderLightDTO[]>) => {
            state.finishedOrders = action.payload
            state.ordersById = _.keyBy(action.payload, 'id')
        },
        updatePendingOrder: (state, action: PayloadAction<OrderLightDTO>) => {
            const index = state.pendingOrders.findIndex((order) => order.id === action.payload.id)
            if (index >= 0) {
                state.pendingOrders[index] = action.payload
            } else {
                state.pendingOrders = [action.payload, ...state.pendingOrders].sort((a, b) =>
                    sortByDate(a.createdDate, b.createdDate),
                )
            }
            state.ordersById[action.payload.id] = action.payload

            if (state.currentOrder?.id === action.payload.id) {
                state.currentOrder = { ...state.currentOrder, ...action.payload }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logout, () => {
            return initialState
        })
    },
})

const { actions, reducer } = orderSlice
export const { setPendingOrders, setFinishedOrders, updatePendingOrder, setCurrentOrder, setAmountErrors } = actions
export { reducer as orderReducer }
