const base = '/topic'

export const wsTopics = {
    orders: base + '/orders/',
    ordersByRestaurant: (frNumber: string) => `${base}/orders/${frNumber}`,
    complaints: base + '/complaints/',
    complaintsByRestaurant: (frNumber: string) => `${base}/complaints/${frNumber}`,
    birthdayBookings: base + '/birthday-bookings/',
    birthdayBookingsByRestaurant: (frNumber: string) => `${base}/birthday-bookings/${frNumber}`,
    restaurants: base + '/restaurants/',
    restaurant: (frNumber: string) => `${base}/restaurants/${frNumber}`,
}
