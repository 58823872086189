import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

export const ShoppingCartIcon = (props: IconProps) => {
    const { color = defaultGrey, size = 21, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" {...otherProps}>
            <path
                d="M18.997 13.276a2.833 2.833 0 00-1.794-2.625l1.766-6.61a.944.944 0 00-.17-.822.944.944 0 00-.746-.33H5.116l-.311-1.19A.944.944 0 003.889 1H2v1.889h1.161l2.342 8.744a.944.944 0 00.945.699h9.717a.944.944 0 110 1.888H3.889a.944.944 0 100 1.889h1.114a2.833 2.833 0 105.326 0h2.228a2.833 2.833 0 105.118-.444 2.833 2.833 0 001.322-2.389zm-3.692-2.833H7.194L5.626 4.777h11.2l-1.52 5.666zm-7.64 7.555a.944.944 0 110-1.889.944.944 0 010 1.889zm7.555 0a.944.944 0 110-1.889.944.944 0 010 1.889z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    )
}
