import { Switch } from 'components/switch/Switch'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { H4, Text } from 'styles/typography'
import { SectionParameters } from 'types/parameters'

import { StyledContentParameters, StyledParamContainer, StyledTitleParameters, StyledToggleContainer } from './style'

interface Props {
    titleKey: string
    parameters: SectionParameters[]
}

export const ParametersSwitch = ({ titleKey, parameters }: Props) => {
    const { t } = useTranslation()
    if (parameters.length <= 1) {
        const { isActive, textKey, onChange, disabled } = parameters[0]
        return (
            <StyledParamContainer>
                <StyledTitleParameters>
                    <H4 color={colors.brown}>{t(titleKey)}</H4>
                    <StyledToggleContainer>
                        <H4 color={colors.brown}>{t(isActive ? 'component.button.on' : 'component.button.off')}</H4>
                        <Switch onChange={onChange} checked={isActive} disabled={disabled} />
                    </StyledToggleContainer>
                </StyledTitleParameters>
                <Text>{t(textKey)}</Text>
            </StyledParamContainer>
        )
    }
    return (
        <StyledParamContainer>
            <H4 color={colors.brown}>{t(titleKey)}</H4>
            {parameters.map((param) => {
                const { textKey, isActive, onChange, disabled } = param
                return (
                    <StyledContentParameters key={textKey}>
                        <Text>{t(textKey)}</Text>
                        <StyledToggleContainer>
                            <H4 color={colors.brown}>{t(isActive ? 'component.button.on' : 'component.button.off')}</H4>
                            <Switch onChange={onChange} checked={isActive} disabled={disabled} />
                        </StyledToggleContainer>
                    </StyledContentParameters>
                )
            })}
        </StyledParamContainer>
    )
}
