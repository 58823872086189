import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { SPACING } from 'styles/tokens'

export const TextArea = styled.textarea`
    margin-top: 30px;
    padding: 12px;
    border: 1px solid ${colors.greyLight};
    border-radius: 5px;
    width: 100%;
    height: 180px;
    box-sizing: border-box;
    font-family: ${fonts.flameSans};
    color: ${colors.defaultGrey};
    font-size: 14px;
`

export const Input = styled.input`
    font-size: 1rem;
    font-family: ${fonts.flameSans};
    border: 1px solid ${colors.greyLight};
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: ${SPACING.XXS} ${SPACING.XS};
    width: 100%;
`
