import { useFeatureToggleData } from 'redux/feature-toggle/featureToggle'
import { FEATURE_TOGGLE_CODE } from 'utils/enums'

export const useFeatureToggle = (features: FEATURE_TOGGLE_CODE[]) => {
    const { data: availableFeatures, isLoading } = useFeatureToggleData()
    let featureEnabled = false

    if (availableFeatures) {
        featureEnabled = features.every((feat) => availableFeatures.includes(feat))
    }

    return featureEnabled && !isLoading
}
