import { ArrowRightIcon, TableIcon, TerraceIcon } from 'assets/icons'
import { OutlinedTextField } from 'components/outlined-text-field/OutlinedTextField'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { Text } from 'styles/typography'
import * as Yup from 'yup'

interface KingTableRangeInputProps {
    terrace: boolean
    min: number
    max: number
    onChangeMin: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onChangeMax: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    overlapingError?: boolean
}

export const KingTableRangeInput = ({
    terrace = false,
    min,
    max,
    onChangeMax,
    onChangeMin,
    overlapingError,
}: KingTableRangeInputProps) => {
    const { t } = useTranslation()
    const [errors, setErrors] = useState('')

    const schema = Yup.object().shape({
        min: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .required(t('services.kingTable.management.table.requiredError'))
            .min(1, t('services.kingTable.management.table.minValueError'))
            .nullable(),
        max: Yup.number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .required(t('services.kingTable.management.table.requiredError'))
            .min(1, t('services.kingTable.management.table.minValueError'))
            .test('', t('services.kingTable.management.table.error'), (val) => {
                return Number.isNaN(min) ? true : val !== undefined && val >= min
            })
            .nullable(),
    })

    useEffect(() => {
        schema
            .validate({ min, max })
            .then(() => setErrors(''))
            .catch((error) => {
                setErrors(error.message)
            })
    }, [max, min, schema])

    return (
        <StyledMainContainer>
            <StyledLabel>
                {terrace ? <TerraceIcon color={colors.brown} /> : <TableIcon color={colors.brown} />}
                <Text color={colors.brown} font={fonts.flameSans}>
                    {terrace
                        ? t('services.kingTable.management.table.terrace')
                        : t('services.kingTable.management.table.room')}
                </Text>
            </StyledLabel>
            <StyledContainer>
                <StyledInputsContainer>
                    <StyledOutlinedTextField
                        label={t('services.kingTable.management.table.lowerBoundTableNumber')}
                        value={min}
                        width={'100%'}
                        type="number"
                        required
                        name={'min'}
                        onChange={(e) => onChangeMin(e)}
                        min={1}
                        variant={'outlined'}
                    />

                    <StyledIconContainer>
                        <ArrowRightIcon color={colors.brown} size={24} />
                    </StyledIconContainer>

                    <StyledOutlinedTextField
                        label={t('services.kingTable.management.table.upperBoundTableNumber')}
                        value={max}
                        width={'100%'}
                        type="number"
                        required
                        onChange={(e) => onChangeMax(e)}
                        min={1}
                        name={'max'}
                        variant={'outlined'}
                    />
                </StyledInputsContainer>

                {!!errors && <Text color={colors.red}>{errors}</Text>}

                {terrace && overlapingError && max > 0 && (
                    <Text color={colors.red}>{t('services.kingTable.management.table.overlapingRoomError')}</Text>
                )}
            </StyledContainer>
        </StyledMainContainer>
    )
}

const StyledMainContainer = styled.div`
    width: 100%;
    padding: ${SPACING.XXXXL} ${SPACING.L} ${SPACING.L} ${SPACING.L};
    display: flex;
    flex-direction: row;
    align-items: baseline;
    border: ${BORDER.WIDTH.XS} solid ${colors.greyLight};
    border-radius: ${BORDER.RADIUS.S};
    position: relative;
`

const StyledOutlinedTextField = styled(OutlinedTextField)`
    max-width: 250px;
`

const StyledLabel = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXS};
    padding: ${SPACING.XXS};
    position: absolute;
    top: -${SPACING.M};
    background-color: ${colors.white};
`

const StyledIconContainer = styled.div`
    margin: 0 ${SPACING.XXS};
`

const StyledInputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${SPACING.XXS};
`

const StyledContainer = styled.div`
    width: 100%;
`
