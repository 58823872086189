import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const PointsCircleIcon = (props: IconProps) => {
    const { color = colors.brown, size = 21, ...otherProps } = props

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" {...otherProps}>
            <path
                d="M10.1698 2C6.03234 2 2.45602 5.25095 2.50041 9.42342C2.51943 11.1481 3.22645 12.9623 4.41221 14.383C4.48197 14.4662 4.29174 17.7748 4.57391 17.9892C4.79267 18.1555 6.88837 16.3509 6.98348 16.3957C7.89341 16.8212 8.91114 17.0452 9.99862 16.994C14.1329 16.994 17.5 13.5991 17.5 9.42342C17.5 5.24775 14.3041 2 10.1698 2ZM10.005 15.1894C8.81603 15.1894 7.91244 14.975 7.07226 14.495C6.87569 14.383 5.90235 15.8389 5.7248 15.7045C5.54725 15.5701 5.96893 13.6951 5.8104 13.5415C4.75463 12.508 4.21564 11.1321 4.03176 9.50022C3.67349 6.34206 6.85349 3.66067 10.005 3.66067C13.1945 3.43349 15.8355 5.54852 15.7182 9.42662C15.6199 12.604 13.1564 15.1926 10.005 15.1926V15.1894Z"
                fill={color}
            />
            <path
                d="M6.42936 10.7205C5.7082 10.7205 5.12305 10.1354 5.12305 9.41422C5.12305 8.69306 5.7082 8.10791 6.42936 8.10791C7.15051 8.10791 7.73566 8.69306 7.73566 9.41422C7.73566 10.1354 7.15051 10.7205 6.42936 10.7205Z"
                fill={color}
            />
            <path
                d="M10.0983 10.7205C9.37714 10.7205 8.79199 10.1354 8.79199 9.41422C8.79199 8.69306 9.37714 8.10791 10.0983 8.10791C10.8195 8.10791 11.4046 8.69306 11.4046 9.41422C11.4046 10.1354 10.8195 10.7205 10.0983 10.7205Z"
                fill={color}
            />
            <path
                d="M13.6093 10.7205C12.8881 10.7205 12.303 10.1354 12.303 9.41422C12.303 8.69306 12.8881 8.10791 13.6093 8.10791C14.3304 8.10791 14.9156 8.69306 14.9156 9.41422C14.9156 10.1354 14.3304 10.7205 13.6093 10.7205Z"
                fill={color}
            />
        </svg>
    )
}
