import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { longFormatDateTime } from 'utils/util'

type CaptionLineProps = {
    date?: string
    text?: string
}

export const CaptionLine = ({ date, text }: CaptionLineProps) => (
    <StyledLineContainer>
        {date && <StyledMessage>{longFormatDateTime(new Date(date || 0))}</StyledMessage>}
        {text && <StyledMessage>{text}</StyledMessage>}
    </StyledLineContainer>
)

const StyledLineContainer = styled.div`
    display: flex;
    align-items: center;

    &::before,
    ::after {
        flex: 1;
        content: '';
        padding: 0.5px;
        background-color: #e2e0df;
        margin: 17px 16px;
    }
`

export const StyledMessage = styled.span`
    color: ${colors.blackLight};
    opacity: 0.5;
    font-family: ${fonts.flameSans};
    font-size: 12px;
`
