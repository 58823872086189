import { Exclamation } from 'assets/icons'
import { Button } from 'components/button/Button'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { BigText } from 'styles/typography'

interface BannerProps {
    onClick: () => void
}

export const Banner = ({ onClick }: BannerProps) => {
    const { t } = useTranslation()

    return (
        <StyledCard>
            <StyledContentContainer>
                <Exclamation size={48} color={colors.white} />
                <BigText color={colors.white} font={fonts.flame}>
                    {t('complaints.banner.description')}
                </BigText>
            </StyledContentContainer>
            <Button
                color={colors.white}
                text={t('complaints.banner.button')}
                textColor={colors.black}
                onClick={onClick}
            />
        </StyledCard>
    )
}

const StyledCard = styled.div`
    --botmind-button-padding: 60px;

    position: fixed;
    background-color: ${colors.red};
    border-radius: ${BORDER.RADIUS.L} ${BORDER.RADIUS.L} 0 0;
    width: 100%;
    bottom: 0;
    right: 0;
    display: flex;
    box-sizing: border-box;
    padding: ${SPACING.M} calc(${SPACING.XXL} + var(--botmind-button-padding)) ${SPACING.M} ${SPACING.XXL};
    justify-content: space-between;
    align-items: center;
    z-index: 2;
`

const StyledContentContainer = styled.div`
    display: flex;
    gap: ${SPACING.L};
    align-items: center;
`
