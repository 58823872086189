import { Car, Gift, KingTable, Motorbike, RedDot, Restaurant } from 'assets/icons'
import { ErrorBlock } from 'components/error-block/ErrorBlock'
import { ServiceBirthDay } from 'components/service-birthday/ServiceBirthday'
import { ServiceDelivery } from 'components/service-delivery/ServiceDelivery'
import { ServiceKingTable } from 'components/service-king-table/ServiceKingTable'
import { ServiceParkingDrive } from 'components/service-parking-drive/ServiceParkingDrive'
import { ServiceRestaurant } from 'components/service-restaurant/ServiceRestaurant'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchRestaurant } from 'redux/restaurant/thunks'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { NoPermissions } from 'screens/no-permissions/NoPermissions'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { RestaurantDTO, ServiceDTO } from 'types/api'
import { Permissions, ServiceCode } from 'types/api.enum'
import { hasPermission, isServiceActive } from 'utils/util'

import {
    StyledCardContainer,
    StyledH4,
    StyledNotifIconContainer,
    StyledServicesContainer,
    StyledTab,
    StyledTabsContainer,
    StyledTitleContainer,
} from './style'

export const Services = () => {
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const [restaurant, setRestaurant] = useState<RestaurantDTO>()
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [selectedTabService, setSelectedTabService] = useState<ServiceDTO>()
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    const displayNotificationSticker = useAppSelector((state) => state.birthdays.existsStandbyBirthday)

    useEffect(() => {
        let cancel = false
        dispatch(fetchRestaurant(restaurantFrNumber))
            .then((newRestaurant) => {
                if (cancel) return

                const serviceRestaurant: ServiceDTO = {
                    active: true,
                    code: ServiceCode.RESTAURANT,
                    id: t(`services.${ServiceCode.RESTAURANT}`),
                    name: t(`services.${ServiceCode.RESTAURANT}`),
                }
                const serviceNoDriveParking: ServiceDTO = {
                    active:
                        !isServiceActive(ServiceCode.CLICK_AND_COLLECT_DRIVE, newRestaurant) &&
                        !isServiceActive(ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING, newRestaurant),
                    code: ServiceCode.NO_DRIVE_PARKING,
                    id: t(`services.${ServiceCode.NO_DRIVE_PARKING}`),
                    name: t(`services.${ServiceCode.NO_DRIVE_PARKING}`),
                }

                setRestaurant(newRestaurant)
                newRestaurant.services.unshift(serviceRestaurant)
                newRestaurant.services.push(serviceNoDriveParking)
                setSelectedTabService(newRestaurant.services[0])
            })
            .catch((error) => setError(error))
        return () => {
            cancel = true
        }
    }, [dispatch, restaurantFrNumber, t])

    const setServices = (services: ServiceDTO[]) => {
        let finalServices = restaurant?.services
        if (restaurant && finalServices) {
            services.forEach((service) => {
                finalServices = finalServices?.map((finalService: ServiceDTO) =>
                    finalService.code === service.code ? { ...finalService, active: service.active } : finalService,
                )
            })

            setRestaurant({ ...restaurant, services: finalServices })
        }
    }

    // undefined if not found, boolean if found
    const getServiceActive = (serviceCode: ServiceCode) =>
        restaurant?.services.find((service) => service.code === serviceCode)?.active

    const serviceSelectedByCode = (service: ServiceDTO | undefined) => {
        if (
            !service ||
            !restaurant ||
            (service.code === ServiceCode.NO_DRIVE_PARKING && !service.active) ||
            (service.code === ServiceCode.CLICK_AND_COLLECT_DRIVE && !service.active) ||
            (service.code === ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING && !service.active) ||
            (service.code === ServiceCode.BIRTHDAY && !service.active)
        )
            return null

        switch (service.code) {
            case ServiceCode.RESTAURANT:
                return {
                    icon: <Restaurant color={colors.brown} size={25} />,
                    content: <ServiceRestaurant restaurant={restaurant} />,
                    title: t(`services.servicesCode.${service.code}`),
                    subTitle: t('services.card') + t(`services.servicesCode.${service.code}`),
                }
            /*case ServiceCode.CLICK_AND_COLLECT:
                return {
                    icon: <ShoppingBag color={colors.brown} size={25} />,
                    content: <ServiceClickCollect service={service} />,
                    title: t(`services.servicesCode.${service.code}`),
                    subTitle: t("services.card") + t(`services.servicesCode.${service.code}`),
                };*/
            case ServiceCode.CLICK_AND_COLLECT_DRIVE:
            case ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING:
            case ServiceCode.NO_DRIVE_PARKING:
                // BTR-502: avoid showing this menu more than one time
                if (
                    service.code !== ServiceCode.NO_DRIVE_PARKING &&
                    (getServiceActive(ServiceCode.NO_DRIVE_PARKING) ||
                        (service.code !== ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING &&
                            getServiceActive(ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING)))
                )
                    return null
                return {
                    icon: <Car color={colors.brown} size={25} />,
                    content: (
                        <ServiceParkingDrive
                            service={service}
                            setServices={setServices}
                            restaurant={restaurant}
                            setRestaurant={setRestaurant}
                            selectedTabService={setSelectedTabService}
                            disabled={!hasPermission(userPermissions, Permissions.MANAGE_SERVICE)}
                        />
                    ),
                    title: t(`services.servicesCode.${service.code}`),
                    subTitle: t('services.card') + t(`services.servicesCode.${service.code}`),
                }
            case ServiceCode.DELIVERY_IN_APP:
                return {
                    icon: <Motorbike color={colors.brown} size={25} />,
                    content: (
                        <ServiceDelivery
                            service={service}
                            setServices={setServices}
                            restaurant={restaurant}
                            setRestaurant={setRestaurant}
                            disabled={!hasPermission(userPermissions, Permissions.MANAGE_SERVICE)}
                        />
                    ),
                    title: t(`services.servicesCode.${service.code}`),
                    subTitle: t('services.card') + t(`services.servicesCode.${service.code}`),
                }
            case ServiceCode.TABLE_ORDER:
                return {
                    icon: <KingTable color={colors.brown} size={25} />,
                    content: (
                        <ServiceKingTable
                            service={service}
                            setServices={setServices}
                            disabled={!hasPermission(userPermissions, Permissions.MANAGE_SERVICE)}
                        />
                    ),
                    title: t(`services.servicesCode.${service.code}`),
                    subTitle: t('services.card') + t(`services.servicesCode.${service.code}`),
                }
            case ServiceCode.BIRTHDAY:
                return {
                    icon: (
                        <StyledNotifIconContainer>
                            <Gift color={colors.brown} size={25} />
                            {displayNotificationSticker && <RedDot />}
                        </StyledNotifIconContainer>
                    ),
                    content: (
                        <ServiceBirthDay
                            service={service}
                            disabled={!hasPermission(userPermissions, Permissions.MANAGE_SERVICE)}
                        />
                    ),
                    title: t(`services.servicesCode.${service.code}`),
                    subTitle: t('services.card') + t(`services.servicesCode.${service.code}`),
                }
            default:
                return null
        }
    }

    if (!hasPermission(userPermissions, Permissions.SHOW_SERVICE)) {
        return <NoPermissions />
    }

    if (error) {
        return <ErrorBlock errorCode={error.response?.status} />
    }

    return (
        <StyledServicesContainer>
            <StyledTabsContainer>
                {restaurant?.services.map((service) => {
                    const serviceProps = serviceSelectedByCode(service)
                    if (!serviceProps || !selectedTabService) {
                        return null
                    }
                    return (
                        <StyledTab
                            key={service.code}
                            selected={service.code === selectedTabService.code}
                            onClick={() => setSelectedTabService(service)}
                        >
                            {serviceProps.icon}
                            <StyledTitleContainer>
                                <StyledH4>{serviceProps.title}</StyledH4>
                                <Text>{serviceProps.subTitle}</Text>
                            </StyledTitleContainer>
                        </StyledTab>
                    )
                })}
            </StyledTabsContainer>
            <StyledCardContainer>{serviceSelectedByCode(selectedTabService)?.content}</StyledCardContainer>
        </StyledServicesContainer>
    )
}
