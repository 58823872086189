import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
    CookingEquipementsIcon,
    DiagramIcon,
    LinkIcon,
    NewsPaperIcon,
    PointsCircleIcon,
    SearchManIcon,
    TeamMemberIcon,
} from '../../../../../assets/icons'
import { ComplaintsTutorialModal } from '../../../../../components/complaints-tutorial-modal/ComplaintsTutorialModal'
import { useOpenModal } from '../../../../../hooks/useOpenModal'
import { colors } from '../../../../../styles/colors'
import { BORDER, SPACING } from '../../../../../styles/tokens'
import { H4 } from '../../../../../styles/typography'
import { SmallLinkCard } from './components/SmallCard'

export const UsefulLinksSection = () => {
    const { t } = useTranslation()
    const { isOpen: isOpenModalTutorial, toggleModal: setOpenModalTutorial } = useOpenModal()

    return (
        <StyledMainContainer>
            <StyledCard>
                <StyledTitleContainer>
                    <NewsPaperIcon size={24} />
                    <H4 color={colors.brown}>{t('page.home.dashboard.usefulLinks.usefulLink')}</H4>
                </StyledTitleContainer>
                <StyledContainer>
                    <StyledLinksContainer>
                        <StyledLinkContainer>
                            <SmallLinkCard
                                icon={<SearchManIcon size={30} />}
                                title={t('page.home.dashboard.usefulLinks.technicalSupport.title')}
                                description={t('page.home.dashboard.usefulLinks.technicalSupport.description')}
                            />
                            <StyledLinkIcon href={'https://bkfservices-ism.ivanticloud.com'} target={'blank'}>
                                <PointsCircleIcon size={24} />
                            </StyledLinkIcon>
                        </StyledLinkContainer>
                        <StyledLine />
                        <StyledLinkContainer>
                            <SmallLinkCard
                                icon={<TeamMemberIcon size={30} />}
                                title={t('page.home.dashboard.usefulLinks.customerService.title')}
                                description={t('page.home.dashboard.usefulLinks.customerService.description')}
                            />
                            <StyledLinkIcon href={'mailto:bonjour@burgerking.fr'} target={'blank'}>
                                <LinkIcon size={24} />
                            </StyledLinkIcon>
                        </StyledLinkContainer>
                    </StyledLinksContainer>
                    <StyledLinksContainer>
                        <StyledLinkContainer>
                            <SmallLinkCard
                                icon={<CookingEquipementsIcon size={30} />}
                                title={t('page.home.dashboard.usefulLinks.digitalCustomer.title')}
                                description={t('page.home.dashboard.usefulLinks.digitalCustomer.description')}
                            />
                            <StyledLinkIcon href={'mailto:digital.clients@bkfservices.fr'} target={'blank'}>
                                <PointsCircleIcon size={24} />
                            </StyledLinkIcon>
                        </StyledLinkContainer>
                        <StyledLine />
                        <StyledLinkContainer>
                            <SmallLinkCard
                                icon={<DiagramIcon size={30} />}
                                title={t('page.home.dashboard.usefulLinks.tutoBob.title')}
                                description={t('page.home.dashboard.usefulLinks.tutoBob.description')}
                            />
                            <StyledLinkIcon onClick={setOpenModalTutorial}>
                                <LinkIcon size={24} />
                            </StyledLinkIcon>
                        </StyledLinkContainer>
                    </StyledLinksContainer>
                </StyledContainer>
            </StyledCard>
            {isOpenModalTutorial && <ComplaintsTutorialModal handleClose={setOpenModalTutorial} />}
        </StyledMainContainer>
    )
}

const StyledLine = styled.div`
    background: ${colors.greyLight};
    height: ${BORDER.WIDTH.XS};
    width: 98%;
    display: flex;
    align-self: end;
`

const StyledLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.M};
`

const StyledLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${SPACING.XS} ${SPACING.XXS};
    width: 100%;
`

const StyledLinkIcon = styled.a`
    &:hover {
        cursor: pointer;
    }
`

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    background: ${colors.white};
    padding: ${SPACING.XXS} ${SPACING.XS};
    margin-bottom: ${SPACING.XXL};
    width: 100%;
    border-radius: ${BORDER.RADIUS.S};
    box-sizing: border-box;
    ${BORDER.SHADOW_BOX};
`

const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 ${SPACING.XXS} ${SPACING.XXXXL};
    box-sizing: border-box;
`

const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXS};
    padding: ${SPACING.XS};
    align-items: center;
`
