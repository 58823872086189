import { Divider } from '@mui/material'
import { CheckIcon, StrikethroughTerraceIcon, TerraceIcon } from 'assets/icons'
import { BackButton } from 'components/back-button/BackButton'
import { Button } from 'components/button/Button'
import { Dropdown } from 'components/dropdown/Dropdown'
import { FeatureToggle } from 'components/feature-toggle/FeatureToggle'
import { Modal } from 'components/modal/Modal'
import { Notif } from 'components/notif/Notif'
import { RadioButton } from 'components/radio-button/RadioButton'
import { useRangeInput } from 'hooks/useRangeInput'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { postQrCodeInformations } from 'redux/king-table/thunks'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { colors } from 'styles/colors'
import { H2 } from 'styles/typography'
import { RestaurantLightDTO } from 'types/api'
import { Permissions } from 'types/api.enum'
import { DropDownItem } from 'types/dropdown-item'
import { FEATURE_TOGGLE_CODE, LocalStorageKey, NotyfType } from 'utils/enums'
import { hasPermission } from 'utils/util'

import { ConfirmationModalContent } from './components/ConfirmationModalContent'
import { DownloadModalContent } from './components/DownloadModalContent'
import { KingTableFormSectionTitle } from './components/KingTableFormSectionTitle'
import { KingTableRangeInput } from './components/KingTableRangeInput'
import {
    StyledButtonContainer,
    StyledButtonsContainer,
    StyledContainer,
    StyledInputsContainer,
    StyledSectionContainer,
    StyledTitleContainer,
} from './style'

export const KingTableManagement = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [hasTerrace, setHasTerrace] = useState(false)
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const [openDownloadModal, setOpenDownloadModal] = useState(false)
    const [selectedRestaurant, setSelectedRestaurant] = useState('')
    const [loading, setLoading] = useState(false)
    const entryUri = localStorage.getItem(LocalStorageKey.KING_TABLE_ROUTING)
    const dispatch = useAppDispatch()

    const selectedFrNumberRestaurant: string = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const restaurants: RestaurantLightDTO[] = useAppSelector((state) => state.restaurant.restaurants)
    const data: Array<DropDownItem> = restaurants.map((restaurant) => ({
        value: restaurant,
        label: restaurant.name,
        labelOnSelect: t('burgerKing') + ' ' + restaurant.name,
    }))

    const initialRestaurantIndex = restaurants.map((el) => el.frNumber).indexOf(selectedFrNumberRestaurant)

    const {
        min: lowerBoundRoomTableNumber,
        max: upperBoundRoomTableNumber,
        handleMaxChange: handleRoomMaxChange,
        handleMinChange: handleRoomMinChange,
        handleInitialState: handleRoomInitialState,
    } = useRangeInput()

    const {
        min: lowerBoundTerraceTableNumber,
        max: upperBoundTerraceTableNumber,
        handleMaxChange: handleTerraceMaxChange,
        handleMinChange: handleTerraceMinChange,
        handleInitialState: handleTerraceInitialState,
    } = useRangeInput()

    const gapRoomNumber = upperBoundRoomTableNumber - lowerBoundRoomTableNumber
    const gapTerraceNumber = upperBoundTerraceTableNumber - lowerBoundTerraceTableNumber

    const handleInitialState = () => {
        handleRoomInitialState()
        handleTerraceInitialState()
        setHasTerrace(false)
    }

    const onChangeDropdown = (item: DropDownItem) => {
        setSelectedRestaurant(item.value.frNumber)
        handleInitialState()
    }

    const handleClose = () => {
        setOpenConfirmationModal(false)
        setOpenDownloadModal(false)
    }

    const handleFilename = (contentDispositionHeader: string) => {
        const fileName = contentDispositionHeader.split(';')[1].split('filename')[1].split('=')[1].trim()

        return fileName
    }

    const onSubmit = async (downloadFile: boolean) => {
        const payload = {
            frNumber: selectedRestaurant || selectedFrNumberRestaurant,
            lowerBoundRoomTableNumber: lowerBoundRoomTableNumber,
            upperBoundRoomTableNumber: upperBoundRoomTableNumber,
            ...(hasTerrace && { lowerBoundTerraceTableNumber: lowerBoundTerraceTableNumber }),
            ...(hasTerrace && { upperBoundTerraceTableNumber: upperBoundTerraceTableNumber }),
            downloadFile,
        }

        const response = await dispatch(postQrCodeInformations(payload))
        if (downloadFile) {
            const blob = new Blob([response.data], { type: response.headers['content-type'] })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            const fileName = handleFilename(response.headers['content-disposition'])
            link.download = fileName
            document.body.appendChild(link)
            link.click()
        }
    }

    const handleConfirmation = async () => {
        try {
            setLoading(true)
            await onSubmit(false)
            setOpenDownloadModal(true)
        } catch (error) {
            Notif({
                type: NotyfType.ERROR,
                text: t('error.qrCodeSendEmail'),
            })
            console.error('Error sending king table files by email', error)
        } finally {
            setLoading(false)
        }
    }

    const handleDownloadFile = async () => {
        try {
            setLoading(true)
            await onSubmit(true)
        } catch (error) {
            Notif({
                type: NotyfType.ERROR,
                text: t('error.qrCodeDownloadFiles'),
            })
            console.error('Error download king table files', error)
        } finally {
            setLoading(false)
        }
    }

    const inRange = (x: number, y: number, min: number, max: number) => {
        return (x >= min && x <= max) || (y >= min && y <= max)
    }

    const overlapingIntervalRoomError =
        inRange(
            lowerBoundTerraceTableNumber,
            upperBoundTerraceTableNumber,
            lowerBoundRoomTableNumber,
            upperBoundRoomTableNumber,
        ) && lowerBoundRoomTableNumber > 0

    const overlapingIntervalTerraceError =
        hasTerrace &&
        inRange(
            lowerBoundRoomTableNumber,
            upperBoundRoomTableNumber,
            lowerBoundTerraceTableNumber,
            upperBoundTerraceTableNumber,
        ) &&
        lowerBoundTerraceTableNumber > 0

    const disabled =
        gapRoomNumber < 0 ||
        (hasTerrace && gapTerraceNumber < 0) ||
        lowerBoundRoomTableNumber < 1 ||
        (hasTerrace && lowerBoundTerraceTableNumber < 1) ||
        (hasTerrace && (overlapingIntervalTerraceError || overlapingIntervalRoomError)) ||
        !lowerBoundRoomTableNumber ||
        !upperBoundRoomTableNumber ||
        !lowerBoundTerraceTableNumber ||
        !upperBoundTerraceTableNumber

    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))

    const hasKingTablePermission = hasPermission(userPermissions, Permissions.MANAGE_TABLE_ORDER_QR_CODES)

    if (entryUri) {
        localStorage.removeItem(LocalStorageKey.KING_TABLE_ROUTING)
    }

    if (!hasKingTablePermission) {
        return <Navigate to={t('page.home.route')} replace />
    }

    return (
        <FeatureToggle features={[FEATURE_TOGGLE_CODE.TABLE_ORDER_QR_CODE]}>
            <>
                <StyledContainer>
                    <div>
                        <StyledTitleContainer>
                            <BackButton onClick={() => navigate(t('page.services.route'))} />
                            <H2 color={colors.brown}>{t('services.kingTable.management.title')}</H2>
                        </StyledTitleContainer>

                        <div>
                            <StyledSectionContainer>
                                <KingTableFormSectionTitle
                                    title={t('services.kingTable.management.restaurant.title')}
                                    description={t('services.kingTable.management.restaurant.description')}
                                />
                                <Dropdown
                                    list={data}
                                    onChange={onChangeDropdown}
                                    placeholder={t('services.kingTable.management.restaurant.placeholder')}
                                    dialogVariant
                                    specialStyle={{ width: '550px' }}
                                    initIndex={initialRestaurantIndex}
                                />
                            </StyledSectionContainer>
                            <Divider variant="fullWidth" />
                        </div>

                        <div>
                            <StyledSectionContainer>
                                <KingTableFormSectionTitle
                                    title={t('services.kingTable.management.terrace.title')}
                                    description={t('services.kingTable.management.terrace.description')}
                                />
                                <StyledButtonsContainer>
                                    <RadioButton
                                        icon={<StrikethroughTerraceIcon color={colors.brown} />}
                                        label={t('services.kingTable.management.terrace.labelNoTerrace')}
                                        name={'noTerrace'}
                                        checked={!hasTerrace}
                                        onPress={() => setHasTerrace(false)}
                                    />
                                    <RadioButton
                                        icon={<TerraceIcon color={colors.brown} />}
                                        label={t('services.kingTable.management.terrace.labelTerrace')}
                                        name={'Terrace'}
                                        checked={hasTerrace}
                                        onPress={() => setHasTerrace(true)}
                                    />
                                </StyledButtonsContainer>
                            </StyledSectionContainer>
                            <Divider variant="fullWidth" />
                        </div>

                        <div>
                            <StyledSectionContainer>
                                <KingTableFormSectionTitle
                                    title={t('services.kingTable.management.table.title')}
                                    description={t('services.kingTable.management.table.description')}
                                />
                            </StyledSectionContainer>

                            <StyledInputsContainer>
                                <KingTableRangeInput
                                    terrace={false}
                                    min={lowerBoundRoomTableNumber}
                                    max={upperBoundRoomTableNumber}
                                    onChangeMin={handleRoomMinChange}
                                    onChangeMax={handleRoomMaxChange}
                                />
                                {hasTerrace && (
                                    <KingTableRangeInput
                                        terrace={true}
                                        min={lowerBoundTerraceTableNumber}
                                        max={upperBoundTerraceTableNumber}
                                        onChangeMin={handleTerraceMinChange}
                                        onChangeMax={handleTerraceMaxChange}
                                        overlapingError={overlapingIntervalRoomError || overlapingIntervalTerraceError}
                                    />
                                )}
                            </StyledInputsContainer>

                            <Divider variant="fullWidth" />
                        </div>
                    </div>

                    <StyledButtonContainer>
                        <Divider variant="fullWidth" />

                        <Button
                            color={colors.red}
                            text={t('services.kingTable.management.button')}
                            icon={<CheckIcon color={colors.white} />}
                            onClick={() => setOpenConfirmationModal(true)}
                            disabled={disabled}
                        />
                    </StyledButtonContainer>
                </StyledContainer>

                {openConfirmationModal && (
                    <Modal handleClose={handleClose} canCloseOnClickOutside={false}>
                        {!openDownloadModal ? (
                            <ConfirmationModalContent
                                loading={loading}
                                onConfirm={handleConfirmation}
                                onCancel={handleClose}
                                hasTerrace={hasTerrace}
                                lowerBoundRoomTableNumber={lowerBoundRoomTableNumber}
                                upperBoundRoomTableNumber={upperBoundRoomTableNumber}
                                lowerBoundTerraceTableNumber={lowerBoundTerraceTableNumber}
                                upperBoundTerraceTableNumber={upperBoundTerraceTableNumber}
                            />
                        ) : (
                            <DownloadModalContent onDownload={handleDownloadFile} loading={loading} />
                        )}
                    </Modal>
                )}
            </>
        </FeatureToggle>
    )
}
