import React from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'

import {
    BagClickAndCollectIcon,
    BikeDeliveryManIcon,
    DriveIcon,
    KingTable2Icon,
    MenuIcon,
} from '../../../../../../assets/icons'
import { ErrorBlock } from '../../../../../../components/error-block/ErrorBlock'
import { useAppSelector } from '../../../../../../hooks/useRedux'
import { useGetListStorage } from '../../../../../../redux/storage/storage'
import { colors } from '../../../../../../styles/colors'
import { BORDER, SPACING } from '../../../../../../styles/tokens'
import { H4 } from '../../../../../../styles/typography'
import { ServiceCode } from '../../../../../../types/api.enum'
import { ServiceCard } from './ServiceCard'

export const ServicesCardContainer = () => {
    const { t } = useTranslation()
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const { data: allServiceRestaurants, isLoading, isError, refetch } = useGetListStorage()

    if (isLoading) {
        return (
            <StyledServiceContainer>
                <StyledCard>
                    <ClipLoader size={70} color={colors.orange} loading={true} />
                </StyledCard>
            </StyledServiceContainer>
        )
    }

    if (isError) {
        return (
            <StyledServiceContainer>
                <StyledCard>
                    <ErrorBlock disableImage handleClick={refetch} labelButton={t('component.button.refreshContent')} />
                </StyledCard>
            </StyledServiceContainer>
        )
    }

    const currentRestaurant = allServiceRestaurants.restaurants[restaurantFrNumber]

    if (!currentRestaurant) {
        return (
            <StyledServiceContainer>
                <StyledCard>
                    <ErrorBlock disableImage handleClick={refetch} labelButton={t('component.button.refreshContent')} />
                </StyledCard>
            </StyledServiceContainer>
        )
    }

    const { services, openings } = currentRestaurant
    const today = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(new Date()).toUpperCase()

    const allServices = [
        {
            isActive: services?.includes(ServiceCode.CLICK_AND_COLLECT),
            icon: <BagClickAndCollectIcon color={colors.lightMayo} size={40} />,
            schedule: openings.cac?.[today],
            label: t('page.home.dashboard.services.serviceCC'),
        },
        {
            isActive: services?.includes(ServiceCode.DELIVERY_IN_APP),
            icon: <BikeDeliveryManIcon color={colors.lightMayo} size={40} />,
            schedule: openings.dlv?.[today],
            label: t('page.home.dashboard.services.serviceDelivery'),
        },
        {
            isActive: services?.includes(ServiceCode.TABLE_ORDER),
            icon: <KingTable2Icon color={colors.lightMayo} size={40} />,
            schedule: openings.room?.[today],
            label: t('page.home.dashboard.services.serviceKingTable'),
        },
        {
            isActive: services?.includes(ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING),
            icon: <DriveIcon color={colors.lightMayo} size={40} />,
            schedule: openings.cacParking?.[today],
            label: t('page.home.dashboard.services.serviceParking'),
        },
        {
            isActive: services?.includes(ServiceCode.CLICK_AND_COLLECT_DRIVE),
            icon: <DriveIcon color={colors.lightMayo} size={40} />,
            schedule: openings.cacDrive?.[today],
            label: t('page.home.dashboard.services.serviceDrive'),
        },
    ]

    return (
        <StyledServiceContainer>
            <StyledCard>
                <StyledTitleContainer>
                    <MenuIcon />
                    <H4 color={colors.brown}>{t('page.home.dashboard.services.restaurantServices')}</H4>
                </StyledTitleContainer>
                <StyledServiceCardsContainer>
                    {allServices.map((element, index) => (
                        <ServiceCard key={index} card={element} />
                    ))}
                </StyledServiceCardsContainer>
            </StyledCard>
        </StyledServiceContainer>
    )
}

const StyledServiceCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

const StyledCard = styled.div<{
    warning?: boolean
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${colors.white};
    padding: ${SPACING.XXS};
    border-radius: ${BORDER.RADIUS.S};
    box-sizing: border-box;
    ${BORDER.SHADOW_BOX};
    ${(props) => props.warning && `border: ${BORDER.WIDTH.S} solid ${colors.orange};`};
`

const StyledServiceContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 200%;
`

const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXS};
    padding: ${SPACING.S};
    align-items: center;
`
