import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'

import { BagClickAndCollectIcon, BikeDeliveryManIcon, KingTable } from '../../../../../assets/icons'
import { ErrorBlock } from '../../../../../components/error-block/ErrorBlock'
import { ShouldDisplay } from '../../../../../components/should-display/ShouldDisplay'
import { useAppSelector } from '../../../../../hooks/useRedux'
import {
    selectCcOrdersByStatusType,
    selectClickAndCollectOrders,
    selectDeliveryOrdersByStatusType,
    selectDriveAndParkingOrders,
    selectDriveAndParkingOrdersByStatusType,
    selectKingTableOrdersByStatusType,
    selectOrdersByPickUpType,
} from '../../../../../redux/order/selectors'
import { selectPermissionsByFrNumber } from '../../../../../redux/user/selectors'
import { colors } from '../../../../../styles/colors'
import { BORDER, SPACING } from '../../../../../styles/tokens'
import { Permissions, PickUpType } from '../../../../../types/api.enum'
import { StatusType } from '../../../../../utils/enums'
import { hasPermission } from '../../../../../utils/util'
import { TripleCards } from './components/TripleCards'

type OrdersSectionProps = {
    isOrderDataLoading: boolean
    isOrderDataError: boolean
    handleRefresh: () => void
}

export const OrdersSection = ({ isOrderDataLoading, isOrderDataError, handleRefresh }: OrdersSectionProps) => {
    const { t } = useTranslation()
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    const ordersPermissions = hasPermission(userPermissions, Permissions.SHOW_ORDER)

    const numberOfDeliveryReceivedOrders =
        useAppSelector((state) => selectOrdersByPickUpType(state.order.pendingOrders, PickUpType.DELIVERY_IN_APP))
            .length +
        useAppSelector((state) => selectOrdersByPickUpType(state.order.finishedOrders, PickUpType.DELIVERY_IN_APP))
            .length

    const receivedDeliveryOrder = {
        textKey: 'page.home.dashboard.orders.receivedOrders',
        number: numberOfDeliveryReceivedOrders,
        url: `${t('page.orders.route')}?pickUpType=${PickUpType.DELIVERY_IN_APP}&status=${StatusType.ALL}`,
    }
    const currentDeliveryOrder = {
        textKey: 'page.home.dashboard.orders.currentOrders',
        number: useAppSelector((state) =>
            selectDeliveryOrdersByStatusType(state.order.pendingOrders, StatusType.IN_PROGRESS),
        ).length,
        url: `${t('page.orders.route')}?pickUpType=${PickUpType.DELIVERY_IN_APP}&status=${StatusType.IN_PROGRESS}`,
    }
    const completeDeliveryOrder = {
        textKey: 'page.home.dashboard.orders.completeOrders',
        number: useAppSelector((state) =>
            selectDeliveryOrdersByStatusType(state.order.finishedOrders, StatusType.FINISHED),
        ).length,
        url: `${t('page.orders.route')}?status=${StatusType.FINISHED}&pickUpType=${PickUpType.DELIVERY_IN_APP}`,
    }

    const numberOfKingTableReceivedOrders =
        useAppSelector((state) => selectOrdersByPickUpType(state.order.pendingOrders, PickUpType.TABLE_ORDER)).length +
        useAppSelector((state) => selectOrdersByPickUpType(state.order.finishedOrders, PickUpType.TABLE_ORDER)).length

    const receivedKingTableOrder = {
        textKey: 'page.home.dashboard.orders.receivedOrders',
        number: numberOfKingTableReceivedOrders,
        url: `${t('page.orders.route')}?pickUpType=${PickUpType.TABLE_ORDER}&status=${StatusType.ALL}`,
    }
    const currentKingTableOrder = {
        textKey: 'page.home.dashboard.orders.currentOrders',
        number: useAppSelector((state) =>
            selectKingTableOrdersByStatusType(state.order.pendingOrders, StatusType.IN_PROGRESS),
        ),
        url: `${t('page.orders.route')}?pickUpType=${PickUpType.TABLE_ORDER}&status=${StatusType.IN_PROGRESS}`,
    }
    const completeKingTableOrder = {
        textKey: 'page.home.dashboard.orders.completeOrders',
        number: useAppSelector((state) =>
            selectKingTableOrdersByStatusType(state.order.finishedOrders, StatusType.FINISHED),
        ),
        url: `${t('page.orders.route')}?status=${StatusType.FINISHED}&pickUpType=${PickUpType.TABLE_ORDER}`,
    }

    const nbCcOrders =
        useAppSelector((state) => selectClickAndCollectOrders(state.order.pendingOrders)).length +
        useAppSelector((state) => selectClickAndCollectOrders(state.order.finishedOrders)).length +
        useAppSelector((state) => selectDriveAndParkingOrders(state.order.pendingOrders)).length +
        useAppSelector((state) => selectDriveAndParkingOrders(state.order.finishedOrders)).length

    const receivedCcOrder = {
        textKey: 'page.home.dashboard.orders.receivedOrders',
        number: nbCcOrders,
        url: `${t('page.orders.route')}?pickUpType=${PickUpType.ON_SITE}-${PickUpType.DRIVE}-${PickUpType.PICK_UP}-${
            PickUpType.TABLE_SERVICE
        }&status=${StatusType.ALL}`,
    }
    const currentCcOrder = {
        textKey: 'page.home.dashboard.orders.currentOrders',
        number: useAppSelector(
            (state) =>
                selectCcOrdersByStatusType(state.order.pendingOrders, StatusType.IN_PROGRESS) +
                selectDriveAndParkingOrdersByStatusType(state.order.pendingOrders, StatusType.IN_PROGRESS),
        ),
        url: `${t('page.orders.route')}?pickUpType=${PickUpType.ON_SITE}-${PickUpType.DRIVE}-${PickUpType.PICK_UP}-${
            PickUpType.TABLE_SERVICE
        }&status=${StatusType.IN_PROGRESS}`,
    }
    const completeCcOrder = {
        textKey: 'page.home.dashboard.orders.completeOrders',
        number: useAppSelector(
            (state) =>
                selectCcOrdersByStatusType(state.order.finishedOrders, StatusType.FINISHED) +
                selectDriveAndParkingOrdersByStatusType(state.order.finishedOrders, StatusType.FINISHED),
        ),
        url: `${t('page.orders.route')}?status=${StatusType.FINISHED}&pickUpType=${PickUpType.ON_SITE}-${
            PickUpType.DRIVE
        }-${PickUpType.PICK_UP}-${PickUpType.TABLE_SERVICE}`,
    }

    return (
        <ShouldDisplay condition={ordersPermissions}>
            <StyledMainContainer>
                <StyledCardContainer>
                    {!isOrderDataError && !isOrderDataLoading ? (
                        <TripleCards
                            title={t('page.home.dashboard.orders.deliveryOrders')}
                            icon={<BikeDeliveryManIcon size={24} />}
                            receivedOrders={receivedDeliveryOrder}
                            completeOrders={completeDeliveryOrder}
                            currentOrders={currentDeliveryOrder}
                        />
                    ) : (
                        <StyledErrorCard>
                            {isOrderDataError ? (
                                <ErrorBlock
                                    disableImage
                                    handleClick={handleRefresh}
                                    labelButton={t('component.button.refreshContent')}
                                />
                            ) : (
                                <ClipLoader size={70} color={colors.orange} loading={true} />
                            )}
                        </StyledErrorCard>
                    )}
                </StyledCardContainer>
                <StyledCardContainer>
                    {!isOrderDataError && !isOrderDataLoading ? (
                        <TripleCards
                            title={t('page.home.dashboard.orders.CCOrders')}
                            icon={<BagClickAndCollectIcon size={24} />}
                            receivedOrders={receivedCcOrder}
                            completeOrders={completeCcOrder}
                            currentOrders={currentCcOrder}
                        />
                    ) : (
                        <StyledErrorCard>
                            {isOrderDataError ? (
                                <ErrorBlock
                                    disableImage
                                    handleClick={handleRefresh}
                                    labelButton={t('component.button.refreshContent')}
                                />
                            ) : (
                                <ClipLoader size={70} color={colors.orange} loading={true} />
                            )}
                        </StyledErrorCard>
                    )}
                </StyledCardContainer>
                <StyledCardContainer>
                    {!isOrderDataError && !isOrderDataLoading ? (
                        <TripleCards
                            title={t('page.home.dashboard.orders.kingTableOrders')}
                            icon={<KingTable color={colors.brown} size={24} />}
                            receivedOrders={receivedKingTableOrder}
                            completeOrders={completeKingTableOrder}
                            currentOrders={currentKingTableOrder}
                        />
                    ) : (
                        <StyledErrorCard>
                            {isOrderDataError ? (
                                <ErrorBlock
                                    disableImage
                                    handleClick={handleRefresh}
                                    labelButton={t('component.button.refreshContent')}
                                />
                            ) : (
                                <ClipLoader size={70} color={colors.orange} loading={true} />
                            )}
                        </StyledErrorCard>
                    )}
                </StyledCardContainer>
            </StyledMainContainer>
        </ShouldDisplay>
    )
}

const StyledCardContainer = styled.div`
    width: 100%;
    padding: 0 ${SPACING.XXS};
    box-sizing: border-box;
`

const StyledErrorCard = styled.div`
    display: flex;
    width: 100%;
    background: ${colors.white};
    border-radius: ${BORDER.RADIUS.S};
    ${BORDER.SHADOW_BOX};
    min-height: 180px;
    align-items: center;
`

const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.M};
    width: 100%;
`
