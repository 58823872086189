import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const LinkIcon = (props: IconProps) => {
    const { color = colors.brown, size = 21, ...otherProps } = props

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" {...otherProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0001 9.01663C14.5398 9.01663 14.1667 9.38972 14.1667 9.84996V15.8333C14.1667 16.2935 13.7936 16.6666 13.3334 16.6666H4.16675C3.70651 16.6666 3.33341 16.2935 3.33341 15.8333V6.66663C3.33341 6.20639 3.70651 5.83329 4.16675 5.83329H10.1501C10.6103 5.83329 10.9834 5.4602 10.9834 4.99996C10.9834 4.53972 10.6103 4.16663 10.1501 4.16663H4.16675C2.78604 4.16663 1.66675 5.28591 1.66675 6.66663V15.8333C1.66675 17.214 2.78604 18.3333 4.16675 18.3333H13.3334C14.7141 18.3333 15.8334 17.214 15.8334 15.8333V9.84996C15.8334 9.38972 15.4603 9.01663 15.0001 9.01663ZM18.2667 2.18329C18.1822 1.97967 18.0204 1.81786 17.8167 1.73329C17.7166 1.69059 17.609 1.66794 17.5001 1.66663H12.5001C12.0398 1.66663 11.6667 2.03972 11.6667 2.49996C11.6667 2.9602 12.0398 3.33329 12.5001 3.33329H15.4917L6.90841 11.9083C6.75065 12.0648 6.66191 12.2778 6.66191 12.5C6.66191 12.7222 6.75065 12.9352 6.90841 13.0916C7.06489 13.2494 7.27788 13.3381 7.50008 13.3381C7.72228 13.3381 7.93527 13.2494 8.09175 13.0916L16.6667 4.50829V7.49996C16.6667 7.9602 17.0398 8.33329 17.5001 8.33329C17.9603 8.33329 18.3334 7.9602 18.3334 7.49996V2.49996C18.3321 2.39106 18.3094 2.28348 18.2667 2.18329Z"
                fill={color}
            />
        </svg>
    )
}
