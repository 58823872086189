import { Cross } from 'assets/icons'
import { useClickOutside } from 'hooks/useClickOutside'
import { RefObject, useRef } from 'react'
import { colors } from 'styles/colors'

import { StyledContainer, StyledContent, StyledIconContainer } from './style'

interface ModalProps {
    handleClose: () => void
    children: JSX.Element
    center?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    contentStyle?: Record<string, any>
    closeIconColor?: string
    canCloseOnClickOutside?: boolean
}

export const Modal = ({
    handleClose,
    children,
    center = true,
    contentStyle,
    closeIconColor = colors.defaultGrey,
    canCloseOnClickOutside = true,
}: ModalProps) => {
    const ref: RefObject<HTMLInputElement> = useRef(null)
    useClickOutside(ref, canCloseOnClickOutside ? handleClose : undefined)

    return (
        <StyledContainer>
            <StyledContent ref={ref} center={center} style={contentStyle}>
                <StyledIconContainer onClick={handleClose}>
                    <Cross color={closeIconColor} size={18} />
                </StyledIconContainer>
                {children}
            </StyledContent>
        </StyledContainer>
    )
}
