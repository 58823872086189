import { useState } from 'react'

type Return = {
    isOpen: boolean
    toggleModal: () => void
}

export const useOpenModal = (): Return => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleModal = () => {
        setIsOpen((x) => !x)
    }

    return { isOpen, toggleModal }
}
