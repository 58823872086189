import { createSelector } from '@reduxjs/toolkit'
import { RestaurantLightDTO } from 'types/api'
import { RootState } from 'types/redux'

export const selectCurrentRestaurant = createSelector(
    (state: RootState) => state.restaurant.restaurantsByFrNumber,
    (state: RootState) => state.restaurant.selectedFrNumber,
    (restaurantsByFrNumber, selectedFrNumber) => restaurantsByFrNumber[selectedFrNumber],
)

export const selectRestaurantsbyName = createSelector(
    (state: RootState) => state.restaurant.restaurants,
    (restaurants: RestaurantLightDTO[]) => {
        return restaurants.map((restaurant) => restaurant.name)
    },
)
