import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { defaultGrey } = colors

interface ExclamationProps extends IconProps {
    bubbleColor?: string
    pointColor?: string
}

export const Exclamation = (props: ExclamationProps) => {
    const { color = defaultGrey, bubbleColor = color, pointColor = colors.red, size = 21, ...otherProps } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            viewBox="0 0 120 120"
            {...otherProps}
        >
            <path
                fill={bubbleColor}
                d="M61.282 12c-24.967 0-46.547 19.506-46.28 44.54.115 10.348 4.382 21.234 11.537 29.758.42.5-.727 20.351.976 21.637 1.32.998 13.966-9.83 14.54-9.56 5.49 2.552 11.632 3.896 18.194 3.589 24.947 0 45.265-20.37 45.265-45.423C105.514 31.487 86.23 12 61.282 12zm-.995 79.136c-7.174 0-12.627-1.286-17.697-4.166-1.186-.672-7.06 8.064-8.13 7.257-1.072-.806 1.472-12.056.516-12.978-6.37-6.201-9.623-14.456-10.733-24.248-2.162-18.949 17.027-35.037 36.044-35.037C79.534 20.601 95.47 33.291 94.762 56.56c-.593 19.064-15.458 34.596-34.475 34.596v-.02z"
            ></path>
            <path
                fill={pointColor}
                d="M53.419 56.962c-.383-4.32-1.952-9.964-3.788-15.531a3.947 3.947 0 01-.191-1.19c0-3.744 4.572-6.029 10.847-6.029 6.275 0 10.848 2.362 10.848 6.029 0 .403-.058.787-.192 1.19-1.836 5.568-3.386 11.212-3.788 15.532-.459 4.972-2.812 6.68-6.6 6.68h-.593c-3.73 0-6.065-1.708-6.524-6.68h-.02zm-1.952 17.433c0-4.128 3.195-7.545 8.82-7.545s8.82 3.417 8.82 7.545c0 4.127-3.195 7.526-8.82 7.526s-8.82-3.399-8.82-7.526z"
            ></path>
        </svg>
    )
}
