import { Button } from 'components/button/Button'
import { Modal } from 'components/modal/Modal'
import { Notif } from 'components/notif/Notif'
import { NumberSelector } from 'components/number-selector/NumberSelector'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setPreparationTime } from 'redux/restaurant/thunks'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H2, H4 } from 'styles/typography'
import { NotyfType } from 'utils/enums'

import { StyledButtonContainer, StyledButtonsContainer, StyledContainer, StyledTimerContainer } from './style'

interface OrderPreparationTimeModalProps {
    preparationTime: number
    handleClose: () => void
}

export const OrderPreparationTimeModal = ({ preparationTime, handleClose }: OrderPreparationTimeModalProps) => {
    const [value, setValue] = useState(preparationTime)
    const [pending, setPending] = useState(false)
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const onSubmit = () => {
        setPending(true)
        dispatch(
            // @ts-ignore
            setPreparationTime({
                frNumber: restaurantFrNumber,
                preparationTime: value,
            }),
        )
            // @ts-ignore
            .then(() => {
                handleClose()
            })
            .catch(() => {
                Notif({ type: NotyfType.ERROR, text: t('error.serviceActivate') })
            })
            .finally(() => {
                setPending(false)
            })
    }

    return (
        <Modal handleClose={handleClose}>
            <StyledContainer>
                <H2 color={colors.brown}>{t('component.timeSelector.modal.title')}</H2>
                <H4 font={fonts.flameSans}>{t('component.timeSelector.modal.textAbove')}</H4>
                <StyledTimerContainer>
                    <NumberSelector
                        onChange={setValue}
                        initValue={preparationTime}
                        unit={t('time.minute', { count: value })}
                        min={0}
                        max={15}
                    />
                    <H4 font={fonts.flameSans}>{t('component.timeSelector.modal.textBelow', { number: value })}</H4>
                </StyledTimerContainer>
                <StyledButtonsContainer>
                    <StyledButtonContainer>
                        <Button
                            text={t('component.button.cancel')}
                            color={colors.black}
                            outlined
                            onClick={handleClose}
                        />
                    </StyledButtonContainer>
                    <StyledButtonContainer>
                        <Button
                            text={t('component.button.validate')}
                            color={colors.red}
                            onClick={onSubmit}
                            disabled={pending}
                        />
                    </StyledButtonContainer>
                </StyledButtonsContainer>
            </StyledContainer>
        </Modal>
    )
}
